import { IconType } from '@main/core-ui';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { CardProps } from '../Card';
import { StyledActionButtonArea, StyledCard } from './wrappers';

export interface CardWithActionProps {
  /** CTA button text */
  actionButtonText: string;
  /** An optional icon to use for the CTA button */
  actionButtonIcon?: IconType;
  /** CTA onClick callback */
  onActionButtonClick: () => void;
  /** Is the Action button disabled */
  actionButtonDisabled?: boolean;
  /** the html id of the card */
  id?: string;
}

/**
 * The card component with a hover overlay that shows an action button that will
 * fire a callback on click, i.e. quick adding data silos in the Integrations catalogue
 */
export const CardWithAction: React.FC<CardWithActionProps & CardProps> = ({
  actionButtonText,
  actionButtonIcon,
  onActionButtonClick,
  actionButtonDisabled = false,
  children,
  ...cardProps
}) => {
  const theme = useTheme();
  // Toggle visibility of the action button for keyboard accessibility
  const [visible, setVisible] = useState(false);

  return (
    <StyledCard
      {...cardProps}
      href={!actionButtonDisabled ? cardProps.href : undefined}
    >
      {children}
      {!actionButtonDisabled && (
        <StyledActionButtonArea
          className="action-button-area"
          style={visible ? { opacity: 1 } : {}}
        >
          <Button
            variant="secondary"
            size="sm"
            icon={actionButtonIcon}
            style={{ background: theme.colors.white }}
            onFocus={() => setVisible(true)}
            onBlur={() => setVisible(false)}
            onClick={(event) => {
              // call the onclick
              onActionButtonClick();
              // stop the click from propagating up to the containing link
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            {actionButtonText}
          </Button>
        </StyledActionButtonArea>
      )}
    </StyledCard>
  );
};
