import {
  ErrorAlert,
  FlexColumn,
  FlexRowCenterVertical,
  Icon,
  StyleUtils,
} from '@main/core-ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, commonButtonMessages } from '../Button';
import { TextArea } from '../Input';
import { editDetailsMessages } from './messages';

export const EditDetails: React.FC<{
  /** the details for the request */
  details: string | undefined;
  /** Whether saving changes to details */
  loading?: boolean;
  /** Error that has occurred */
  error?: Error;
  /** Callback on change */
  onChange: (newDetails: string) => void;
}> = ({ details, onChange, loading, error }) => {
  const { formatMessage } = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(details || '');

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
      {(isEditing || details) && (
        <TextArea
          value={editedValue}
          rows={isEditing ? 5 : undefined}
          disabled={!isEditing}
          onChange={(e) => setEditedValue(e.target.value)}
          id="detailsTextArea"
        />
      )}
      <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
        {isEditing ? (
          <>
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (editedValue !== details) {
                  onChange(editedValue);
                }
                setIsEditing(false);
              }}
              icon="save"
              id="detailsSaveButton"
            >
              {formatMessage(commonButtonMessages.save)}
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              id="detailsCancelButton"
              onClick={() => {
                setEditedValue(details || '');
                setIsEditing(false);
              }}
            >
              {formatMessage(commonButtonMessages.cancel)}
            </Button>
          </>
        ) : (
          <Button
            loading={loading}
            disabled={loading}
            id="detailsEditButton"
            variant="secondary"
            icon={<Icon type={details ? 'pencil' : 'plus'} />}
            onClick={() => setIsEditing(true)}
          >
            {formatMessage(
              details
                ? editDetailsMessages.editNotes
                : editDetailsMessages.addNotes,
            )}
          </Button>
        )}
      </FlexRowCenterVertical>
    </FlexColumn>
  );
};
