import { endpoints } from '@main/access-control-types';
import { Button, commonButtonMessages } from '@main/ad-core-components';
import { navMenuMessages } from '@main/admin-dashboard/src/routes/OrganizationRoute/NavMenu/messages';
import {
  Alert,
  buildUseQuery,
  ErrorAlert,
  FlexColumn,
  FlexRow,
  FlexRowCenterVertical,
  LoadingSpinner,
  StyleUtils,
} from '@main/core-ui';
import { TranscendProduct } from '@transcend-io/privacy-types';
import groupBy from 'lodash/groupBy';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { OnboardingItemsTable } from '../OnboardingItemsTable/OnboardingItemsTable';
import { CreateOnboardingCollectionModal } from './CreateOnboardingCollectionModal';
import { onboardingItemCollectionsMessages } from './messages';

const useActionItemCollections = buildUseQuery(
  endpoints.globalActionItemCollections,
);

interface OnboardingItemCollectionsProps {
  /** the current location */
  productLine: TranscendProduct;
}

/**
 * OnboardingItemCollection
 */
export const OnboardingItemCollections: React.FC<
  OnboardingItemCollectionsProps
> = ({ productLine }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { formatMessage } = useIntl();
  const { data, loading, error } = useActionItemCollections({
    variables: { filterBy: { productLine } },
  });
  const [showHidden, setShowHidden] = useState(false);
  const { true: hidden, false: visible } = useMemo(
    () => groupBy(data?.nodes ?? [], 'hidden'),
    [data?.nodes],
  );

  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
      <h2>{formatMessage(navMenuMessages.linkOnboardingItems)}</h2>
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
      {/* only show loading spinner if refreshing */}
      {loading && data && <LoadingSpinner />}
      {hidden && hidden.length > 0 && (
        <Alert variant="secondary" icon="eye" style={{ marginBottom: 0 }}>
          <FlexRowCenterVertical style={StyleUtils.Flex.SpaceBetween}>
            <span>
              {formatMessage(
                showHidden
                  ? onboardingItemCollectionsMessages.hiddenAreVisible
                  : onboardingItemCollectionsMessages.hiddenDescription,
                {
                  count: hidden.length,
                },
              )}
            </span>
            <Button
              variant="link"
              onClick={() => setShowHidden(!showHidden)}
              size="sm"
            >
              {formatMessage(
                showHidden
                  ? commonButtonMessages.hide
                  : commonButtonMessages.show,
              )}
            </Button>
          </FlexRowCenterVertical>
        </Alert>
      )}
      {(showHidden ? data?.nodes ?? [] : visible ?? []).map((collection) => (
        <OnboardingItemsTable
          collection={collection}
          key={collection.id}
          productLine={productLine}
        />
      ))}
      <FlexRow>
        <Button
          variant="secondary"
          icon="circle-add"
          onClick={() => setShowAddModal(true)}
        >
          {formatMessage(onboardingItemCollectionsMessages.createCollection)}
        </Button>
      </FlexRow>
      {showAddModal ? (
        <CreateOnboardingCollectionModal
          onHide={() => setShowAddModal(false)}
          productLine={productLine}
        />
      ) : null}
    </FlexColumn>
  );
};

/**
 * Function that returns a react component
 */
export function createOnboardingItemsComponent(
  productLine: TranscendProduct,
): React.FC {
  return () => <OnboardingItemCollections productLine={productLine} />;
}
