import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { uploadCsvMessages } from './messages';

/**
 * Button to remove uploaded requests from the CSV list
 */
export const DeleteSelectedRowsButton: React.FC<{
  /** rows to be deleted */
  selectedRows: string[];
  /** callback function for when row deletion occurs */
  onDelete: () => void;
}> = ({ selectedRows, onDelete }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="danger"
      icon="trash"
      size="sm"
      onClick={() => {
        onDelete();
      }}
    >
      {formatMessage(uploadCsvMessages.deleteRecords, {
        count: selectedRows.length,
      })}
    </Button>
  );
};
