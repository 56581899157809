import { Icon } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { Color } from '../Color';
import { isVerifiedMessages } from './messages';

/**
 * Props for IsVerified
 */
export interface IsVerifiedProps {
  /** Whether to show verified or not */
  isVerified: boolean;
  /** Verification count */
  count?: number;
  /** No text */
  iconOnly?: boolean;
  /** Style */
  style?: React.CSSProperties;
}

/**
 * Displays whether something is verified or not
 */
export const IsVerified = React.forwardRef<HTMLDivElement, IsVerifiedProps>(
  ({ isVerified, count, iconOnly, ...rest }, ref) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
      <Color ref={ref} color={isVerified ? 'success' : 'error'} {...rest}>
        {!iconOnly &&
          formatMessage(
            isVerified
              ? isVerifiedMessages.verified
              : isVerifiedMessages.unverified,
          )}
        {!!count && count > 1 && `(${count})`}
        <Icon
          type={isVerified ? 'checkmark' : 'circle-question'}
          color={isVerified ? theme.colors.success : theme.colors.danger}
        />
      </Color>
    );
  },
);
