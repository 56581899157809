/**
 * Intl messages can be of a few different types
 */
export enum MessageValidationType {
  /** This is just standard text, not HTML */
  Title = 'title',
  /** HTML text */
  Html = 'html',
  /** Message is a URL */
  Url = 'url',
}

/* eslint-disable max-len */
/**
 * The names of the internationalized messages that we expose to all customers for customization.
 * We can add additional react-intl message ids to this enum and those messages will show up
 * for customization on the admin dashboard.
 */
export enum PrivacyCenterCustomizableMessageName {
  HeroTitle = 'pc-types.src.privacyCenterCustomizable.heroTitle',
  HeroDescription = 'pc-types.src.privacyCenterCustomizable.heroDescription',
  TakeControlDescription = 'pc-types.src.privacyCenterCustomizable.takeControlDescription',
  MetaTitle = 'pc-types.src.privacyCenterCustomizable.metaTitle',
  MetaDescription = 'pc-types.src.privacyCenterCustomizable.metaDescription',
  AboutTranscendTitleHeader = 'privacy-center.containers.AboutTranscendPage.TranscendTitle.transcendTitle.header',
  AboutTranscendTitleDescription = 'privacy-center.containers.AboutTranscendPage.TranscendTitle.transcendTitle.description',
  AboutTranscendDescription = 'pc-types.src.privacyCenterCustomizable.aboutTranscendDescription',
  PrivacyCenterTitle = 'pc-types.src.privacyCenterCustomizable.privacyCenterTitle',
  TakeControl = 'privacy-center.containers.TakeControl.takeControl.takeControl',
  ViewRequests = 'privacy-center.containers.TakeControl.takeControl.viewPastRequests',
  TakeControlHeader = 'privacy-center.containers.TakeControl.takeControl.header',
  OurPolicies = 'pc-types.src.messages.POLICIES',
  TrackingTechnologies = 'pc-types.src.messages.TRACKING_TECHNOLOGIES',
  ManageYourPrivacy = 'pc-types.src.messages.MANAGE_YOUR_PRIVACY',
  SaleOfInfo = 'pc-types.src.messages.SALE_OF_INFO',
  CommunicationsPreferences = 'pc-types.src.messages.COMMUNICATIONS_PREFERENCES',
  ShortForm = 'pc-core-components.DataSubjectRequestForm.dataSubjectRequestForm.shortForm',
  ShortFormDescription = 'pc-core-components.DataSubjectRequestForm.dataSubjectRequestForm.shortFormDescription',
  ExpandedForm = 'pc-core-components.DataSubjectRequestForm.dataSubjectRequestForm.expandedForm',
  ExpandedFormDescription = 'pc-core-components.DataSubjectRequestForm.dataSubjectRequestForm.expandedFormDescription',
  SubmitDsrButton = 'pc-core-components.DataSubjectRequestForm.dataSubjectRequestForm.submit',
  RectificationDetails = 'datamap-types.src.rectification.details',
  ReturnToMainSite = 'privacy-center.containers.Footer.footer.mainSite',
  LoginEmailTitle = 'privacy-center.containers.VerifyLoginEmail.verifyLoginEmail.title',
  LoginEmailSuccess = 'privacy-center.containers.VerifyLoginEmail.verifyLoginEmail.success',
  LoginEmailLoading = 'privacy-center.containers.VerifyLoginEmail.verifyLoginEmail.loading',
  LoginEmailConfirmation = 'privacy-center.containers.VerifyLoginEmail.verifyLoginEmail.confirmation',
  VerifyEmailTitle = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.title',
  VerifyEmailSuccess = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.success',
  VerifyEmailLoading = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.loading',
  VerifyEmailError = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.error',
  EmailFooterConfirmation = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.emailFooterConfirmation',
  EmailFooterNegate = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.emailFooterNegate',
  VerifyEmailUndo = 'privacy-center.containers.VerifyRequestEmail.verifyRequestEmail.undo',
  VerifyPhoneUndo = 'privacy-center.containers.VerifyRequestPhone.verifyRequestPhone.undo',
  VerifyPhoneTitle = 'privacy-center.containers.VerifyRequestPhone.verifyRequestPhone.title',
  VerifyPhoneSuccess = 'privacy-center.containers.VerifyRequestPhone.verifyRequestPhone.success',
  VerifyPhoneLoading = 'privacy-center.containers.VerifyRequestPhone.verifyRequestPhone.loading',
  VerifyPhoneError = 'privacy-center.containers.VerifyRequestPhone.verifyRequestPhone.error',
  WrongPhoneTitle = 'privacy-center.containers.WrongRequestPhone.wrongRequestPhone.title',
  WrongPhoneHeader = 'privacy-center.containers.WrongRequestPhone.wrongRequestPhone.header',
  WrongPhoneLoading = 'privacy-center.containers.WrongRequestPhone.wrongRequestPhone.loading',
  WrongPhoneError = 'privacy-center.containers.WrongRequestPhone.wrongRequestPhone.error',
  WrongEmailTitle = 'privacy-center.containers.WrongRequestEmail.wrongRequestEmail.title',
  WrongEmailHeader = 'privacy-center.containers.WrongRequestEmail.wrongRequestEmail.header',
  WrongEmailLoading = 'privacy-center.containers.WrongRequestEmail.wrongRequestEmail.loading',
  WrongEmailError = 'privacy-center.containers.WrongRequestEmail.wrongRequestEmail.error',
  TrackingTechnologiesMisc = 'privacy-center.pages.TrackingTechnologies.trackingTechnologies.misc',
  TrackingTechnologiesInfo = 'privacy-center.pages.TrackingTechnologies.trackingTechnologies.intro',
  TrackingTechnologiesChangePreferences = 'privacy-center.pages.TrackingTechnologies.trackingTechnologies.changePreferences',
  TakeControlTitle = 'privacy-center.containers.TakeControl.takeControl.title',
  AnalyticsDescription = 'privacy-center.pages.TrackingTechnologies.purpose.Analytics',
  AdvertisingDescription = 'privacy-center.pages.TrackingTechnologies.purpose.Advertising',
  FunctionalDescription = 'privacy-center.pages.TrackingTechnologies.purpose.Functional',
  EssentialDescription = 'privacy-center.pages.TrackingTechnologies.purpose.Essential',
  SaleOfInfoDescription = 'privacy-center.pages.TrackingTechnologies.purpose.SaleOfInfo',
  UnknownDescription = 'privacy-center.pages.TrackingTechnologies.purpose.Unknown',
  DataSubjectModalTitle = 'privacy-center.containers.ActiveDataSubject.activeDataSubject.header',
  AboutTranscendWidget = 'privacy-center.components.AboutTranscendWidget.aboutTranscendWidget.aboutTranscend',
  AboutTranscendLimitationsHeader = 'privacy-center.containers.AboutTranscendPage.TranscendLimitations.transcendLimitations.header',
  AboutTranscendFooter = 'privacy-center.containers.Footer.footer.aboutTranscend',
  AboutTranscendHero = 'privacy-center.components.Hero.hero.aboutTranscend',
  ContactSupports = 'privacy-center.containers.Footer.footer.support',
  PolicyEffectiveDate = 'privacy-center.pages.Policies.policies.effectiveDate',
  EmailLoginDescriptionLabel = 'datamap-types.src.subject.emailLogin',
  EmailLoginModalTitle = 'privacy-center.containers.SessionManager.sessionManager.label',
  EmailLoginEmailLabel = 'privacy-center.containers.SessionManager.sessionManager.email',
  EmailLoginButtonLabel = 'privacy-center.containers.SessionManager.sessionManager.submit',
  EmailLoginFailedLabel = 'privacy-center.containers.SessionManager.sessionManager.loginFailed',
  EmailLoginSuccessLabel = 'privacy-center.containers.SessionManager.sessionManager.success',
  EmailLoginAdblockErrorLabel = 'privacy-center.containers.SessionManager.sessionManager.adblockError',
  RequestPreviewCanceledDescription = 'privacy-center.containers.RequestPreview.requestPreview.canceledDescription',
  RequestPreviewCanceledTitle = 'privacy-center.containers.RequestPreview.requestPreview.canceledTitle',
  RequestPreviewCompletedDescription = 'privacy-center.containers.RequestPreview.requestPreview.completedDescription',
  RequestPreviewCompletedTitle = 'privacy-center.containers.RequestPreview.requestPreview.completedTitle',
  RequestPreviewDelayedDescription = 'privacy-center.containers.RequestPreview.requestPreview.delayedDescription',
  RequestPreviewDelayedTitle = 'privacy-center.containers.RequestPreview.requestPreview.delayedTitle',
  RequestPreviewDownloadableDescription = 'privacy-center.containers.RequestPreview.requestPreview.downloadableDescription',
  RequestPreviewDownloadableTitle = 'privacy-center.containers.RequestPreview.requestPreview.downloadableTitle',
  RequestPreviewFailedVerificationDescription = 'privacy-center.containers.RequestPreview.requestPreview.failedVerificationDescription',
  RequestPreviewFailedVerificationTitle = 'privacy-center.containers.RequestPreview.requestPreview.failedVerificationTitle',
  RequestPreviewOnHoldDescription = 'privacy-center.containers.RequestPreview.requestPreview.onHoldDescription',
  RequestPreviewOnHoldTitle = 'privacy-center.containers.RequestPreview.requestPreview.onHoldTitle',
  RequestPreviewProcessingDescription = 'privacy-center.containers.RequestPreview.requestPreview.processingDescription',
  RequestPreviewProcessingTitle = 'privacy-center.containers.RequestPreview.requestPreview.processingTitle',
  RequestPreviewRequestMadeDescription = 'privacy-center.containers.RequestPreview.requestPreview.requestMadeDescription',
  RequestPreviewRequestMadeTitle = 'privacy-center.containers.RequestPreview.requestPreview.requestMadeTitle',
  RequestPreviewRevokedDescription = 'privacy-center.containers.RequestPreview.requestPreview.revokedDescription',
  RequestPreviewRevokedTitle = 'privacy-center.containers.RequestPreview.requestPreview.revokedTitle',
  RequestPreviewSecondaryCompletedDescription = 'privacy-center.containers.RequestPreview.requestPreview.secondaryCompletedDescription',
  RequestPreviewSecondaryCompletedTitle = 'privacy-center.containers.RequestPreview.requestPreview.secondaryCompletedTitle',
  RequestPreviewSecondaryDescription = 'privacy-center.containers.RequestPreview.requestPreview.secondaryDescription',
  RequestPreviewSecondaryTitle = 'privacy-center.containers.RequestPreview.requestPreview.secondaryTitle',
  SaleOfInfoPageIntro = 'privacy-center.pages.SaleOfInfo.saleOfInfo.intro',
  SaleOfInfoPageDescription = 'privacy-center.pages.SaleOfInfo.saleOfInfo.description',
  SaleOfInfoPageOptOut = 'privacy-center.pages.SaleOfInfo.saleOfInfo.optOut',
  SaleOfInfoPageOptOutSuccess = 'privacy-center.pages.SaleOfInfo.saleOfInfo.optOutSuccess',
  SaleOfInfoPageAllowSalesOptIn = 'privacy-center.pages.SaleOfInfo.saleOfInfo.allowSalesOptIn',
  SaleOfInfoPageEmailLabel = 'privacy-center.pages.SaleOfInfo.saleOfInfo.emailLabel',
  SaleOfInfoPageEmailSent = 'privacy-center.pages.SaleOfInfo.saleOfInfo.emailSent',
  SaleOfInfoPageGoBack = 'privacy-center.pages.SaleOfInfo.saleOfInfo.goBack',
  SaleOfInfoPageSalesOptOutLabel = 'privacy-center.pages.SaleOfInfo.saleOfInfo.salesOptOutLabel',
  SaleOfInfoPageSalesOptOutDescriptionLoggedIn = 'privacy-center.pages.SaleOfInfo.saleOfInfo.salesOptOutDescriptionLoggedIn',
  SaleOfInfoPageSalesOptOutDescriptionLoggedOut = 'privacy-center.pages.SaleOfInfo.saleOfInfo.salesOptOutDescriptionLoggedOut',
  SaleOfInfoPageOn = 'privacy-center.pages.SaleOfInfo.saleOfInfo.on',
  SaleOfInfoPageOff = 'privacy-center.pages.SaleOfInfo.saleOfInfo.off',
  SaleOfInfoSelectDataSubjectTypeRegionPlaceholder = 'privacy-center.pages.SaleOfInfo.components.SelectDataSubjectTypeAndRegion.selectDataSubjectTypeAndRegion.selectRegion',
  SaleOfInfoSelectDataSubjectTypeRegionDescription = 'privacy-center.pages.SaleOfInfo.components.SelectDataSubjectTypeAndRegion.selectDataSubjectTypeAndRegion.selectRegionDescription',
  ManageYourPrivacyGlobalSettingsGlobalPreferences = 'privacy-center.pages.ManageYourPrivacy.components.GlobalSettings.globalSettings.globalPreferences',
  ManageYourPrivacyGlobalSettingsLogIn = 'privacy-center.pages.ManageYourPrivacy.components.GlobalSettings.globalSettings.logIn',
  ManageYourPrivacyGlobalSettingsLoggedInMessage = 'privacy-center.pages.ManageYourPrivacy.components.GlobalSettings.globalSettings.loggedInMessage',
  ManageYourPrivacyGlobalSettingsLoggedOutMessage = 'privacy-center.pages.ManageYourPrivacy.components.GlobalSettings.globalSettings.loggedOutMessage',
  ManageYourPrivacyPrivacySettingsEmailOptOutDescription = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.emailOptOutDescription',
  ManageYourPrivacyPrivacySettingsEmailPlaceholder = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.emailPlaceholder',
  ManageYourPrivacyPrivacySettingsIntro = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.intro',
  ManageYourPrivacyPrivacySettingsLogIn = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.logIn',
  ManageYourPrivacyPrivacySettingsLoggedInMessage = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.loggedInMessage',
  ManageYourPrivacyPrivacySettingsLoggedOutMessage = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.loggedOutMessage',
  ManageYourPrivacyPrivacySettingsOptInAll = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.optInAll',
  ManageYourPrivacyPrivacySettingsOptOut = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.optOut',
  ManageYourPrivacyPrivacySettingsOptOutAll = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.optOutAll',
  ManageYourPrivacyPrivacySettingsOptOutSuccess = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.optOutSuccess',
  ManageYourPrivacyPrivacySettingsResetPreferences = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.resetPreferences',
  ManageYourPrivacyPrivacySettingsSuccess = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.success',
  ManageYourPrivacyPrivacySettingsUnauthenticatedUpdatesWarning = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.unauthenticatedUpdatesWarning',
  ManageYourPrivacyPrivacySettingsUnconfirmedChanges = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.unconfirmedChanges',
  ManageYourPrivacyPrivacySettingsUpdatePreferences = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.updatePreferences',
  ManageYourPrivacyPrivacySettingsNoRegimePurposes = 'privacy-center.pages.ManageYourPrivacy.components.PrivacySettings.privacySetting.noRegimePurposes',
  EmailFormInputEmailPlaceholder = 'privacy-center.components.EmailFormInput.emailFormInput.emailPlaceholder',
  SelectDataSubjectTypeDescription = 'privacy-center.components.SelectDataSubjectType.selectDataSubjectType.selectDataSubjectType',
  SelectPartitionDescription = 'privacy-center.components.SelectPartition.selectPartition.selectPartition',
  SelectPartitionDisabledDescription = 'privacy-center.components.SelectPartition.selectPartition.selectedPartition',
  CommunicationsPreferencesEmailTitle = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.emailTitle',
  CommunicationsPreferencesIntro = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.intro',
  CommunicationsPreferencesLogOut = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.logOut',
  CommunicationsPreferencesLoggedInMessage = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.loggedInMessage',
  CommunicationsPreferencesLoggedOutMessage = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.loggedOutMessage',
  CommunicationsPreferencesResetPreferences = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.resetPreferences',
  CommunicationsPreferencesSignIn = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.signIn',
  CommunicationsPreferencesGoToParentPrivacyCenter = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.goToParentPrivacyCenter',
  CommunicationsPreferencesSubscribeAll = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.subscribeAll',
  CommunicationsPreferencesSuccess = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.success',
  CommunicationsPreferencesUnconfirmedChanges = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.unconfirmedChanges',
  CommunicationsPreferencesUnsubscribeAll = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.unsubscribeAll',
  CommunicationsPreferencesUpdatePreferences = 'privacy-center.pages.CommunicationsPreferences.communicationsPreferences.updatePreferences',
  TakeControlConfirmSuccess = 'privacy-center.components.TakeControlConfirm.takeControlConfirm.success',
  TakeControlConfirmBack = 'privacy-center.components.TakeControlConfirm.takeControlConfirm.back',
  TakeControlConfirmDisabledWorkflow = 'privacy-center.components.TakeControlConfirm.takeControlConfirm.disabledWorkflow',
  TakeControlConfirmDisabledWorkflowForAccess = 'privacy-center.components.TakeControlConfirm.takeControlConfirm.disabledWorkflowForAccess',
}
/* eslint-enable max-len */
