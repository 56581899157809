import React from 'react';
import { useIntl } from 'react-intl';

import { humanFileSize } from './helpers';
import { byteSizeMessages } from './messages';

/**
 * Props
 */
export interface ByteSizeProps {
  /** Prop child  */
  children: number;
}

/**
 * Displays bytes in string representation
 */
export function ByteSize({ children }: ByteSizeProps): JSX.Element {
  const { formatMessage, formatNumber } = useIntl();
  return (
    <span>
      {children
        ? humanFileSize(children, formatNumber)
        : formatMessage(byteSizeMessages.none)}
    </span>
  );
}
