import { defineMessages } from '@transcend-io/internationalization';

/**
 * Review Changes and Publish button
 */
export const reviewChangesAndPublishMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.ReviewChangesAndPublishButton.reviewChangesAndPublish',
  {
    reviewChangesAndPublish: {
      defaultMessage: 'Review & Publish',
    },
    modalTitle: {
      defaultMessage: 'Review Changes & Publish Privacy Center',
    },
    setPrivacyCenterLive: {
      defaultMessage: 'Set Privacy Center Live',
    },
    noChangesDetected: {
      defaultMessage:
        'No changes detected in {module}. You may have to refresh the page to see recent changes.',
    },
    reviewChanges: {
      defaultMessage: 'Please review your changes below:',
    },
    reviewChangesAndPublishSuccess: {
      defaultMessage: 'Changes to your Privacy Center should be live soon!',
    },
    reviewChangesAndPublishError: {
      defaultMessage:
        'Encountered unexpected error when setting changes to your Privacy Center live.',
    },
    savedStateLoading: {
      defaultMessage: 'Computing the latest Draft Privacy Center state',
    },
    publishedStateLoading: {
      defaultMessage: 'Fetching the latest published Privacy Center state',
    },
    savingDraftLoading: {
      defaultMessage: 'Saving changes to the Draft Privacy Center',
    },
    preparingPublishDescription: {
      defaultMessage: 'Preparing to publish the Privacy Center:',
    },
  },
);

/**
 * Privacy Center Module Names
 */
export const privacyCenterModuleNames = defineMessages(
  'admin-dashboard.PrivacyCenter.ReviewChangesAndPublishButton.privacyCenterModuleNames',
  {
    privacyCenter: {
      defaultMessage: 'Privacy Center',
    },
    policies: {
      defaultMessage: 'Policies',
    },
    messages: {
      defaultMessage: 'Messages',
    },
    subjects: {
      defaultMessage: 'Subjects',
    },
    requestsProcessedStats: {
      defaultMessage: 'Requests Processed Stats',
    },
    purposes: {
      defaultMessage: 'Purposes',
    },
  },
);

/**
 * Privacy center module name
 */
export type PrivacyCenterModuleName = keyof typeof privacyCenterModuleNames;
