import { Button } from '@main/ad-core-components';
import { endpoints } from '@main/cm-types';
import { buildUseMutation, formatErrorMessage, message } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { approveAppButtonMessages } from './messages';

const useCreateConsentApplication = buildUseMutation(
  endpoints.createOrUpdateConsentApplications,
);

export interface ApproveAppButtonProps {
  /** Name of Application */
  name: string;
  /** Description of Application */
  description: string;
  /** Code Package ID */
  codePackageId: ID<'codePackage'>;
}

export const ApproveAppButton: React.FC<ApproveAppButtonProps> = ({
  name,
  description,
  codePackageId,
}) => {
  const { formatMessage } = useIntl();
  const [createConsentApplication, { loading, error }] =
    useCreateConsentApplication({
      refetchQueries: [
        'TriageApplicationsCodePackages',
        'ConsentApplicationsTable',
      ],
      awaitRefetchQueries: true,
    });

  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  return (
    <Button
      icon="thumbs-up"
      loading={loading}
      onClick={() => {
        createConsentApplication({
          variables: {
            input: {
              consentApplications: [
                {
                  name,
                  description,
                  codePackageId,
                },
              ],
            },
          },
        }).then((data) => {
          if (data) {
            message.success(
              formatMessage(approveAppButtonMessages.approveSuccess),
            );
          }
        });
      }}
    >
      {formatMessage(approveAppButtonMessages.buttonText)}
    </Button>
  );
};
