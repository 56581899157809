import './style.css';

import { Icon, message } from '@main/core-ui';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { AntUpload, AntUploadProps, UploadChangeParam } from './AntUpload';
import { uploadMessages } from './messages';
import { UploadWrapper } from './wrappers';

/**
 * Props for Upload
 */
export interface UploadProps extends AntUploadProps {
  /** The upload button content */
  children?: React.ReactNode;
  /** When true, use the draggable component */
  draggable?: boolean;
  /** The header message for the upload */
  headerMessage?: MessageDescriptor | null;
  /** Indicates if multiple files can be uploaded */
  multiple?: boolean;
  /** Dragger name */
  name?: string;
  /** Indicates if there should be no upload header */
  noHeader?: boolean;
  /** When true, don't show the info message hint */
  noHint?: boolean;
  /** Callback that should occurs on successful upload */
  onSuccess?: (info: UploadChangeParam, headers?: HttpRequestHeader) => void;
  /** Callback when an upload error occurs */
  onError?: (info: UploadChangeParam) => void;
  /** Indicates if only a single file can be uploaded */
  single?: boolean;
  /** Indicates if the uploaded content should be unique */
  unique?: boolean;
  /** Don't show success flash */
  skipSuccessFlash?: boolean;
  /** Style for the upload object */
  style?: React.CSSProperties;
  /** Optional padding for the upload wrapper */
  padding?: string;
}

/**
 * Upload a file to s3
 */
export function Upload({
  onSuccess,
  onError,
  headers,
  headerMessage = uploadMessages.header,
  noHint = false,
  skipSuccessFlash = false,
  children = <Icon type="plus" />,
  draggable = false,
  multiple = true,
  padding = '10px',
  ...draggerProps
}: UploadProps): JSX.Element {
  const [key, setKey] = useState(0);
  const { formatMessage } = useIntl();
  // @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
  const Component: any = draggable ? AntUpload.Dragger : AntUpload;
  return (
    <Component
      unique
      withCredentials
      name="file"
      key={key}
      headers={headers}
      multiple={multiple}
      onChange={(info: UploadChangeParam) => {
        const { status, name, response } = info.file;
        if (status === 'done') {
          if (!skipSuccessFlash) {
            message.success(
              formatMessage(uploadMessages.success, { file: name }),
            );
          }
          if (onSuccess) {
            onSuccess(info, headers);
          }
          setKey(key + 1);
        } else if (status === 'error') {
          message.error(
            (response && response.message) ||
              formatMessage(uploadMessages.error, { file: name }),
          );
          if (onError) {
            onError(info);
          }
        }
      }}
      {...draggerProps}
    >
      <UploadWrapper padding={padding}>
        {children && <span>{children}</span>}
        {headerMessage && (
          <p className="ant-upload-text">{formatMessage(headerMessage)}</p>
        )}
        {!noHint && (
          <p className="ant-upload-hint">
            {formatMessage(
              multiple ? uploadMessages.info : uploadMessages.singleInfo,
            )}
          </p>
        )}
      </UploadWrapper>
    </Component>
  );
}
