import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventDataCollection = mkAuditEventCodec(
  AuditEventCode.DataCollection,
  t.type({
    /** ID of the data collection */
    dataCollectionId: dbModelId('dataCollection'),
  }),
);

/** Override types. */
export type AuditEventDataCollection = t.TypeOf<
  typeof AuditEventDataCollection
>;
