import { ItemsWithOverflow } from '@main/core-ui';
import {
  dataCategoryTypeMessages,
  DataInventoryCategory,
} from '@main/datamap-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { UNSPECIFIED_INVENTORY_SUB_CATEGORY } from '../../DataPoints/components/InlineSelectCategory/constants';
import { DATA_CATEGORY_READ_ONLY } from './constants';
import { DataCategoryPill, DataCategoryPillProps } from './DataCategoryPill';

/**
 * Selected data category
 */
export type SelectedDataCategory = Pick<
  DataInventoryCategory,
  keyof typeof DATA_CATEGORY_READ_ONLY
> & {
  /** Variant to display for the DataCategoryPill */
  variant?: DataCategoryPillProps['variant'];
};

export const DataCategoriesReadOnly: React.FC<{
  /** List of data sub categories to render */
  dataSubCategories: SelectedDataCategory[];
  /** Number of items to display before putting the rest into an overflow container */
  overflowLimit?: number;
}> = ({ dataSubCategories, overflowLimit = 2 }) => {
  const { formatMessage } = useIntl();

  return (
    <ItemsWithOverflow
      items={
        dataSubCategories.length === 0
          ? [UNSPECIFIED_INVENTORY_SUB_CATEGORY]
          : dataSubCategories
      }
      limit={overflowLimit}
      renderElement={(category) => (
        <DataCategoryPill
          key={category.id || category.category}
          variant={category.variant || 'filled'}
          label={formatMessage(dataCategoryTypeMessages[category.category])}
          name={category.name}
          dataCategory={category.category}
        />
      )}
    />
  );
};
