import { FlexColumn, FlexRow, LoadingSpinner, StyleUtils } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import { Change } from 'diff';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useWebWorker } from '../hooks';
import { ChangeBlock } from './ChangeBlock';
import { jsonDiffMessages } from './messages';
import { DiffMessage, DiffResultMessage } from './types';

interface BaseJsonDiffProps {
  /** The current value */
  value: object | string;
  /** The pending value */
  otherValue: object | string;
}

export interface WorkerizedJsonDiffProps extends BaseJsonDiffProps {
  /** Pretty print? */
  isPretty?: boolean;
}

export const WorkerizedJsonDiff: React.FC<
  WorkerizedJsonDiffProps & {
    /** optional text to display above the left diff block */
    leftHeaderMessage?: DefinedMessage;
    /** optional text to display above the right diff block */
    rightHeaderMessage?: DefinedMessage;
  }
> = ({
  value,
  otherValue,
  isPretty = false,
  leftHeaderMessage,
  rightHeaderMessage,
}) => (
  <FlexRow style={StyleUtils.Flex.SpaceBetween}>
    <WorkerizedJsonDiffBlock
      value={value}
      otherValue={otherValue}
      isPretty={isPretty}
      isBefore
      headerMessage={leftHeaderMessage}
    />
    <WorkerizedJsonDiffBlock
      value={otherValue}
      otherValue={value}
      isPretty={isPretty}
      isBefore={false}
      headerMessage={rightHeaderMessage}
    />
  </FlexRow>
);

export interface JsonDiffProps extends BaseJsonDiffProps {
  /** Change results from a JsonDiff call */
  changes: Change[];
}

export const JsonDiff: React.FC<{
  /** Change results from a JsonDiff call */
  changes: Change[];
  /** Current module value */
  value: object | string;
  /** Pending module value */
  otherValue: object | string;
}> = ({ changes, value, otherValue }) => (
  <FlexRow style={StyleUtils.Flex.SpaceBetween}>
    <JsonDiffBlock changes={changes} otherValue={otherValue} isBefore />
    <JsonDiffBlock changes={changes} otherValue={value} isBefore={false} />
  </FlexRow>
);

export const WorkerizedJsonDiffBlock: React.FC<
  WorkerizedJsonDiffProps & {
    /** whether it's before or after */
    isBefore: boolean;
    /** optional text to display above the diff block */
    headerMessage?: DefinedMessage;
  }
> = ({
  value: rawValue,
  otherValue: rawOtherValue,
  isPretty = false,
  isBefore,
  headerMessage,
}) => {
  const { formatMessage } = useIntl();
  const diffMessage = useMemo(
    () => ({
      rawValue,
      rawOtherValue,
      isBefore,
      isPretty,
    }),
    [rawValue, rawOtherValue, isBefore, isPretty],
  );

  const { isLoading: diffLoading, result: diffResult } = useWebWorker<
    DiffMessage,
    DiffResultMessage
  >(
    () => new Worker(new URL('./diff-worker.ts', import.meta.url)),
    diffMessage,
  );

  return (
    <FlexColumn style={{ width: '100%', gap: StyleUtils.Spacing.sm }}>
      {headerMessage && <span>{formatMessage(headerMessage)}</span>}
      <JsonDiffBlock
        changes={diffResult?.changes ?? []}
        isBefore={isBefore}
        diffLoading={diffLoading}
        otherValue={diffResult?.otherValue ?? {}}
      />
    </FlexColumn>
  );
};

export const JsonDiffBlock: React.FC<{
  /** Change results from a JsonDiff call */
  changes: Change[];
  /** The pending value */
  otherValue: object | string;
  /** whether it's before or after */
  isBefore: boolean;
  /** Whether the diff is still loading */
  diffLoading?: boolean;
}> = ({ changes, otherValue, isBefore, diffLoading = false }) => {
  const { formatMessage } = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'left',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        minWidth: 0,
      }}
    >
      {diffLoading ? (
        <>
          <div style={{ marginBottom: StyleUtils.Spacing.md }}>
            {formatMessage(jsonDiffMessages.loading)}
          </div>
          <LoadingSpinner />
        </>
      ) : (
        <ChangeBlock
          changes={changes}
          isBefore={isBefore}
          otherValue={otherValue}
        />
      )}
    </div>
  );
};
