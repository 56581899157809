import { FlexColumnCenterHorizontal, Popover, StyleUtils } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { CSSProperties, useTheme } from 'styled-components';

import { Button } from '../Button';
import { FromNow } from '../FromNow';
import { dashboardTileMessages } from './messages';

export interface DashboardCachedAtProps {
  /** the current cachedAt date */
  cachedAt?: Date | string;
  /** is the data currently loading? */
  loading?: boolean;
  /**
   * handler for when the force refetch button is clicked. If undefined,
   * button isn't shown
   */
  onClickForceRefetch?: () => void;
  /** style overrides */
  style?: CSSProperties;
}

/**
 * DashboardCachedAt
 */
export const DashboardCachedAt: React.FC<DashboardCachedAtProps> = ({
  cachedAt,
  onClickForceRefetch,
  loading,
  style,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  if (loading && !cachedAt) {
    return null;
  }

  return (
    <Popover
      contents={
        <FlexColumnCenterHorizontal style={{ gap: StyleUtils.Spacing.sm }}>
          <div style={{ fontWeight: 600, color: theme.colors.transcendNavy }}>
            {formatMessage(dashboardTileMessages.lastUpdated)}
          </div>
          <FromNow date={new Date(cachedAt ?? Date.now())} />
          {onClickForceRefetch && (
            <Button
              onClick={onClickForceRefetch}
              loading={loading}
              size="sm"
              variant="link"
            >
              {formatMessage(dashboardTileMessages.refetchNow)}
            </Button>
          )}
        </FlexColumnCenterHorizontal>
      }
      trigger="click"
    >
      <Button size="sm" variant="link" iconOnly icon="refresh" style={style} />
    </Popover>
  );
};
