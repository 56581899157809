import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardPrivacyCenterPath,
  PRIVACY_CENTER_PATH_PREFIX,
} from '@main/pc-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import AssetFiles from './AssetFiles/Loadable';
import AuditTrail from './AuditTrail/Loadable';
import { AuthenticationMethodsLoadable } from './AuthenticationMethods/Loadable';
import ColorPalette from './ColorPalette/Loadable';
import GeneralSettings from './GeneralSettings/Loadable';
import GlobalMessages from './GlobalMessages/Loadable';
import { privacyCenterPageTitleMessages } from './messages';
import Policies from './Policies/Loadable';
import { RequestsProcessedStats } from './RequestsProcessedStats';

const privacyCenterBreadcrumb = {
  id: PRIVACY_CENTER_PATH_PREFIX,
  displayText: privacyCenterPageTitleMessages.privacyCenter,
  href: PRIVACY_CENTER_PATH_PREFIX,
};

export const privacyCenterRoutes: {
  [k in AdminDashboardPrivacyCenterPath]: ExtendableRoute;
} = {
  [AdminDashboardPrivacyCenterPath.GeneralSettings]: {
    path: AdminDashboardPrivacyCenterPath.GeneralSettings,
    title: privacyCenterPageTitleMessages.generalSettings,
    component: GeneralSettings,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.GeneralSettings,
        displayText: privacyCenterPageTitleMessages.generalSettings,
        href: AdminDashboardPrivacyCenterPath.GeneralSettings,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.AssetFiles]: {
    path: AdminDashboardPrivacyCenterPath.AssetFiles,
    title: privacyCenterPageTitleMessages.assetFiles,
    component: AssetFiles,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.AssetFiles,
        displayText: privacyCenterPageTitleMessages.assetFiles,
        href: AdminDashboardPrivacyCenterPath.AssetFiles,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.MessagesInternationalization]: {
    path: AdminDashboardPrivacyCenterPath.MessagesInternationalization,
    title: privacyCenterPageTitleMessages.messages,
    component: GlobalMessages,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.MessagesInternationalization,
        displayText: privacyCenterPageTitleMessages.messages,
        href: AdminDashboardPrivacyCenterPath.MessagesInternationalization,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.ColorsAndStyles]: {
    path: AdminDashboardPrivacyCenterPath.ColorsAndStyles,
    title: privacyCenterPageTitleMessages.colorsAndStyles,
    component: ColorPalette,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.ColorsAndStyles,
        displayText: privacyCenterPageTitleMessages.colorsAndStyles,
        href: AdminDashboardPrivacyCenterPath.ColorsAndStyles,
      },
    ],
  },

  [AdminDashboardPrivacyCenterPath.Policies]: {
    path: AdminDashboardPrivacyCenterPath.Policies,
    title: privacyCenterPageTitleMessages.policies,
    component: Policies,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.Policies,
        displayText: privacyCenterPageTitleMessages.policies,
        href: AdminDashboardPrivacyCenterPath.Policies,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.AuditTrail]: {
    path: AdminDashboardPrivacyCenterPath.AuditTrail,
    title: privacyCenterPageTitleMessages.privacyCenterAuditTrail,
    component: AuditTrail,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.AuditTrail,
        displayText: privacyCenterPageTitleMessages.privacyCenterAuditTrail,
        href: AdminDashboardPrivacyCenterPath.AuditTrail,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.RequestsProcessedStats]: {
    path: AdminDashboardPrivacyCenterPath.RequestsProcessedStats,
    title: privacyCenterPageTitleMessages.requestsProcessedStats,
    component: RequestsProcessedStats,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.RequestsProcessedStats,
        displayText: privacyCenterPageTitleMessages.requestsProcessedStats,
        href: AdminDashboardPrivacyCenterPath.RequestsProcessedStats,
      },
    ],
  },
  [AdminDashboardPrivacyCenterPath.ActionItems]: {
    path: AdminDashboardPrivacyCenterPath.ActionItems,
    title: navMenuMessages.linkActionItems,
    component: createOnboardingItemsComponent(TranscendProduct.PrivacyCenter),
    breadcrumbs: [privacyCenterBreadcrumb],
  },
  [AdminDashboardPrivacyCenterPath.AuthenticationMethods]: {
    path: AdminDashboardPrivacyCenterPath.AuthenticationMethods,
    title: privacyCenterPageTitleMessages.authenticationMethods,
    component: AuthenticationMethodsLoadable,
    breadcrumbs: [
      privacyCenterBreadcrumb,
      {
        id: AdminDashboardPrivacyCenterPath.AuthenticationMethods,
        displayText: privacyCenterPageTitleMessages.authenticationMethods,
        href: AdminDashboardPrivacyCenterPath.AuthenticationMethods,
      },
    ],
  },
};
