import partition from 'lodash/partition';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  AssessmentFormTemplateSource,
  AssessmentFormTemplateStatus,
} from '@transcend-io/privacy-types';

import { buildUseMutation, message } from '@main/core-ui';
import { AssessmentQuestionInput, endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import { createFrontendUuidv4 } from '@main/utils';

import { requestAssessmentFromInventoryMessages } from '../messages';
import { UseRequestAssessmentFromInventorySubmitOptions } from '../types';
import { getQuestionConfigForColumn } from './getQuestionConfigForColumn';

const useCreateAssessmentFormTemplate = buildUseMutation(
  endpoints.createAssessmentFormTemplate,
);
const useCreateAssessmentGroup = buildUseMutation(
  endpoints.createAssessmentGroup,
);
const useCreateAssessmentForms = buildUseMutation(
  endpoints.createAssessmentForms,
);
const useDeleteAssessmentFormTemplates = buildUseMutation(
  endpoints.deleteAssessmentFormTemplates,
);
const useDeleteAssessmentGroups = buildUseMutation(
  endpoints.deleteAssessmentGroups,
);

export const useRequestAssessmentFromInventorySubmit = ({
  getValues,
  nativeColumnHeaders,
  nativeColumnDescriptions,
  syncModel,
  selectedRows,
  setAssessmentGroupId,
  columnNameToSyncOptionMapping,
}: UseRequestAssessmentFromInventorySubmitOptions): {
  /** Whether the form is in a loading state */
  loading: boolean;
  /** Callback to submit the form */
  handleSubmit: () => Promise<void>;
} => {
  const { formatMessage } = useIntl();

  const [
    createAssessmentTemplate,
    { loading: createAssessmentTemplateLoading },
  ] = useCreateAssessmentFormTemplate();
  const [createAssessmentGroup, { loading: createAssessmentGroupLoading }] =
    useCreateAssessmentGroup();
  const [createAssessmentForms, { loading: createAssessmentFormsLoading }] =
    useCreateAssessmentForms();

  const [
    deleteAssessmentFormTemplates,
    { loading: deleteAssessmentFormTemplatesLoading },
  ] = useDeleteAssessmentFormTemplates();
  const [deleteAssessmentGroups, { loading: deleteAssessmentGroupsLoading }] =
    useDeleteAssessmentGroups();

  const handleSubmit = useCallback(async () => {
    const { title, columnIds, rowAssignees } = getValues();

    const questionsInput = columnIds.reduce<AssessmentQuestionInput[]>(
      (questionsInput, column) => {
        const questionConfig = getQuestionConfigForColumn(
          column,
          syncModel,
          columnNameToSyncOptionMapping,
        );
        const logger = console;
        if (questionConfig === null) {
          logger.error('Invalid config for column:', column);
          return questionsInput;
        }
        if (!nativeColumnHeaders[column]) {
          logger.error('Missing header message for column:', column);
          return questionsInput;
        }
        if (!nativeColumnDescriptions[column]) {
          logger.error(
            'Missing tooltip/description message for column:',
            column,
          );
          return questionsInput;
        }
        return [
          ...questionsInput,
          {
            ...questionConfig,
            title: formatMessage(nativeColumnHeaders[column]),
            description: formatMessage(nativeColumnDescriptions[column]),
            isRequired: true,
            referenceId: createFrontendUuidv4(),
            allowSelectOther: false,
          },
        ];
      },
      [],
    );

    try {
      if (questionsInput.length === 0) {
        message.error(
          formatMessage(
            requestAssessmentFromInventoryMessages.columnConfigError,
          ),
        );
        return;
      }

      const { data, errors } = await createAssessmentTemplate({
        variables: {
          input: {
            title,
            source: AssessmentFormTemplateSource.DataInventory,
            status: AssessmentFormTemplateStatus.Published,
            sections: [
              {
                title: formatMessage(
                  requestAssessmentFromInventoryMessages.sectionTitle,
                ),
                questions: questionsInput,
              },
            ],
          },
        },
      });

      if (errors) {
        message.error(errors[0].message);
        return;
      }

      if (data) {
        const { data: assessmentGroupData, errors: assessmentGroupErrors } =
          await createAssessmentGroup({
            variables: {
              input: {
                title,
                assessmentFormTemplateId: data.assessmentFormTemplate.id,
              },
            },
          });
        if (assessmentGroupErrors) {
          // delete the template if the group errors
          await deleteAssessmentFormTemplates({
            variables: {
              input: {
                ids: [data.assessmentFormTemplate.id],
              },
            },
          });
          message.error(assessmentGroupErrors[0].message);
          return;
        }

        if (assessmentGroupData) {
          const { data: assessmentFormsData, errors: assessmentFormsErrors } =
            await createAssessmentForms({
              variables: {
                input: {
                  assessmentForms: selectedRows.map(
                    ({ id, title: rowTitle }) => {
                      const [externalAssignees, internalAssignees] = partition(
                        rowAssignees.find(
                          (rowAssignees) => id === rowAssignees.rowId,
                        )?.assignees || [],
                        'isNew',
                      );

                      return {
                        title: `${title} - ${rowTitle}`,
                        titleIsInternal: false,
                        assessmentGroupId:
                          assessmentGroupData.assessmentGroup.id,
                        externalAssigneeEmails: externalAssignees.map(
                          ({ email }) => email,
                        ),
                        assigneeIds: internalAssignees.map(
                          ({ id }) => id as ID<'user'>,
                        ),
                        syncRows: [
                          {
                            syncModel,
                            syncRowIds: [id] as string[],
                          },
                        ],
                      };
                    },
                  ),
                },
              },
            });

          if (assessmentFormsErrors) {
            // delete the template and group if the form errors
            await deleteAssessmentFormTemplates({
              variables: {
                input: {
                  ids: [data.assessmentFormTemplate.id],
                },
              },
            });
            await deleteAssessmentGroups({
              variables: {
                input: {
                  ids: [assessmentGroupData.assessmentGroup.id],
                },
              },
            });
            message.error(assessmentFormsErrors[0].message);
            return;
          }

          if (assessmentFormsData) {
            // Once the form has been submitted successfully, set the assessment group id
            // so we can use it to link to the created assessment(s)
            setAssessmentGroupId(assessmentGroupData.assessmentGroup.id);
          }
        }
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [
    getValues,
    syncModel,
    columnNameToSyncOptionMapping,
    nativeColumnHeaders,
    nativeColumnDescriptions,
    formatMessage,
    createAssessmentTemplate,
    createAssessmentGroup,
    deleteAssessmentFormTemplates,
    createAssessmentForms,
    selectedRows,
    deleteAssessmentGroups,
    setAssessmentGroupId,
  ]);

  const loading =
    createAssessmentTemplateLoading ||
    createAssessmentGroupLoading ||
    createAssessmentFormsLoading ||
    deleteAssessmentFormTemplatesLoading ||
    deleteAssessmentGroupsLoading;

  return { handleSubmit, loading };
};
