import type { ActionItemCode } from '@transcend-io/privacy-types';

import { ActionItemConfigurationOverride } from './codecs';

/**
 * Check if an action item is enabled for an organization
 *
 * @param actionItemConfig - The action item configuration override
 * @param actionItemCode - The action item code
 * @param organizationId - The organization ID
 * @returns True if the action item is enabled for the organization
 */
export function actionItemIsEnabledForOrg(
  actionItemConfig: ActionItemConfigurationOverride,
  actionItemCode: ActionItemCode,
  organizationId: string,
): boolean {
  return (
    // If the action item code does not exist in config, then it should be enabled for all orgs
    actionItemConfig?.[actionItemCode] === undefined ||
    // If the action item code exists in config and is set to 'none', then it should not run for any org
    (actionItemConfig?.[actionItemCode] !== 'none' &&
      // If the action item code exists and it includes this org ID, then it is enabled for this org
      actionItemConfig?.[actionItemCode].includes(organizationId))
  );
}
