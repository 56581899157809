import { defineMessages } from '@transcend-io/internationalization';

export const datamapPageTitleMessages = defineMessages(
  'admin-dashboard.DataMap.datamapPageTitle',
  {
    dataMap: {
      defaultMessage: 'Data Mapping',
    },
    dashboardMenuLabel: {
      defaultMessage: 'Dashboard',
    },
    dashboardPageTitle: {
      defaultMessage: 'Data Mapping Dashboard',
    },
    siloDiscovery: {
      defaultMessage: 'Silo Discovery',
    },
    ropa: {
      defaultMessage: 'Record of Processing Activities',
    },
    processingActivities: {
      defaultMessage: 'Processing Activities',
    },
    businessEntities: {
      defaultMessage: 'Business Entities',
    },
    vendors: {
      defaultMessage: 'Vendors',
    },
    ropaSidebar: {
      defaultMessage: 'ROPA',
    },
    dataReports: {
      defaultMessage: 'Data Reports',
    },
    dataMapOnboarding: {
      defaultMessage: 'Data Mapping Onboarding',
    },
    dataInventory: {
      defaultMessage: 'Data Inventory',
    },
    dataLineage: {
      defaultMessage: 'Data Lineage',
    },
    contentClassification: {
      defaultMessage: 'Structured Discovery',
    },
    audit: {
      defaultMessage: 'Audit Trail',
    },
    unstructuredDiscovery: {
      defaultMessage: 'Unstructured Discovery',
    },
    unstructuredDiscoveryScanResult: {
      defaultMessage: 'Scan Result',
    },
  },
);

// Global messages used across the Data Mapping product
export const datamapMessages = defineMessages(
  'admin-dashboard.DataMap.datamap',
  {
    exportToCsv: {
      defaultMessage: 'Export To CSV',
    },
  },
);
