import { defineMessages } from '@transcend-io/internationalization';

import { IntlMessageType } from '@main/internationalization';

export const messageTypeMessages = defineMessages<IntlMessageType>(
  'admin-dashboard.PrivacyCenter.GlobalMessages.messageType',
  {
    [IntlMessageType.ActionSecondaryDescription]: {
      defaultMessage: 'Action Confirmation Description',
    },
    [IntlMessageType.ActionFormItem]: {
      defaultMessage: 'Action Form Item',
    },
    [IntlMessageType.ActionSubjectTitle]: {
      defaultMessage: 'Action Title',
    },
    [IntlMessageType.ActionDescription]: {
      defaultMessage: 'Action Description',
    },
    [IntlMessageType.DataSubjectLoginInstructions]: {
      defaultMessage: 'Data Subject Login Instructions',
    },
    [IntlMessageType.DataSubjectDisabledInstructions]: {
      defaultMessage: 'Data Subject Disabled Instructions',
    },
    [IntlMessageType.DataSubjectTitle]: {
      defaultMessage: 'Data Subject Title',
    },
    [IntlMessageType.IdentifierTitle]: {
      defaultMessage: 'Identifier Title',
    },
    [IntlMessageType.IdentifierDescription]: {
      defaultMessage: 'Identifier Description',
    },
    [IntlMessageType.DataSubjectRedirectUrl]: {
      defaultMessage: 'Data Subject Redirect URL',
    },
    [IntlMessageType.PolicyTitle]: {
      defaultMessage: 'Policy Title',
    },
    [IntlMessageType.PolicyContent]: {
      defaultMessage: 'Policy HTML Content',
    },
    [IntlMessageType.AssetFileAlt]: {
      defaultMessage: 'Asset Alt',
    },
    [IntlMessageType.TemplateSubject]: {
      defaultMessage: 'Email Template Subject',
    },
    [IntlMessageType.TemplateTemplate]: {
      defaultMessage: 'Email Template Body',
    },
    // TODO: https://transcend.height.app/T-42677 - remove this
    [IntlMessageType.DataCategoryTitle]: {
      defaultMessage: 'Data Category Title',
    },
    // TODO: https://transcend.height.app/T-42677 - remove this
    [IntlMessageType.DataApplicationTitle]: {
      defaultMessage: 'Data Application Title',
    },
    // TODO: https://transcend.height.app/T-42677 - remove this
    [IntlMessageType.DataApplicationDescription]: {
      defaultMessage: 'Data Application Description',
    },
    [IntlMessageType.DataCollectionTitle]: {
      defaultMessage: 'Data Collection Title',
    },
    // TODO: https://transcend.height.app/T-42677 - remove this
    [IntlMessageType.DataCollectionDescription]: {
      defaultMessage: 'Data Collection Description',
    },
    [IntlMessageType.DataPointTitle]: {
      defaultMessage: 'Datapoint Title',
    },
    [IntlMessageType.DataPointDescription]: {
      defaultMessage: 'Datapoint Description',
    },
    [IntlMessageType.LooseMessage]: {
      defaultMessage: 'Loose Message',
    },
    [IntlMessageType.PrivacyCenterHomeUrl]: {
      defaultMessage: 'Privacy Center Home URL',
    },
    [IntlMessageType.ConsentManagerMessage]: {
      defaultMessage: 'Consent Manager Message',
    },
    [IntlMessageType.ConsentPurposeTitle]: {
      defaultMessage: 'Consent Purpose Title',
    },
    [IntlMessageType.ConsentPurposeDescription]: {
      defaultMessage: 'Consent Purpose Description',
    },
    [IntlMessageType.ConsentManagerTcfMessage]: {
      defaultMessage: 'Consent Manager TCF UI Message',
    },
    [IntlMessageType.WorkflowTitle]: {
      defaultMessage: 'Workflow Title',
    },
    [IntlMessageType.WorkflowSubtitle]: {
      defaultMessage: 'Workflow Subtitle',
    },
    [IntlMessageType.WorkflowDescription]: {
      defaultMessage: 'Workflow Description',
    },
    [IntlMessageType.PreferenceTopicTitle]: {
      defaultMessage: 'Preference Topic Title',
    },
    [IntlMessageType.PreferenceTopicDescription]: {
      defaultMessage: 'Preference Topic Description',
    },
    [IntlMessageType.PreferenceOptionTitle]: {
      defaultMessage: 'Preference Option Title',
    },
  },
);

export const globalMessagesMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.GlobalMessages.globalMessages',
  {
    source: {
      defaultMessage: 'Default ({defaultLocale})',
    },
    defaultLocale: {
      defaultMessage: 'Default',
    },
    localeWithKey: {
      defaultMessage: '{name} ({key})',
    },
    header: {
      defaultMessage: 'Messages & Internationalization',
    },
    type: {
      defaultMessage: 'Type',
    },
    noExport: {
      defaultMessage: 'Attempted to export an empty list!',
    },
    import: {
      defaultMessage: 'Import from CSV',
    },
    missingId: {
      defaultMessage: 'CSV must have column named "id"',
    },
    missingIdValue: {
      defaultMessage: 'CSV column named "id" must have a value',
    },
    missingSource: {
      defaultMessage: 'CSV must have column named "Source"',
    },
    missingSourceValue: {
      defaultMessage: 'CSV column named "Source" must have a value',
    },
    missingAllValues: {
      defaultMessage: 'CSV row is missing all values',
    },
    invalidRow: {
      defaultMessage: 'Row {row}:\n{errors}',
    },
    recordCount: {
      defaultMessage: '{count} {count, plural, one {row} other {rows}}',
    },
    uploadSuccessful: {
      defaultMessage:
        'Messages imported successfully - please review and save your changes',
    },
    duplicateIds: {
      defaultMessage: 'Duplicate id found: \n"{duplicateId}" in rows: {rows}',
    },
  },
);
