import { OrganizationIcon } from '@main/ad-core-components';
import type { DiscoveredByDataSiloPreview } from '@main/datamap-types';
import React from 'react';

import { DATA_SILO_ICON } from './constants';
import { LogoIconList, LogoIconListProps } from './LogoIconList';
import { StyledOrganizationIcon } from './wrappers';

/**
 * Data needed to render this component
 */
export type DataSiloIconData = Pick<
  DiscoveredByDataSiloPreview,
  keyof typeof DATA_SILO_ICON
>;

export const DataSiloIconList: React.FC<
  Omit<
    LogoIconListProps<DataSiloIconData>,
    'renderElement' | 'renderOverflowElement'
  >
> = (props) => (
  <LogoIconList
    renderElement={({ title, catalog: { logoSquare } }, index) => (
      <StyledOrganizationIcon
        organizationIconSrc={logoSquare}
        organizationName={title}
        size={24}
        style={index === 0 ? { marginLeft: 0 } : undefined}
      />
    )}
    renderOverflowElement={({ title, catalog: { logoSquare } }) => (
      <OrganizationIcon
        organizationIconSrc={logoSquare}
        organizationName={title}
        size={20}
      />
    )}
    {...props}
  />
);
