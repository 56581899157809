import { generatePath } from 'react-router-dom';

import {
  AdminDashboardInfrastructurePath,
  AdminDashboardPrivateMiscPath,
  AdminDashboardRedirectPath,
} from '@main/access-control-types';
import {
  PRIVACY_CENTER_REDIRECT_MAP,
  SETTINGS_REDIRECT_MAP,
} from '@main/admin-dashboard/src/routes/constants';
import { RedirectRouteProps } from '@main/admin-dashboard/src/routes/RedirectRoute';
import { AdminDashboardPreferenceStorePath } from '@main/cm-types';
import { AdminDashboardDataMapPath } from '@main/datamap-types';
import { AdminDashboardPrivacyRequestPath } from '@main/dsr-types';
import { AdminDashboardPrivacyCenterPath } from '@main/pc-types';

// ALL ADDITIONS SHOULD HAVE THE DATE ADDED HERE
// this makes it much easier to know when we can prune these
export const miscRedirectRoutes: {
  [k in AdminDashboardRedirectPath]: RedirectRouteProps & {
    /** Path to redirect from */
    path: string;
  };
} = {
  // Adv1 redirects - TODO: https://transcend.height.app/T-9644 - track these
  // 12/17/21
  [AdminDashboardRedirectPath.PrivacyCenter]: {
    path: AdminDashboardRedirectPath.PrivacyCenter,
    to: {
      pathname: AdminDashboardPrivacyCenterPath.GeneralSettings,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.PrivacyRequestsPrivacyCenter]: {
    path: AdminDashboardRedirectPath.PrivacyCenter,
    to: (props, location) => ({
      pathname:
        PRIVACY_CENTER_REDIRECT_MAP[location.hash] ??
        AdminDashboardPrivacyCenterPath.GeneralSettings,
      hash: undefined, // remove hash
    }),
  },
  // 12/17/21
  [AdminDashboardRedirectPath.Requests]: {
    path: AdminDashboardRedirectPath.Requests,
    to: {
      pathname: AdminDashboardPrivacyRequestPath.IncomingRequests,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.RequestBase]: {
    path: AdminDashboardRedirectPath.RequestBase,
    to: {
      pathname: AdminDashboardPrivacyRequestPath.IncomingRequests,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.Request]: {
    path: AdminDashboardRedirectPath.Request,
    to: (params) => ({
      pathname: generatePath(AdminDashboardRedirectPath.Request, {
        requestId: params.requestId,
      }),
    }),
  },
  // 12/17/21
  [AdminDashboardRedirectPath.RequestDataSilo]: {
    path: AdminDashboardRedirectPath.RequestDataSilo,
    to: (params) => ({
      pathname: generatePath(AdminDashboardRedirectPath.RequestDataSilo, {
        requestId: params.requestId,
        dataSiloId: params.dataSiloId,
      }),
    }),
  },
  // 12/17/21
  [AdminDashboardRedirectPath.DataMap]: {
    path: AdminDashboardRedirectPath.DataMap,
    to: {
      pathname: AdminDashboardRedirectPath.ConnectedServices,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.DataMapSilos]: {
    path: AdminDashboardRedirectPath.DataMapSilos,
    to: {
      pathname: AdminDashboardRedirectPath.ConnectNewService,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.DataMapSiloConfig]: {
    path: AdminDashboardRedirectPath.DataMapSiloConfig,
    to: (params) => ({
      pathname: generatePath(AdminDashboardRedirectPath.ServiceConfiguration, {
        dataSiloId: params.dataSiloId,
      }),
    }),
  },
  // 12/17/21
  [AdminDashboardRedirectPath.Settings]: {
    path: AdminDashboardRedirectPath.Settings,
    to: (props, location) => ({
      pathname: SETTINGS_REDIRECT_MAP[location.hash] ?? '/',
      hash: undefined, // remove hash
    }),
  },
  // Permanent (Default page in the Admin Dashboard)
  // 12/17/21
  [AdminDashboardRedirectPath.Base]: {
    path: AdminDashboardRedirectPath.Base,
    to: {
      pathname: AdminDashboardPrivateMiscPath.ActionItems,
    },
  },
  // 12/17/21
  [AdminDashboardRedirectPath.WellKnownChangePassword]: {
    path: AdminDashboardRedirectPath.WellKnownChangePassword,
    to: {
      pathname: AdminDashboardPrivateMiscPath.Profile,
    },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.ConnectedServices]: {
    path: AdminDashboardRedirectPath.ConnectedServices,
    to: { pathname: AdminDashboardInfrastructurePath.Integrations },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.ServiceConfiguration]: {
    path: AdminDashboardRedirectPath.ServiceConfiguration,
    to: { pathname: AdminDashboardInfrastructurePath.ServiceConfiguration },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloActivities]: {
    path: AdminDashboardRedirectPath.DataSiloActivities,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloActivities },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloConfiguration]: {
    path: AdminDashboardRedirectPath.DataSiloConfiguration,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloConnection },
  },
  // 12/7/23
  [AdminDashboardRedirectPath.DataSiloConfiguration2023]: {
    path: AdminDashboardRedirectPath.DataSiloConnection,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloConnection },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloConnection]: {
    path: AdminDashboardRedirectPath.DataSiloConnection,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloConnection },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloEmailAutomation]: {
    path: AdminDashboardRedirectPath.DataSiloDsrAutomation,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloDsrAutomation },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloManageDatapoints]: {
    path: AdminDashboardRedirectPath.DataSiloManageDatapoints,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloManageDatapoints },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloProcessedRequests]: {
    path: AdminDashboardRedirectPath.DataSiloProcessedRequests,
    to: {
      pathname: AdminDashboardInfrastructurePath.DataSiloProcessedRequests,
    },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloLookups]: {
    path: AdminDashboardRedirectPath.DataSiloLookups,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloLookups },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.DataSiloAuditTrail]: {
    path: AdminDashboardRedirectPath.DataSiloAuditTrail,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloAuditTrail },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.ConnectNewService]: {
    path: AdminDashboardRedirectPath.ConnectNewService,
    to: { pathname: AdminDashboardInfrastructurePath.ConnectNewService },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.OAuthSuccess]: {
    path: AdminDashboardRedirectPath.OAuthSuccess,
    to: { pathname: AdminDashboardInfrastructurePath.OAuthSuccess },
  },
  // 5/18/22
  [AdminDashboardRedirectPath.OAuthFailure]: {
    path: AdminDashboardRedirectPath.OAuthFailure,
    to: { pathname: AdminDashboardInfrastructurePath.OAuthFailure },
  },
  // 12/7/23
  [AdminDashboardRedirectPath.DataSiloDsrAutomation]: {
    path: AdminDashboardRedirectPath.DataSiloDsrAutomation,
    to: { pathname: AdminDashboardInfrastructurePath.DataSiloDsrAutomation },
  },
  // 12/12/23
  [AdminDashboardRedirectPath.EmailSettings]: {
    path: AdminDashboardRedirectPath.EmailSettings,
    to: {
      pathname: AdminDashboardPrivacyRequestPath.EmailSettingsConfiguration,
    },
  },
  // 12/12/23
  [AdminDashboardRedirectPath.EmailTemplates]: {
    path: AdminDashboardRedirectPath.EmailTemplates,
    to: { pathname: AdminDashboardPrivacyRequestPath.EmailSettingsTemplates },
  },
  // 12/20/23
  [AdminDashboardRedirectPath.EmailSettingsGeneral]: {
    path: AdminDashboardRedirectPath.EmailSettingsGeneral,
    to: {
      pathname: AdminDashboardPrivacyRequestPath.EmailSettingsConfiguration,
    },
  },
  // 10/11/24
  [AdminDashboardRedirectPath.RequestSettingsAuthenticationMethods]: {
    path: AdminDashboardRedirectPath.RequestSettingsAuthenticationMethods,
    to: {
      pathname: AdminDashboardPrivacyCenterPath.AuthenticationMethods,
    },
  },
  // 11/12/24
  [AdminDashboardRedirectPath.PreferenceStoreSettings]: {
    path: AdminDashboardRedirectPath.PreferenceStoreSettings,
    to: {
      pathname: AdminDashboardPreferenceStorePath.PreferenceStoreSettings,
    },
  },
  // 11/12/24
  [AdminDashboardRedirectPath.PreferenceOptions]: {
    path: AdminDashboardRedirectPath.PreferenceOptions,
    to: {
      pathname: AdminDashboardPreferenceStorePath.PreferenceOptions,
    },
  },
  // 1/7/24
  [AdminDashboardRedirectPath.DataInventoryAudit]: {
    path: AdminDashboardRedirectPath.DataInventoryAudit,
    to: {
      pathname: AdminDashboardDataMapPath.DataInventoryAudit,
    },
  },
};
