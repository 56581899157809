import { ErrorAlert, LoadingSpinner } from '@main/core-ui';
import { indexBy, ONE_SECOND } from '@main/utils';
import difference from 'lodash/difference';
import Papa, { ParseResult } from 'papaparse';
import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { Upload } from '../Upload';
import { uploadCsvMessages } from './messages';
import { CsvColumn, RowUploadStatus, RowWithUploadStatus } from './types';

export const findColumnNames = (
  row: Record<string, string>,
  columnName: string,
): string[] =>
  Object.keys(row).filter(
    (key) => key.toLowerCase().trim() === columnName.toLowerCase().trim(),
  );

const normalizeColumnName = (name: string): string =>
  name.toLowerCase().trim().replace(/\s/g, '');

export interface UploadFromCsvButtonProps<T extends {}> {
  /** Called with raw data after csv is parsed, used to reformat any columns that are specific to the csv - if needed */
  transformRows?: (results: T[]) => T[];
  /** Errors from parsing csv */
  allErrors?: Error;
  /** Loading state of parsing csv */
  allLoading?: boolean;
  /** Callback after csv is parsed */
  onParseComplete?: (results: RowWithUploadStatus<T>[]) => void;
  /** callback to set file size error */
  setFileSizeError: (value: boolean) => void;
  /** map of expected columns, keyed by the column name, and containing value validation logic */
  expectedColumns: Record<keyof T, CsvColumn<T>>;
}

const MAX_ROW_AMOUNT = 20000;

/**
 * Button that uploads and parses a CSV into rows of the data
 */
export function UploadFromCsvButton<T extends {}>({
  transformRows,
  allErrors,
  allLoading,
  onParseComplete,
  expectedColumns,
  setFileSizeError,
}: UploadFromCsvButtonProps<T>): JSX.Element {
  const { formatMessage } = useIntl();

  if (allErrors) {
    return <ErrorAlert error={allErrors} />;
  }

  if (allLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Upload
      multiple={false}
      skipSuccessFlash
      action={(file) => {
        // Reset the content being read
        file.text().then((csvContents) => {
          // On success parse the csv
          Papa.parse(csvContents, {
            header: true,
            skipEmptyLines: true,
            // when initially parsed, any empty cell value is ""
            // which can cause issues when submitting optional rows
            transform: (value) => (value === '' ? undefined : value),
            complete: ({ data }: ParseResult<T>) => {
              setFileSizeError(false);
              if (data.length > MAX_ROW_AMOUNT) {
                setFileSizeError(true);
                return;
              }
              let formattedCsv = data;
              if (transformRows) {
                formattedCsv = transformRows(data);
              }

              const normalizedExpectedColumnsByName = indexBy(
                Object.values<CsvColumn<T>>(expectedColumns),
                (column) => normalizeColumnName(column.columnName as string),
              );

              const uniqueColumns = Object.values<CsvColumn<T>>(
                expectedColumns,
              ).filter((column) => column.enforceUnique);

              const duplicateColumns = uniqueColumns.map((column) => {
                const allCellsInColumn = formattedCsv.map(
                  (row) => row[column.columnName],
                );
                const duplicateRows = allCellsInColumn.reduce(
                  (acc, value, i) => {
                    const val = value as string;
                    if (!acc[val]) {
                      acc[val] = [i + 1];
                    } else {
                      acc[val].push(i + 1);
                    }

                    return acc;
                  },
                  {} as Record<string, number[]>,
                );

                const filteredDuplicateRows = Object.fromEntries(
                  Object.entries(duplicateRows).filter(
                    ([, indexes]) => indexes.length > 1,
                  ),
                );
                return {
                  columnName: column.columnName,
                  duplicateRows: filteredDuplicateRows,
                };
              });

              const duplicateColumnsMap = indexBy(
                duplicateColumns,
                (column) => column.columnName as string,
              );

              const rows = formattedCsv.map((row, rowIndex) => {
                // Reformat data
                const formattedRow = Object.entries(row).reduce(
                  (acc, [k, value]) =>
                    Object.assign(acc, {
                      [k]:
                        value === 'true' || value === 'TRUE'
                          ? true
                          : value === 'false' || value === 'FALSE'
                            ? false
                            : value,
                    }),
                  {},
                );

                const normalizedRow = Object.fromEntries(
                  Object.entries(formattedRow).map(([columnName, value]) => {
                    const normalizedColumnName =
                      normalizeColumnName(columnName);

                    if (
                      normalizedColumnName in normalizedExpectedColumnsByName
                    ) {
                      return [
                        normalizedExpectedColumnsByName[normalizedColumnName]
                          .columnName,
                        value,
                      ];
                    }

                    return [columnName, value];
                  }),
                ) as T;

                // determine if columns are valid
                const columnIsValidMap = Object.entries(normalizedRow).reduce(
                  (acc, [name, value]) =>
                    Object.assign(acc, {
                      [name]:
                        name in expectedColumns
                          ? value === undefined
                            ? !expectedColumns[name].required
                            : expectedColumns[name].validate?.(value, row) ??
                              true
                          : undefined,
                    }),
                  {} as Record<keyof T, boolean>,
                );

                // separate errors
                const errors = Object.entries(columnIsValidMap).filter(
                  ([, value]) => value === false,
                );

                const requiredColumns = Object.values<CsvColumn<T>>(
                  expectedColumns,
                )
                  .filter((column) => column.required)
                  .map((column) => column.columnName);

                // Missing columns
                const missingColumns = difference(
                  requiredColumns as string[],
                  Object.keys(normalizedRow),
                );

                const rowDuplicateMap: Record<string, number[]> = {};
                let hasDuplicate = false;
                Object.entries(formattedRow).forEach(
                  ([columnName, value], i) => {
                    if (
                      columnName in duplicateColumnsMap &&
                      duplicateColumnsMap[columnName].duplicateRows[
                        value as string
                      ]
                    ) {
                      hasDuplicate = true;
                      rowDuplicateMap[columnName] = duplicateColumnsMap[
                        columnName
                      ].duplicateRows[value as string].filter(
                        (index) => index !== i + 1,
                      );
                    }
                  },
                );

                const id =
                  'id' in normalizedRow
                    ? hasDuplicate
                      ? // if there are duplicate rows, append the row index to the id so all rows are displayed in the table
                        (normalizedRow.id as string) + rowIndex
                      : (normalizedRow.id as string)
                    : // Assign an id if the rows don't come with one
                      // otherwise only one row will be displayed in the table
                      rowIndex.toString();

                return {
                  ...normalizedRow,
                  // metadata
                  uploadStatus:
                    errors.length > 0 ||
                    missingColumns.length > 0 ||
                    Object.keys(rowDuplicateMap).length > 0
                      ? RowUploadStatus.Error
                      : RowUploadStatus.Pending,
                  errorDetails: {
                    invalidInputColumns: errors.map(([k]) => k),
                    missingColumns,
                    duplicateRows: rowDuplicateMap,
                  },
                  columnIsValidMap,
                  key: id,
                  id,
                };
              });

              onParseComplete?.(rows);
            },
          });
        });

        // Prevent upload
        return '';
      }}
      noHint
      customRequest={({ onSuccess, onProgress, file }) => {
        setTimeout(() => onProgress({ percent: 80 }, file), ONE_SECOND / 4);

        // Need to return first
        setTimeout(
          () => onSuccess({ content: 'Success' }, file),
          ONE_SECOND / 2,
        );

        return {};
      }}
      accept=".csv"
      unique={false}
      headerMessage={null}
      padding="0px"
    >
      <Button variant="secondary" icon="upload">
        {formatMessage(uploadCsvMessages.new)}
      </Button>
    </Upload>
  );
}
