import {
  DarkColorOption,
  FlexRow,
  GenericMessageDescriptor,
  Icon,
  IconType,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { ReactNode, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { useTheme } from 'styled-components';

import { Button, ButtonProps } from '../Button/Button';

export interface DropdownItem {
  /** the displayed content */
  displayTitle: GenericMessageDescriptor;
  /** The HTML id */
  id?: string;
  /** the icon for the item */
  icon?: IconType | ReactNode;
  /** onClick handler */
  onClick?: DropdownItemProps['onClick'];
  /** Item disabled? */
  disabled?: boolean;
  /** The color of the icon */
  color?: keyof DarkColorOption;
}

export interface DropdownMenuProps {
  /** the actions */
  items: DropdownItem[];
  /** The HTML id for cypress */
  id?: string;
  /** Additional props to pass to the button */
  buttonProps?: ButtonProps;
}

/**
 * DropdownMenu
 */
export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  id,
  items,
  buttonProps,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const hasAnyIcons = useMemo(() => items.some(({ icon }) => !!icon), [items]);
  const theme = useTheme();

  return (
    <FlexRow
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Dropdown drop="start" id={id}>
        <Dropdown.Toggle
          as={Button}
          size="sm"
          variant="link"
          icon="dots-vertical"
          iconOnly
          bsPrefix="nonsense-prefix-to-hide-arrow"
          {...buttonProps}
        />
        <Dropdown.Menu
          style={{ borderRadius: '.7em' }}
          popperConfig={{
            strategy: 'fixed',
          }}
          renderOnMount
        >
          {items.map(
            (
              { displayTitle, id, icon, onClick, disabled = false, color },
              i,
            ) => (
              <Dropdown.Item
                key={i}
                id={id}
                onClick={onClick}
                style={{
                  ...(hasAnyIcons
                    ? {
                        display: 'grid',
                        gridTemplateColumns: `1.1em 1fr`,
                        alignItems: 'center',
                        gap: '.3em',
                      }
                    : {}),
                  color: color ? theme.colors[color] : undefined,
                }}
                disabled={disabled}
              >
                {!icon ? (
                  <span />
                ) : typeof icon === 'string' ? (
                  <Icon type={icon as IconType} />
                ) : (
                  icon
                )}
                {formatMessageGeneric(displayTitle)}
              </Dropdown.Item>
            ),
          )}
        </Dropdown.Menu>
      </Dropdown>
    </FlexRow>
  );
};
