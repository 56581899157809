import styled from 'styled-components';

import { Button } from '../Button';

export const StyledPillBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.2em 0.3em;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  min-width: 16em;
  gap: 0.3rem;
  justify-content: space-between;

  :hover button#add-filter {
    opacity: 1;
  }
  button#add-filter {
    opacity: 0;
    transition: 0.5s opacity;

    :hover,
    :focus,
    :active {
      opacity: 1;
    }
  }
`;

export const StyledMenuItem = styled(Button)`
  justify-content: start;
  color: ${({ theme }) => theme.colors.transcendNavy};
  font-weight: 400;
  min-width: 15em;
  white-space: normal;
  text-align: left;
`;

export const StyledPill = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.3rem;
  background-color: ${({ theme }) => theme.colors.gray4};
  border-radius: 4px;
  padding: 0.2rem;
`;

export const StyledPillLabel = styled.div`
  color: ${({ theme }) => theme.colors.transcendNavy2};
  border-right: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  padding: 2px 8px 2px 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

export const StyledCategoryTitle = styled.div`
  font-family: neue-haas-grotesk-display, sans-serif;
  color: ${({ theme }) => theme.colors.transcendNavy};
  font-weight: 600;
  letter-spacing: 0.3px;
`;
