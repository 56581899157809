/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LanguageKey } from '@transcend-io/internationalization';
import {
  ConfigurableColorPaletteColor,
  CustomizableComponent,
  CustomizableText,
} from '@transcend-io/privacy-types';

import { PrivacyCenterChildOrganizationPreview } from '@main/access-control-types';
import { RegionsList } from '@main/datamap-types';
import { createLocalizedMessage } from '@main/internationalization';
import type {
  AssetFile,
  FontBasicInput,
  PrivacyCenter,
  PrivacyCenterAssetName,
  RequestsProcessedDisclosureStatOverrideInput,
  RequestsProcessedDisclosureStatsSettings,
} from '@main/pc-types';

/**
 * The PrivacyCenter reducer state
 */
export interface PrivacyCenterState {
  /** The privacy center that exists in the database and is deployed. */
  deployedPrivacyCenter?: PrivacyCenter;
  /** The privacy center that is stored in the cache, possibly being edited */
  privacyCenter?: PrivacyCenter;
}

// TODO: https://transcend.height.app/T-5506 - break down store into sub-stores
export const privacyCenterSlice = createSlice({
  name: 'PrivacyCenter',
  initialState: {} as PrivacyCenterState,
  reducers: {
    // Update privacy center in store
    setPrivacyCenter: (state, { payload }: PayloadAction<PrivacyCenter>) => ({
      ...state,
      privacyCenter: payload,
      deployedPrivacyCenter: payload,
    }),
    // Reset the privacy center to the deployed state
    resetPrivacyCenter: (state) => ({
      ...state,
      privacyCenter: state.deployedPrivacyCenter,
    }),

    // ////// //
    // Assets //
    // ////// //

    // Update asset
    changeAsset: (
      state,
      {
        payload: { asset, assetName },
      }: PayloadAction<{
        /** The modified asset */
        asset: AssetFile | undefined;
        /** The name of the asset that was modified */
        assetName: PrivacyCenterAssetName;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.assets[assetName] = asset;
      }
    },
    // Update asset's alt text
    changeAssetAlt: (
      state,
      {
        payload: { alt, assetName },
      }: PayloadAction<{
        /** The alt text */
        alt: string;
        /** The name of the asset that was modified */
        assetName: PrivacyCenterAssetName;
      }>,
    ) => {
      if (state.privacyCenter) {
        const assetFile = state.privacyCenter.assets[assetName];
        if (assetFile && assetFile.alt) {
          assetFile.alt.defaultMessage = alt;
        } else if (assetFile) {
          assetFile.alt = createLocalizedMessage(alt, true);
        }
      }
    },

    // ///// //
    // Theme //
    // ///// //

    // Update a color
    changeColor: (
      state,
      {
        payload: { color, colorName },
      }: PayloadAction<{
        /** The value of the color */
        color: string;
        /** The name of the color in the palette */
        colorName: ConfigurableColorPaletteColor;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.partialTheme.colors[colorName] = color;
        state.privacyCenter.theme.colors[colorName] = color;
      }
    },

    // Update a font
    changeTextStyle: (
      state,
      {
        payload: { textType, textStyle },
      }: PayloadAction<{
        /** The type of text */
        textStyle: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.theme.textStyles[textType].name = textStyle;

        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        state.privacyCenter.partialTheme.textStyles![textType]!.name =
          textStyle;
      }
    },
    changeTextStyleLink: (
      state,
      {
        payload: { textType, link },
      }: PayloadAction<{
        /** The link of text */
        link: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.theme.textStyles[textType].url = link;

        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        state.privacyCenter.partialTheme.textStyles![textType]!.url = link;
      }
    },
    addTextStyleAsset: (
      state,
      {
        payload: { textType, font },
      }: PayloadAction<{
        /** The asset to add */
        font: FontBasicInput;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (!state.privacyCenter.theme.textStyles[textType].assets) {
          state.privacyCenter.theme.textStyles[textType].assets = [];
        }
        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]!.assets) {
          state.privacyCenter.partialTheme.textStyles![textType]!.assets = [];
        }
        state.privacyCenter.theme.textStyles[textType].assets!.push(font);
        state.privacyCenter.partialTheme.textStyles![textType]!.assets!.push(
          font,
        );
      }
    },
    removeTextStyleAsset: (
      state,
      {
        payload: { textType, url },
      }: PayloadAction<{
        /** The asset URL to remote */
        url: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (state.privacyCenter.theme.textStyles[textType].assets) {
          state.privacyCenter.theme.textStyles[textType].assets =
            state.privacyCenter.theme.textStyles[textType].assets!.filter(
              (asset) => asset.url !== url,
            );
        }
        if (state.privacyCenter.partialTheme.textStyles?.[textType]?.assets) {
          state.privacyCenter.partialTheme!.textStyles![textType]!.assets =
            state.privacyCenter.partialTheme!.textStyles![
              textType
            ]!.assets!.filter((asset) => asset.url !== url);
        }
      }
    },

    // Update a component style
    changeComponentStyle: (
      state,
      {
        payload: { componentCss, componentType },
      }: PayloadAction<{
        /** The new style for the button */
        componentCss: string;
        /** The type of component */
        componentType: CustomizableComponent;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (!state.privacyCenter.partialTheme.componentStyles) {
          state.privacyCenter.partialTheme.componentStyles = {};
        }

        state.privacyCenter.partialTheme!.componentStyles[componentType] =
          componentCss;
        state.privacyCenter.theme.componentStyles[componentType] = componentCss;
      }
    },

    // /////// //
    // General //
    // /////// //

    // Update home url
    changeHome: (state, { payload }: PayloadAction<string>) => {
      if (state.privacyCenter) {
        state.privacyCenter.home.defaultMessage = payload;
      }
    },
    // Emails
    changeEmailSettings: (
      state,
      { payload }: PayloadAction<Partial<PrivacyCenter>>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter = Object.assign(state.privacyCenter, payload);
      }
    },
    changeCustomSubdomain: (state, { payload }: PayloadAction<string>) => {
      if (state.privacyCenter) {
        state.privacyCenter.customSubdomain = payload;
      }
    },
    // Update locales
    changeLocales: (state, { payload }: PayloadAction<LanguageKey[]>) => {
      if (state.privacyCenter) {
        state.privacyCenter.locales = payload;
      }
    },
    // Update defaultLocale
    changeDefaultLocale: (state, { payload }: PayloadAction<LanguageKey>) => {
      if (state.privacyCenter) {
        state.privacyCenter.defaultLocale = payload;
      }
    },
    // Update preferBrowserDefaultLocale
    changePreferBrowserDefaultLocale: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.preferBrowserDefaultLocale = payload;
      }
    },
    // Update showTrackingTechnologies
    changeShowTrackingTechnologies: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showTrackingTechnologies = payload;
      }
    },
    // Update showManageYourPrivacy
    changeShowManageYourPrivacy: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showManageYourPrivacy = payload;
      }
    },
    // Update showSaleOfInfo
    changeShowSaleOfInfo: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showSaleOfInfo = payload;
      }
    },
    // Update unauthenticatedDoNotSellRegions
    changeUnauthenticatedDoNotSellRegions: (
      state,
      { payload }: PayloadAction<RegionsList>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.unauthenticatedDoNotSellRegions = payload;
      }
    },
    // Update showPrivacyRequestButton
    changeShowPrivacyRequestButton: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPrivacyRequestButton = payload;
      }
    },
    // Update showPolicies
    changeShowPolicies: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPolicies = payload;
      }
    },
    // Update isDisabled
    changeShowPrivacyCenter: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.isDisabled = !payload;
      }
    },
    // Update showCookies
    changeShowCookies: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showCookies = payload;
      }
    },
    // Update showConsentManager
    changeShowConsentManager: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showConsentManager = payload;
      }
    },
    // Update showDataFlows
    changeShowDataFlows: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showDataFlows = payload;
      }
    },
    // Update transformAccessReportJsonToCsv
    changeTransformAccessReportJsonToCsv: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.transformAccessReportJsonToCsv = payload;
      }
    },

    // Update allowPartitionChange
    changeAllowPartitionChange: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.allowPartitionChange = payload;
      }
    },
    // Update showPartition
    changeShowPartition: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPartition = payload;
      }
    },
    // Update showMarketingPreferences
    changeShowMarketingPreferences: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showMarketingPreferences = payload;
      }
    },
    // Update what child organizations to display in the privacy center
    changeChildOrganizations: (
      state,
      { payload }: PayloadAction<PrivacyCenterChildOrganizationPreview[]>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.childOrganizations = payload;
      }
    },
  },
});

export const {
  setPrivacyCenter,
  resetPrivacyCenter,
  changeAsset,
  changeAssetAlt,
  changeTextStyle,
  changeTextStyleLink,
  changeColor,
  changeComponentStyle,
  changeHome,
  changeShowPolicies,
  changeEmailSettings,
  changeShowCookies,
  changeShowDataFlows,
  changeCustomSubdomain,
  changeDefaultLocale,
  changeLocales,
  changePreferBrowserDefaultLocale,
  addTextStyleAsset,
  removeTextStyleAsset,
  changeShowTrackingTechnologies,
  changeAllowPartitionChange,
  changeShowPartition,
  changeShowManageYourPrivacy,
  changeShowSaleOfInfo,
  changeUnauthenticatedDoNotSellRegions,
  changeTransformAccessReportJsonToCsv,
  changeShowConsentManager,
  changeShowPrivacyRequestButton,
  changeShowPrivacyCenter,
  changeShowMarketingPreferences,
  changeChildOrganizations,
} = privacyCenterSlice.actions;

/**
 * The RequestsProcessedDisclosureStatsSettingsState reducer state
 */
export interface RequestsProcessedDisclosureStatsSettingsState {
  /** The settings */
  requestsProcessedDisclosureStatsSettings?: RequestsProcessedDisclosureStatsSettings;
  /** The new settings to apply */
  newRequestsProcessedDisclosureStatsSettings: RequestsProcessedDisclosureStatsSettings;
}

export const requestsProcessedDisclosureStatsSettingsSlice = createSlice({
  name: 'RequestsProcessedDisclosureStatsSettings',
  initialState: {} as RequestsProcessedDisclosureStatsSettingsState,
  reducers: {
    resetRequestsProcessedDisclosureStatsSettings: (state) => ({
      ...state,
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
      } as RequestsProcessedDisclosureStatsSettings,
    }),

    // Update the settings in the store. This is for syncing with the backend state
    setInitialRequestsProcessedDisclosureStatsSettings: (
      state,
      { payload }: PayloadAction<RequestsProcessedDisclosureStatsSettings>,
    ) => ({
      ...state,
      requestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
      // "sync" new and old states, aka no modifications
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
    }),
    // Update the settings in the store
    setRequestsProcessedDisclosureStatsSettings: (
      state,
      {
        payload,
      }: PayloadAction<Partial<RequestsProcessedDisclosureStatsSettings>>,
    ) => ({
      ...state,
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.newRequestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
    }),
    setSingleOverride: (
      state,
      {
        payload,
      }: PayloadAction<
        Omit<RequestsProcessedDisclosureStatOverrideInput, 'statValue'> & {
          /** Optional stat value */
          statValue?: number;
        }
      >,
    ) => {
      // Remove duplicate/s from array
      const dedupedOverrides =
        state.newRequestsProcessedDisclosureStatsSettings.valueOverrides.filter(
          (override) =>
            !(
              override.year === payload.year &&
              override.statType === payload.statType &&
              override.actionType === payload.actionType
            ),
        );

      return {
        ...state,
        newRequestsProcessedDisclosureStatsSettings: {
          ...state.newRequestsProcessedDisclosureStatsSettings,
          // Un-set override if stat value is empty
          valueOverrides: payload.statValue
            ? [...dedupedOverrides, payload]
            : dedupedOverrides,
        } as RequestsProcessedDisclosureStatsSettings,
      };
    },
  },
});
/* eslint-enable max-lines */
