/* eslint-disable max-lines */
import * as t from 'io-ts';

import {
  MobileConfig,
  Purpose,
  RegimePurposeScopesConfig,
  TCFBundledDataConfig,
  TrackingPurposesConfig,
  ViewState,
} from '@transcend-io/airgap.js-types';
import { DataFlowScope } from '@transcend-io/privacy-types';
import { dictionary, Optionalize, valuesOf } from '@transcend-io/type-utils';

import {
  AirgapSettings,
  GPPBundledModuleData,
  ICookiePurposeMapInputJSON,
  IPurposeMapJSON,
  MacroregionMapInput,
  RegionRegimesConfig,
  RegulatedPaths,
} from '@main/ag-types';
import { dbModelId } from '@main/schema-utils';

import {
  AirgapModuleName,
  OptionalAirgapModuleName,
  RequiredAirgapModuleName,
} from './airgapBundleName';

/** Airgap module metadata */
export const AirgapModule = t.intersection([
  t.type({
    location: t.string,
  }),
  t.partial({
    loadOptions: AirgapSettings,
  }),
]);

/** Type override */
export type AirgapModule = t.TypeOf<typeof AirgapModule>;

/** Required airgap module names */
export const RequiredAirgapModuleNameCodec = t.keyof(RequiredAirgapModuleName);

/** Optional airgap module names */
export const OptionalAirgapModuleNameCodec = t.keyof(OptionalAirgapModuleName);

/** Airgap module name */
export const AirgapModuleNameCodec = t.keyof(AirgapModuleName);

/** Airgap modules config */
export const AirgapModulesConfig = t.intersection([
  t.record(RequiredAirgapModuleNameCodec, AirgapModule),
  dictionary(OptionalAirgapModuleNameCodec, AirgapModule),
]);

/** Type override */
export type AirgapModulesConfig = t.TypeOf<typeof AirgapModulesConfig>;

export const BundledModuleData = t.partial({
  tcf: TCFBundledDataConfig,
  gpp: GPPBundledModuleData,
});

/** Type override */
export type BundledModuleData = t.TypeOf<typeof BundledModuleData>;

/** Airgap bundle configuration data for compileBundles() */
export const AirgapBundleConfig = t.intersection([
  t.partial({
    /** Airgap bundle group name */
    name: t.string,
    /** Airgap generated CSP inclusions */
    csp: IPurposeMapJSON,
    /** Privacy center URL (if any) */
    privacyCenter: t.string,
    /**
     * Airgap request overrides. These are JS source code strings representing RequestOverride descriptors.
     */
    overrides: t.array(t.string),
    /** Airgap regulated paths purpose map */
    regulatedPaths: RegulatedPaths,
    /** Airgap regulated scripts purpose map */
    regulatedScripts: RegulatedPaths,
    /**
     * Region to regimes mappings
     * TODO: https://transcend.height.app/T-19149 - make required once experiences fully rolled out
     */
    regionRegimesMap: RegionRegimesConfig,
    /** Regime purpose scopes */
    regimePurposeScopes: RegimePurposeScopesConfig,
    /** Regime purpose opt outs */
    regimePurposeOptOuts: RegimePurposeScopesConfig,
    /** Airgap regulated cookies */
    cookies: t.union([IPurposeMapJSON, ICookiePurposeMapInputJSON]),
    /** Airgap purpose types configuration */
    purposes: TrackingPurposesConfig,
    /** List of hosts where the bundles are allowed to run */
    hosts: t.array(t.string),
    /**
     * Custom Consent Manager UI module
     * This can either be either a location string to a custom UI
     * or `false`, which disables the Consent Manager UI.
     */
    ui: t.union([t.string, t.literal(false)]),
    /**
     * Custom Transcend Secure XDI module
     * This can either be either a location string to a custom XDI
     * or `false`, which disables Transcend Secure XDI.
     */
    xdi: t.union([t.string, t.literal(false)]),
    /** Should purpose maps be automatically sorted by ascending host depth? (default: true) */
    sortPurposeMaps: t.boolean,
    /** Should bundles be pretty-printed (e.g. for development)? (default: false) */
    prettyPrintBundle: t.boolean,
    /** Default load options for all bundles */
    loadOptions: AirgapSettings,
    /** Core bundle prefix */
    prefixScript: t.string,
    /** Core bundle suffix */
    suffixScript: t.string,
    /** Record containing per-module arbitrary data to be inserted alongside bundled module code */
    bundledModuleData: BundledModuleData,
  }),
  t.type({
    /** Airgap bundle identifier */
    id: t.string,
    /** Airgap modules config */
    modules: AirgapModulesConfig,
    /** Airgap domain purpose map */
    purposeMap: IPurposeMapJSON,
    /** Bundle build version (only used for non-prod builds) */
    version: t.string,
    /** Bundle CDN URL (based on DEPLOY_ENV) */
    cdn: t.string,
    /** Macroregion map */
    macroregions: MacroregionMapInput,
  }),
]);

/** Type override */
export type AirgapBundleConfig = t.TypeOf<typeof AirgapBundleConfig>;

/** Airgap bundle config JSON file (doesn't include cdn or bundle ID) */
export type AirgapBundleConfigJSON = Optionalize<
  AirgapBundleConfig,
  'cdn' | 'id'
>;

/**
 * The types of Airgap modules that can be 'compiled'
 */
export type CompilableAirgapModuleName = Exclude<
  AirgapModuleName,
  'metadata' | 'nativeAppConfig'
>;

/** Compiled airgap.js module bundles */
export type CompiledAirgapBundles = Map<
  CompilableAirgapModuleName,
  string | null
>;

/**
 * The classification data for an item (cookie, host, or cookie-host pair)
 */
export const TelemetryClassificationItem = t.intersection([
  t.type({
    /** The purposes predicted for the item */
    purposes: t.array(t.string),
    /** If it is essential for the site the consent manager is on to run */
    essential: t.boolean,
  }),
  t.partial({
    /** The service (product) that is associated with this item */
    service: t.string,
    /** Whether the item should be considered junk */
    isJunk: t.boolean,
    /** The description for the classification item */
    description: t.string,
  }),
]);

/** Type override */
export type TelemetryClassificationItem = t.TypeOf<
  typeof TelemetryClassificationItem
>;

/**
 * Shape of purposes-summarization lambda response objects for hosts
 */
export const TelemetryHostSummaryItem = t.intersection([
  /** The required fields */
  t.type({
    /** The name of the host that was requested by airgap */
    host: t.string,
    /** The number of times that the host was requested under the current consent manager for the past 3 days */
    occurrences: t.number,
  }),
  /** The optional fields */
  t.partial({
    /** The classification data for a host. If the host has not yet been classified, this will be undefined */
    classification: TelemetryClassificationItem,
  }),
]);

/** Override types. */
export type TelemetryHostSummaryItem = t.TypeOf<
  typeof TelemetryHostSummaryItem
>;

/**
 * Shape of purposes-summarization lambda response objects for cookies
 */
export const TelemetryCookieSummaryItem = t.intersection([
  /** The required fields */
  t.type({
    /** The name of the cookie that was requested by airgap */
    cookie: t.string,
    /** The number of times that the cookie was requested under the current consent manager for the past 3 days */
    occurrences: t.number,
    /** The hosts associated with this cookie */
    hosts: t.array(TelemetryHostSummaryItem),
  }),
  /** The optional fields */
  t.partial({
    /** The classification data for a cookie. If the cookie has not been classified per se, this will be undefined */
    classification: TelemetryClassificationItem,
  }),
]);

/** Override types. */
export type TelemetryCookieSummaryItem = t.TypeOf<
  typeof TelemetryCookieSummaryItem
>;

/**
 * A usage record for a Consent Manager for Stripe billing
 */
export const StripeUsageRecordItem = t.type({
  /** The consent manager ID */
  consentManagerId: dbModelId('airgapBundle'),
  /** The session count */
  sessions: t.number,
  /** The reporting timestamp */
  timestamp: t.number,
  /** Idempotency token */
  token: t.string,
});

/** Override type */
export type StripeUsageRecordItem = t.TypeOf<typeof StripeUsageRecordItem>;

/**
 * Cookie metadata (from Contentful)
 */
export const AirgapCookieMetadata = t.intersection([
  t.type({
    name: t.string,
    purposes: t.array(valuesOf(Purpose)),
  }),
  t.partial({
    description: t.string,
    isJunk: t.boolean,
  }),
]);

/**
 * Overload AirgapCookieMetadata as a type
 */
export type AirgapCookieMetadata = t.TypeOf<typeof AirgapCookieMetadata>;

/**
 * Data flow metadata (from Contentful)
 */
export const AirgapDataFlowMetadata = t.intersection([
  t.type({
    /** value */
    value: t.string,
    /** tracking purposes */
    purposes: t.array(valuesOf(Purpose)),
    /** The type (regex, host, csp, paths, query parm) */
    type: valuesOf(DataFlowScope),
  }),
  t.partial({
    /** any optional description */
    description: t.string,
    /** whether or not this is junk */
    isJunk: t.boolean,
  }),
]);

/**
 * Overload AirgapDataFlowMetadata as a type
 */
export type AirgapDataFlowMetadata = t.TypeOf<typeof AirgapDataFlowMetadata>;

/**
 * Consent configuration (from Contentful)
 */
export const ConsentConfig = t.partial({
  /** List of exact cookie matches */
  cookies: t.array(AirgapCookieMetadata),
  /** List of cookie regexes */
  cookieRegexes: t.array(AirgapCookieMetadata),
  /** List of data flows (and paths, csps, etc.) */
  dataFlows: t.array(AirgapDataFlowMetadata),
});

/**
 * Overload ConsentConfig  as a type
 */
export type ConsentConfig = t.TypeOf<typeof ConsentConfig>;

/** Mobile bridge config used to update bridge.html */
export const MobileBridgeConfig = t.type({
  /** Airgap.js bundle ID */
  bundleId: t.string,
  /** CDN url for the deployed airgap.js bundle */
  cdn: t.string,
  /** Airgap core location */
  agCoreLocation: t.string,
});

/** Overload MobileBridgeConfig as a type */
export type MobileBridgeConfig = t.TypeOf<typeof MobileBridgeConfig>;

/** Native app config */
export const NativeAppConfig = t.type({
  /** Associated consent services */
  consentServices: MobileConfig,
  /** Associated consent applications */
  consentApplications: t.array(t.string),
});

/** Overload NativeAppConfig as a type */
export type NativeAppConfig = t.TypeOf<typeof NativeAppConfig>;

export interface ConditionalExpression {
  /** The And node */
  And?: ConditionalExpression[] | Record<string, boolean>[];
  /** The Or node */
  Or?: ConditionalExpression[] | Record<string, boolean>[];
}

/** Conditional Expression codec */
export const ConditionalExpression: t.RecursiveType<
  t.Type<ConditionalExpression>
> = t.recursion('ConditionalExpression', (self) =>
  t.partial({
    /** The And node */
    And: t.array(t.union([self, t.record(t.string, t.boolean)])),
    /** The Or node */
    Or: t.array(t.union([self, t.record(t.string, t.boolean)])),
  }),
);

/**
 * The default preference topic configuration for a purpose preference
 */
export const DefaultPreferenceTopicConfiguration = t.partial({
  /** The default values when purpose is opted in */
  defaultOptInValue: t.union([t.string, t.array(t.string), t.boolean]),
  /** The default values when purpose is opted out */
  defaultOptOutValue: t.union([t.string, t.array(t.string), t.boolean]),
});

/** Type override */
export type DefaultPreferenceTopicConfiguration = t.TypeOf<
  typeof DefaultPreferenceTopicConfiguration
>;

/**
 * The JSON value metadata that represents an ID used for `EnrichedPreferenceOption` schema
 */
export const PreferenceOptionIdLookup = t.type({
  /** Slug of preference topic */
  preferenceTopicSlug: t.string,
  /** Slug of preference option value */
  preferenceOptionValue: t.string,
  /** Index of preference option */
  preferenceIndex: t.number,
  /** ID of purpose */
  purposeId: dbModelId('purpose'),
});

/** Type override */
export type PreferenceOptionIdLookup = t.TypeOf<
  typeof PreferenceOptionIdLookup
>;

/**
 * The format of preference values.
 * Supports boolean, string, array of strings, or undefined.
 */
export const PreferenceValues = t.union([
  t.boolean,
  t.string,
  t.array(t.string),
]);

/** Override types. */
export type PreferenceValues = t.TypeOf<typeof PreferenceValues>;

/** Preferences are key-value pairs where the key is a preference slug and the value is user preference. */
export const Preferences = t.record(t.string, PreferenceValues);

/** Override types. */
export type Preferences = t.TypeOf<typeof Preferences>;

/** Preferences with null values that will be used to delete preferences. */
export const PreferencesWithNulls = t.record(
  t.string,
  t.union([PreferenceValues, t.null]),
);

/** Override types. */
export type PreferencesWithNulls = t.TypeOf<typeof PreferencesWithNulls>;

/** Purpose to preferences mapping. */
export const PurposeToPreferences = t.record(t.string, Preferences);

/** Override types. */
export type PurposeToPreferences = t.TypeOf<typeof PurposeToPreferences>;

/** Purpose to preferences mapping with null values. */
export const PurposeToPreferencesWithNulls = t.record(
  t.string,
  t.union([PreferencesWithNulls, t.null]),
);

/** Override types. */
export type PurposeToPreferencesWithNulls = t.TypeOf<
  typeof PurposeToPreferencesWithNulls
>;

/** Purposes are key-value pairs where the key is a purpose slug and the value is user consent. */
export const PreferenceStorePurposes = t.record(
  t.string,
  t.union([t.boolean, t.undefined]),
);

/** Override types. */
export type PreferenceStorePurposes = t.TypeOf<typeof PreferenceStorePurposes>;

/** Purposes with null values that will be used to delete from dynamo. */
export const PreferenceStorePurposesWithNulls = t.record(
  t.string,
  t.union([t.boolean, t.undefined, t.null]),
);

/** Override types. */
export type PreferenceStorePurposesWithNulls = t.TypeOf<
  typeof PreferenceStorePurposesWithNulls
>;

/** Consent Metadata */
export const ConsentMetadata = t.intersection([
  t.partial({
    tcmp: t.partial({
      tcf: t.partial({
        tcString: t.string,
      }),
    }),
  }),
  t.UnknownRecord,
]);

/**
 * Override type
 */
export type ConsentMetadata = t.TypeOf<typeof ConsentMetadata>;

/**
 * Consent Preference codec that doesn't include consent metadata
 */
export const ConsentPreferenceWithoutMetadataCodec = t.intersection([
  t.type({
    /** The encrypted Identifier of the user */
    encryptedIdentifier: t.string,
    /** The airgap partition */
    partition: t.string,
    /** timestamp when the change occurred on client */
    timestamp: t.string,
    /** The purpose associated */
    purposes: PreferenceStorePurposes,
  }),
  t.partial({
    /** The tcf string */
    tcf: t.string,
    /** The usp string */
    usp: t.string,
    /** The gpp string */
    gpp: t.string,
    /** The view state of Transcend UI */
    viewState: valuesOf(ViewState),
    /** airgap.js version */
    airgapVersion: t.string,
    /** When the metadata was last updated */
    metadataTimestamp: t.string,
    /** A mapping of purposes to their associated preferences */
    purposeToPreferences: PurposeToPreferences,
  }),
]);

/** Override types. */
export type ConsentPreferenceWithoutMetadataCodec = t.TypeOf<
  typeof ConsentPreferenceWithoutMetadataCodec
>;

/**
 *  The shape of Preference store identifier
 */
export const PreferenceStoreIdentifier = t.type({
  /** The name of linked identifier */
  name: t.string,
  /** The value of the linked identifier */
  value: t.string,
});

/** Override types. */
export type PreferenceStoreIdentifier = t.TypeOf<
  typeof PreferenceStoreIdentifier
>;

/**
 * Admin consent preference is a combination of preference document
 * and any other dynamodb specific fields e.g. `updatedAt`.
 */
export const PreferenceStoreRecordWithoutMetadata = t.intersection([
  ConsentPreferenceWithoutMetadataCodec,
  t.type({
    /** User identifier - a combination of encryptedIdentifier and partition */
    userId: t.string,
    /** is consent confirmed */
    confirmed: t.boolean,
  }),
  t.partial({
    /** record update iso timestamp on dynamodb */
    updatedAt: t.string,
  }),
]);

/** Override types. */
export type PreferenceStoreRecordWithoutMetadata = t.TypeOf<
  typeof PreferenceStoreRecordWithoutMetadata
>;

/** API response of a single preference store record from Admin query/update lambdas */
export const AdminPreferenceStoreRecord = t.intersection([
  PreferenceStoreRecordWithoutMetadata,
  t.type({
    /** The identifiers associated to a user */
    identifiers: t.array(PreferenceStoreIdentifier),
  }),
  t.partial({
    /** metadata associated to the user consent */
    metadata: t.string,
  }),
]);

/** Override types. */
export type AdminPreferenceStoreRecord = t.TypeOf<
  typeof AdminPreferenceStoreRecord
>;

/** Preference store record */
export const PreferenceStoreRecord = t.intersection([
  PreferenceStoreRecordWithoutMetadata,
  t.type({
    /** The identifiers associated to a user */
    identifiers: t.array(PreferenceStoreIdentifier),
  }),
  t.partial({
    /** metadata associated to the user consent */
    metadata: ConsentMetadata,
  }),
]);

/** Override types. */
export type PreferenceStoreRecord = t.TypeOf<typeof PreferenceStoreRecord>;

/** The format of error returned to the backend. */
export const ConsentLambdaErrorReturn = t.partial({
  /** The error string */
  clientErrorMessage: t.string,
});

/** Override types. */
export type LambdaErrorReturn = t.TypeOf<typeof ConsentLambdaErrorReturn>;

export const ConsentLastKey = t.intersection([
  t.type({
    /** encrypted identifier + partition delimited by space */
    userId: t.string,
    /** partition */
    partition: t.string,
  }),
  t.partial({
    /** timestamp */
    timestamp: t.string,
    /** updated at */
    updatedAt: t.string,
  }),
]);

/** Override types. */
export type ConsentLastKey = t.TypeOf<typeof ConsentLastKey>;

/** Admin query handler lambda response type. */
export const ConsentPreferenceResponse = t.intersection([
  t.partial({
    /** Array of updated consent preferences */
    nodes: t.array(AdminPreferenceStoreRecord),
    /** Last key for pagination */
    lastKey: ConsentLastKey,
    /** The mapping of lookup identifiers to primary key of PS */
    requestIdentifierToPrimaryKeyMap: t.record(t.string, t.string),
  }),
  ConsentLambdaErrorReturn,
]);

/** Override types. */
export type ConsentPreferenceResponse = t.TypeOf<
  typeof ConsentPreferenceResponse
>;

/** Response type for admin update handler lambda. */
export const AdminUpdateHandlerResponse = t.intersection([
  t.partial({
    /** Array of updated consent preferences */
    data: t.array(AdminPreferenceStoreRecord),
  }),
  ConsentLambdaErrorReturn,
]);

/** Override types. */
export type AdminUpdateHandlerResponse = t.TypeOf<
  typeof AdminUpdateHandlerResponse
>;

/** Next token typed needed for pagination of the GetStatementResultCommand */
export const NextToken = t.type({
  /** AWS statement ID */
  statementId: t.string,
  /** Next Token */
  nextToken: t.string,
});

/** Override types. */
export type NextToken = t.TypeOf<typeof NextToken>;

/** Record of consent type. */
export const RecordOfConsentCodec = t.intersection([
  t.type({
    /** partition */
    partition: t.string,
    /** encrypted identifier */
    encryptedIdentifier: t.string,
    /** timestamp */
    timestamp: t.string,
  }),
  t.partial({
    /** updated purposes */
    purposes: t.union([PreferenceStorePurposes, t.null]),
    /** updated tcf */
    tcf: t.union([t.string, t.null]),
    /** updated usp */
    usp: t.union([t.string, t.null]),
    /** updated gpp */
    gpp: t.union([t.string, t.null]),
    /** updated metadata */
    metadata: t.union([t.string, t.null]),
    /** updated purpose to preferences */
    purposeToPreferences: t.union([PurposeToPreferences, t.null]),
    /** updated updated at */
    updatedAt: t.union([t.string, t.null]),
  }),
]);

/** Override types. */
export type RecordOfConsentCodec = t.TypeOf<typeof RecordOfConsentCodec>;

/** Response when Querying Records of Consent */
export const QueryRecordsResponse = t.intersection([
  t.partial({
    /** Array of records of consent */
    data: t.array(RecordOfConsentCodec),
    /** Next token for pagination */
    nextToken: NextToken,
  }),
  ConsentLambdaErrorReturn,
]);

/** Override types. */
export type QueryRecordsResponse = t.TypeOf<typeof QueryRecordsResponse>;

/** Record of consent lookup request type. */
export const RecordOfConsentLookup = t.intersection([
  t.type({
    /** partition */
    partition: t.string,
  }),
  t.partial({
    /** Array of encrypted identifiers */
    encryptedIdentifiers: t.array(t.string),
    /** Retrieve records where UI actions were taken by users before the specified timestamp (ISO format) */
    timestampBefore: t.string,
    /** Retrieve records where UI actions were taken by users after the specified timestamp (ISO format) */
    timestampAfter: t.string,
    /** Retrieve records last updated in the database before the specified timestamp (ISO format) */
    updatedBefore: t.string,
    /** Retrieve records last updated in the database after the specified timestamp (ISO format) */
    updatedAfter: t.string,
    /** Indicates the starting point for the next set of response records in a subsequent request. */
    nextToken: NextToken,
  }),
]);

/** Override types. */
export type RecordOfConsentLookup = t.TypeOf<typeof RecordOfConsentLookup>;

/**
 *  Response body from record of consent lookup.
 *
 */
export const RecordOfConsentResponse = t.intersection([
  t.type({
    /** S3 Prefix for Records of Consent unloaded to S3 */
    recordOfConsentPrefix: t.string,
  }),
  t.partial({
    /** AWS Statement ID for the unload operation and checking the status of it */
    unloadStatementId: t.string,
  }),
]);

/** Override types. */
export type RecordOfConsentResponse = t.TypeOf<typeof RecordOfConsentResponse>;

/** Unload Lambda handler lambda response type. */
export const UnloadRecordsResponse = t.intersection([
  t.partial({ data: RecordOfConsentResponse }),
  ConsentLambdaErrorReturn,
]);

/** Override types. */
export type UnloadRecordsResponse = t.TypeOf<typeof UnloadRecordsResponse>;

/* eslint-enable max-lines */
