/* eslint-disable react-hooks/rules-of-hooks,max-lines */
import { FlexColumn } from '@main/core-ui';
import { FIXED_COLORS } from '@main/theme';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleLog, scaleOrdinal } from '@visx/scale';
import { getStringWidth } from '@visx/text';
import mapValues from 'lodash/mapValues';
import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { StyledVisxTooltipColorCircle } from '../BarStacks/wrappers';
import { DEFAULT_COLORS } from '../constants';
import { getInternalChartData, getMinMaxY } from '../utils';
import { useVisxTooltip, VisxTooltip } from '../VisxTooltip';
import {
  AXIS_FONT_SIZE,
  AXIS_TEXT_PROPS,
  AXIS_VISX_PADDING,
  DEFAULT_LABEL_WIDTH_ON_NULL,
  FIRST_OMIT_X_LABELS_BREAKPOINT,
  LABEL_ANGLE_OF_ROTATION,
  LEGEND_GAP,
  LEGEND_HEIGHT,
  MAX_LEGEND_HEIGHT_PERCENTAGE,
  SECOND_OMIT_X_LABELS_BREAKPOINT,
} from './constants';
import { useChartGridSVGAnimation } from './hooks';
import {
  ChartBodyProps,
  GridLinesProps,
  TooltipDataT,
  XYChartTemplateProps,
  YScale,
} from './types';
import {
  ChartContainer,
  ChartSVGGrid,
  LegendItem,
  MainStyledLegend,
} from './wrappers';

const GridLines: React.FC<GridLinesProps> = ({
  chartInteriorWidth,
  chartInteriorHeight,
  yScale,
  tickValues,
}) => {
  const chartGridSVGAnimationStyles =
    useChartGridSVGAnimation(chartInteriorWidth);
  return (
    <ChartSVGGrid
      height={chartInteriorHeight}
      style={chartGridSVGAnimationStyles}
    >
      <GridRows
        scale={yScale}
        numTicks={5}
        height={chartInteriorHeight}
        width={chartInteriorWidth}
        stroke={FIXED_COLORS.transcendNavy2}
        opacity={0.2}
        strokeWidth={1}
        strokeLinecap="round"
        tickValues={tickValues}
      />
    </ChartSVGGrid>
  );
};

export const XYChartTemplate = <TooltipData extends TooltipDataT>({
  data,
  scale: yScaleType,
  colors = DEFAULT_COLORS,
  children: chartBody,
  tooltip,
  legendScale: legendScaleOverride,
  hideLegend = false,
  xAxisMouseHandlerOverrides = {},
  isStacked = false,
  yUnitTransform,
  yAxisTopLabel,
  yAxisBottomLabel,
  maxBottomLabels,
  yMaxOverride,
  yMinOverride,
}: XYChartTemplateProps<TooltipData>): JSX.Element => {
  const { formatNumber } = useIntl();
  const yUnitTransformOrDefault = yUnitTransform ?? ((s) => formatNumber(s));
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
    updateTooltip,
    preferredPosition: preferredTooltipPosition,
    setPreferredPosition,
  } = useVisxTooltip<TooltipData>();
  const chartBodyWrapperRef = useRef<HTMLDivElement | null>(null);
  const [hiddenKeys, setHiddenKeys] = useState<Set<string>>(new Set());

  return (
    <ParentSize>
      {({ height: containerHeight, width: containerWidth }) => {
        const theme = useTheme();
        const maxLegendHeight = containerHeight * MAX_LEGEND_HEIGHT_PERCENTAGE;

        const internalChartData = useMemo(
          () => getInternalChartData(data),
          [data],
        );

        // passed to legend to map series name to series color
        const legend = data.series.map((s, idx) => ({
          displayName: s.name,
          color: colors[idx % colors.length],
        }));
        const seriesNames = legend.map((l) => l.displayName);
        const legendRange = legend.map((l) => theme.colors[l.color]);
        const legendScale =
          legendScaleOverride ||
          scaleOrdinal({
            domain: seriesNames,
            range: legendRange,
          });

        const xDomain = internalChartData.map((p) => p.x);
        const { max: yMaxFromData, min: yMinFromData } = useMemo(
          () => getMinMaxY(internalChartData, isStacked),
          [internalChartData],
        );
        const yMax = yMaxOverride ?? yMaxFromData;
        const yMin = yMinOverride ?? yMinFromData;

        const hasNegativeY = yMin < 0;
        const logScalePowerMax = Math.ceil(
          Math.log10(Math.max(yMax || 1, Math.abs(yMin || 1))),
        );

        const positiveLogScaleMajorYTicks = Array(logScalePowerMax)
          .fill(0)
          .map((x, idx) => 10 ** idx);
        const negativeLogScaleMajorYTicks = hasNegativeY
          ? positiveLogScaleMajorYTicks.map((t) => t * -1).reverse()
          : [];
        const prettyLogScaleYTicks = [
          ...negativeLogScaleMajorYTicks,
          ...positiveLogScaleMajorYTicks,
        ];

        const legendHeight = useMemo(() => {
          if (!hideLegend) {
            const legendWidths = seriesNames.map(
              (name) =>
                (getStringWidth(name, { fontSize: '14px' }) ??
                  DEFAULT_LABEL_WIDTH_ON_NULL) +
                LEGEND_GAP +
                // legend dot and spacing
                16,
            );
            // walk through the legend items and wrap as needed
            let numLegendLines = 1;
            let lastWidth = 0;
            legendWidths.forEach((width) => {
              if (lastWidth + width > containerWidth) {
                lastWidth = width;
                numLegendLines += 1;
              } else {
                lastWidth += width;
              }
            });
            return Math.min(
              LEGEND_HEIGHT * numLegendLines +
                // extra spacing between lines
                (numLegendLines - 1) * LEGEND_GAP,
              maxLegendHeight,
            );
          }
          return 0;
        }, [seriesNames, containerWidth, containerHeight]);

        const {
          scaledYAxisWidth,
          scaledXAxisHeight,
          chartInteriorHeight,
          chartInteriorWidth,
        } = useMemo(() => {
          const xAxisLabelWidths = xDomain.map(
            (label) =>
              getStringWidth(label, AXIS_TEXT_PROPS) ??
              DEFAULT_LABEL_WIDTH_ON_NULL,
          );
          const maxXAxisLabelSize = Math.max(...xAxisLabelWidths);
          let scaledYAxisWidth =
            (getStringWidth(
              yUnitTransformOrDefault(Math.round(yMax)),
              AXIS_TEXT_PROPS,
            ) ?? DEFAULT_LABEL_WIDTH_ON_NULL) +
            AXIS_VISX_PADDING +
            AXIS_FONT_SIZE;
          const prelimXAxisLabelSpacing =
            (containerWidth - scaledYAxisWidth) / xDomain.length;
          // preliminary version of rotateLabels not completely accurate
          const prelimRotateLabels =
            prelimXAxisLabelSpacing - maxXAxisLabelSize < 12;
          const scaledXAxisHeight =
            // include offset from the vertical size of the font (visx, y-u-do-dis)
            (prelimRotateLabels
              ? (maxXAxisLabelSize + AXIS_FONT_SIZE) *
                Math.sin(Math.abs(LABEL_ANGLE_OF_ROTATION * Math.PI) / 180)
              : AXIS_FONT_SIZE) + AXIS_VISX_PADDING;
          const chartInteriorHeight = Math.max(
            containerHeight - scaledXAxisHeight - legendHeight,
            0,
          );
          // when the labels are diagonal, they can go past the left of the screen,
          // so we need to compensate for that here
          const leftmostLabelX = Math.min(
            ...xAxisLabelWidths.map(
              (width, i) =>
                scaledYAxisWidth +
                i * prelimXAxisLabelSpacing -
                (width + AXIS_FONT_SIZE) *
                  Math.cos(Math.abs(LABEL_ANGLE_OF_ROTATION * Math.PI) / 180),
            ),
          );

          // if the leftmost label is past the left of the screen,
          if (leftmostLabelX < 0) {
            // increase the width of the axis to compensate for the long label
            scaledYAxisWidth -= leftmostLabelX;
          }

          const chartInteriorWidth = Math.max(
            containerWidth - scaledYAxisWidth,
            0,
          );

          return {
            scaledYAxisWidth,
            scaledXAxisHeight,
            chartInteriorHeight,
            chartInteriorWidth,
          };
        }, [containerWidth, containerHeight, xDomain]);

        const xScale = scaleBand({
          domain: xDomain,
          range: [0, chartInteriorWidth],
        });

        const positivePiecewiseYScaleScaleSection = scaleLog({
          domain: [1, Math.max(Math.abs(yMin), yMax)],
          range: [chartInteriorHeight / 2, 0],
        });

        /**
         * custom d3scale function to support positive and negative ranges for a log scale
         */
        function piecewiseYScale(value: number): number {
          return value === 0
            ? chartInteriorHeight / 2
            : value < 0
              ? chartInteriorHeight / 2 +
                (chartInteriorHeight / 2 -
                  positivePiecewiseYScaleScaleSection(Math.abs(value)))
              : positivePiecewiseYScaleScaleSection(value);
        }

        // d3 scale methods used by GridLines and Axis components
        piecewiseYScale.range = () => [chartInteriorHeight, 0];
        piecewiseYScale.domain = () => [yMin, yMax];
        piecewiseYScale.ticks = () => prettyLogScaleYTicks;

        const yScale =
          yScaleType === 'logarithmic' && hasNegativeY
            ? (piecewiseYScale as YScale)
            : yScaleType === 'logarithmic'
              ? scaleLog({
                  domain: [Math.min(1, yMin || 1), yMax],
                  range: [chartInteriorHeight, 0],
                })
              : scaleLinear({
                  domain: [Math.min(0, yMin), yMax],
                  range: [chartInteriorHeight, 0],
                });

        /**
         * the tick spacing corresponds to the space between two ticks on the xAxis, so as the axis
         * changes size when the window resizes this will recalculate because the xAxis is getting smaller or bigger.
         * We then use that space number in combination with the sizes of each label to figure out the amount of space
         * leftover between the longest two labels. When that leftover space is below 32px we then remove half of the
         * labels then at 16px we remove 2/3rds.
         * The second one is just eyeballed but the first breakpoint is basically just when the labels run out of room
         * note that "longest two" is a simplification because it looks at the smallest space leftover between all label pairs
         * which might not be between the longest two labels if they aren't next to one another
         */
        const firstSeriesPoints = data.series[0]?.points || [];
        const currentTickSpacing =
          firstSeriesPoints.length < 2
            ? chartInteriorWidth / 2
            : (xScale(firstSeriesPoints[1].key) || 0) -
              (xScale(firstSeriesPoints[0].key) || 0);

        const labelSpacingOverflows = xDomain.map(
          (l) => (getStringWidth(l, AXIS_TEXT_PROPS) || 10000) / 2,
        );
        const smallestTextGap = Math.min(
          ...labelSpacingOverflows.reduce(
            (soFar, curr, idx) => [
              ...soFar,
              currentTickSpacing - curr - (labelSpacingOverflows[idx + 1] || 0),
            ],
            [] as Array<number>,
          ),
        );
        let rotateLabels = false;
        if (maxBottomLabels) {
          for (let i = 0; i < maxBottomLabels; i += 1) {
            const maxLabelWidth = chartInteriorWidth / maxBottomLabels;
            const index = Math.floor((i * xDomain.length) / maxBottomLabels);
            if (labelSpacingOverflows[index] * 2 > maxLabelWidth) {
              rotateLabels = true;
            }
          }
        } else {
          rotateLabels = smallestTextGap < 12;
        }
        const skipLabelMultiplier =
          currentTickSpacing < SECOND_OMIT_X_LABELS_BREAKPOINT
            ? 3
            : currentTickSpacing < FIRST_OMIT_X_LABELS_BREAKPOINT
              ? 2
              : 1;
        const numLabels = maxBottomLabels
          ? Math.min(maxBottomLabels, xDomain.length)
          : xDomain.length / skipLabelMultiplier;

        const chartBodyRect =
          chartBodyWrapperRef.current?.getBoundingClientRect();
        const chartBodyProps: ChartBodyProps<TooltipData> = {
          showTooltip,
          hideTooltip,
          updateTooltip,
          chartInteriorHeight,
          chartInteriorWidth,
          xScale,
          yScale,
          seriesNames,
          legendScale,
          xDomain,
          legendRange,
          hasNegativeY,
          boundingRectTop: chartBodyRect?.top ?? 0,
          boundingRectLeft: chartBodyRect?.left ?? 0,
          setPreferredTooltipPosition: setPreferredPosition,
          data: internalChartData,
          hiddenKeys,
        };

        const hasYAxisLabel = yAxisTopLabel || yAxisBottomLabel;

        return (
          <ChartContainer
            style={{
              ...(hasYAxisLabel ? { paddingTop: '12px' } : {}),
              height: containerHeight,
              width: containerWidth,
            }}
          >
            {hasYAxisLabel && (
              <FlexColumn
                style={{
                  justifyContent: 'space-between',
                  height: chartInteriorHeight + scaledXAxisHeight + 4,
                  fontSize: '12px',
                  fill: FIXED_COLORS.transcendNavy3,
                  position: 'absolute',
                  top: '-20px',
                }}
              >
                <span>{yAxisTopLabel}</span>
                <span>{yAxisBottomLabel}</span>
              </FlexColumn>
            )}
            <svg
              height={chartInteriorHeight + scaledXAxisHeight + 4}
              width={scaledYAxisWidth}
              style={{
                position: 'absolute',
                left: 0,
                top: -8,
              }}
            >
              <AxisLeft
                tickLabelProps={() => ({
                  ...AXIS_TEXT_PROPS,
                  textAnchor: 'end',
                  dy: '12px',
                })}
                tickTransform={`translate(${scaledYAxisWidth},0)`}
                numTicks={
                  yScaleType === 'logarithmic' ? prettyLogScaleYTicks.length : 5
                }
                scale={yScale}
                tickFormat={(nv) => {
                  const n = nv.valueOf();
                  if (yScaleType === 'linear') {
                    return yUnitTransformOrDefault(n);
                  }
                  // label only major ticks
                  return n.toString().match(/^(-?)[.01?[\]]*$/)
                    ? yUnitTransformOrDefault(n)
                    : '';
                }}
                hideZero={yScaleType === 'logarithmic'}
                hideAxisLine
                hideTicks
              />
            </svg>
            <div
              ref={chartBodyWrapperRef}
              style={{
                position: 'absolute',
                height: chartInteriorHeight,
                width: chartInteriorWidth,
                left: scaledYAxisWidth,
                top: 0,
              }}
            >
              <GridLines
                yScale={yScale}
                chartInteriorHeight={chartInteriorHeight}
                chartInteriorWidth={chartInteriorWidth}
                tickValues={
                  yScaleType === 'logarithmic'
                    ? prettyLogScaleYTicks
                    : undefined
                }
              />
              {hasNegativeY && (
                <svg
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: chartInteriorHeight,
                    width: chartInteriorWidth,
                  }}
                >
                  <line
                    x1={0}
                    x2={chartInteriorWidth}
                    y1={yScale(0)}
                    y2={yScale(0)}
                    stroke="black"
                    strokeDasharray="4 4"
                    strokeWidth={1}
                  />
                </svg>
              )}
              {chartBody(chartBodyProps)}
            </div>
            <svg
              style={{
                position: 'absolute',
                top: chartInteriorHeight,
                height: scaledXAxisHeight,
                left: 0,
                width: chartInteriorWidth + scaledYAxisWidth,
              }}
            >
              <g transform={`translate(${scaledYAxisWidth})`}>
                <AxisBottom
                  scale={xScale}
                  top={0}
                  hideTicks
                  hideAxisLine
                  numTicks={numLabels}
                  tickLabelProps={(xValue) => ({
                    ...AXIS_TEXT_PROPS,
                    textAnchor: rotateLabels ? 'end' : 'middle',
                    cursor:
                      Object.keys(xAxisMouseHandlerOverrides).length > 0
                        ? 'pointer'
                        : undefined,
                    angle: rotateLabels ? LABEL_ANGLE_OF_ROTATION : undefined,
                    ...mapValues(
                      xAxisMouseHandlerOverrides,
                      (fn) => fn?.(chartBodyProps, xValue),
                    ),
                  })}
                />
              </g>
            </svg>
            <div
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                height: chartInteriorHeight,
                width: chartInteriorWidth,
                top: 0,
                left: scaledYAxisWidth,
                zIndex: 999,
              }}
            >
              {tooltipOpen && (
                <VisxTooltip
                  key={tooltipOpen ? 1 : 0}
                  top={tooltipTop || 0}
                  left={tooltipLeft || 0}
                  preferredPosition={preferredTooltipPosition}
                >
                  {tooltipData && tooltip({ tooltipData })}
                </VisxTooltip>
              )}
            </div>
            {!hideLegend && (
              <LegendOrdinal scale={legendScale}>
                {(labels) => (
                  <MainStyledLegend
                    style={{
                      top: chartInteriorHeight + scaledXAxisHeight,
                      maxHeight: maxLegendHeight,
                    }}
                  >
                    {labels.map(({ text: key }) => (
                      <LegendItem
                        key={key}
                        className="hideToggle"
                        style={{
                          opacity: hiddenKeys.has(key) ? '50%' : '100%',
                        }}
                        onClick={(e) => {
                          if (e.metaKey) {
                            // cmd + click = solo
                            if (
                              hiddenKeys.size === labels.length - 1 &&
                              !hiddenKeys.has(key)
                            ) {
                              // clear the solo
                              setHiddenKeys(new Set());
                            } else {
                              // solo this key
                              setHiddenKeys(
                                new Set(
                                  labels
                                    .map(({ text }) => text)
                                    .filter((val) => val !== key),
                                ),
                              );
                            }
                          } else {
                            const newSet = new Set([...hiddenKeys]);
                            if (newSet.has(key)) {
                              newSet.delete(key);
                            } else {
                              newSet.add(key);
                            }
                            setHiddenKeys(newSet);
                          }
                        }}
                        variant="secondary"
                      >
                        <StyledVisxTooltipColorCircle
                          isOutline={false}
                          color={legendScale(key)}
                        />
                        <span
                          style={{
                            textDecoration: hiddenKeys.has(key)
                              ? 'line-through'
                              : undefined,
                          }}
                        >
                          {key}
                        </span>
                      </LegendItem>
                    ))}
                  </MainStyledLegend>
                )}
              </LegendOrdinal>
            )}
          </ChartContainer>
        );
      }}
    </ParentSize>
  );
};
/* eslint-enable react-hooks/rules-of-hooks,max-lines */
