import { defineMessages } from '@transcend-io/internationalization';

export const inventoryTabMessages = defineMessages(
  'admin-dashboard.DataMap.components.InventoryTabs.inventoryTab',
  {
    dataSilos: { defaultMessage: 'Data Silos' },
    dataPoints: { defaultMessage: 'Datapoints' },
    businessEntities: { defaultMessage: 'Business Entities' },
    vendors: { defaultMessage: 'Vendors' },
    processingActivities: { defaultMessage: 'Processing Activities' },
    purposes: { defaultMessage: 'Purposes of Processing' },
    dataCategories: { defaultMessage: 'Data Categories' },
  },
);
