import { endpoints as analyticsEndpoints } from '@main/analytics-types';
import {
  buildUseQuery,
  FlexColumn,
  GenericMessageDescriptor,
  Icon,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { createFrontendUuidv4 } from '@main/utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DashboardTile, DashboardTileProps } from '../../DashboardTile';
import { LineChart } from '../LineChart';
import { remapAnalyticsPrettyNames } from './helpers';
import { dashboardChartMessages } from './messages';
import { SimpleDashboardXYChartProps } from './types';

const useAnalytics = buildUseQuery(analyticsEndpoints.analyticsData);

export interface SimpleDashboardLineChartProps
  extends SimpleDashboardXYChartProps {
  /** The label for units being represented */
  unitLabel: GenericMessageDescriptor;
  /** whether to calculate the total and display as a separate series */
  calculateTotal?: boolean;
  /** force more readable tooltip position */
  forceLeftDownTooltip?: boolean;
}

/**
 * SimpleDashboardPieChart - a component to reuse the common pie chart configuration
 * for dashboards
 */
export const SimpleDashboardLineChart: React.FC<
  SimpleDashboardLineChartProps & DashboardTileProps
> = ({
  dataSource,
  prettyLabelMap,
  startDate,
  endDate,
  isTestRequest,
  defaultLabel,
  scale,
  dateKeys,
  prettySeriesMap,
  unitLabel,
  extraProps,
  calculateTotal,
  forceLeftDownTooltip = false,
  skipIfNoStartDate,
  uniqueId = '',
  appId,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [userScale, setUserScale] = useState(scale ?? 'linear');
  const input = {
    dataSource,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    isTestRequest,
    ...(uniqueId ? { uniqueId } : {}),
    ...(appId ? { appId } : {}),
    ...extraProps,
  };
  const { data, loading, error, refetch } = useAnalytics({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipIfNoStartDate && !startDate,
  });
  const relabeledData = useMemo(
    () =>
      data
        ? {
            series: remapAnalyticsPrettyNames({
              data,
              prettyLabelMap,
              prettySeriesMap,
              defaultLabel,
              dateKeys,
              formatMessageGeneric,
              calculateTotal,
            }),
          }
        : undefined,
    [data],
  );

  const hasDataPoints =
    relabeledData &&
    relabeledData.series.length > 0 &&
    relabeledData.series.some(({ points }) => points.length > 0);
  return (
    <DashboardTile
      {...other}
      header={formatMessageGeneric(other.header, {
        summaryValue:
          data?.metadata.summaryValue !== undefined
            ? Math.round(data.metadata.summaryValue * 10) / 10
            : undefined,
      })}
      percentChange={other.percentChange ?? data?.metadata.percentChange}
      cachedAt={data?.cachedAt}
      loading={loading}
      error={error}
      menuOptions={[
        {
          displayTitle: dashboardChartMessages.logScale,
          icon:
            userScale === 'logarithmic' ? <Icon type="checkmark" /> : undefined,
          onClick: () =>
            setUserScale((current) =>
              current === 'linear' ? 'logarithmic' : 'linear',
            ),
        },
      ]}
      actions={other.actions}
      onClickForceRefetch={() =>
        refetch({
          input: {
            ...input,
            forceRefetch: true,
            ...(uniqueId ? { uniqueId: createFrontendUuidv4() } : {}),
          },
        })
      }
    >
      {hasDataPoints && (
        <FlexColumn style={{ height: '300px', flexGrow: 1 }}>
          <LineChart
            data={relabeledData!}
            unitLabel={formatMessageGeneric(unitLabel) as string}
            scale={userScale}
            forceLeftDownTooltip={forceLeftDownTooltip}
            maxBottomLabels={dateKeys ? 7 : undefined}
            displayNextXValueInTooltip={dateKeys} // show the next date in the tooltip since payload returns start date
          />
        </FlexColumn>
      )}
      {!hasDataPoints &&
        !error &&
        !loading &&
        formatMessage(dashboardChartMessages.noData)}
    </DashboardTile>
  );
};
