import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventCustomFunctionCode = mkAuditEventCodec(
  AuditEventCode.CustomFunctionCode,
  t.type({
    /** ID of the custom function. */
    customFunctionId: dbModelId('customFunction'),
  }),
);

/** Override types. */
export type AuditEventCustomFunctionCode = t.TypeOf<
  typeof AuditEventCustomFunctionCode
>;

export const AuditEventCustomFunctionCodeContext = mkAuditEventCodec(
  AuditEventCode.CustomFunctionCodeContext,
  t.type({
    /** ID of the custom function. */
    customFunctionId: dbModelId('customFunction'),
  }),
);

/** Override types. */
export type AuditEventCustomFunctionCodeContext = t.TypeOf<
  typeof AuditEventCustomFunctionCodeContext
>;
