import * as t from 'io-ts';

import { UnstructuredSubDataPointRecommendationStatus } from '@transcend-io/privacy-types';
import { valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

const baseFields = {
  /** ID of the recommendation */
  unstructuredSubDataPointRecommendationId: dbModelId(
    'unstructuredSubDataPointRecommendation',
  ),
  /** ID of the data silo */
  dataSiloId: dbModelId('dataSilo'),
  /** Data sub-category */
  dataSubCategory: dbModelId('dataSubCategory'),
  /** Status of the recommendation */
  status: valuesOf(UnstructuredSubDataPointRecommendationStatus),
};

export const AuditEventUnstructuredSubDataPointRecommendation =
  mkAuditEventCodec(
    AuditEventCode.UnstructuredSubDataPointRecommendation,
    t.type(baseFields),
  );

/** Override types. */
export type AuditEventUnstructuredSubDataPointRecommendation = t.TypeOf<
  typeof AuditEventUnstructuredSubDataPointRecommendation
>;
