import * as t from 'io-ts';

export const DaemonQueueConfig = t.type({
  splitByOrgEnabled: t.boolean,
  orgsPerChunk: t.number,
  orgConcurrency: t.number,
  skipOrderBy: t.boolean,
});

/** Override types */
export type DaemonQueueConfig = t.TypeOf<typeof DaemonQueueConfig>;
