import { endpoints } from '@main/access-control-types';
import {
  Button,
  InlineTextInput,
  useInlineInput,
} from '@main/ad-core-components';
import {
  buildUseMutation,
  FlexRowCenterVertical,
  StyleUtils,
} from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useIntl } from 'react-intl';

import { actionItemsTableMessages } from '../messages';
import { RowTableContext } from '../types';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface InlineActionItemLinkProps {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  values: string[];
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
  /** are there multiple different values for this field */
  hasMultipleValues?: boolean;
}

export const InlineActionItemLink: React.FC<InlineActionItemLinkProps> = ({
  ids,
  values: initialValues,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
  hasMultipleValues,
}) => {
  const { formatMessage } = useIntl();
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );
  const { value, setValue, onBlur } = useInlineInput<string>({
    // If there are multiple different notes, show nothing as the default
    initialValue: hasMultipleValues ? '' : initialValues[0],
    optionsComparator: (value, previousValue) => isEqual(value, previousValue),
    onUpdate: (link) =>
      updateActionItems({
        variables: {
          input: {
            link,
            ...(context.count > 1
              ? {
                  queryFilters: context.filters,
                  rollupFilters: [context.rollupFilters],
                }
              : {
                  ids,
                }),
          },
        },
      }).then(({ data, errors }) => ({
        data: link,
        errors,
        success: data?.success,
      })),
  });

  let validUrl: string | undefined;
  if (!hasMultipleValues) {
    try {
      validUrl = new URL(
        value.includes('//') ? value : `https://${value}`,
      ).toString();
    } catch (e) {
      // ignore
    }
  }

  return (
    <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
      <InlineTextInput
        onBlur={() => onBlur()}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        disabled={loading}
        placeholder={formatMessage(
          hasMultipleValues
            ? actionItemsTableMessages.multipleLinks
            : actionItemsTableMessages.links,
        )}
      />
      {validUrl && (
        <Button
          icon="link"
          iconOnly
          href={validUrl}
          target="_blank"
          variant="link"
          size="sm"
        />
      )}
    </FlexRowCenterVertical>
  );
};
