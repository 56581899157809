import {
  FlexRow,
  FlexRowCenterVertical,
  Icon,
  StyleUtils,
} from '@main/core-ui';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CSSProperties } from 'styled-components';

import { Button } from '../Button';
import { InputNumber } from '../InputNumber';

export interface PaginationProps {
  /** the current page (indexes start at 1 for compatibility) */
  current: number;
  /** the total number of elements, if undefined then only shows prev/next buttons */
  total?: number;
  /** the number of elements per page, if undefined then only shows prev/next buttons */
  pageSize: number;
  /** on page change handler */
  onChange?: (newCurrentPage: number) => void;
  /** only show the total pages, a textbox, and prev/next buttons */
  simple?: boolean;
  /** should we hide this control when zero or one pages? */
  hideOnSinglePage?: boolean;
  /** the number of page buttons to display around the current page */
  numPaddingButtons?: number;
  /** style overrides */
  style?: CSSProperties;
}

/**
 * Pagination
 */
export const Pagination: React.FC<PaginationProps> = ({
  current,
  total,
  onChange,
  hideOnSinglePage,
  numPaddingButtons,
  pageSize,
  simple,
  style,
}) => {
  const { formatNumber } = useIntl();

  const totalPages = Math.ceil((total ?? 0) / pageSize);
  const numPaddingButtonsOrDefault = numPaddingButtons ?? 2;
  // shift buttons from right or left to reduce positioning changes
  const shiftFromRightToLeft = Math.max(
    0,
    current + numPaddingButtonsOrDefault - totalPages,
  );
  const shiftFromLeftToRight = Math.max(
    0,
    numPaddingButtonsOrDefault - current,
  );
  const startPage = Math.max(
    1,
    current - shiftFromRightToLeft - numPaddingButtonsOrDefault,
  );
  const endPage = Math.min(
    totalPages,
    current + shiftFromLeftToRight + numPaddingButtonsOrDefault,
  );
  const pageNumbers = useMemo(
    () =>
      new Array(endPage - startPage + 1).fill(0).map((_, i) => startPage + i),
    [startPage, endPage],
  );
  if (totalPages <= 1 && hideOnSinglePage) {
    return <></>;
  }

  return (
    <FlexRow
      style={{ gap: StyleUtils.Spacing.sm, ...style }}
      className="pagination"
    >
      <Button
        variant="secondary"
        icon="caret-left"
        iconOnly
        disabled={current <= 1}
        onClick={() => onChange?.(Math.max(1, current - 1))}
        className="pagination-previous"
      />
      {simple ? (
        <>
          <InputNumber
            initialValue={current}
            onChange={(newVal) =>
              onChange?.(Math.max(1, Math.min(totalPages, newVal)))
            }
            style={{ width: 80 }}
          />
          <FlexRowCenterVertical>/</FlexRowCenterVertical>
          <FlexRowCenterVertical>
            {formatNumber(totalPages)}
          </FlexRowCenterVertical>
        </>
      ) : (
        <>
          {startPage > 1 && (
            <>
              <Button variant="secondary" onClick={() => onChange?.(1)}>
                1
              </Button>
              {startPage > 2 && (
                <FlexRowCenterVertical>
                  <Icon type="ellipsis" />
                </FlexRowCenterVertical>
              )}
            </>
          )}
          {pageNumbers.map((num) => (
            <Button
              key={num}
              variant={num === current ? 'primary' : 'secondary'}
              onClick={num === current ? undefined : () => onChange?.(num)}
              className={`pagination-page ${num === current ? 'pagination-current' : ''}`}
            >
              {formatNumber(num)}
            </Button>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && (
                <FlexRowCenterVertical>
                  <Icon type="ellipsis" />
                </FlexRowCenterVertical>
              )}
              <Button
                variant="secondary"
                onClick={() => onChange?.(totalPages)}
              >
                {formatNumber(totalPages)}
              </Button>
            </>
          )}
        </>
      )}

      <Button
        variant="secondary"
        icon="caret-right"
        iconOnly
        disabled={current === totalPages}
        onClick={() => onChange?.(Math.min(totalPages, current + 1))}
        className="pagination-next"
      />
    </FlexRow>
  );
};
