/* eslint-disable max-lines */
import {
  ErrorAlert,
  FlexColumn,
  FlexColumnCenterVertical,
  FlexRow,
  FlexRowCenterBoth,
  FlexRowCenterVertical,
  Icon,
  IconType,
  Progress,
  StyleUtils,
} from '@main/core-ui';
import { ONE_MB } from '@main/utils';
import { map } from 'bluebird';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  AnimatedEnterExitListContainer,
  AnimatedEnterExitListItem,
  AnimatedEnterExitSingleItem,
} from '../Animation';
import { Button, commonButtonMessages } from '../Button';
import { Card } from '../Card';
import { humanFileSize } from '../Mimetype';
import { uploadNewMessages } from './messages';
import { uploadFileRaw } from './uploadFileRaw';
import { DropZoneWrapper } from './wrappers';

/**
 *
 */
interface TransformedFileResult {
  /** the updated file */
  file: any;
  /** any header overrides to be spread before making the request */
  headerOverrides?: Record<string, string | undefined>;
  /** updated filename */
  filename?: string;
  /** should we send the file as raw bytes or wrap with FormData? */
  sendRaw?: boolean;
}

/**
 * handler to transform the file before sending the xhr request
 */
type TransformFileHandler = (
  file: File,
) => TransformedFileResult | Promise<TransformedFileResult>;

export interface UploadNewProps {
  /** shrink down the upload button as much as possible */
  small?: boolean;
  /** is the component read only? */
  disabled?: boolean;
  /** should we allow multiple files in one upload? */
  multiple?: boolean;
  /** when uploading multiple files, how many can we upload simultaneously */
  uploadConcurrency?: number;
  /** the list of extensions or mime types to accept (e.g. ['csv','png','jpg', 'json', 'application/json']) */
  accept?: string[];
  /** the url to upload to */
  targetUrl: string;
  /** the HTTP method to use when uploading */
  method?: 'POST' | 'PUT';
  /** the max file size to allow in MB */
  maxFileSizeMb?: number;
  /** the icon to use */
  icon?: IconType | ReactElement;
  /** the headers to add to the request */
  headers?: Record<string, string>;
  /** whether to show the longer & clearer message or just "Upload"  */
  useShortMessage?: boolean;
  /** success handler */
  onSuccess?: (
    files: (File & {
      /** the response from the server */
      responseBody: any;
    })[],
  ) => void;
  /** error handler  */
  onError?: (error: Error) => void;
  /**
   * function to transform a file before uploading it. Returns the
   * transformed file and any necessary header overrides to include in the
   * final request
   */
  transformFile?: TransformFileHandler;
}

const DEFAULT_UPLOAD_CONCURRENCY = 2;

/**
 * UploadNew
 */
export const UploadNew: React.FC<UploadNewProps> = ({
  small,
  multiple,
  accept,
  targetUrl,
  method,
  maxFileSizeMb,
  icon,
  headers,
  useShortMessage,
  onSuccess,
  transformFile,
  onError,
  disabled,
  uploadConcurrency,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { formatMessage, formatNumber } = useIntl();
  const [abort, setAbort] = useState<Record<string, () => void>>({});
  const [loading, setLoading] = useState(false);
  const [progressMapBytes, setProgressMapBytes] = useState({});
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  // keep track of drag event depth because enter/leave is fired when hovering over
  // children too, dragDepth>0 means is currently over the component
  const [dragDepth, setDragDepth] = useState(0);
  const [uploadResponseBodies, setUploadResponseBodies] = useState<
    Record<string, any>
  >({});

  const overallProgress = useMemo(
    () =>
      Math.round(
        (sumBy(files, (file) => progressMapBytes[file.name] ?? 0) /
          Math.max(1, sumBy(files, 'size'))) *
          100,
      ),
    [files, progressMapBytes],
  );
  const filteredAllowedExtensionsOrTypes = useMemo(
    () =>
      accept
        ?.filter((ext) => !!ext.trim())
        .map((ext) =>
          ext.startsWith('.') || ext.includes('/') ? ext : `.${ext}`,
        ),
    [accept],
  );

  const doUpload = (): void => {
    setLoading(true);
    clearProgressAndErrors();
    const uploadOrder = orderBy(files, ['size'], ['asc']);
    const transformFileOrDefault: TransformFileHandler =
      transformFile ?? ((file) => ({ file }));

    map(
      uploadOrder,
      (file) =>
        new Promise<{
          /** the error for the request */
          error: Error | undefined;
          /** the file for the request */
          file: File;
          /** the body of the response */
          body: any;
        }>((resolve) => {
          Promise.resolve(transformFileOrDefault(file)).then((transformed) => {
            const abort = uploadFileRaw({
              file: transformed.file ?? file,
              method: method ?? 'POST',
              action: targetUrl,
              filename:
                transformed.filename ?? transformed.file?.name ?? file.name,
              headers: {
                ...headers,
                ...(transformed.headerOverrides ?? {}),
              },
              asForm: !transformed.sendRaw,
              withCredentials: true,
              onProgress: (event) => {
                setProgressMapBytes((curr) => ({
                  ...curr,
                  [file.name]: event.loaded,
                }));
              },
              onError: (error, body) => {
                setProgressMapBytes((curr) => ({
                  ...curr,
                  [file.name]: file.size,
                }));
                setErrors((curr) => ({
                  ...curr,
                  [file.name]: formatMessage(
                    uploadNewMessages.unexpectedError,
                    {
                      error: error.message,
                    },
                  ),
                }));
                onError?.(error);
                resolve({ error, file, body });
              },
              onSuccess: (body) => {
                setProgressMapBytes((curr) => ({
                  ...curr,
                  [file.name]: file.size,
                }));
                setUploadResponseBodies((curr) => ({
                  ...curr,
                  [file.name]: body,
                }));
                resolve({ file, body, error: undefined });
              },
            });
            setAbort((curr) => ({
              ...curr,
              [file.name]: abort.abort,
            }));
          });
        }),
      {
        concurrency: Math.max(
          uploadConcurrency ?? DEFAULT_UPLOAD_CONCURRENCY,
          1,
        ),
      },
    ).then((results) => {
      setLoading(false);
      // if any uploads completed
      const [failedFiles, finishedFiles] = partition(
        results,
        ({ error }) => !!error,
      );
      if (failedFiles.length < files.length) {
        // still call success to trigger any necessary refetches for completed
        // files but don't clear entire file list, just completed items
        onSuccess?.(
          finishedFiles.map(({ file, body }) =>
            Object.assign(file, {
              responseBody: body,
            }),
          ),
        );
        setFiles(failedFiles.map(({ file }) => file));
      }
      clearProgressAndErrors();
      clearSelectedFiles(true);
    });
  };
  const changeFiles = (fileList: FileList | null): void => {
    clearProgressAndErrors();
    const filesFromEvent = Array.from(fileList ?? []);
    const [filteredFiles, invalidFiles] = maxFileSizeMb
      ? partition(
          filesFromEvent,
          (file) =>
            file.size / ONE_MB < maxFileSizeMb &&
            (!filteredAllowedExtensionsOrTypes ||
              filteredAllowedExtensionsOrTypes.some(
                (extOrType) =>
                  (!extOrType.includes('/') && file.name.endsWith(extOrType)) ||
                  (extOrType.includes('/') && file.type === extOrType),
              )),
        )
      : [filesFromEvent, []];
    setErrors(() =>
      Object.fromEntries(
        invalidFiles.map((file) => [
          file.name,
          formatMessage(uploadNewMessages.fileTooLarge, {
            actual: humanFileSize(file.size, formatNumber),
            limit: humanFileSize(maxFileSizeMb! * ONE_MB, formatNumber),
          }),
        ]),
      ),
    );

    if (filteredFiles.length > 0) {
      setFiles(orderBy(uniqBy(filteredFiles, 'name'), 'name'));
    }
  };

  const clearProgressAndErrors = (): void => {
    setErrors({});
    setProgressMapBytes({});
  };
  const propagateClickToInput = (): void => inputRef.current?.click();
  const stopPropagation = (e): void => e.stopPropagation();

  const clearSelectedFiles = (filesAlreadySet?: boolean): void => {
    if (!filesAlreadySet) {
      setFiles([]);
    }
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <DropZoneWrapper
      style={{
        backgroundColor:
          dragDepth > 0 ? theme.colors.gray3 : theme.colors.gray2,
        ...(disabled ? { pointerEvents: 'none' } : {}),
      }}
      onDragOver={(e) => {
        // disabling dragover tells the browser you can drop stuff here
        // see: https://stackoverflow.com/a/50233827
        const event = e;
        event.stopPropagation();
        event.preventDefault();
      }}
      onDragEnter={() => {
        setDragDepth((curr) => curr + 1);
      }}
      onDragLeave={() => {
        setDragDepth((curr) => curr - 1);
      }}
      onDrop={(event) => {
        setDragDepth(0);
        event.preventDefault();
        event.stopPropagation();
        changeFiles(event.dataTransfer.files);
      }}
      onClick={propagateClickToInput}
    >
      <input
        ref={inputRef}
        disabled={loading || disabled}
        type="file"
        style={{
          display: 'none',
        }}
        multiple={multiple}
        onChange={(event) => {
          changeFiles(event.target.files);
        }}
        accept={
          filteredAllowedExtensionsOrTypes?.join(',') ||
          // if empty string after join (no elements) then just don't set
          undefined
        }
      />

      <AnimatedEnterExitSingleItem animateHeight show={files.length === 0}>
        <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.sm }}>
          {!icon || typeof icon === 'string' ? (
            <Icon size={small ? undefined : '2em'} type={icon ?? 'upload'} />
          ) : (
            icon
          )}
          {!useShortMessage ? (
            <div className="upload-hint" style={{ fontWeight: 600 }}>
              {formatMessage(
                multiple
                  ? uploadNewMessages.info
                  : uploadNewMessages.singleInfo,
              )}
            </div>
          ) : (
            formatMessage(commonButtonMessages.upload)
          )}
        </FlexRowCenterBoth>
      </AnimatedEnterExitSingleItem>
      <AnimatedEnterExitSingleItem animateHeight show={files.length > 0}>
        <FlexRow style={{ fontWeight: 600 }}>
          {formatMessage(uploadNewMessages.selectedFiles, {
            num: files.length,
            prettyNum: formatNumber(files.length),
          })}
        </FlexRow>
      </AnimatedEnterExitSingleItem>
      <AnimatedEnterExitSingleItem animateHeight show={files.length > 0}>
        <FlexColumn
          style={{
            maxHeight: '20em',
            overflowY: 'auto',
            gap: StyleUtils.Spacing.md,
          }}
        >
          <AnimatedEnterExitListContainer>
            {files.map((file, i) => (
              <AnimatedEnterExitListItem
                key={file.name}
                animateHeight
                highlightOnRemove
                highlightOnAppear
                wrapperStyle={{ flexShrink: 0 }}
              >
                <Card
                  padding={StyleUtils.Spacing.sm}
                  style={{ gap: StyleUtils.Spacing.sm }}
                  onClick={stopPropagation}
                >
                  <FlexRowCenterVertical
                    style={{
                      gap: StyleUtils.Spacing.md,
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{file.name}</div>
                    <div>{humanFileSize(file.size, formatNumber)}</div>
                    <Button
                      icon="trash"
                      iconOnly
                      variant="outline-danger"
                      disabled={loading}
                      onClick={() => {
                        setFiles([...files.slice(0, i), ...files.slice(i + 1)]);
                      }}
                    />
                  </FlexRowCenterVertical>
                  <AnimatedEnterExitSingleItem animateHeight>
                    {!errors[file.name] ? (
                      progressMapBytes[file.name] && (
                        <Progress
                          value={Math.round(
                            ((progressMapBytes[file.name] ?? 0) / file.size) *
                              100,
                          )}
                        />
                      )
                    ) : (
                      <ErrorAlert noMarginBelow>{errors[file.name]}</ErrorAlert>
                    )}
                  </AnimatedEnterExitSingleItem>
                </Card>
              </AnimatedEnterExitListItem>
            ))}
          </AnimatedEnterExitListContainer>
        </FlexColumn>
      </AnimatedEnterExitSingleItem>
      <AnimatedEnterExitSingleItem
        animateHeight
        wrapperStyle={{
          ...StyleUtils.Flex.Row.CenterVertical,
          gap: StyleUtils.Spacing.sm,
        }}
      >
        {files.length > 0 && (
          <>
            <Button variant="secondary" icon="retry" disabled={loading}>
              {formatMessage(uploadNewMessages.changeFile)}
            </Button>
            <Button
              variant="secondary"
              icon="trash"
              size="sm"
              disabled={loading}
              onClick={(e) => {
                clearProgressAndErrors();
                clearSelectedFiles();
                stopPropagation(e);
              }}
            >
              {formatMessage(uploadNewMessages.clearFiles)}
            </Button>
          </>
        )}
      </AnimatedEnterExitSingleItem>
      {(!small || files.length > 0) && (
        <Button
          disabled={files.length === 0 || loading}
          variant={files.length === 0 ? 'secondary' : 'primary'}
          onClick={(e) => {
            doUpload();
            stopPropagation(e);
          }}
          loading={loading}
        >
          {formatMessage(uploadNewMessages.upload)}
        </Button>
      )}
      {(overallProgress > 0 || loading) && (
        <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
          <FlexColumnCenterVertical style={{ flexGrow: 1 }}>
            <Progress
              value={overallProgress}
              variant={Object.keys(errors).length > 0 ? 'danger' : undefined}
            />
          </FlexColumnCenterVertical>
          {loading && (
            <Button
              iconOnly
              icon="stop-hand"
              variant="danger"
              onClick={(e) => {
                // if any uploads completed
                const [failedFiles, finishedFiles] = partition(
                  files,
                  (file) =>
                    (progressMapBytes[file.name] ?? 0) < file.size ||
                    errors[file.name],
                );
                if (failedFiles.length < files.length) {
                  // still call success to trigger any necessary refetches for completed
                  // files but don't clear entire file list, just completed items
                  onSuccess?.(
                    finishedFiles.map((file) =>
                      Object.assign(file, {
                        responseBody: uploadResponseBodies[file.name],
                      }),
                    ),
                  );
                  setFiles(failedFiles);
                }
                failedFiles.forEach((file) => abort[file.name]?.());
                setLoading(false);
                clearSelectedFiles(true);
                clearProgressAndErrors();
                stopPropagation(e);
              }}
            />
          )}
        </FlexRowCenterVertical>
      )}
    </DropZoneWrapper>
  );
};
/* eslint-enable max-lines */
