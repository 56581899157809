import { UNKNOWN_FILE_EXTENSION } from './constants';
import mime from './mime';

/**
 * get the file extension from a mime type
 *
 * @param mimeType - the mime type
 *
 * @returns the file extension
 */
export function getFileExtensionFromMimeType(mimeType: string): string {
  return mime.getExtension(mimeType) || UNKNOWN_FILE_EXTENSION;
}
