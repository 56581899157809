import {
  BackendSyncOption,
  ConsentPrecedenceOption,
  CspOption,
  SignedIabAgreementOption,
  TelemetryPartitionStrategy,
  UnknownRequestPolicy,
  UspapiOption,
} from '@transcend-io/privacy-types';

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const SyncedConsentManagerBundleConfig = mkType({
  name: 'SyncedConsentManagerBundleConfig',
  comment:
    'Subset of configuration for a consent manager that pertains to syncing consent',
  fields: {
    syncEndpoint: {
      comment: 'The XDI sync endpoint for this airgap bundle',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SyncedConsentManagerBundleConfig = SchemaToType<
  typeof SyncedConsentManagerBundleConfig
>;

export const ConsentManagerBundleConfig = mkType({
  name: 'ConsentManagerBundleConfig',
  comment: 'Configuration for the Consent Manager',
  fields: {
    domains: {
      comment: 'Domains that a consent manager is allowed to run on.',
      type: 'string',
      list: true,
    },
    csp: {
      comment: 'CSP protection configuration.',
      type: { CspOption },
    },
    unknownRequestPolicy: {
      comment: 'The consent manager unknown request policy',
      type: { UnknownRequestPolicy },
    },
    unknownCookiePolicy: {
      comment: 'The consent manager unknown cookie policy',
      type: { UnknownRequestPolicy },
    },
    syncEndpoint: {
      comment: 'The XDI sync endpoint for this airgap bundle',
      type: 'string',
      optional: true,
    },
    telemetryPartitioning: {
      comment: 'The telemetry partitioning strategy',
      type: { TelemetryPartitionStrategy },
    },
    telemetrySampleRate: {
      comment: 'The Sampling Rate for telemetry sessions',
      type: 'string',
      optional: true,
    },
    /** @deprecated - TODO: https://transcend.height.app/T-28974 - remove */
    reqCookieSampleRate: {
      comment:
        'Client-side sampling rate for outgoing requests and cookie mutations',
      type: 'string',
      optional: true,
    },
    eventSampleRate: {
      comment:
        'Client-side sampling rate for outgoing requests and cookie mutations',
      type: 'string',
      optional: true,
    },
    signedIabAgreement: {
      comment: 'Whether the site owner has signed the IAB agreement',
      type: { SignedIabAgreementOption },
      optional: true,
    },
    uspapi: {
      comment: 'Whether or not to use the US Privacy API',
      type: { UspapiOption },
      optional: true,
    },
    syncGroups: {
      comment:
        'The Shared XDI host sync groups config (JSON) for this airgap bundle',
      type: 'string',
      optional: true,
    },
    partition: {
      comment: 'Key used to partition consent records',
      type: 'string',
      optional: true,
    },
    consentPrecedence: {
      comment: 'The consent resolution precedence strategy',
      type: { ConsentPrecedenceOption },
    },
    backendSync: {
      comment: 'Backend consent sync',
      type: { BackendSyncOption },
      optional: true,
    },
    backendSyncEndpoint: {
      comment: 'Backend consent sync endpoint URL',
      type: 'string',
      optional: true,
    },
    useDefaultTcfSettings: {
      comment: 'Whether to use the default settings for TCF',
      type: 'boolean',
      optional: true,
    },
    strategy: {
      comment: 'How the Consent Manager should partition telemetry data',
      type: { TelemetryPartitionStrategy },
      optional: true,
    },
  },
});

/** Override type */
export type ConsentManagerBundleConfig = SchemaToType<
  typeof ConsentManagerBundleConfig
>;

export const ConsentManagerBundleConfigInput = mkInput({
  name: 'ConsentManagerBundleConfigInput',
  comment: 'Configuration for the Consent Manager',
  fields: {
    domains: {
      comment:
        'Domains that a consent manager is allowed to run on. Defaults to the organization URI.',
      type: 'string',
      list: true,
      optional: true,
    },
    csp: {
      comment: 'CSP protection configuration. Defaults to Lax.',
      type: { CspOption },
      optional: true,
    },
  },
});

/** Override type */
export type ConsentManagerBundleConfigInput = SchemaToType<
  typeof ConsentManagerBundleConfigInput
>;
