import {
  AdminDashboardInfrastructurePath,
  Resource,
} from '@main/access-control-types';
import { A, Avatar, StyleUtils } from '@main/core-ui';
import React from 'react';
import { generatePath } from 'react-router-dom';

/**
 * Preview a data silo
 */
export const DataSiloPreview: React.FC<Resource> = ({ id, logo, title }) => (
  <div key={id} style={{ paddingBottom: '5px' }}>
    <A
      href={generatePath(
        AdminDashboardInfrastructurePath.ServiceConfiguration,
        {
          dataSiloId: id,
        },
      )}
      style={{
        ...StyleUtils.Flex.Row.CenterVertical,
        gap: StyleUtils.Spacing.sm,
      }}
    >
      <Avatar circle={false} src={logo} />
      <span>{title}</span>
    </A>
  </div>
);
