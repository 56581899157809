/* eslint-disable max-lines */
import * as t from 'io-ts';

import {
  AuditEventActionItem,
  AuditEventAirgapBundle,
  AuditEventAirgapBundleDeployment,
  AuditEventAirgapBundleTcfStack,
  AuditEventAirgapBundleVersion,
  AuditEventAirgapCookie,
  AuditEventAirgapCookieAttribute,
  AuditEventAirgapCookieOwner,
  AuditEventAirgapCookiePurpose,
  AuditEventAirgapCookieTeam,
  AuditEventAirgapDataFlow,
  AuditEventAirgapDataFlowAttribute,
  AuditEventAirgapDataFlowOwner,
  AuditEventAirgapDataFlowPurpose,
  AuditEventAirgapDataFlowTeam,
  AuditEventAirgapPartition,
  AuditEventAirgapTheme,
  AuditEventAssessmentAnswer,
  AuditEventAssessmentEmailSet,
  AuditEventAssessmentForm,
  AuditEventAssessmentFormAssignee,
  AuditEventAssessmentFormComment,
  AuditEventAssessmentFormReviewer,
  AuditEventAssessmentFormTemplate,
  AuditEventAssessmentGroup,
  AuditEventAssessmentQuestion,
  AuditEventAssessmentQuestionComment,
  AuditEventAssessmentSection,
  AuditEventAssessmentSectionAssignee,
  AuditEventAssessmentSectionComment,
  AuditEventAssetFile,
  AuditEventBusinessEntity,
  AuditEventBusinessEntityAttribute,
  AuditEventBusinessEntityOwner,
  AuditEventBusinessEntityTeam,
  AuditEventConsentService,
  AuditEventCustomFunctionCode,
  AuditEventCustomFunctionCodeContext,
  AuditEventDataCollection,
  AuditEventDataPoint,
  AuditEventDataPointAction,
  AuditEventDataPointLevelDescription,
  AuditEventDataPointLevelOwner,
  AuditEventDataPointLevelTeam,
  AuditEventDataPointOwner,
  AuditEventDataPointTeam,
  AuditEventDataReport,
  AuditEventDataSilo,
  AuditEventDataSiloApiKey,
  AuditEventDataSiloAttribute,
  AuditEventDataSiloBusinessEntity,
  AuditEventDataSiloDataSilo,
  AuditEventDataSiloDataSubCategory,
  AuditEventDataSiloDependentDataSilo,
  AuditEventDataSiloOwner,
  AuditEventDataSiloPlugin,
  AuditEventDataSiloProcessingPurposeSubCategory,
  AuditEventDataSiloSaaSCategory,
  AuditEventDataSiloSubjectDenies,
  AuditEventDataSiloTeam,
  AuditEventDataSiloVendor,
  AuditEventDataSubCategory,
  AuditEventDataSubCategoryAttribute,
  AuditEventDataSubCategoryOwner,
  AuditEventDataSubCategoryTeam,
  AuditEventEmailSenderAddress,
  AuditEventEmailSenderAddressAttributeValue,
  AuditEventExperience,
  AuditEventExperiencePurpose,
  AuditEventIdentifier,
  AuditEventIdentifierAction,
  AuditEventIdentifierDataSilo,
  AuditEventIdentifierSubject,
  AuditEventMessage,
  AuditEventOrgTcfPurpose,
  AuditEventOrgTcfPurposePurpose,
  AuditEventOrgTcfSpecialFeature,
  AuditEventOrgTcfSpecialFeaturePurpose,
  AuditEventPolicy,
  AuditEventPolicyVersion,
  AuditEventPreference,
  AuditEventPreferenceOption,
  AuditEventPreferenceTopic,
  AuditEventPrivacyCenter,
  AuditEventPrivacyCenterEmailSenderAddress,
  AuditEventProcessingPurposeSubCategory,
  AuditEventProcessingPurposeSubCategoryAttribute,
  AuditEventProcessingPurposeSubCategoryOwner,
  AuditEventProcessingPurposeSubCategoryTeam,
  AuditEventPurpose,
  AuditEventRiskCategory,
  AuditEventRiskCategoryOwner,
  AuditEventRiskCategoryTeam,
  AuditEventRiskFramework,
  AuditEventRiskLevel,
  AuditEventRiskMatrixColumn,
  AuditEventRiskMatrixRow,
  AuditEventScannedObject,
  AuditEventSombra,
  AuditEventSombraApiKey,
  AuditEventSubDataPoint,
  AuditEventSubDataPointAttribute,
  AuditEventSubDataPointDataSubCategory,
  AuditEventSubDataPointProcessingPurposeSubCategory,
  AuditEventSubDataPointSubject,
  AuditEventTheme,
  AuditEventUnstructuredSubDataPointRecommendation,
  AuditEventVendor,
  AuditEventVendorAttribute,
  AuditEventVendorBusinessEntity,
  AuditEventVendorOwner,
  AuditEventVendorTeam,
  AuditEventWorkflowTrigger,
} from './logCodecs';
import { AuditEventBaseModelCode, AuditEventCode } from './schema/enums';

/** Union type of all audit log sub-types */
export const AuditEvent = t.union([
  AuditEventActionItem,
  AuditEventAirgapBundle,
  AuditEventAirgapBundleDeployment,
  AuditEventAirgapBundleTcfStack,
  AuditEventAirgapBundleVersion,
  AuditEventAirgapCookie,
  AuditEventAirgapCookieAttribute,
  AuditEventAirgapCookieOwner,
  AuditEventAirgapCookiePurpose,
  AuditEventAirgapCookieTeam,
  AuditEventAirgapDataFlow,
  AuditEventAirgapDataFlowAttribute,
  AuditEventAirgapDataFlowOwner,
  AuditEventAirgapDataFlowPurpose,
  AuditEventAirgapDataFlowTeam,
  AuditEventAirgapPartition,
  AuditEventAirgapTheme,
  AuditEventAssessmentAnswer,
  AuditEventAssessmentEmailSet,
  AuditEventAssessmentForm,
  AuditEventAssessmentFormAssignee,
  AuditEventAssessmentFormComment,
  AuditEventAssessmentFormReviewer,
  AuditEventAssessmentFormTemplate,
  AuditEventAssessmentGroup,
  AuditEventAssessmentQuestion,
  AuditEventAssessmentQuestionComment,
  AuditEventAssessmentSection,
  AuditEventAssessmentSectionAssignee,
  AuditEventAssessmentSectionComment,
  AuditEventAssetFile,
  AuditEventBusinessEntity,
  AuditEventBusinessEntityAttribute,
  AuditEventBusinessEntityOwner,
  AuditEventBusinessEntityTeam,
  AuditEventConsentService,
  AuditEventDataCollection,
  AuditEventDataPoint,
  AuditEventDataPointAction,
  AuditEventDataPointLevelDescription,
  AuditEventDataPointLevelOwner,
  AuditEventDataPointLevelTeam,
  AuditEventDataPointOwner,
  AuditEventDataPointTeam,
  AuditEventDataReport,
  AuditEventDataSilo,
  AuditEventDataSiloApiKey,
  AuditEventDataSiloAttribute,
  AuditEventDataSiloBusinessEntity,
  AuditEventDataSiloDataSilo,
  AuditEventDataSiloDataSubCategory,
  AuditEventDataSiloDependentDataSilo,
  AuditEventDataSiloOwner,
  AuditEventDataSiloPlugin,
  AuditEventDataSiloProcessingPurposeSubCategory,
  AuditEventDataSiloSaaSCategory,
  AuditEventDataSiloSubjectDenies,
  AuditEventDataSiloTeam,
  AuditEventDataSiloVendor,
  AuditEventDataSubCategory,
  AuditEventDataSubCategoryAttribute,
  AuditEventDataSubCategoryOwner,
  AuditEventDataSubCategoryTeam,
  AuditEventEmailSenderAddress,
  AuditEventEmailSenderAddressAttributeValue,
  AuditEventExperience,
  AuditEventExperiencePurpose,
  AuditEventIdentifier,
  AuditEventIdentifierAction,
  AuditEventIdentifierDataSilo,
  AuditEventIdentifierSubject,
  AuditEventMessage,
  AuditEventOrgTcfPurpose,
  AuditEventOrgTcfPurposePurpose,
  AuditEventOrgTcfSpecialFeature,
  AuditEventOrgTcfSpecialFeaturePurpose,
  AuditEventPolicy,
  AuditEventPolicyVersion,
  AuditEventPreference,
  AuditEventPrivacyCenter,
  AuditEventPrivacyCenterEmailSenderAddress,
  AuditEventProcessingPurposeSubCategory,
  AuditEventProcessingPurposeSubCategoryAttribute,
  AuditEventProcessingPurposeSubCategoryOwner,
  AuditEventProcessingPurposeSubCategoryTeam,
  AuditEventPurpose,
  AuditEventRiskCategory,
  AuditEventRiskCategoryOwner,
  AuditEventRiskCategoryTeam,
  AuditEventRiskFramework,
  AuditEventRiskLevel,
  AuditEventRiskMatrixColumn,
  AuditEventRiskMatrixRow,
  AuditEventScannedObject,
  AuditEventSombra,
  AuditEventSombraApiKey,
  AuditEventSubDataPoint,
  AuditEventSubDataPointAttribute,
  AuditEventSubDataPointDataSubCategory,
  AuditEventSubDataPointProcessingPurposeSubCategory,
  AuditEventSubDataPointSubject,
  AuditEventTheme,
  AuditEventUnstructuredSubDataPointRecommendation,
  AuditEventVendor,
  AuditEventVendorAttribute,
  AuditEventVendorBusinessEntity,
  AuditEventVendorOwner,
  AuditEventVendorTeam,
  AuditEventPreferenceOption,
  AuditEventPreferenceTopic,
  AuditEventWorkflowTrigger,
  AuditEventCustomFunctionCode,
  AuditEventCustomFunctionCodeContext,
]);

export const AUDIT_EVENT_BASE_MODEL_CODES: AuditEventCode[] = [
  AuditEventCode.ActionItem,
  AuditEventCode.AirgapBundle,
  AuditEventCode.AirgapBundleDeployment,
  AuditEventCode.AirgapCookie,
  AuditEventCode.AirgapDataFlow,
  AuditEventCode.AirgapPartition,
  AuditEventCode.AirgapTheme,
  AuditEventCode.AssessmentForm,
  AuditEventCode.AssessmentGroup,
  AuditEventCode.AssetFile,
  AuditEventCode.BusinessEntity,
  AuditEventCode.ConsentService,
  AuditEventCode.DataCollection,
  AuditEventCode.DataPoint,
  AuditEventCode.DataPointLevelDescription,
  AuditEventCode.DataReport,
  AuditEventCode.DataSilo,
  AuditEventCode.DataSiloPlugin,
  AuditEventCode.DataSubCategory,
  AuditEventCode.EmailSenderAddress,
  AuditEventCode.Experience,
  AuditEventCode.Identifier,
  AuditEventCode.Message,
  AuditEventCode.OrgTcfPurpose,
  AuditEventCode.OrgTcfSpecialFeature,
  AuditEventCode.Policy,
  AuditEventCode.PolicyVersion,
  AuditEventCode.Preference,
  AuditEventCode.PrivacyCenter,
  AuditEventCode.ProcessingPurposeSubCategory,
  AuditEventCode.ScannedObject,
  AuditEventCode.Sombra,
  AuditEventCode.SubDataPoint,
  AuditEventCode.Theme,
  AuditEventCode.TrackingPurpose,
  AuditEventCode.Vendor,
  AuditEventCode.RiskFramework,
  AuditEventCode.RiskLevel,
  AuditEventCode.RiskCategory,
  AuditEventCode.RiskMatrixColumn,
  AuditEventCode.RiskMatrixRow,
  AuditEventCode.PreferenceOption,
  AuditEventCode.PreferenceTopic,
  AuditEventCode.ConsentWorkflowTrigger,
];

export const AUDIT_EVENT_SENSITIVE_FIELDS = ['saaSContext'] as const;
/**
 * override types to union type
 */
export type AUDIT_EVENT_SENSITIVE_FIELDS =
  (typeof AUDIT_EVENT_SENSITIVE_FIELDS)[number];

/** Override types. */
export type AuditEvent = t.TypeOf<typeof AuditEvent>;

const codeCheck: (typeof AuditEventCode)[keyof typeof AuditEventCode] =
  '' as any;

// If this line fails to compile, you are forgetting to add a type for
// a newly added Audit Code. You need to add the type to AuditEvent above
const AuditCodeCompletenessCheck: AuditEvent['code'] = codeCheck;

// this is needed bc of the unused variable
// eslint-disable-next-line no-unused-expressions
AuditCodeCompletenessCheck;

export const AUDIT_EVENT_CODES_BASE_GROUPS: Record<
  AuditEventCode,
  AuditEventBaseModelCode[]
> = {
  [AuditEventCode.ActionItem]: [AuditEventBaseModelCode.ActionItem],
  [AuditEventCode.AssessmentAnswer]: [AuditEventBaseModelCode.AssessmentAnswer],
  [AuditEventCode.AssessmentFormTemplate]: [
    AuditEventBaseModelCode.AssessmentFormTemplate,
  ],
  [AuditEventCode.AssessmentEmailSet]: [
    AuditEventBaseModelCode.AssessmentEmailSet,
  ],
  [AuditEventCode.AssessmentForm]: [AuditEventBaseModelCode.AssessmentForm],
  [AuditEventCode.AssessmentGroup]: [AuditEventBaseModelCode.AssessmentGroup],
  [AuditEventCode.AssessmentFormAssignee]: [
    AuditEventBaseModelCode.AssessmentForm,
  ],
  [AuditEventCode.AssessmentSectionAssignee]: [
    AuditEventBaseModelCode.AssessmentSection,
  ],
  [AuditEventCode.AssessmentFormComment]: [
    AuditEventBaseModelCode.AssessmentForm,
  ],
  [AuditEventCode.AssessmentFormReviewer]: [
    AuditEventBaseModelCode.AssessmentForm,
  ],
  [AuditEventCode.AssessmentQuestion]: [
    AuditEventBaseModelCode.AssessmentQuestion,
  ],
  [AuditEventCode.AssessmentQuestionComment]: [
    AuditEventBaseModelCode.AssessmentQuestionComment,
  ],
  [AuditEventCode.AssessmentSection]: [
    AuditEventBaseModelCode.AssessmentSection,
  ],
  [AuditEventCode.AssessmentSectionComment]: [
    AuditEventBaseModelCode.AssessmentSectionComment,
  ],
  [AuditEventCode.AirgapBundle]: [AuditEventBaseModelCode.AirgapBundle],
  [AuditEventCode.AirgapBundleVersion]: [AuditEventBaseModelCode.AirgapBundle],
  [AuditEventCode.AirgapBundleDeployment]: [
    AuditEventBaseModelCode.AirgapBundleDeployment,
  ],
  [AuditEventCode.AirgapCookie]: [AuditEventBaseModelCode.AirgapCookie],
  [AuditEventCode.AirgapCookieTeam]: [AuditEventBaseModelCode.AirgapCookie],
  [AuditEventCode.AirgapCookieOwner]: [AuditEventBaseModelCode.AirgapCookie],
  [AuditEventCode.AirgapCookieAttribute]: [
    AuditEventBaseModelCode.AirgapCookie,
  ],
  [AuditEventCode.AirgapCookiePurpose]: [AuditEventBaseModelCode.AirgapCookie],
  [AuditEventCode.AirgapDataFlow]: [AuditEventBaseModelCode.AirgapDataFlow],
  [AuditEventCode.AirgapDataFlowTeam]: [AuditEventBaseModelCode.AirgapDataFlow],
  [AuditEventCode.AirgapDataFlowOwner]: [
    AuditEventBaseModelCode.AirgapDataFlow,
  ],
  [AuditEventCode.AirgapDataFlowAttribute]: [
    AuditEventBaseModelCode.AirgapDataFlow,
  ],
  [AuditEventCode.AirgapDataFlowPurpose]: [
    AuditEventBaseModelCode.AirgapDataFlow,
  ],
  [AuditEventCode.AirgapTheme]: [AuditEventBaseModelCode.AirgapTheme],
  [AuditEventCode.ConsentService]: [AuditEventBaseModelCode.ConsentService],
  [AuditEventCode.DataPoint]: [AuditEventBaseModelCode.DataPoint],
  [AuditEventCode.DataPointAction]: [AuditEventBaseModelCode.DataPoint],
  [AuditEventCode.DataPointLevelDescription]: [
    AuditEventBaseModelCode.DataPointLevel,
  ],
  [AuditEventCode.DataPointLevelOwner]: [
    AuditEventBaseModelCode.DataPointLevel,
  ],
  [AuditEventCode.DataPointLevelTeam]: [AuditEventBaseModelCode.DataPointLevel],
  [AuditEventCode.DataPointOwner]: [AuditEventBaseModelCode.DataPoint],
  [AuditEventCode.DataPointTeam]: [AuditEventBaseModelCode.DataPoint],
  [AuditEventCode.DataReport]: [AuditEventBaseModelCode.DataReport],
  [AuditEventCode.DataSilo]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloApiKey]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloVendor]: [
    AuditEventBaseModelCode.Vendor,
    AuditEventBaseModelCode.DataSilo,
  ],
  [AuditEventCode.DataSiloBusinessEntity]: [
    AuditEventBaseModelCode.BusinessEntity,
    AuditEventBaseModelCode.DataSilo,
  ],
  [AuditEventCode.DataSiloAttribute]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloDependentDataSilo]: [
    AuditEventBaseModelCode.DataSilo,
  ],
  [AuditEventCode.Experience]: [AuditEventBaseModelCode.Experience],
  [AuditEventCode.ExperiencePurpose]: [AuditEventBaseModelCode.Experience],
  [AuditEventCode.TrackingPurpose]: [AuditEventBaseModelCode.TrackingPurpose],
  [AuditEventCode.VendorOwner]: [AuditEventBaseModelCode.Vendor],
  [AuditEventCode.VendorTeam]: [AuditEventBaseModelCode.Vendor],
  [AuditEventCode.RiskCategoryTeam]: [AuditEventBaseModelCode.RiskCategory],
  [AuditEventCode.RiskCategoryOwner]: [AuditEventBaseModelCode.RiskCategory],
  [AuditEventCode.Vendor]: [AuditEventBaseModelCode.Vendor],
  [AuditEventCode.VendorAttribute]: [AuditEventBaseModelCode.Vendor],
  [AuditEventCode.VendorBusinessEntity]: [
    AuditEventBaseModelCode.Vendor,
    AuditEventBaseModelCode.BusinessEntity,
  ],
  [AuditEventCode.BusinessEntityAttribute]: [
    AuditEventBaseModelCode.BusinessEntity,
  ],
  [AuditEventCode.BusinessEntityTeam]: [AuditEventBaseModelCode.BusinessEntity],
  [AuditEventCode.BusinessEntityOwner]: [
    AuditEventBaseModelCode.BusinessEntity,
  ],
  [AuditEventCode.Preference]: [AuditEventBaseModelCode.Preference],
  [AuditEventCode.ProcessingPurposeSubCategoryAttribute]: [
    AuditEventBaseModelCode.ProcessingPurposeSubCategory,
  ],
  [AuditEventCode.ProcessingPurposeSubCategoryTeam]: [
    AuditEventBaseModelCode.ProcessingPurposeSubCategory,
  ],
  [AuditEventCode.ProcessingPurposeSubCategoryOwner]: [
    AuditEventBaseModelCode.ProcessingPurposeSubCategory,
  ],
  [AuditEventCode.DataSubCategoryTeam]: [
    AuditEventBaseModelCode.DataSubCategory,
  ],
  [AuditEventCode.DataSubCategoryOwner]: [
    AuditEventBaseModelCode.DataSubCategory,
  ],
  [AuditEventCode.DataSubCategoryAttribute]: [
    AuditEventBaseModelCode.DataSubCategory,
  ],
  [AuditEventCode.BusinessEntity]: [AuditEventBaseModelCode.BusinessEntity],
  [AuditEventCode.DataSiloOwner]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloPlugin]: [AuditEventBaseModelCode.DataSiloPlugin],
  [AuditEventCode.DataSiloSubjectDenies]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloTeam]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloDataSilo]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSubCategory]: [AuditEventBaseModelCode.DataSubCategory],
  [AuditEventCode.Identifier]: [AuditEventBaseModelCode.Identifier],
  [AuditEventCode.IdentifierAction]: [AuditEventBaseModelCode.Identifier],
  [AuditEventCode.IdentifierDataSilo]: [AuditEventBaseModelCode.Identifier],
  [AuditEventCode.IdentifierSubject]: [AuditEventBaseModelCode.Identifier],
  [AuditEventCode.ProcessingPurposeSubCategory]: [
    AuditEventBaseModelCode.ProcessingPurposeSubCategory,
  ],
  [AuditEventCode.SubDataPoint]: [AuditEventBaseModelCode.SubDataPoint],
  [AuditEventCode.SubDataPointAttribute]: [
    AuditEventBaseModelCode.SubDataPoint,
  ],
  [AuditEventCode.SubDataPointDataSubCategory]: [
    AuditEventBaseModelCode.SubDataPoint,
  ],
  [AuditEventCode.SubDataPointProcessingPurposeSubCategory]: [
    AuditEventBaseModelCode.SubDataPoint,
  ],
  [AuditEventCode.SubDataPointSubject]: [AuditEventBaseModelCode.SubDataPoint],
  [AuditEventCode.AssetFile]: [AuditEventBaseModelCode.AssetFile],
  [AuditEventCode.DataCollection]: [AuditEventBaseModelCode.DataCollection],
  [AuditEventCode.Message]: [AuditEventBaseModelCode.Message],
  [AuditEventCode.Policy]: [AuditEventBaseModelCode.Policy],
  [AuditEventCode.PolicyVersion]: [AuditEventBaseModelCode.Policy],
  [AuditEventCode.PrivacyCenter]: [AuditEventBaseModelCode.PrivacyCenter],
  [AuditEventCode.RiskCategory]: [AuditEventBaseModelCode.RiskCategory],
  [AuditEventCode.RiskFramework]: [AuditEventBaseModelCode.RiskFramework],
  [AuditEventCode.RiskLevel]: [AuditEventBaseModelCode.RiskLevel],
  [AuditEventCode.RiskMatrixColumn]: [AuditEventBaseModelCode.RiskMatrixColumn],
  [AuditEventCode.RiskMatrixRow]: [AuditEventBaseModelCode.RiskMatrixRow],
  [AuditEventCode.Theme]: [AuditEventBaseModelCode.Theme],
  [AuditEventCode.EmailSenderAddress]: [
    AuditEventBaseModelCode.EmailSenderAddress,
  ],
  [AuditEventCode.PrivacyCenterEmailSenderAddress]: [
    AuditEventBaseModelCode.EmailSenderAddress,
  ],
  [AuditEventCode.EmailSenderAddressAttributeValue]: [
    AuditEventBaseModelCode.EmailSenderAddress,
  ],
  [AuditEventCode.ScannedObject]: [AuditEventBaseModelCode.ScannedObject],
  [AuditEventCode.UnstructuredSubDataPointRecommendation]: [
    AuditEventBaseModelCode.UnstructuredSubDataPointRecommendation,
  ],
  [AuditEventCode.Sombra]: [AuditEventBaseModelCode.Sombra],
  [AuditEventCode.SombraApiKey]: [AuditEventBaseModelCode.Sombra],
  [AuditEventCode.OrgTcfPurpose]: [AuditEventBaseModelCode.OrgTcfPurpose],
  [AuditEventCode.OrgTcfSpecialFeature]: [
    AuditEventBaseModelCode.OrgTcfSpecialFeature,
  ],
  [AuditEventCode.OrgTcfPurposePurpose]: [
    AuditEventBaseModelCode.OrgTcfPurpose,
  ],
  [AuditEventCode.OrgTcfSpecialFeaturePurpose]: [
    AuditEventBaseModelCode.OrgTcfSpecialFeature,
  ],
  [AuditEventCode.AirgapBundleTcfStack]: [AuditEventBaseModelCode.AirgapBundle],
  [AuditEventCode.DataSiloDataSubCategory]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.DataSiloProcessingPurposeSubCategory]: [
    AuditEventBaseModelCode.DataSilo,
  ],
  [AuditEventCode.DataSiloSaaSCategory]: [AuditEventBaseModelCode.DataSilo],
  [AuditEventCode.AirgapPartition]: [AuditEventBaseModelCode.AirgapPartition],
  [AuditEventCode.PreferenceTopic]: [AuditEventBaseModelCode.PreferenceTopic],
  [AuditEventCode.PreferenceOption]: [AuditEventBaseModelCode.PreferenceOption],
  [AuditEventCode.ConsentWorkflowTrigger]: [
    AuditEventBaseModelCode.ConsentWorkflowTrigger,
  ],
  [AuditEventCode.CustomFunctionCode]: [AuditEventBaseModelCode.CustomFunction],
  [AuditEventCode.CustomFunctionCodeContext]: [
    AuditEventBaseModelCode.CustomFunction,
  ],
};

// Pivot of AUDIT_EVENT_CODES_BASE_GROUPS
export const GROUPED_AUDIT_EVENT_CODES = (
  Object.keys(AUDIT_EVENT_CODES_BASE_GROUPS) as AuditEventCode[]
).reduce(
  (codesToGroups, auditEventCode) => {
    AUDIT_EVENT_CODES_BASE_GROUPS[auditEventCode].forEach(
      (auditEventBaseModelCode) => {
        if (!codesToGroups[auditEventBaseModelCode]) {
          // eslint-disable-next-line no-param-reassign
          codesToGroups[auditEventBaseModelCode] = [];
        }
        codesToGroups[auditEventBaseModelCode].push(auditEventCode);
      },
    );
    return codesToGroups;
  },
  {} as Record<AuditEventBaseModelCode, AuditEventCode[]>,
);
/* eslint-enable max-lines */
