import { ReactSelect, ReactSelectExtendedProps } from '@main/core-ui';
import { switchCase } from '@main/utils';
import { CodePackageType } from '@transcend-io/privacy-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { DefaultTheme } from 'styled-components';

import { codePackageTypeMessages } from './messages';

/** An option for the select component */
interface SelectCodePackageTypeOption {
  /** The value for the option */
  value: CodePackageType;
  /** The label for the option */
  label: string;
}

/** Props for the `SelectCodePackageType` component */
export interface SelectCodePackageTypeProps
  extends Omit<
    ReactSelectExtendedProps<false, SelectCodePackageTypeOption>,
    'value' | 'onBlur' | 'onChange'
  > {
  /** The value for the cell */
  value?: CodePackageType;
  /** Callback to fire when component blurs */
  onBlur?: () => void;
  /** Callback to fire when the purpose is updated */
  onChange?: (category: CodePackageType) => void;
}

export const CODE_PACKAGE_TYPE_COLOR_NAMES: (theme: DefaultTheme) => (option: {
  /** The value being selected */
  value: CodePackageType;
}) => string =
  (theme) =>
  ({ value }) =>
    switchCase({
      [CodePackageType.PackageJson]: theme.colors.blue1,
      [CodePackageType.RequirementsTxt]: theme.colors.red1,
      [CodePackageType.Gradle]: theme.colors.yellow1,
      [CodePackageType.CocoaPods]: theme.colors.purple1,
      [CodePackageType.Pubspec]: theme.colors.mint1,
      [CodePackageType.Gemfile]: theme.colors.pink1,
      [CodePackageType.ComposerJson]: theme.colors.orange1,
      [CodePackageType.Swift]: theme.colors.lime1,
    })(theme.colors.gray1)(value);

export const SelectCodePackageType: React.FC<SelectCodePackageTypeProps> = ({
  value: initialValue,
  onBlur,
  onChange,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const toOption: (value: CodePackageType) => SelectCodePackageTypeOption =
    useCallback(
      (value) => ({
        value,
        label: formatMessage(codePackageTypeMessages[value]),
      }),
      [],
    );
  const options = Object.values(CodePackageType).map(toOption);

  return (
    <ReactSelect<false, SelectCodePackageTypeOption>
      options={options}
      value={initialValue ? toOption(initialValue) : null}
      onChange={(option) => {
        if (option && onChange) {
          onChange(option.value);
        }
      }}
      onBlur={onBlur}
      isClearable={false}
      getOptionLabel={(option) => option.label}
      colorMap={CODE_PACKAGE_TYPE_COLOR_NAMES}
      {...props}
    />
  );
};
