/* eslint-disable max-lines */
import { IdentifierType, RequestAction } from '@transcend-io/privacy-types';

import { Action, DataSubject } from '@main/datamap-types';
import { LocalizedMessage } from '@main/internationalization';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import {
  CollectDataSubjectRegions,
  MissingIdBehavior,
  WorkflowCommunicationMethod,
  WorkflowConfigStatus,
  WorkflowInstanceStatus,
  WorkflowPhase,
  WorkflowStepCompletionState,
  WorkflowTrigger,
} from '../mixed-types/workflows';

export const WorkflowFilterInput = mkInput({
  name: 'WorkflowFilterInput',
  comment: 'Input for filtering workflows',
  fields: {
    subjectIds: {
      comment: 'Filter to requests for these subjects',
      modelName: 'subject',
      optional: true,
      list: true,
      type: 'id',
    },
    action: {
      comment: 'Filter only certain request types',
      type: { RequestAction },
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type WorkflowFilterInput = SchemaToType<typeof WorkflowFilterInput>;

export const WorkflowCompletedStepInterface = mkInterface({
  name: 'WorkflowCompletedStepInterface',
  comment: 'The completion state for a step in a workflow',
  fields: {
    step: {
      comment: 'The step ID',
      type: 'string',
    },
    value: {
      comment: 'The value to set the step to',
      type: { WorkflowStepCompletionState },
    },
  },
});

/** Override type */
export type WorkflowCompletedStepInterface = SchemaToType<
  typeof WorkflowCompletedStepInterface
>;

export const WorkflowCompletedStep = mkType({
  name: 'WorkflowCompletedStep',
  comment: 'The completion state for a step in a workflow',
  interfaces: [WorkflowCompletedStepInterface],
  fields: {
    ...WorkflowCompletedStepInterface.fields,
  },
});

/** Override type */
export type WorkflowCompletedStep = SchemaToType<typeof WorkflowCompletedStep>;

export const WorkflowCompletedStepInput = mkInput({
  name: 'WorkflowCompletedStepInput',
  comment: 'Input for updating workflow step completion state',
  interfaces: [WorkflowCompletedStepInterface],
  fields: {
    ...WorkflowCompletedStepInterface.fields,
  },
});

/** Override type */
export type WorkflowCompletedStepInput = SchemaToType<
  typeof WorkflowCompletedStepInput
>;

export const UpdateWorkflowConfigInput = mkInput({
  name: 'UpdateWorkflowConfigInput',
  comment: 'Input for updating a workflow config',
  fields: {
    workflowConfigId: {
      comment:
        'Id of the initial workflow config being updated even though the end config might be a new version',
      optional: false,
      type: 'id',
      modelName: 'workflowConfig',
    },
    actionType: {
      comment:
        'DSR action type for this workflow config, will create new version',
      type: { RequestAction },
      optional: true,
    },
    title: {
      comment:
        'New plaintext title for the workflow config, will not create new version',
      type: 'string',
      optional: true,
    },
    subtitle: {
      comment:
        'New subtitle for the workflow config, will not create new version',
      type: 'string',
      optional: true,
    },
    description: {
      comment:
        'New description for the workflow config, will not create new version',
      type: 'string',
      optional: true,
    },
    subjectId: {
      comment: 'Data subjects for the workflow',
      modelName: 'subject',
      type: 'id',
      optional: true,
    },
    inputIdentifierIds: {
      comment: 'The input identifiers for the workflow',
      type: 'id',
      modelName: 'identifier',
      list: true,
      optional: true,
    },
    collectDataSubjectRegions: {
      comment: 'The region collection method',
      type: { CollectDataSubjectRegions },
      optional: true,
    },
    status: {
      comment: 'The status of the workflow',
      type: { WorkflowConfigStatus },
      optional: true,
    },
    completedSteps: {
      comment: 'Completion state for steps, mapping of step ID to enum',
      type: WorkflowCompletedStepInput,
      list: true,
      optional: true,
    },
    regionList: {
      comment: 'The list of regions',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateWorkflowConfigInput = SchemaToType<
  typeof UpdateWorkflowConfigInput
>;

export const CreateWorkflowInput = mkInput({
  name: 'CreateWorkflowInput',
  comment: 'Input for creating a new workflow',
  fields: {
    title: {
      comment: 'The title of the workflow',
      type: 'string',
    },
    actionType: {
      comment: 'The action of the workflow',
      type: { RequestAction },
    },
  },
});

/** Override type */
export type CreateWorkflowInput = SchemaToType<typeof CreateWorkflowInput>;

export const WorkflowDataSiloInput = mkInput({
  name: 'WorkflowDataSiloInput',
  comment: 'Input for data silos and workflows',
  fields: {
    workflowConfigId: {
      comment: 'Id of the workflow config',
      optional: false,
      type: 'id',
      modelName: 'workflowConfig',
    },
    dataSiloIds: {
      comment: 'The data silo',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
  },
});

/** Override type */
export type WorkflowDataSiloInput = SchemaToType<typeof WorkflowDataSiloInput>;

export const WorkflowConfigInput = mkInput({
  name: 'WorkflowConfigInput',
  comment: 'Input for getting the latest workflow config',
  fields: {
    id: {
      comment: 'The workflow config ID',
      type: 'id',
      modelName: 'workflowConfig',
    },
  },
});

/** Override type */
export type WorkflowConfigInput = SchemaToType<typeof WorkflowConfigInput>;

export const WorkflowConfigIdInput = mkInput({
  name: 'WorkflowConfigIdInput',
  comment: 'Input getting workflow data with a workflowConfigId',
  fields: {
    workflowConfigId: {
      comment: 'The workflow config ID',
      type: 'id',
      modelName: 'workflowConfig',
    },
  },
});

/** Override type */
export type WorkflowConfigIdInput = SchemaToType<typeof WorkflowConfigIdInput>;

export const WorkflowEnrichersInput = mkInput({
  name: 'WorkflowEnrichersInput',
  comment:
    'Input for getting specific enrichers related or not related to a workflow',
  fields: {
    ...WorkflowConfigIdInput.fields,
    notAssociatedWithWorkflowConfig: {
      comment: 'Whether to fetch those associated or not associated',
      type: 'boolean',
      optional: true,
    },
    preflightChecks: {
      comment: 'Whether to fetch enrichers that are used for preflight checks',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type WorkflowEnrichersInput = SchemaToType<
  typeof WorkflowEnrichersInput
>;

export const IdentifierDestinationDataSilo = mkType({
  name: 'IdentifierDestinationDataSilo',
  comment: 'Basic Data Silo Info',
  fields: {
    id: {
      comment: 'DataSilo id',
      modelName: 'dataSilo',
      type: 'id',
    },
    title: {
      comment: 'DataSilo title',
      type: 'string',
    },
    logo: {
      comment: 'Logo for corresponding integration catalog entry',
      type: 'string',
    },
  },
});

/** Override type */
export type IdentifierDestinationDataSilo = SchemaToType<
  typeof IdentifierDestinationDataSilo
>;

export const WorkflowIdentifierBaseAttributes = mkType({
  name: 'WorkflowIdentifierBaseAttributes',
  comment: 'Base attributes for a workflowIdentifier',
  fields: {
    name: {
      comment: 'Identifier plaintext name',
      type: 'string',
    },
    type: {
      comment: 'Identifier type',
      type: { IdentifierType },
    },
    id: {
      comment: 'identifier id',
      type: 'id',
      modelName: 'identifier',
    },
    logo: {
      comment: 'Identifier icon name (called logo in identifier resolver)',
      type: 'string',
    },
  },
});

/** Override type */
export type WorkflowIdentifierBaseAttributes = SchemaToType<
  typeof WorkflowIdentifierBaseAttributes
>;

export const IdentifierSourceEnricher = mkType({
  name: 'IdentifierSourceEnricher',
  comment: 'Enricher from which an identifier is sourced',
  fields: {
    id: {
      comment: 'Enricher id',
      modelName: 'enricher',
      type: 'id',
    },
    title: {
      comment: 'Enricher title',
      type: 'string',
    },
    logo: {
      comment: 'Enricher icon name (called logo in enricher resolver)',
      type: 'string',
    },
  },
});

/** Override type */
export type IdentifierSourceEnricher = SchemaToType<
  typeof IdentifierSourceEnricher
>;

export const WorkflowIdentifier = mkType({
  name: 'WorkflowIdentifier',
  comment: 'An identifier for a specific workflow',
  fields: {
    ...WorkflowIdentifierBaseAttributes.fields,
    sourceEnrichers: {
      comment:
        'Source enricher, if present, associated with workflow that provides this identifier',
      list: true,
      type: IdentifierSourceEnricher,
    },
    destinationDataSilos: {
      comment: 'The data silos that require this identifier for processing',
      list: true,
      type: IdentifierDestinationDataSilo,
    },
    missingIdBehavior: {
      comment:
        'The behavior to perform if an identifier is missing from the request',
      type: { MissingIdBehavior },
    },
  },
});

/** Override type */
export type WorkflowIdentifier = SchemaToType<typeof WorkflowIdentifier>;

export const WorkflowEnricher = mkType({
  name: 'WorkflowEnricher',
  comment: 'An enricher associated with a specific workflow',
  fields: {
    title: {
      comment: 'Identifier plaintext name',
      type: 'string',
    },
    id: {
      comment: 'enricher id',
      type: 'id',
      modelName: 'enricher',
    },
    logo: {
      comment: 'Enricher icon name (called logo in enricher resolver)',
      type: 'string',
    },
    inputIdentifier: {
      comment: 'Identifier that feeds into this enricher',
      type: WorkflowIdentifierBaseAttributes,
    },
    outputIdentifiers: {
      comment: 'The identifiers produced by this enricher',
      list: true,
      type: WorkflowIdentifierBaseAttributes,
    },
    identifierSources: {
      comment: 'The sources for this identifier',
      list: true,
      type: IdentifierSourceEnricher,
    },
    isDisabled: {
      comment: 'Whether the enricher is disabled',
      type: 'boolean',
    },
  },
});

/** Override type */
export type WorkflowEnricher = SchemaToType<typeof WorkflowEnricher>;

export const AddEnricherToWorkflowConfigInput = mkInput({
  name: 'AddEnricherToWorkflowConfigInput',
  comment: 'Input used to associate an enricher with a given workflow',
  fields: {
    id: {
      comment: 'Enricher id',
      type: 'id',
      modelName: 'enricher',
    },
    isIdentifierSource: {
      comment:
        'Whether the associated enricher is being used to produce additional identifiers',
      type: 'boolean',
    },
    isDisabled: {
      comment: 'Whether the enricher is disabled',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AddEnricherToWorkflowConfigInput = SchemaToType<
  typeof AddEnricherToWorkflowConfigInput
>;

export const UpdateEnrichersInWorkflowConfigSingleInput = mkInput({
  name: 'UpdateEnricherInWorkflowConfigInput',
  comment: 'Input for updating an enricher in a workflow configuration',
  fields: Object.fromEntries(
    Object.entries(AddEnricherToWorkflowConfigInput.fields).map(
      // make all fields optional except id
      ([key, value]) => [key, { ...value, optional: key !== 'id' }],
    ),
  ),
});

/** Override type */
export type UpdateEnrichersInWorkflowConfigSingleInput = SchemaToType<
  typeof UpdateEnrichersInWorkflowConfigSingleInput
>;

export const WorkflowCommunicationSettings = mkType({
  name: 'WorkflowCommunicationSettings',
  comment: 'The communication configuration for a workflow',
  fields: {
    id: {
      comment: 'The unique ID of the workflow configs communication settings',
      type: 'id',
      modelName: 'WorkflowConfigCommunication',
    },
    templateId: {
      comment: 'The ID of the associated template',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    description: {
      comment: 'The description of the communication',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'The type of communication',
      type: { WorkflowCommunicationMethod },
    },
    enabled: {
      comment: 'Whether the communication is enabled',
      type: 'boolean',
    },
  },
});

/** Override type */
export type WorkflowCommunicationSettings = SchemaToType<
  typeof WorkflowCommunicationSettings
>;

export const AddEnrichersToWorkflowConfigInput = mkInput({
  name: 'AddEnrichersToWorkflowConfigInput',
  comment: 'Input used to associate list of enrichers with a given workflow',
  fields: {
    ...WorkflowConfigIdInput.fields,
    enrichers: {
      comment: 'list of enrichers to add',
      type: AddEnricherToWorkflowConfigInput,
      list: true,
    },
  },
});

/** Override type */
export type AddEnrichersToWorkflowConfigInput = SchemaToType<
  typeof AddEnrichersToWorkflowConfigInput
>;

export const UpdateEnrichersInWorkflowConfigInput = mkInput({
  name: 'UpdateEnrichersInWorkflowConfigInput',
  comment: 'Input for updating enrichers in a workflow configuration',
  fields: {
    ...WorkflowConfigIdInput.fields,
    enrichers: {
      comment: 'list of enrichers to update',
      type: UpdateEnrichersInWorkflowConfigSingleInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateEnrichersInWorkflowConfigInput = SchemaToType<
  typeof UpdateEnrichersInWorkflowConfigInput
>;

export const RemoveEnrichersFromWorkflowConfigInput = mkInput({
  name: 'RemoveEnrichersFromWorkflowConfigInput',
  comment: 'Input used to remove enrichers from a given workflow',
  fields: {
    ...WorkflowConfigIdInput.fields,
    enrichers: {
      comment: 'list of enricher ids to remove',
      type: 'id',
      modelName: 'enricher',
      list: true,
    },
  },
});

/** Override type */
export type RemoveEnrichersFromWorkflowConfigInput = SchemaToType<
  typeof RemoveEnrichersFromWorkflowConfigInput
>;

export const WorkflowConfigIdentifier = mkType({
  name: 'WorkflowConfigIdentifier',
  comment: 'The identifier for a workflow config',
  fields: {
    id: {
      comment: 'The UUID for the workflow config identifier',
      type: 'string',
    },
    workflowConfigId: {
      comment: 'The workflow config ID',
      type: 'id',
      modelName: 'workflowConfig',
    },
    identifierId: {
      comment: 'The identifier ID',
      type: 'id',
      modelName: 'identifier',
    },
  },
});

/** Override type */
export type WorkflowConfigIdentifier = SchemaToType<
  typeof WorkflowConfigIdentifier
>;

export const WorkflowConfig = mkType({
  name: 'WorkflowConfig',
  comment: 'The configuration of a workflow - WIP',
  fields: {
    id: {
      comment: 'The unique ID of the workflow config',
      type: 'id',
      modelName: 'workflowConfig',
    },
    title: {
      comment: 'The title of the workflow',
      type: LocalizedMessage,
    },
    subtitle: {
      comment: 'The subtitle of the workflow',
      type: LocalizedMessage,
      optional: true,
    },
    description: {
      comment: 'The description of the workflow',
      type: LocalizedMessage,
      optional: true,
    },
    action: {
      comment: 'The action',
      type: Action,
    },
    status: {
      comment: 'The status of the workflow',
      type: { WorkflowConfigStatus },
    },
    lastRun: {
      comment: 'The last run of the workflow',
      type: 'Date',
      optional: true,
    },
    collectDataSubjectRegions: {
      comment: 'The region collection method',
      type: { CollectDataSubjectRegions },
    },
    subjectId: {
      comment: 'The data subject this workflow is related to',
      type: 'id',
      modelName: 'subject',
      optional: true,
    },
    WorkflowConfigIdentifiers: {
      comment: 'The related identifiers for the workflow config',
      type: WorkflowConfigIdentifier,
      list: true,
      optional: true,
    },
    subject: {
      comment: 'The data subject for this workflow',
      type: DataSubject,
      optional: true,
    },
    completedSteps: {
      comment: 'Completion state for steps, mapping of step ID to enum',
      type: WorkflowCompletedStep,
      optional: true,
      list: true,
    },
    regionList: {
      comment: 'The list of regions',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type WorkflowConfig = SchemaToType<typeof WorkflowConfig>;

export const WorkflowInstance = mkType({
  name: 'WorkflowInstance',
  comment: 'The instance of a workflow',
  fields: {
    id: {
      comment: 'The unique ID of the workflow instance',
      type: 'id',
      modelName: 'workflowInstance',
    },
    organizationId: {
      comment: 'The organization ID',
      type: 'id',
      modelName: 'organization',
    },
    workflowConfigId: {
      comment: 'The workflow config ID',
      type: 'id',
      modelName: 'workflowConfig',
      optional: true,
    },
    requestId: {
      comment: 'The request ID',
      type: 'id',
      modelName: 'request',
    },
    status: {
      comment: 'The status of the workflow',
      type: { WorkflowInstanceStatus },
    },
    phase: {
      comment: 'The phase of the workflow',
      type: { WorkflowPhase },
    },
    triggeredBy: {
      comment: 'The trigger for the workflow',
      type: { WorkflowTrigger },
    },
    scheduledAt: {
      comment: 'The scheduled time for the workflow',
      type: 'Date',
    },
  },
});

/** Override type */
export type WorkflowInstance = SchemaToType<typeof WorkflowInstance>;

export const UpdateWorkflowCommunicationSettingsInput = mkInput({
  name: 'UpdateWorkflowCommunicationSettingsInput',
  comment: 'Input for updating a workflows communication settings',
  fields: {
    id: {
      comment: 'The unique ID of the workflow configs communication settings',
      type: 'id',
      modelName: 'WorkflowConfigCommunication',
    },
    templateId: {
      comment: 'The ID of the associated template',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    description: {
      comment: 'The description of the communication',
      type: 'string',
      optional: true,
    },
    enabled: {
      comment: 'Whether the communication is enabled',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateWorkflowCommunicationSettingsInput = SchemaToType<
  typeof UpdateWorkflowCommunicationSettingsInput
>;

/* eslint-enable max-lines */
