import { makeEnum } from '@transcend-io/type-utils';

export const CONSENT_MANAGER_PATH_PREFIX = '/consent-manager';

export const AdminDashboardCmPublicPath = makeEnum({
  Signup: '/consent-manager/signup',
  ResendVerification: '/resend-verification',
});

/** Overrides type */
export type AdminDashboardCmPublicPath =
  (typeof AdminDashboardCmPublicPath)[keyof typeof AdminDashboardCmPublicPath];

export const AdminDashboardCmOnboardingPath = makeEnum({
  OnboardConsentManager: '/consent-manager/onboard/get-started',
  FlipTheScript: '/consent-manager/onboard/script',
});

/** Overrides type */
export type AdminDashboardCmOnboardingPath =
  (typeof AdminDashboardCmOnboardingPath)[keyof typeof AdminDashboardCmOnboardingPath];

/** Path prefix for cookie management */
export const COOKIE_PATH_PREFIX = '/consent-manager/cookies';

/** Path prefix for data flow management */
export const DATA_FLOW_PATH_PREFIX = '/consent-manager/data-flows';

/** Path prefix for TCF management */
export const TCF_PATH_PREFIX = '/consent-manager/tcf';

export const TcfPath = makeEnum({
  TcfVendors: `${TCF_PATH_PREFIX}/vendors`,
  TcfVendorDetails: `${TCF_PATH_PREFIX}/vendors/:tcfVendorId`,
  TcfVendorPurposes: `${TCF_PATH_PREFIX}/vendors/:tcfVendorId/purposes`,
  TcfVendorFeatures: `${TCF_PATH_PREFIX}/vendors/:tcfVendorId/features`,
  TcfPurposes: `${TCF_PATH_PREFIX}/purposes`,
  TcfFeatures: `${TCF_PATH_PREFIX}/features`,
  TcfStacks: `${TCF_PATH_PREFIX}/stacks`,
});

export const NATIVE_APPS_PATH_PREFIX = '/consent-manager/native';

export const NativeAppsPath = makeEnum({
  Applications: `${NATIVE_APPS_PATH_PREFIX}/applications`,
  Sdks: `${NATIVE_APPS_PATH_PREFIX}/sdks`,
  TriageSDKs: `${NATIVE_APPS_PATH_PREFIX}/sdks/triage-sdks`,
  TriageApplications: `${NATIVE_APPS_PATH_PREFIX}/applications/triage-applications`,
});

export const AdminDashboardCmPostOnboardingPath = makeEnum({
  ConsentManager: '/consent-manager',
  DataFlowsTriage: DATA_FLOW_PATH_PREFIX,
  DataFlowsApproved: `${DATA_FLOW_PATH_PREFIX}/approved`,
  DataFlowsJunk: `${DATA_FLOW_PATH_PREFIX}/junk`,
  CookiesTriage: COOKIE_PATH_PREFIX,
  CookiesApproved: `${COOKIE_PATH_PREFIX}/approved`,
  CookiesJunk: `${COOKIE_PATH_PREFIX}/junk`,
  DisplaySettings: '/consent-manager/display-settings',
  DisplaySettingsMessages: '/consent-manager/display-settings/messages',
  DeveloperSettings: '/consent-manager/developer-settings',
  Installation: '/consent-manager/developer-settings/installation',
  OtherConsentFrameworks:
    '/consent-manager/developer-settings/other-consent-frameworks',
  AdvancedSettings: '/consent-manager/developer-settings/advanced-settings',
  AirgapChangeLog: '/consent-manager/developer-settings/changelog',
  DeployHistory: '/consent-manager/developer-settings/deploy-history',
  RegionalExperiences: '/consent-manager/regional-experiences',
  ConsentPurposes: '/consent-manager/regional-experiences/purposes',
  AuditTrail: '/consent-manager/audit-trail',
  ActionItems: '/consent-manager/action-items',
  ConsentServices: '/consent-manager/services',
  ...TcfPath,
  ...NativeAppsPath,
});

/** Overrides type */
export type AdminDashboardCmPostOnboardingPath =
  (typeof AdminDashboardCmPostOnboardingPath)[keyof typeof AdminDashboardCmPostOnboardingPath];

export const AdminDashboardCmPath = makeEnum({
  ...AdminDashboardCmPublicPath,
  ...AdminDashboardCmOnboardingPath,
  ...AdminDashboardCmPostOnboardingPath,
});

/** Overrides type */
export type AdminDashboardCmPath =
  (typeof AdminDashboardCmPath)[keyof typeof AdminDashboardCmPath];

export const PREFERENCE_STORE_PREFIX = '/preference-store';

export const USER_PREFERENCES_PATH_PREFIX = `${PREFERENCE_STORE_PREFIX}/user-preferences`;

export const USER_PREFERENCE_TOPICS_PATH_PREFIX = `${PREFERENCE_STORE_PREFIX}/preference-topics`;

export const AdminDashboardPreferenceStorePath = makeEnum({
  PreferenceStore: `${USER_PREFERENCES_PATH_PREFIX}`,
  PreferenceStoreSettings: `${PREFERENCE_STORE_PREFIX}/settings`,
  UserPreferenceSummary: `${USER_PREFERENCES_PATH_PREFIX}/summary`,
  PreferenceTopics: `${USER_PREFERENCE_TOPICS_PATH_PREFIX}`,
  PreferenceOptions: `${USER_PREFERENCE_TOPICS_PATH_PREFIX}/preference-options`,
  ConsentWorkflows: `${PREFERENCE_STORE_PREFIX}/consent-workflows`,
  ActionItems: `${PREFERENCE_STORE_PREFIX}/action-items`,
  AuditTrail: `${PREFERENCE_STORE_PREFIX}/audit-trail`,
});

/** Overrides type */
export type AdminDashboardPreferenceStorePath =
  (typeof AdminDashboardPreferenceStorePath)[keyof typeof AdminDashboardPreferenceStorePath];
