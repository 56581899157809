import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { AdminDashboardPreferenceStorePath } from '@main/cm-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { PreferenceStoreAuditTrailLoadable } from './AuditTrail/Loadable';
import { ConsentWorkflows } from './ConsentWorkflows/Loadable';
import { preferencePageTitleMessages } from './messages';
import { PreferenceDetails } from './PreferenceDetails/Loadable';
import { PreferenceOptions } from './PreferenceOptions/Loadable';
import { PreferenceStoreSettings } from './PreferenceSettings/Loadable';
import { PreferenceStore } from './PreferenceTable/Loadable';
import { PreferenceTopics } from './PreferenceTopics/Loadable';

const preferenceStoreBreadcrumb = {
  id: 'preference-store-header',
  displayText: preferencePageTitleMessages.preferenceManagement,
  href: AdminDashboardPreferenceStorePath.PreferenceStore,
};

const consentWorkflowsBreadcrumb = [
  preferenceStoreBreadcrumb,
  {
    id: AdminDashboardPreferenceStorePath.ConsentWorkflows,
    displayText: preferencePageTitleMessages.consentWorkflows,
    href: AdminDashboardPreferenceStorePath.ConsentWorkflows,
  },
];

export const preferenceStoreRoutes: {
  [k in AdminDashboardPreferenceStorePath]: ExtendableRoute;
} = {
  [AdminDashboardPreferenceStorePath.PreferenceStore]: {
    path: AdminDashboardPreferenceStorePath.PreferenceStore,
    title: preferencePageTitleMessages.preferenceManagement,
    component: PreferenceStore,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.PreferenceStore,
        displayText: preferencePageTitleMessages.userPreferences,
        href: AdminDashboardPreferenceStorePath.PreferenceStore,
      },
    ],
  },
  [AdminDashboardPreferenceStorePath.UserPreferenceSummary]: {
    path: AdminDashboardPreferenceStorePath.UserPreferenceSummary,
    title: preferencePageTitleMessages.userPreferenceSummary,
    component: PreferenceDetails,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.PreferenceStore,
        displayText: preferencePageTitleMessages.userPreferences,
        href: AdminDashboardPreferenceStorePath.PreferenceStore,
      },
      {
        id: AdminDashboardPreferenceStorePath.UserPreferenceSummary,
        displayText: preferencePageTitleMessages.userPreferenceSummary,
        href: AdminDashboardPreferenceStorePath.UserPreferenceSummary,
      },
    ],
  },
  [AdminDashboardPreferenceStorePath.PreferenceStoreSettings]: {
    path: AdminDashboardPreferenceStorePath.PreferenceStoreSettings,
    title: preferencePageTitleMessages.settings,
    component: PreferenceStoreSettings,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.PreferenceStoreSettings,
        displayText: preferencePageTitleMessages.settings,
        href: AdminDashboardPreferenceStorePath.PreferenceStoreSettings,
      },
    ],
  },
  [AdminDashboardPreferenceStorePath.ConsentWorkflows]: {
    path: AdminDashboardPreferenceStorePath.ConsentWorkflows,
    title: preferencePageTitleMessages.consentWorkflows,
    component: ConsentWorkflows,
    breadcrumbs: consentWorkflowsBreadcrumb,
  },
  [AdminDashboardPreferenceStorePath.PreferenceTopics]: {
    path: AdminDashboardPreferenceStorePath.PreferenceTopics,
    title: preferencePageTitleMessages.consentWorkflows,
    component: PreferenceTopics,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.PreferenceTopics,
        displayText: preferencePageTitleMessages.preferenceTopics,
        href: AdminDashboardPreferenceStorePath.PreferenceTopics,
      },
    ],
  },
  [AdminDashboardPreferenceStorePath.PreferenceOptions]: {
    path: AdminDashboardPreferenceStorePath.PreferenceOptions,
    title: preferencePageTitleMessages.preferenceOptions,
    component: PreferenceOptions,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.PreferenceOptions,
        displayText: preferencePageTitleMessages.preferenceOptions,
        href: AdminDashboardPreferenceStorePath.PreferenceOptions,
      },
    ],
  },
  [AdminDashboardPreferenceStorePath.ActionItems]: {
    path: AdminDashboardPreferenceStorePath.ActionItems,
    title: navMenuMessages.linkActionItems,
    component: createOnboardingItemsComponent(
      TranscendProduct.PreferenceManagement,
    ),
    breadcrumbs: [preferenceStoreBreadcrumb],
  },
  [AdminDashboardPreferenceStorePath.AuditTrail]: {
    path: AdminDashboardPreferenceStorePath.AuditTrail,
    title: preferencePageTitleMessages.auditTrail,
    component: PreferenceStoreAuditTrailLoadable,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: AdminDashboardPreferenceStorePath.AuditTrail,
        displayText: preferencePageTitleMessages.auditTrail,
        href: AdminDashboardPreferenceStorePath.AuditTrail,
      },
    ],
  },
};
