import { defineMessages } from '@transcend-io/internationalization';

export const privacyCenterMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.privacyCenter',
  {
    deployChanges: {
      defaultMessage: 'Set Changes Live',
    },
    saveChanges: {
      defaultMessage: 'Save Changes',
    },
    viewDraft: {
      defaultMessage: 'Preview Changes',
    },
    viewLive: {
      defaultMessage: 'View Live Privacy Center',
    },
    title: {
      defaultMessage: 'Privacy Center',
    },
    successfullyDeployed: {
      defaultMessage:
        'Privacy Center was successfully deployed. It may take up to 5 minutes for ' +
        'the changes to be visible on the privacy center',
    },
  },
);

export const privacyCenterPageTitleMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.privacyCenterPageTitle',
  {
    privacyCenter: {
      defaultMessage: 'Privacy Center',
    },
    generalSettings: {
      defaultMessage: 'General Settings',
    },
    emailSettings: {
      defaultMessage: 'Email Settings',
    },
    messages: {
      defaultMessage: 'Messages & Internationalization',
    },
    policies: {
      defaultMessage: 'Policies',
    },
    assetFiles: {
      defaultMessage: 'Asset Files',
    },
    colorsAndStyles: {
      defaultMessage: 'Colors & Styles',
    },
    auditTrail: {
      defaultMessage: 'Audit Trail',
    },
    privacyCenterAuditTrail: {
      defaultMessage: 'Privacy Center Audit Trail',
    },
    requestsProcessedStats: {
      defaultMessage: 'Requests Processed Stats',
    },
    authenticationMethods: {
      defaultMessage: 'Authentication Methods',
    },
  },
);
