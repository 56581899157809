import {
  endpoints,
  GlobalActionItem,
  GlobalActionItemCollection,
  GlobalActionItemFiltersInput,
} from '@main/access-control-types';
import {
  Button,
  Card,
  commonButtonMessages,
  DropdownMenu,
  Pagination,
  Table,
} from '@main/ad-core-components';
import {
  buildUseMutation,
  buildUseQuery,
  ErrorAlert,
  FlexRow,
  FlexRowCenterVertical,
  Icon,
  LoadingSpinner,
  Progress,
  StyleUtils,
  Tooltip,
  usePagination,
} from '@main/core-ui';
import { ActionItemCode, TranscendProduct } from '@transcend-io/privacy-types';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectRequiredUser } from '../../App/selectors';
import {
  ASSIGNEE_TEAM_COLUMN,
  ASSIGNEE_USER_COLUMN,
  DUE_DATE_COLUMN,
  LINKS_COLUMN,
  NOTES_COLUMN,
  PRIORITY_COLUMN,
  RESOLVED_COLUMN,
  TASK_COLUMN,
} from '../ActionItemsTable/columns';
import { useRemapActionItemNodesToRows } from '../ActionItemsTable/components/helpers';
import { actionItemsTableMessages } from '../ActionItemsTable/messages';
import { ActionItemRow } from '../ActionItemsTable/types';
import { CreateOnboardingCollectionModal } from '../OnboardingItemsCollections/CreateOnboardingCollectionModal';
import { selectCollapsedActionItemCollections } from '../selectors';
import { hideActionItemCollection, showActionItemCollection } from '../slice';
import { CreateOnboardingItemModal } from './CreateOnboardingItemModal';
import { useExportActionItemsCsv, useImportOnboardingItemsCsv } from './hooks';
import { onboardingItemCollectionTableMessages } from './messages';

const useActionItems = buildUseQuery(endpoints.globalActionItems);
const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);
const useUpdateActionItemCollection = buildUseMutation(
  endpoints.updateActionItemCollection,
);
const useDeleteActionItemCollections = buildUseMutation(
  endpoints.deleteActionItemCollections,
);
const useDeleteActionItems = buildUseMutation(endpoints.deleteActionItems);
const PAGE_SIZE = 50;

interface OnboardingItemsTableProps {
  /** the collection to display */
  collection: GlobalActionItemCollection;
  /** the current collection location */
  productLine: TranscendProduct;
}

/**
 * OnboardingItemsTable
 */
export const OnboardingItemsTable: React.FC<OnboardingItemsTableProps> = ({
  collection,
  productLine,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const collapsedActionItemCollectionIds = useSelector(
    selectCollapsedActionItemCollections,
  );
  const collapsed = useMemo(
    () => collapsedActionItemCollectionIds.includes(collection.id),
    [collapsedActionItemCollectionIds, collection.id],
  );
  const dispatch = useDispatch();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);
  const [actionItemToEdit, setActionItemToEdit] = useState<GlobalActionItem>();
  const [updateActionItems, { loading: updateLoading }] = useUpdateActionItems({
    notifyOnNetworkStatusChange: true,
  });
  const [updateCollection] = useUpdateActionItemCollection({
    refetchQueries: ['GlobalActionItemCollections'],
  });
  const [deleteCollection] = useDeleteActionItemCollections({
    refetchQueries: ['GlobalActionItemCollections'],
    variables: { input: { ids: [collection.id] } },
  });
  const [deleteItem] = useDeleteActionItems();
  const { currentPage, setCurrentPage, useMaxPage } = usePagination({
    queryParam: 'page',
  });
  const filterBy: GlobalActionItemFiltersInput = {
    collectionId: collection.id,
    type: [ActionItemCode.Onboarding],
  };
  const { data, loading, error, refetch } = useActionItems({
    variables: {
      first: PAGE_SIZE,
      offset: (currentPage - 1) * PAGE_SIZE,
      filterBy,
    },
    fetchPolicy: 'network-only',
  });
  useMaxPage(data?.totalCount ? data.totalCount / PAGE_SIZE : undefined);
  const progressCount = useMemo(
    () => sumBy(data?.nodes, (actionItem) => (actionItem.resolved ? 1 : 0)),
    [data?.nodes],
  );

  // start as collapsed if complete
  useEffect(() => {
    if (collapsed === undefined && data) {
      if (data.nodes.length > 0 && data.nodes.every((item) => item.resolved)) {
        dispatch(hideActionItemCollection(collection.id));
      } else {
        dispatch(showActionItemCollection(collection.id));
      }
    }
  }, [data]);
  const columns = useMemo(
    () => [
      RESOLVED_COLUMN,
      TASK_COLUMN(false),
      ASSIGNEE_TEAM_COLUMN({ filters: filterBy }),
      ASSIGNEE_USER_COLUMN({ filters: filterBy }),
      DUE_DATE_COLUMN({ filters: filterBy }),
      PRIORITY_COLUMN({ filters: filterBy }),
      LINKS_COLUMN({ filters: filterBy }),
      NOTES_COLUMN({ filters: filterBy }),
    ],
    [],
  );
  const importCsvDropdownItem = useImportOnboardingItemsCsv({
    collection,
    refetch,
  });
  const exportCsvDropdownItem = useExportActionItemsCsv({ columns, filterBy });
  const user = useSelector(selectRequiredUser);
  const rows = useRemapActionItemNodesToRows(data?.nodes, user, true);

  return (
    <>
      <Card style={{ gap: StyleUtils.Spacing.sm }}>
        <FlexRowCenterVertical
          style={{ cursor: 'pointer', gap: StyleUtils.Spacing.sm }}
          onClick={() => {
            if (collapsed) {
              dispatch(showActionItemCollection(collection.id));
            } else {
              dispatch(hideActionItemCollection(collection.id));
            }
          }}
        >
          <h3 style={{ marginBottom: 0 }}>{collection.title}</h3>
          <div style={{ flexGrow: 1 }} />
          <Tooltip
            title={formatNumber(progressCount / (data?.nodes.length || 1), {
              style: 'percent',
              maximumFractionDigits: 0,
            })}
          >
            <div style={{ width: 100 }}>
              <Progress
                min={0}
                max={data?.nodes.length ?? 1}
                value={progressCount}
              />
            </div>
          </Tooltip>
          <FlexRowCenterVertical
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <DropdownMenu
              items={[
                {
                  displayTitle: commonButtonMessages.edit,
                  onClick: () => setShowUpdateModal(true),
                  icon: 'pencil',
                },
                {
                  displayTitle: collection.hidden
                    ? onboardingItemCollectionTableMessages.show
                    : onboardingItemCollectionTableMessages.hide,
                  onClick: () =>
                    updateCollection({
                      variables: {
                        input: {
                          id: collection.id,
                          hidden: !collection.hidden,
                        },
                      },
                    }),
                  icon: 'eye',
                },
                exportCsvDropdownItem,
                importCsvDropdownItem,
                {
                  displayTitle: commonButtonMessages.delete,
                  onClick: () => deleteCollection(),
                  icon: 'trash',
                },
              ]}
            />
          </FlexRowCenterVertical>
          <Button
            icon={
              <div>
                <Icon type={collapsed ? 'caret-down' : 'caret-up'} />
              </div>
            }
            iconOnly
            variant="link"
          />
        </FlexRowCenterVertical>
        {!collapsed && (
          <>
            <div>{collection.description}</div>
            {data && (
              <Table<ActionItemRow>
                data={rows}
                columns={columns}
                columnHeaderMessages={actionItemsTableMessages}
                loading={loading}
                scrollHorizontally
                renderRowActions={({ original }) => [
                  <Button
                    key="markResolved"
                    loading={updateLoading}
                    variant={original.resolved ? 'secondary' : 'primary'}
                    onClick={() =>
                      updateActionItems({
                        variables: {
                          input: {
                            ids: original.ids,
                            resolved: !original.resolved,
                          },
                        },
                      }).then(() => refetch())
                    }
                  >
                    {formatMessage(
                      original.resolved
                        ? onboardingItemCollectionTableMessages.markUnresolved
                        : onboardingItemCollectionTableMessages.markResolved,
                    )}
                  </Button>,
                  <DropdownMenu
                    key="menu"
                    items={[
                      {
                        displayTitle: commonButtonMessages.edit,
                        onClick: () => {
                          setActionItemToEdit(original);
                          setShowCreateItemModal(true);
                        },
                        icon: 'pencil',
                      },
                      {
                        displayTitle: commonButtonMessages.delete,
                        onClick: () =>
                          deleteItem({
                            variables: { input: { ids: original.ids } },
                          }).then(() => refetch()),
                        icon: 'trash',
                      },
                    ]}
                  />,
                ]}
              />
            )}
            {error && <ErrorAlert error={error} />}
            {loading && <LoadingSpinner />}
            <FlexRow>
              <Button
                onClick={() => setShowCreateItemModal(true)}
                icon="circle-add"
                variant="secondary"
              >
                {formatMessage(onboardingItemCollectionTableMessages.addItem)}
              </Button>
            </FlexRow>
            <FlexRow style={{ justifyContent: 'flex-end' }}>
              <Pagination
                onChange={(page) => {
                  setCurrentPage(page);
                }}
                current={currentPage}
                total={data ? data.totalCount : PAGE_SIZE}
                pageSize={PAGE_SIZE}
              />
            </FlexRow>
          </>
        )}
      </Card>
      {showUpdateModal && (
        <CreateOnboardingCollectionModal
          onHide={() => setShowUpdateModal(false)}
          productLine={productLine}
          collection={collection}
        />
      )}
      {showCreateItemModal && (
        <CreateOnboardingItemModal
          onHide={() => {
            setShowCreateItemModal(false);
            refetch();
          }}
          collectionId={collection.id}
          item={actionItemToEdit}
        />
      )}
    </>
  );
};
