import { defineMessages } from '@transcend-io/internationalization';

export const preferencePageTitleMessages = defineMessages(
  'admin-dashboard.PreferenceStore.preferencePageTitle',
  {
    preferenceManagement: {
      defaultMessage: 'Preference Management',
    },
    settings: {
      defaultMessage: 'Developer Settings',
    },
    preferenceTopics: {
      defaultMessage: 'Topics',
    },
    preferenceOptions: {
      defaultMessage: 'Options',
    },
    consentWorkflows: {
      defaultMessage: 'Consent Workflows',
    },
    userPreferences: {
      defaultMessage: 'User Preferences',
    },
    userPreferenceSummary: {
      defaultMessage: 'Summary',
    },
    auditTrail: {
      defaultMessage: 'Audit Trail',
    },
  },
);
