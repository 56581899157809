import { isKnownLocale, LanguageKey } from '@transcend-io/internationalization';

import type { ID } from '@main/schema-utils';
import { createFrontendUuidv4, slugify, stringToColor } from '@main/utils';

import type {
  DefinedMessage,
  LocalizedMessage,
  MessageTranslation,
  MessageWithLocalized,
} from './schema';
import type { TranslatedMessage } from './types';

/**
 * Create a localized message for testing purposes
 *
 * @param msg - The message to display
 * @param randomId - Whether to generate a random id or not
 * @returns A localized message
 */
export function createDefinedMessage(
  msg: string,
  randomId = false,
): DefinedMessage {
  return {
    // default to another deterministic function if slugify returns nothing
    id: (randomId
      ? createFrontendUuidv4()
      : slugify(msg) || stringToColor(msg)) as ID<'message'>,
    defaultMessage: msg || ' ', // avoid empty string since it will fall back to message
  };
}

/**
 * Create a localized message for testing purposes
 *
 * @deprecated
 * @param msg - The message to display
 * @param randomId - Whether to generate a random id or not
 * @returns A localized message
 */
export function createLocalizedMessage(
  msg: string | TranslatedMessage,
  randomId = false,
): LocalizedMessage {
  // If passed a message, set defaultMessage -> localizedMessage
  if (typeof msg === 'object') {
    return {
      defaultMessage: msg.defaultMessage,
      id: msg.id as ID<'message'>,
      localizedMessage: msg.defaultMessage,
    };
  }
  return {
    id: (randomId ? createFrontendUuidv4() : slugify(msg)) as ID<'message'>,
    localizedMessage: msg,
    defaultMessage: msg,
  };
}

/**
 * Create a localized message with empty translations for testing purposes
 *
 * @param msg - The message to display
 * @param randomId - Whether to generate a random id or not
 * @returns A localized message with empty translations
 */
export function createLocalizedMessageWithTranslations(
  msg: string | TranslatedMessage,
  randomId = true,
): MessageWithLocalized {
  return {
    ...createLocalizedMessage(msg, randomId),
    translations: [],
  };
}

/**
 * Update a translation for a particular message
 *
 * @param msg - The message to update
 * @param value - The translation to update
 * @param locale - The language to update
 * @returns The message with the translation updated or created
 */
export function updateOrCreateTranslation<
  T extends {
    /** Translations */
    translations: MessageTranslation[];
  },
>(msg: T, value: string, locale: LanguageKey): T {
  let updated = false;
  const translations = msg.translations.map((tr) => {
    if (tr.locale === locale) {
      updated = true;
      return { ...tr, value };
    }
    return tr;
  });
  return {
    ...msg,
    translations: updated ? translations : [...translations, { locale, value }],
  };
}

/**
 * Update a translation for a particular message
 *
 * @param msg - The message to update
 * @param locale - The language to fetch
 * @returns The message with the translation updated or created
 */
export function getTranslation(
  msg: {
    /** Translations */
    translations: MessageTranslation[];
  },
  locale: LanguageKey,
): string {
  return (
    msg.translations.find((translation) => translation.locale === locale)
      ?.value || ''
  );
}

/**
 * Convert translations to an object keyed by locale
 *
 * @param translations - List of translations
 * @returns The translated message style
 */
export function translationsToObject(translations: MessageTranslation[]): {
  [k in LanguageKey]?: string;
} {
  return translations.reduce(
    (acc, tr) =>
      isKnownLocale(tr.locale)
        ? Object.assign(acc, { [tr.locale]: tr.value || null })
        : acc,
    {},
  );
}
