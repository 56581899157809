/**
 * Get error
 *
 * @param option - Option
 * @param xhr - Xhr
 * @returns Error
 */
function getError(option: UploadFileRawOptions, xhr: XMLHttpRequest): Error {
  const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
  const err: any = new Error(msg);
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.action;
  return err;
}

/**
 * Get body
 *
 * @param xhr - Request
 * @returns Body
 */
function getBody(xhr: XMLHttpRequest): any {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

interface UploadFileRawOptions {
  /** progress handler */
  onProgress: (event: ProgressEvent) => void;
  /** error handler */
  onError: (error: Error, body?: any, event?: ProgressEvent) => void;
  /** success handler */
  onSuccess: (body: any, request: XMLHttpRequest) => void;
  /** the filename being uploaded */
  filename: string;
  /** the file to upload */
  file: File;
  /** withCredentials override */
  withCredentials: boolean;
  /** the url target */
  action: string;
  /** the http method to use */
  method: string;
  /** the headers */
  headers: any;
  /** send as form data */
  asForm: boolean;
}
interface UploadFileRawResult {
  /** aborts the xhr request */
  abort: () => void;
}

/**
 * The default form upload request maker, taken from rc-upload: https://github.com/react-component/upload/blob/master/src/request.js
 *
 * @param options - Upload options
 * @returns Aborter
 */
export function uploadFileRaw(
  options: UploadFileRawOptions,
): UploadFileRawResult {
  const xhr = new XMLHttpRequest();

  if (options.onProgress && xhr.upload) {
    /**
     * Handle on progress
     *
     * @param e - The event
     */
    xhr.upload.onprogress = (e) => {
      options.onProgress(e);
    };
  }

  /**
   * Handle on error
   *
   * @param e - the event
   */
  xhr.onerror = (e) => {
    options.onError(getError(options, xhr), e);
  };

  /**
   * Handle on load
   *
   * @param e - the progress event
   * @returns null
   */
  xhr.onload = (e) => {
    // allow success when 2xx status
    // see https://github.com/react-component/upload/issues/34
    if (xhr.status < 200 || xhr.status >= 300) {
      return options.onError(getError(options, xhr), getBody(xhr), e);
    }

    options.onSuccess(getBody(xhr), xhr);
    return null;
  };

  xhr.open(options.method, options.action, true);

  // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
  if (options.withCredentials && 'withCredentials' in xhr) {
    xhr.withCredentials = true;
  }

  const headers = options.headers || {};

  // when set headers['X-Requested-With'] = null , can close default XHR header
  // see https://github.com/react-component/upload/issues/33
  if (headers['X-Requested-With'] !== null) {
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  }

  /* eslint-disable no-prototype-builtins,no-restricted-syntax */
  for (const h in headers) {
    if (headers.hasOwnProperty(h) && headers[h] !== null) {
      xhr.setRequestHeader(h, headers[h]);
    }
  }
  /* eslint-enable no-prototype-builtins,no-restricted-syntax */
  let requestBody: File | FormData = options.file;
  if (options.asForm) {
    const formData = new FormData();
    formData.append('file', options.file);
    requestBody = formData;
  }
  xhr.send(requestBody);

  return {
    /** Abort the request */
    abort() {
      xhr.abort();
    },
  };
}
