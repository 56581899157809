import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardDataMapPath,
  CONTENT_CLASSIFICATION_PATH_PREFIX,
  UNSTRUCTURED_DISCOVERY_PATH_PREFIX,
} from '@main/datamap-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { DataInventoryAuditTrail } from './Audit/Loadable';
import { BusinessEntities } from './BusinessEntities/Loadable';
import { ContentClassificationDetailsLoadable } from './ClassificationDetails/Loadable';
import { ContentClassificationLoadable } from './ContentClassification/Loadable';
import { DataCategories } from './DataCategories/Loadable';
import { DataLineage } from './DataLineage/Loadable';
import { DataMappingDashboardLoadable } from './DataMappingDashboard/Loadable';
import { DataPoints } from './DataPoints/Loadable';
import { DataReports } from './DataReports/Loadable';
import { DataSilos } from './DataSilos/Loadable';
import { JunkSiloDiscovery } from './JunkSiloDiscovery/Loadable';
import { datamapPageTitleMessages } from './messages';
import { ProcessingActivities } from './ProcessingActivities/Loadable';
import { Purposes } from './Purposes/Loadable';
import { TriageSiloDiscovery } from './TriageSiloDiscovery/Loadable';
import { UnstructuredDiscoveryLoadable } from './UnstructuredDiscovery/Loadable';
import { UnstructuredDiscoveryRecommendationsLoadable } from './UnstructuredDiscoveryRecommendations/Loadable';
import { UnstructuredDiscoveryResultLoadable } from './UnstructuredDiscoveryResult/Loadable';
import { Vendors } from './Vendors/Loadable';

export const dataInventoryBreadcrumbs = [
  {
    id: 'data-inventory',
    displayText: datamapPageTitleMessages.dataInventory,
    href: AdminDashboardDataMapPath.DataSilos,
  },
  {
    id: 'data-inventory-base',
    displayText: navMenuMessages.linkDataInventoryBase,
    href: AdminDashboardDataMapPath.DataSilos,
  },
];

export const contentClassificationBreadcrumbs = [
  {
    id: CONTENT_CLASSIFICATION_PATH_PREFIX,
    displayText: navMenuMessages.linkStructuredDiscovery,
    href: AdminDashboardDataMapPath.ContentClassificationActive,
  },
];

export const siloDiscoveryBreadcrumbs = [
  {
    id: AdminDashboardDataMapPath.TriageSiloDiscovery,
    displayText: datamapPageTitleMessages.siloDiscovery,
    href: AdminDashboardDataMapPath.TriageSiloDiscovery,
  },
];

export const unstructuredDiscoveryBreadcrumbs = [
  {
    id: UNSTRUCTURED_DISCOVERY_PATH_PREFIX,
    displayText: datamapPageTitleMessages.unstructuredDiscovery,
    href: AdminDashboardDataMapPath.UnstructuredDiscoveryActive,
  },
];

export const dataMapRoutes: Record<AdminDashboardDataMapPath, ExtendableRoute> =
  {
    /**
     * DATA INVENTORY PAGES
     */
    [AdminDashboardDataMapPath.DataMappingDashboard]: {
      path: AdminDashboardDataMapPath.DataMappingDashboard,
      title: datamapPageTitleMessages.dashboardPageTitle,
      component: DataMappingDashboardLoadable,
      breadcrumbs: [
        dataInventoryBreadcrumbs[0],
        {
          id: AdminDashboardDataMapPath.DataMappingDashboard,
          displayText: datamapPageTitleMessages.dashboardMenuLabel,
          href: AdminDashboardDataMapPath.DataMappingDashboard,
        },
      ],
    },
    [AdminDashboardDataMapPath.DataSilos]: {
      path: AdminDashboardDataMapPath.DataSilos,
      title: datamapPageTitleMessages.dataInventory,
      component: DataSilos,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.DataPoints]: {
      path: AdminDashboardDataMapPath.DataPoints,
      title: datamapPageTitleMessages.dataInventory,
      component: DataPoints,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.DataCategories]: {
      path: AdminDashboardDataMapPath.DataCategories,
      title: datamapPageTitleMessages.dataInventory,
      component: DataCategories,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.Purposes]: {
      path: AdminDashboardDataMapPath.Purposes,
      title: datamapPageTitleMessages.dataInventory,
      component: Purposes,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.BusinessEntities]: {
      path: AdminDashboardDataMapPath.BusinessEntities,
      title: datamapPageTitleMessages.dataInventory,
      component: BusinessEntities,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.Vendors]: {
      path: AdminDashboardDataMapPath.Vendors,
      title: datamapPageTitleMessages.dataInventory,
      component: Vendors,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.DataInventoryAudit]: {
      path: AdminDashboardDataMapPath.DataInventoryAudit,
      title: datamapPageTitleMessages.dataInventory,
      component: DataInventoryAuditTrail,
      breadcrumbs: dataInventoryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.DataLineage]: {
      path: AdminDashboardDataMapPath.DataLineage,
      title: datamapPageTitleMessages.dataLineage,
      component: DataLineage,
      breadcrumbs: [
        {
          id: AdminDashboardDataMapPath.DataLineage,
          displayText: datamapPageTitleMessages.dataLineage,
          href: AdminDashboardDataMapPath.DataLineage,
        },
      ],
    },
    [AdminDashboardDataMapPath.ProcessingActivities]: {
      path: AdminDashboardDataMapPath.ProcessingActivities,
      title: datamapPageTitleMessages.processingActivities,
      component: ProcessingActivities,
    },
    /**
     * SILO DISCOVERY PAGES
     */
    [AdminDashboardDataMapPath.TriageSiloDiscovery]: {
      path: AdminDashboardDataMapPath.TriageSiloDiscovery,
      title: datamapPageTitleMessages.siloDiscovery,
      component: TriageSiloDiscovery,
      breadcrumbs: siloDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.JunkSiloDiscovery]: {
      path: AdminDashboardDataMapPath.JunkSiloDiscovery,
      title: datamapPageTitleMessages.siloDiscovery,
      component: JunkSiloDiscovery,
      breadcrumbs: siloDiscoveryBreadcrumbs,
    },
    /** DATA REPORT PAGES */
    [AdminDashboardDataMapPath.DataReports]: {
      path: AdminDashboardDataMapPath.DataReports,
      title: datamapPageTitleMessages.dataReports,
      component: DataReports,
      breadcrumbs: [
        dataInventoryBreadcrumbs[0],
        {
          id: AdminDashboardDataMapPath.DataReports,
          displayText: datamapPageTitleMessages.dataReports,
          href: AdminDashboardDataMapPath.DataReports,
        },
      ],
    },
    /**
     * STRUCTURED DISCOVERY PAGES
     */
    [AdminDashboardDataMapPath.ContentClassificationActive]: {
      path: AdminDashboardDataMapPath.ContentClassificationActive,
      title: datamapPageTitleMessages.contentClassification,
      component: ContentClassificationLoadable,
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationInactive]: {
      path: AdminDashboardDataMapPath.ContentClassificationInactive,
      title: datamapPageTitleMessages.contentClassification,
      component: ContentClassificationLoadable,
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationDashboard]: {
      path: AdminDashboardDataMapPath.ContentClassificationDashboard,
      component: ContentClassificationDetailsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationDetailsTrain]: {
      path: AdminDashboardDataMapPath.ContentClassificationDetailsTrain,
      component: ContentClassificationDetailsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationDetailsBrowse]: {
      path: AdminDashboardDataMapPath.ContentClassificationDetailsBrowse,
      component: ContentClassificationDetailsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationDetailsDatapoints]: {
      path: AdminDashboardDataMapPath.ContentClassificationDetailsDatapoints,
      component: ContentClassificationDetailsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ContentClassificationDetailsScanDiagnostics]: {
      path: AdminDashboardDataMapPath.ContentClassificationDetailsScanDiagnostics,
      component: ContentClassificationDetailsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: contentClassificationBreadcrumbs,
    },
    /**
     * UNSTRUCTURED DISCOVERY PAGES
     */
    [AdminDashboardDataMapPath.UnstructuredDiscoveryActive]: {
      path: AdminDashboardDataMapPath.UnstructuredDiscoveryActive,
      component: UnstructuredDiscoveryLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: unstructuredDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.UnstructuredDiscoveryInactive]: {
      path: AdminDashboardDataMapPath.UnstructuredDiscoveryInactive,
      component: UnstructuredDiscoveryLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: unstructuredDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.UnstructuredDiscoveryScanResults]: {
      path: AdminDashboardDataMapPath.UnstructuredDiscoveryScanResults,
      component: UnstructuredDiscoveryResultLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: unstructuredDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.UnstructuredDiscoveryBrowse]: {
      path: AdminDashboardDataMapPath.UnstructuredDiscoveryBrowse,
      component: UnstructuredDiscoveryResultLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: unstructuredDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.UnstructuredDiscoveryRecommendations]: {
      path: AdminDashboardDataMapPath.UnstructuredDiscoveryRecommendations,
      component: UnstructuredDiscoveryRecommendationsLoadable,
      // Fallback value. This is set on the page dynamically
      breadcrumbs: unstructuredDiscoveryBreadcrumbs,
    },
    [AdminDashboardDataMapPath.ActionItems]: {
      path: AdminDashboardDataMapPath.ActionItems,
      title: navMenuMessages.linkActionItems,
      component: createOnboardingItemsComponent(TranscendProduct.DataInventory),
      breadcrumbs: [dataInventoryBreadcrumbs[0]],
    },
  };
