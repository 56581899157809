import {
  AUDIT_EVENT_BASE_MODEL_CODES,
  AuditEventFiltersInput,
} from '@main/audit-types';
import {
  ErrorAlert,
  FlexColumn,
  FlexRowCenterVertical,
  LoadingSpinner,
  ReactSelect,
  StyleUtils,
  usePagination,
  useQueryParamJson,
} from '@main/core-ui';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import React from 'react';
import { useIntl } from 'react-intl';

import { Button, commonButtonMessages } from '../Button';
import { capitalCasifyCsvKeys, useExportGqlToCsv } from '../hooks';
import { Pagination } from '../Pagination';
import { PageSizeOption } from '../Table';
import { tableMessages } from '../Table/messages';
import { TablePaginationWrapper } from '../Table/wrappers';
import { RawTableToolbar } from '../TableToolBar';
import { TableWrapper } from '../wrappers';
import { AuditTrailFilter } from './AuditTrailFilter';
import { AdditionalFilterOptions } from './AuditTrailFilter/useAuditTrailFilters';
import { AuditTrailList } from './AuditTrailList';
import {
  buildUseAuditTrailQuery,
  buildUseAuditTrailQueryLazy,
  useFormatAuditTrailJoinMessage,
  useFormatAuditTrailMessage,
} from './hooks';
import { auditTrailMessages } from './messages';
import { AuditTrailDataViewWrapper } from './wrappers';

interface AuditTrailProps {
  /** the default filterBy inputs from the filters component */
  allowedFilters?: AuditEventFiltersInput;
  /** Filter configuration for additional properties to filter on for this audit trail */
  additionalFilters?: AdditionalFilterOptions;
}

const useAuditTrails = buildUseAuditTrailQuery();
const useAuditTrailsLazy = buildUseAuditTrailQueryLazy();

const stateCodeSet = new Set(AUDIT_EVENT_BASE_MODEL_CODES);

/**
 * AuditTrail
 */
export const CoreAuditTrail: React.FC<AuditTrailProps> = ({
  allowedFilters,
  additionalFilters,
}) => {
  const { formatMessage } = useIntl();
  const formatAuditTrailMessage = useFormatAuditTrailMessage();
  const formatAuditTrailJoinMessage = useFormatAuditTrailJoinMessage();

  const { persistQueryParam: setFilters, value: filters } =
    useQueryParamJson<AuditEventFiltersInput>({
      name: 'filters',
      defaultValue: {
        codes: [],
        actorUserIds: [],
        isAutomated: undefined,
      },
      filterOutEmptyStringsAndArrays: true,
    });
  const { currentPage, setCurrentPage, useMaxPage, pageSize, setPageSize } =
    usePagination({
      queryParam: 'page',
      pageSizeQueryParam: 'pageSize',
    });

  const queryFilters = {
    ...allowedFilters,
    ...filters,
    // limit codes to only those applicable
    codes:
      filters?.codes?.filter(
        (code) => allowedFilters?.codes?.includes(code) ?? true,
      ) ?? allowedFilters?.codes,
  };
  const { data, loading, refetch, error } = useAuditTrails({
    variables: {
      first: pageSize,
      offset: (currentPage - 1) * pageSize,
      filterBy: queryFilters,
    },
    fetchPolicy: 'cache-and-network',
  });

  const executeQuery = useAuditTrailsLazy();
  const groupedEvents = groupBy(data?.nodes, 'transactionUuid');

  const [exportToCsv, exportCsvLoading] = useExportGqlToCsv({
    fileNamePrefix: 'audit-trail',
    executeQuery,
    pageSize: 100,
    transformRowToCsvRow: ({
      additionalContext,
      beforeState,
      afterState,
      actorUser,
      ...rawEvent
    }) => {
      const currentGroup = groupedEvents[rawEvent.transactionUuid] || [];
      const firstEventInGroup = currentGroup[0];
      const isBaseModelAuditEvent =
        !firstEventInGroup || stateCodeSet.has(firstEventInGroup?.code);

      return capitalCasifyCsvKeys({
        ...omit(rawEvent, ['__typename']),
        additionalContext: JSON.stringify(additionalContext, null, 2),
        beforeState: JSON.stringify(beforeState, null, 2),
        afterState: JSON.stringify(afterState, null, 2),
        actorId: actorUser?.id ?? rawEvent.actorApiKeyId,
        actorTitle: additionalContext.actorTitle,
        // this will be helpful when filtering in Excel
        action: AUDIT_EVENT_BASE_MODEL_CODES.includes(rawEvent.code)
          ? beforeState && afterState
            ? 'UPDATED'
            : beforeState
              ? 'DELETED'
              : 'CREATED'
          : !beforeState && !afterState
            ? 'CLEARED'
            : beforeState
              ? 'REMOVED'
              : 'ADDED',
        message: isBaseModelAuditEvent
          ? formatAuditTrailMessage(
              {
                afterState,
                beforeState,
                additionalContext,
                code: rawEvent.code,
              },
              true,
            )
          : formatAuditTrailJoinMessage(
              {
                afterState: firstEventInGroup.afterState,
                beforeState: firstEventInGroup.beforeState,
                additionalContext: firstEventInGroup.additionalContext,
                code: firstEventInGroup.code,
              },
              currentGroup,
              true,
            ),
      });
    },
    getVariables: () => ({ filterBy: queryFilters }),
  });
  useMaxPage(data?.totalCount ? data.totalCount / pageSize : undefined);

  return (
    <AuditTrailDataViewWrapper>
      <RawTableToolbar
        withMargin={false}
        leftAlignedContent={[
          <AuditTrailFilter
            key="audit-filters"
            allowedCodes={allowedFilters?.codes || []}
            filters={filters}
            setFilters={setFilters}
            additionalFilters={additionalFilters}
          />,
        ]}
        rightAlignedContent={[
          <Button
            key="export-csv"
            loading={exportCsvLoading}
            onClick={exportToCsv}
            variant="secondary"
          >
            {formatMessage(commonButtonMessages.exportCsv)}
          </Button>,
          <Button
            key="refresh"
            variant="secondary"
            icon="retry"
            iconOnly
            onClick={() => refetch()}
          />,
        ]}
      />
      <TableWrapper style={{ paddingTop: '6px' }}>
        <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
          {error && <ErrorAlert error={error} />}
          {loading && <LoadingSpinner />}
          {data && <AuditTrailList events={data.nodes} />}
        </FlexColumn>
        {!loading && data && data.nodes.length === 0 && (
          <div>{formatMessage(auditTrailMessages.noEvents)}</div>
        )}
      </TableWrapper>
      <TablePaginationWrapper>
        <div style={{ fontWeight: 600 }}>
          {typeof data?.totalCount === 'number' &&
            formatMessage(auditTrailMessages.auditCount, {
              count: data.totalCount,
            })}
        </div>
        <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
          {data && (
            <Pagination
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              pageSize={pageSize}
              total={data.totalCount}
              hideOnSinglePage
            />
          )}
          <FlexRowCenterVertical>
            <ReactSelect<false, PageSizeOption>
              value={{
                value: pageSize,
                label: formatMessage(tableMessages.pageSize, {
                  number: pageSize,
                }),
              }}
              options={[10, 25, 50, 100].map((number) => ({
                value: number,
                label: formatMessage(tableMessages.pageSize, {
                  number,
                }),
              }))}
              isClearable={false}
              onChange={(selection) => {
                if (selection) {
                  setPageSize(selection.value);
                }
              }}
            />
          </FlexRowCenterVertical>
        </FlexRowCenterVertical>
      </TablePaginationWrapper>
    </AuditTrailDataViewWrapper>
  );
};
