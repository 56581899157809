/**
 * Configuration for the graphql API
 */
import { datadogLogs } from '@datadog/browser-logs';
import uniqWith from 'lodash/uniqWith';
import hash from 'object-hash';
import { fetch } from 'whatwg-fetch';

import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  logger,
  RetryLink,
} from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';
import { createFrontendUuidv4 } from '@main/utils/src/isUuid';

import possibleTypes from './fragmentTypes.json';

/**
 * Create backend link for a specific backend URL
 *
 * @param backendUrl - Backend URL
 * @returns Apollo link
 */
export function createApolloBackendLink(backendUrl: ImmutableUrl): ApolloLink {
  const uri = backendUrl.transform({ pathname: '/graphql' }).href;
  datadogLogs.logger.info(`Creating apollo backend link for uri: ${uri}`);

  const httpLink = createHttpLink({
    uri,
    // Requires whatwg-fetch to polyfill fetch in IE11
    // https://github.com/apollographql/apollo-client/issues/2780#issuecomment-388574352
    fetch,
    credentials: 'include',
  });

  // Retry requests whenever we get a network error
  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error, operation) => {
        // If the error has a statusCode, only retry server errors
        if (error && (!error.statusCode || error.statusCode >= 500)) {
          logger.error(
            `GraphQL request being retried failed for operation: "${operation?.operationName}": ${error.message}`,
          );
          return true;
        }

        logger.error(
          `GraphQL request being aborted after failed operation: "${operation?.operationName}": ${error?.message}`,
        );
        return false;
      },
    },
  });

  return concat(retryLink, httpLink);
}

const existingClients = new Map<string, ApolloClient<any>>();

/**
 * Create an apollo client for a specific backend URL
 *
 * @param backendUrl - Backend URL to use
 * @returns Apollo client
 */
export function createApolloClient(
  backendUrl: ImmutableUrl,
): ApolloClient<any> {
  const cachedClient = existingClients.get(backendUrl.href);
  if (cachedClient) {
    return cachedClient;
  }

  const client = new ApolloClient({
    name: backendUrl.href,
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
            // We can specify nested key fields using arrays
            siloDiscoveryRecommendations: {
              keyArgs: [
                'input',
                ['isPending'],
                'filterBy',
                ['text', 'pluginIds'],
              ],
            },
          },
        },
        SiloDiscoveryRecommendationsPayload: {
          keyFields: ['cacheBy'],
          fields: {
            nodes: {
              merge: (prev, incoming) => {
                const merged = [...(prev ?? []), ...incoming];
                return uniqWith(
                  merged,
                  (item1, item2) =>
                    item1.resourceId === item2.resourceId &&
                    item1.pluginId === item2.pluginId,
                );
              },
            },
          },
        },
        SubDataPoint: {
          keyFields: ['id'],
          fields: {
            pendingCategoryGuesses: {
              merge: (prev, incoming) => incoming,
            },
          },
        },
        SaaSCategory: {
          keyFields: ['id'],
          fields: {
            catalogs: {
              merge: (prev, incoming) => incoming,
            },
          },
        },
        DataLineageDataSilo: {
          // DataLineageDataSilos are not unique by id and will overwrite each other if we rely on the default cache.
          keyFields: ['id', 'receiverDataSiloId'],
        },
        AssessmentAnswerSubmission: {
          // AssessmentAnswerSubmission are not unique by id, as the same id can be shared across questions
          keyFields: ['id', 'assessmentQuestionId'],
        },
        AssessmentFormRaw: {
          // Assessments with all sections shown should not share a cache with those that have some hidden.
          // Include section ids in the cache key to catch this
          keyFields: (obj) => {
            const key = ['AssessmentFormRaw'];
            // id should always be provided
            if (typeof obj.id === 'string') {
              key.push(obj.id);
            }
            if (Array.isArray(obj.sections)) {
              key.push('sections');
              obj.sections.forEach((section) => key.push(section.id));
            }
            // mimics the style of the default cache key
            return key.join(':');
          },
        },
        BundleTcfVendor: {
          keyFields: (obj) => {
            // Rolled up objects have a top level tcf vendor id (i.e. table record uuid, not the vendor id number)
            if (obj.id != null) return `${obj.id}`;
            // Otherwise we use the consent service's id
            return obj.consentServices![0].id;
          },
        },
        GlobalActionItem: {
          keyFields: (obj) => hash(obj),
        },
        GlobalActionItemsPayload: {
          keyFields: () => createFrontendUuidv4(),
        },
      },
    }),
    connectToDevTools: true,
    link: createApolloBackendLink(backendUrl),
  });
  existingClients.set(backendUrl.href, client);
  return client;
}
