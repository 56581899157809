import { ClassificationMethod } from '@main/sombra-types';

// TODO: https://transcend.height.app/T-38875 - Utilize getClassificationMethods instead of hardcoding classification methods
export const CLASSIFICATION_METHODS: ClassificationMethod[] = [
  ClassificationMethod.LLM_API,
  ClassificationMethod.TRANSCEND_LLM_CLASSIFY,
  ClassificationMethod.CUSTOM_REGEX_CLASSIFY,
  ClassificationMethod.PROPERTY_NAME_MATCH,
];
export const DATA_CATEGORY_READ_ONLY = {
  id: null,
  category: null,
  name: null,
} as const;
