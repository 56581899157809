import React, { ReactNode, useMemo } from 'react';

import { Table } from '../Table';
import { dnsRecordTableHeaderMessages } from './messages';

export interface DnsRecordRow {
  /** The type of DNS record */
  type: string;
  /** The record name */
  name?: string;
  /** The record value */
  value?: string | ReactNode;
}

/**
 * props for the DnsRecordTable component
 */
export interface DnsRecordTableProps {
  /** rows */
  data: DnsRecordRow[];
}

export const DnsRecordTable: React.FC<DnsRecordTableProps> = ({ data }) => (
  <Table
    columns={useMemo(
      () => [
        {
          accessor: 'type',
          width: '10%',
        },
        {
          accessor: 'name',
          width: '45%',
        },
        {
          accessor: 'value',
          width: '45%',
        },
      ],
      [],
    )}
    columnHeaderMessages={dnsRecordTableHeaderMessages}
    data={data}
  />
);
