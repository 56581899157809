import { IntlFormatters } from 'react-intl';

import { BYTE_SIZE_LEVELS } from './constants';

/**
 * Convert bytes to human readable format
 *
 * @param size - The size in bytes
 * @param formatNumber - formatNumber
 * @returns The human readable form
 */
export function humanFileSize(
  size: number,
  formatNumber: IntlFormatters['formatNumber'],
): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const exp = 1024 ** i;
  return formatNumber(+(size / exp).toFixed(2), {
    style: 'unit',
    unit: BYTE_SIZE_LEVELS[i],
  });
}
