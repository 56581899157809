import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledEnterExitHighlight = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: 0.5em;
`;
