import {
  GenericMessageDescriptor,
  Icon,
  IconType,
  Tooltip,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';

import { Button, ButtonProps } from '../Button';
import { Popconfirm } from '../Popconfirm';
import { deleteButtonMessages } from './messages';

/**
 * Props for DeleteButton
 */
export interface DeleteButtonProps extends ButtonProps {
  /** An HTML id to uniquely identify the button */
  id: string;
  /** Loading indicator */
  loading?: boolean;
  /** Function to delete the item */
  deleteCb: () => void;
  /** The button class */
  className?: string;
  /** Button is disabled */
  disabled?: boolean;
  /** tooltip to show when the button is disabled */
  disabledMessage?: GenericMessageDescriptor;
  /** When true, display the delete icon/message without a button */
  iconOnly?: boolean;
  /** The cancel message */
  cancelMessage?: GenericMessageDescriptor;
  /** The ok message */
  okMessage?: GenericMessageDescriptor;
  /** The title of the popconfirm */
  titleMessage?: GenericMessageDescriptor;
  /** The button message */
  message?: GenericMessageDescriptor;
  /** the name for the button */
  name?: string;
}

/**
 * Button for deleting something
 */
export const DeleteButton: React.FC<DeleteButtonProps> = ({
  deleteCb,
  message,
  disabled,
  id,
  loading,
  icon,
  cancelMessage = deleteButtonMessages.cancel,
  className = 'delete-button',
  iconOnly = false,
  okMessage = deleteButtonMessages.ok,
  titleMessage = deleteButtonMessages.header,
  disabledMessage,
  ...buttonProps
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  // Construct an id
  const deleteId = `delete-button-${id}`;
  const common = {
    id: deleteId,
    className,
    loading,
  };

  // The content of the button or icon
  const content = formatMessageGeneric(message);
  const iconOrDefault =
    !icon || typeof icon === 'string' ? (
      <Icon
        {...common}
        type={(icon as IconType) || (disabled ? 'lock' : 'trash')}
      />
    ) : (
      icon
    );

  // The button itself
  const buttonContent = (
    <Button
      {...common}
      variant={disabled ? 'link' : 'outline-danger'}
      disabled={disabled}
      icon={iconOrDefault}
      iconOnly={iconOnly}
      {...buttonProps}
    >
      {content}
    </Button>
  );

  // When disabled there is no pop confirm
  if (disabled) {
    return (
      <Tooltip
        title={
          disabledMessage ? formatMessageGeneric(disabledMessage) : undefined
        }
      >
        {buttonContent}
      </Tooltip>
    );
  }

  // Display the popconfirm
  return (
    <Popconfirm
      sureMessage={titleMessage}
      onConfirm={deleteCb}
      okMessage={okMessage}
      cancelMessage={cancelMessage}
    >
      {buttonContent}
    </Popconfirm>
  );
};
