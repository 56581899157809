import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

import { applyEnum } from '@transcend-io/type-utils';

import {
  PrivacyCenterAssetName,
  UpdatePrivacyCenterInput,
} from '@main/pc-types';

import { PrivacyCenterState } from './slice';

export const getPrivacyCenterView = (state: any): PrivacyCenterState =>
  state.PrivacyCenter;

/**
 * The privacy center that is the current state of production (does not contain local changes)
 */
export const selectDeployedPrivacyCenter = createSelector(
  getPrivacyCenterView,
  ({ deployedPrivacyCenter }) => deployedPrivacyCenter,
);

/**
 * The privacy center that contains all local changes
 */
export const selectPrivacyCenter = createSelector(
  getPrivacyCenterView,
  ({ privacyCenter }) => privacyCenter,
);

/**
 * The ID of the privacy center
 */
export const selectPrivacyCenterId = createSelector(
  selectPrivacyCenter,
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  (privacyCenter) => privacyCenter?.id!, // type enforcing this as it will always be string once moved to the URL
);

/**
 * The fully defined theme
 */
export const selectTheme = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.theme,
);

/**
 * The partial theme
 */
export const selectPartialTheme = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.partialTheme,
);

/**
 * The URL
 */
export const selectPrivacyCenterUrl = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.url,
);

/**
 * Indicator that the asset files have changed and need to be saved
 */
export const selectIsModified = createSelector(
  selectDeployedPrivacyCenter,
  selectPrivacyCenter,
  (deployedPrivacyCenter, privacyCenter) =>
    !!deployedPrivacyCenter &&
    !!privacyCenter &&
    !isEqual(deployedPrivacyCenter, privacyCenter),
);

/**
 * The data practices as an input type
 */
export const selectPrivacyCenterInput = createSelector(
  selectPrivacyCenter,
  (privacyCenter): UpdatePrivacyCenterInput | undefined =>
    !privacyCenter
      ? undefined
      : {
          privacyCenterId: privacyCenter.id,
          // Get the id and alt text of the assets that were updated
          assets: applyEnum(PrivacyCenterAssetName, (name) => {
            const asset = privacyCenter.assets[name];
            return asset
              ? { id: asset.id, alt: asset.alt?.defaultMessage }
              : null!;
          }),
          // Get the privacy center home url
          home: privacyCenter.home.defaultMessage,
          locales: privacyCenter.locales,
          isDisabled: privacyCenter.isDisabled,
          customSubdomain: privacyCenter.customSubdomain,
          supportEmail: privacyCenter.supportEmail,
          replyToEmail: privacyCenter.replyToEmail,
          emailPrefix: privacyCenter.emailPrefix,
          defaultLocale: privacyCenter.defaultLocale,
          preferBrowserDefaultLocale: privacyCenter.preferBrowserDefaultLocale,
          showTrackingTechnologies: privacyCenter.showTrackingTechnologies,
          showManageYourPrivacy: privacyCenter.showManageYourPrivacy,
          showSaleOfInfo: privacyCenter.showSaleOfInfo,
          unauthenticatedDoNotSellRegions:
            privacyCenter.unauthenticatedDoNotSellRegions.map((region) => ({
              country: region.country,
              countrySubDivision: region.countrySubDivision,
            })),
          showMarketingPreferences: privacyCenter.showMarketingPreferences,
          showPrivacyRequestButton: privacyCenter.showPrivacyRequestButton,
          showPolicies: privacyCenter.showPolicies,
          showCookies: privacyCenter.showCookies,
          showConsentManager: privacyCenter.showConsentManager,
          showDataFlows: privacyCenter.showDataFlows,
          useNoReplyEmailAddress: privacyCenter.useNoReplyEmailAddress,
          useCustomEmailDomain: privacyCenter.useCustomEmailDomain,
          transformAccessReportJsonToCsv:
            privacyCenter.transformAccessReportJsonToCsv,
          allowPartitionChange: privacyCenter.allowPartitionChange,
          showPartition: privacyCenter.showPartition,
          // Get editable (partial theme) colors
          colorPalette: privacyCenter.partialTheme.colors,
          componentStyles: privacyCenter.partialTheme.componentStyles,
          textStyles: privacyCenter.partialTheme.textStyles,
          displayedChildOrganizationIds: privacyCenter.childOrganizations.map(
            ({ id }) => id,
          ),
        },
);

/**
 * The url of the privacy center
 */
export const selectUrl = createSelector(
  selectDeployedPrivacyCenter,
  (deployedPrivacyCenter) => deployedPrivacyCenter?.url,
);

/**
 * The custom subdomain of the (fully launched) privacy center
 */
export const selectCustomSubdomain = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.customSubdomain,
);

/**
 * The base domain of the (fully launched) privacy center
 */
export const selectDomain = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.transcendHostedDomain,
);
