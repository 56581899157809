import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { TableInstance } from 'react-table';

import { Pagination } from '../Pagination';
import { TablePaginationWrapper } from './wrappers';

export interface TablePaginationProps<T extends object> {
  /** The table instance */
  table: TableInstance<T>;
  /** Record count */
  recordCount: number;
  /** The message to render about how many items we're paginating over, e.g. '5 Data Flows' */
  recordCountMessage?: MessageDescriptor;
  /** Is pagination controlled for this table? */
  isControlled?: boolean;
  /** Callback to fire when page changes */
  onPageChange?: (page: number) => void;
}

export const TablePagination = <T extends object>({
  table: {
    gotoPage,
    state: { pageIndex, pageSize },
  },
  recordCount,
  recordCountMessage,
  isControlled = false,
  onPageChange,
}: TablePaginationProps<T>): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <TablePaginationWrapper>
      <div style={{ fontWeight: 600 }}>
        {recordCountMessage &&
          formatMessage(recordCountMessage, {
            count: recordCount,
          })}
      </div>
      <Pagination
        onChange={(page) => {
          if (!isControlled) gotoPage(page - 1);
          onPageChange?.(page);
        }}
        current={pageIndex + 1}
        total={recordCount}
        pageSize={pageSize}
        hideOnSinglePage
      />
    </TablePaginationWrapper>
  );
};
