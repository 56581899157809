/* eslint-disable max-lines */
import { compareVersions } from '@main/utils';

/** Enum of airgap issue severity levels */
export enum AirgapIssueSeverity {
  Moderate = 'MODERATE',
  High = 'HIGH',
}

export interface BaseAirgapIssue {
  /** Description of the issue in this version */
  description: string;
  /** The severity of the issue */
  severity: AirgapIssueSeverity;
}

export interface AirgapIssueVersionRange extends BaseAirgapIssue {
  /** versions up to which the issue applies */
  fixedIn: string;
  /** versions after which the issue applies, including the specified version */
  introducedIn?: string;
}

/** Airgap issue type representing description, severity, and version(s) affected */
export type AirgapIssue = AirgapIssueVersionRange;

/**
 * type-forcing function
 *
 * @param issue - the issue to pass through
 * @returns the same issue but with correct types
 */
const mkIssue = (issue: AirgapIssue): AirgapIssue => issue;

/** List of known airgap version issues */
/* eslint-disable max-len */
export const AIRGAP_VERSION_ISSUES = {
  useModuleDefaultRegimeUiLoading: mkIssue({
    description:
      'Consent manager configuration that included multiple experiences, used IAB experiences, and had an unhidden default view state did not properly load the UI for users in the default regime.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.48.7',
    introducedIn: '9.24.1',
  }),
  cookieQuarantineExpiration: mkIssue({
    description:
      'Cookies from that were placed into quarantine on page init or consent change would also be expired upon restoration.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.45.2',
    introducedIn: '9.5.0',
  }),
  cookieConsentSyncOverwrite: mkIssue({
    description:
      'Certain airgap.js configurations were not correctly parsing stored consent when using cookie-based consent sync.',
    severity: AirgapIssueSeverity.High,
    fixedIn: '9.45.1',
    introducedIn: '9.26.0',
  }),
  cmUiLocalizationMatching: mkIssue({
    description:
      'Previous versions of the consent management UI had some issues around language resolution. ' +
      'Browser language preference order was not factored into the matching algorithm, and certain ' +
      'duplicative localizations continued to fallback languages instead of resolving to an identical dialect',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.41.2',
  }),
  reactIntlIncompatibility: mkIssue({
    description:
      'react-intl, a library used in ui.js, was updated from 6.2.5 to 6.6.8 in airgap version 9.33.0. ' +
      'For customers using certain version of react-intl on the site, this introduced ' +
      'an incompatibility issue. Airgap 9.35.2 pinned react-intl back to 6.2.5 where there ' +
      'were no known compatibility issues. ' +
      'There may still be incompatibility issues for ' +
      'certain version of react-intl that can be resolved using workaround defined here: ' +
      'https://github.com/transcend-io/consent-manager-ui/pull/178',

    severity: AirgapIssueSeverity.Moderate,
    introducedIn: '9.33.0',
    fixedIn: '9.35.2',
  }),
  cookiePrefixPurposeOverlap: mkIssue({
    description:
      'String cookies that are prefixed by the full text of another string cookie ' +
      "would gain the prefix cookie's purposes. This would only ever result in " +
      'over-regulation of cookies with respect to advertising or analytics, but ' +
      'could potentially have blocked the use of some essential cookies.',
    severity: AirgapIssueSeverity.Moderate,
    introducedIn: '8.24.0',
    fixedIn: '8.34.0',
  }),
  purposeResolution: mkIssue({
    description:
      'Cookie purposes were being resolved incorrectly when the user had fully ' +
      'opted in and "data-disable-when-consented=on".',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '8.36.0',
  }),
  unknownCookieRequest: mkIssue({
    description:
      'When using both the "data-regulate-unknown-cookies=block" and ' +
      '"data-disable-when-consented=on" options, unknown cookie regulation was ' +
      'incorrectly disabled.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '8.36.0',
    introducedIn: '8.15.7',
  }),
  syncEndpointGatedRemoteSync: mkIssue({
    description:
      'Remote sync was previously gated on sync endpoint, this could hinder ' +
      'remote sync if the endpoint was not specified using "data-sync-endpoint".',
    severity: AirgapIssueSeverity.High,
    fixedIn: '8.41.8',
    introducedIn: '8.38.0',
  }),
  relativeURLParsing: mkIssue({
    description:
      'Relative URLs were being parsed incorrectly in some circumstances, ' +
      'resulting in requests bypassing regulation.',
    severity: AirgapIssueSeverity.High,
    fixedIn: '9.5.1',
    introducedIn: '9.0.0',
  }),
  crossRealmFormSubmission: mkIssue({
    description:
      'One of our form regulation components was using a static reference to ' +
      'the context (init) realm, allowing for potential regulation bypass.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.8.0',
    introducedIn: '9.0.0',
  }),
  localSyncGatedRemoteSync: mkIssue({
    description:
      'Our sync logic incorrectly gated remote sync behind browser support for ' +
      'local sync. The result was that Safari users were unable to remotely sync ' +
      'consent preferences to Preference Store.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.8.2',
    introducedIn: '9.5.0',
  }),
  baseURIResolution: mkIssue({
    description:
      'URL resolution does not account for baseURI. This results in a possible ' +
      'general bypass of regulations with relative URLs.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.10.0',
  }),
  realmAutoProtectionGatingIssue: mkIssue({
    description:
      'Realm auto-protection did not respect data-protect-realms="self" setting for iframe protection. This can cause unintended regulation in some documents.',
    severity: AirgapIssueSeverity.Moderate,
    introducedIn: '9.0.0',
    fixedIn: '9.12.0',
  }),
  tfpfIOSBug: mkIssue({
    description:
      'airgap.js initialization can fail in iOS Safari on iOS versions >= v17.5 depending on user agent configuration',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.13.0',
    introducedIn: '9.1.0',
  }),
  allowedHostsDeniedValidSubdomain: mkIssue({
    description:
      'airgap.js incorrectly prevented initialization on some subdomains of allowed hosts.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.13.2',
    introducedIn: '9.10.0',
  }),
  builtInConsentIntegrationsNotApplied: mkIssue({
    description:
      'The built-in Privacy-Enhancing Tracker Override consent integrations in airgap.js based on our request override system were internally registered but not applied to requests.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.14.0',
    introducedIn: '9.0.0',
  }),
  fixEssentialPurposesIssue: mkIssue({
    description:
      'Essential tracking purposes could be ignored for overlapping rules depending on rule order, causing over-regulation of events.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.20.0',
  }),
  xhrPatcherToggleIssue: mkIssue({
    description:
      'Our XMLHttpRequest patcher would incorrectly continue to regulate requests after disabling protections through the airgap.toggle() API.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.29.1',
    introducedIn: '8.25.0',
  }),
  docAdoptNodePatcherToggleIssue: mkIssue({
    description:
      'Our document.adoptNode() patcher would incorrectly continue to regulate requests after disabling protections through the airgap.toggle() API.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.29.3',
    introducedIn: '8.25.0',
  }),
  commentNodeMixinPatcherIssue: mkIssue({
    description:
      'Our DOM regulation ChildNode mixin patcher would cause errors when called on comment nodes.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.29.2',
  }),
  setAttributeNSIssue: mkIssue({
    description:
      'Our element.setAttributeNS() patcher called an incorrect method internally when protections were enabled.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.32.0',
    introducedIn: '8.25.0',
  }),
  cookieParsing: mkIssue({
    description:
      'Our document.cookie patcher could incorrectly reserialize cookies that contain extra equals signs in the cookie value, causing tools to store incorrect cookie values in rare circumstances.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.37.0',
  }),
  parentNodeReplaceChildrenAndTreeWalkerIssue: mkIssue({
    description:
      'Our DOM protection scoping system had an issue that could cause some elements to skip regulation. Also, our DOM mixin patcher system would incorrectly decompose output for calls to ParentNode.replaceChildren(...nodes), such that only the last node provided to replaceChildren() was effectively inserted in the DOM.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.42.0',
    introducedIn: '8.22.0',
  }),
  cookieBasedSyncSetConsentMetadataIssue: mkIssue({
    description:
      'When cookie-based sync was enabled, airgap.setConsentMetadata() would not update consent metadata. Workaround available: call airgap.setConsent(auth, {}, { metadata: { ... } }) instead.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.54.1',
    introducedIn: '9.5.0',
  }),
  openPatcherTreatsAllInvocationsAsSubresourceRequests: mkIssue({
    description:
      'Whenever any <embed> elements are present on the page, all open() calls are treated as subresource requests, potentially resulting in over-regulation.',
    severity: AirgapIssueSeverity.Moderate,
    fixedIn: '9.58.1',
    introducedIn: '9.58.0',
  }),
} as const; // satisfies Record<string, AirgapIssue> <-- add this once our build process can handle it
/* eslint-enable max-len */

/** key representing a known airgap version issue */
export type AirgapIssueKey = keyof typeof AIRGAP_VERSION_ISSUES;

export interface AirgapRawChangelogEntry {
  /** Description of the updates in this version */
  description: string;
  /** Whether this version constitutes an urgent fix and should create an action item to upgrade */
  urgent?: boolean;
}

/* eslint-disable max-len */
export const AIRGAP_VERSION_CHANGELOG = {
  '9.58.5':
    'Improved input desynchronization attack resistance for the open() patcher.',
  '9.58.4':
    'Improved fetch patcher compatibility with cross-realm Request constructor instances.',
  '9.58.3':
    'Improved compatibility with non-document embeds (e.g. PDF, image, text, etc. iframes) in `nav:sync` realm protection hook.',
  '9.58.2':
    'Telemetry is now always sent using fetch(), and always using credentials=omit mode. The prioritize-telemetry load option is no longer supported.',
  '9.58.1': 'Fix open() patcher target matching issue for embed elements.',
  '9.58.0':
    'open() patcher now more accurately treats invocations where the target parameter matches non-frame elements as navigations.',
  '9.57.0':
    'Consent precedence gating now applies to runtime consent changes in addition to consent resolution during init. This feature can be used to prevent users from opting into to tracking purposes that conflict with their enabled privacy signals by setting data-consent-precedence="signals" on your airgap.js script tag.',
  '9.56.1':
    "Resolved issue with IAB's GPP package not properly setting the load and status signals.",
  '9.56.0':
    'Added telemetry false-positive filter detection cache. This further reduces the amount of extension-initiated requests and cookie mutations that can end up in telemetry.',
  '9.55.3':
    'Update bridge.js to skip view-state listener when data-prompt="0" is set.',
  '9.55.2': 'Update bridge.js to respect data-prompt="0" attribute.',
  '9.55.1':
    'Fixed an issue with data-autofocus="off" not working in the Consent Manager UI.',
  '9.55.0':
    'Added experimental option to encode sync cookies with additional escaping to potentially bypass WAF issues. This option can be enabled by setting data-waf="esc" on your airgap.js script tag. Encoding changes are not auto-migrated.',
  '9.54.3': "Added a link to an organization's Privacy Policy in the TCF UI.",
  '9.54.2': 'CSS changes to the CM UI and TCF UI.',
  '9.54.1':
    'Fix metadata not updating when airgap.setConsentMetadata() is called with cookie-based sync enabled.',
  '9.54.0':
    'Ensure that localStorage.tcmMPConsent is updated when both cookie-based sync and consent partitioning are enabled.',
  '9.53.0':
    'Improved cross-realm DOM regulation quarantine replay compatibility.',
  '9.52.0':
    'The resolved cookie sync site is now exposed in airgap.loadOptions.site post-init if available.',
  '9.51.1': 'Performance improvement in telemetry false positive filtering.',
  '9.51.0':
    'airgap.js now additionally saves sync data in localStorage along with cookie-based sync.',
  '9.50.0':
    "Added support for type=module (ESM) worker realm protection to our optional realm protection hook system. This extends the functionality of the 'worker' realm protection hook. Read more at https://docs.transcend.io/docs/articles/consent-management/reference/realm-auto-protection",
  '9.49.4':
    'Added new data-tcf-ui-dismissed-view-state attribute to control dismissed view mode of TCF modal.',
  '9.49.3':
    'Fixed: an issue with UI loading for certain consent manager configurations containing multiple experiences, IAB view states, and a non-hidden default view state.',
  '9.49.2': 'Updated dependencies.',
  '9.49.1':
    'Improved document.execCommand() patcher to better resist input desynchronization attacks.',
  '9.49.0': 'Enhanced extensibility with a new internal plugin API.',
  '9.48.7':
    'Improved safety checks in stacktrace search module to improve system reliability.',
  '9.48.6': 'DOM regulation performance improvement.',
  '9.48.5': 'Internal CSS update for the TCF UI.',
  '9.48.4': 'Internal types update.',
  '9.48.3':
    'Added Iowa, Delaware, New Hampshire, Nebraska, and New Jersey to the US-Do-Not-Sell/Share experience.',
  '9.48.2': 'Adds improved error message for missing domain list domain.',
  '9.48.1':
    "Improved compatibility for 'nav:async' same-origin resource realm protection hook.",
  '9.48.0':
    'Improved CSP generation to exclude query parameters from generated path rules.',
  '9.46.5':
    'Updated the load option in the consent manager UI to support data-autofocus="on" or data-autofocus="off" for controlling autofocus behavior.',
  '9.46.4':
    "Improved compatibility and performance for regulating 'rel' attribute mutations in our DOM regulation engine.",
  '9.46.3':
    'Added a load option to disable auto-focusing of the first focusable element in the Consent Management UI. This can be enabled with data-autofocus="false". The default is true.',
  '9.46.2': 'Improved XDI client hint encoding.',
  '9.46.1': 'Improved XDI compatibility for the latest version of Firefox.',
  '9.46.0':
    'XDI implementation adjusted to prefer transferring client origin hints in location hashes as opposed to location fragment directives.',
  '9.45.4':
    'The `tcm` cookie used for cookie based sync is now auto-classified as essential. With this change, cookie based sync can now be used without classifying the `tcm` cookie in your dashboard.',
  '9.45.3':
    'Added support for invalid URL handling to airgap.getPurposes() and airgap.getRequestPurposes() APIs.',
  '9.45.2':
    'Fixed regression where cookie quarantine on page init or consent change would expire cookies.',
  '9.45.1':
    'Fixed an issue with certain airgap.js configurations not correctly parsing stored consent when using cookie-based consent sync.',
  '9.45.0':
    'Added a flag to disable node baseURI propagation in our DOM regulation engine. The flag is data-bup="off". This flag is helpful when using base-uri CSP rules that potentially conflict with internally propagated baseURIs.',
  '9.44.0':
    'Added cross-browser support for cookie-based consent sync for browsers other than Safari. This feature can be enabled with data-local-sync="allow-network-observable".',
  '9.43.1':
    'Removed Sale of Info as a purpose from the default LGPD and nFADP regional experiences.',
  '9.43.0':
    'Cookie-auto clearing is now auto-triggered for cookie mutations detected by the CookieStore cookie change listener API when supported by the browser. Cookie overrides are now also applied to cookie monitoring, allowing detected cookies to be marked for deletion via IPendingCookieMutation.deny().',
  '9.42.4':
    'Fixed data-require-tfpf="on" detecting the latest version of Chrome as not supporting telemetry false-positive filtering, causing event telemetry to be suppressed.',
  '9.42.3':
    'Removed Sale of Info as a purpose from the default GDPR regional experience.',
  '9.42.2': 'Improved remote consent sync performance.',
  '9.42.1':
    'Enabled tracking purpose title and description customization in the Consent Management UI.',
  '9.42.0':
    'Fixes DOM protection scoping issue, improves mixin patcher system compatibility, and fixes issue with ParentNode.replaceChildren() patcher.',
  '9.41.2':
    'Further improved language matching in the Consent Management UI module and resolved a bug causing Mexican Spanish to not display.',
  '9.41.1': 'Improved language matching in the Consent Management UI module.',
  '9.41.0':
    'Added inline CSS options to the Consent Manager UI. data-inline-css="off" disables inline CSS used for a \'CSS reset\'. data-inline-css="data:" converts inline CSS into pseudo-external stylesheets with data: URIs.',
  '9.40.0':
    'Added CSP nonce propagation support to the airgap.js module loader and consent management UI via the data-nonce attribute. We auto-hide the attribute once read to secure against nonce leaks post-init.',
  '9.39.2': 'Improved initialization performance.',
  '9.39.1':
    'Improved de-duplication of requests across our protection and monitoring systems. This increases request count telemetry accuracy in scenarios where many requests are synchronously dispatched back-to-back. Previously, our monitoring system could over-count these requests.',
  '9.39.0':
    'We now only count pageviews in the topmost browser frame for both telemetry and the consent auto-prompting pageview trigger feature.',
  '9.38.3': 'Performance improvements to URL resolution.',
  '9.38.2':
    'Fixed two issues in the TCF UI: 1. The `success` argument was not being passed to the `getTCData` callback. ' +
    '2. The TCF UI would throw an error when removing event listeners',
  '9.38.1':
    'Fixed an issue with HTML in CM UI messages causing internationalization to fall back to default messages.',
  '9.38.0':
    'Renames prototype functions: transcend.getTranscendPolicies() to transcend.getPolicies(), ' +
    'transcend.setTranscendUiVariables() to transcend.setUiVariables() and  transcend.getTranscendUiVariables() to transcend.getUiVariables()',
  '9.37.0': 'Improved cookie patcher compatibility.',
  '9.36.0':
    'Exposes new prototype functions: transcend.getTranscendPolicies(), ' +
    'transcend.setTranscendUiVariables(), transcend.getTranscendUiVariables(), transcend.getActiveLocale()',
  '9.35.3':
    'Fixed an issue where TCF API would fail if enableAdvertiserConsentMode is not defined in TCF UI.',
  '9.35.2':
    'Pins react-intl version in ui.js to prevent incompatibility issue introduced in 9.33.0.',
  '9.35.1':
    "Removed currently-unused admin dashboard 'userscript installation detection' integration from airgap.js.",
  '9.35.0':
    'Unknown Request Policy = Block is now enforced even if the user has not been afforded any consent rights.',
  '9.34.0':
    'The GoogleAdsRDP integration has been separated from the GoogleConsentMode integration. You can now use the GoogleConsentMode integration without emitting a Google Ads Restricted Data Processing (RDP) signal when opted out of SaleOfInfo. Also added support for tag-specific Google Ads RDP flagging. You can specify a list of tags for selective RDP flagging as such: data-tracker-overrides="GoogleAdsRDP:tags=TAG_ID_1,TAG_ID_2"',
  '9.33.0': 'Remove tabindex="0" from the consent management UI.',
  '9.32.0': 'Fixed compatibility issue with element.setAttributeNS() patcher.',
  '9.31.0':
    'Improved cross-realm DOM mutation quarantine replay compatibility.',
  '9.30.0':
    'fetch() patcher improvements: We now support `init` mutability for overrides through IPendingEvent<fetch>.requestInit for fetch(input, init) calls. Our fetch() patcher also now supports our omitCredentials API.',
  '9.29.3':
    'Fixed an issue where our document.adoptNode() patcher was not disabled by the airgap.toggle() API.',
  '9.29.2':
    'Fixed an issue where our DOM regulation ChildNode mixin patcher would cause errors when called on comment nodes.',
  '9.29.1':
    'Fixed an issue where our XMLHttpRequest patcher would continue to regulate requests after disabling protections through the airgap.toggle() API.',
  '9.29.0':
    'Added a new load option called data-require-tfpf="on" to require telemetry false positive filtering support for data flow and cookie telemetry emission. This should reduce the amount of junk data flows from browser extensions that can reach triage. Enabling this load option causes telemetry from Safari users to only include session and regional experience metrics.',
  '9.28.0':
    'Improved support for protecting web workers and worklets. See https://docs.transcend.io/docs/articles/consent-management/reference/workers for more information.',
  '9.27.0': `Defining any request overrides or cookie overrides now preempts our 'disable protections when fully consented' mode, ensuring that protections remain active for overrides. Also fixes a bug where airgap.status.protection could incorrectly return true.`,
  '9.26.0':
    'Cookie overrides are now evaluated for cookie auto-clearing. Cookie overrides can be bypassed by passing false as the second argument to airgap.clearDisallowedCookies(quarantineDisallowedCookies?: boolean, overrideCookies?: boolean) or the third argument to airgap.clearCookies(filter: (cookie: IPendingCookieMutation) => boolean, quarantineDisallowedCookies?: boolean, overrideCookies?: boolean).',
  '9.25.0': 'Improved airgap.js DOM mutation quarantine replay compatibility.',
  '9.24.1': 'The data-ui load option now overrides the location of UI modules.',
  '9.24.0':
    'Improvements to consent resolution consistency when adding purposes to Unknown events using our override APIs.',
  '9.23.0':
    'Updated airgap.optIn() and airgap.optOut() APIs to only modify consent for in-scope tracking purposes based on applicable regional experiences.',
  '9.22.0': `Exposed consent resolution API as airgap.isConsented(purposes: Set<string>, use?: 'request' | 'cookie'): boolean`,
  '9.21.2':
    'data-disabled="true" can be set on the airgap.js script tag to disable airgap.js entirely.',
  '9.21.1':
    'Internal security improvements. Internal Object.fromEntries() helper no longer inherits the default object prototype, better protecting against prototype pollution attacks.',
  '9.21.0':
    'Essential tracking purposes can now be used on overlapping data flow and cookie matchers. Previously, Essential tracking purposes could be ignored for overlapping matchers, e.g. setting a cookie matcher for `foo` as `SaleOfInfo` and `foobar` as `Essential` could end in `foobar` cookies not inheriting the Essential purpose due to the resolution order being { SaleOfInfo, Essential }. Now the Essential purposes will always override other purposes.',
  '9.19.0':
    'The consent manager UI now supports a data attribute for defining the initial view states for a non-consented user. e.g. data-initial-view-state-by-privacy-regime=\'{"CPRA":"CompleteOptions","Unknown":"Hidden"}\'.',
  '9.18.1': 'Adds support for consent sdks to pass UI view state.',
  '9.18.0':
    'All Tracker Overrides are now disabled by default whenever airgap.js is in report-only mode. Previously, consent signal integrations such as Google Consent Mode always ran regardless of report-only mode state.',
  '9.17.0':
    'Added airgap.watchRealms API and declarative airgap.realmWatchers config. Can be used to selectively apply realm protections with data-protect-realms="self" + airgap.realmWatchers = [(realm) => { if (condition) { airgap.protect(realm) } }] pre-init',
  '9.16.1':
    'Improved CSP violation logging to support cross-realm event listener registration.',
  '9.16.0': 'Add support for regulating ShadowRoot.setHTML API.',
  '9.15.1': 'Build size optimization.',
  '9.15.0': `Improved Content Security Policy generator compatibility with path-type data flows.`,
  '9.14.5': `airgap.isCookieAllowed() now supports string input, e.g. airgap.isCookieAllowed('cookieName=cookieValue') in addition to the previous format airgap.isCookieAllowed({name: 'cookieName', value: 'cookieValue'})`,
  '9.14.4':
    'Optional GoogleAdsRDP consent integration is no longer additionally gated by opt-out of data-tracker-overrides-unconsented-purpose (defaults to SaleOfInfo). To use the GoogleAdsRDP consent integration, add it to your airgap.loadOptions.trackerOverrides / data-tracker-overrides config: e.g. data-tracker-overrides="[...] GoogleAdsRDP"',
  '9.14.3':
    'Our open() patcher now resolves target documents at runtime based on `this` context value. This means open.call(otherWindow, url, windowName) will now search otherWindow.document for target frames matching windowName.',
  '9.14.2': 'DOM regulation performance optimizations.',
  '9.14.1': 'Internal type documentation update.',
  '9.14.0':
    'Fix request override-based built-in Privacy-Enhancing Tracker Override consent integrations not being applied.',
  '9.13.3': 'Consent resolution performance improvements.',
  '9.13.2':
    'Fix bug in allowed host validation algorithm that was denying valid subdomains.',
  '9.13.1': 'Build size optimizations for airgap.js.',
  '9.13.0':
    'Improved feature gating for telemetry false-positive filtering in Safari on iOS.',
  '9.12.0': 'Added additional gating for realm auto-protection features.',
  '9.11.0':
    'Cookies that are auto-deleted during init are now quarantined for replay.',
  '9.10.0': 'Relative URL resolution now accounts for node.baseURI.',
  '9.9.0':
    'Our Content Security Policy generator now generates additional default-src entries based on path-type data flow matchers. This means that a data flow for https://example.com/widget.js can be marked as Essential / no consent needed and you don’t need to add any additional data flows for the CSP to allow just this widget.js file to load. Previously, you would need to include an additional host-type data flow for example.com to allow this file to load with our generated CSPs.',
  '9.8.3':
    'airgap.js now emits consent-change events for consent updates from cookie-based sync.',
  '9.8.2':
    'Fixed issue where lack of browser support for local sync prevented remote sync which is used by Preference Store.',
  '9.8.1': 'Improved cookie-based sync to specify a default cookie path.',
  '9.8.0': 'Improved cross-realm form submission regulation.',
  '9.7.0':
    'Strict auth mode now requires either a load event from before/as airgap.js initialized or the unique auth key conferred via transcend.setAuth() and a genuine user interaction for consent changes.',
  '9.6.2': 'Performance improvements for resolving tracking purposes.',
  '9.6.1': 'Added a new default experience: US Do Not Sell/Share',
  '9.6.0': 'Added regulation support for the Range.insertNode() DOM API.',
  '9.5.5': 'Fix default consent management ui ordering behavior',
  '9.5.4': 'Updated session tracking key to sessionStorage.tcms',
  '9.5.3':
    'Fix consent-change event to not trigger multiple times when the consent state is same.',
  '9.5.2': 'Fixes an issue with userscript initialization.',
  '9.5.1': 'Fixes an issue with relative URL parsing.',
  '9.5.0':
    'Adds support for cookie-based consent sync on Safari, which can be enabled with data-local-sync="allow-network-observable". This feature requires specifying a cookie domain mapping, which can be set through data-site (single site/eTLD+1) or data-sites (applicable site/eTLD+1 is selected at runtime from a space-separated list of sites). Read more at https://docs.transcend.io/docs/consent-management/reference/sync',
  '9.4.0':
    'Added data-require-auth="strict" mode, where consent mutations require pre-airgap.js-init load event auth.',
  '9.3.0':
    "Added regulation support for document.execCommand({'insertHTML', 'insertImage'}) DOM APIs.",
  '9.2.0':
    'Added regulation support for {Element, ShadowRoot}.setHTMLUnsafe() DOM APIs.',
  '9.1.3':
    'Updated telemetry output encoding to support regex data flow matcher stats collection.',
  '9.1.2': 'Performance optimizations.',
  '9.1.1':
    'Updated consent manager UI to support configurable shadow root attachment mode via data-ui-shadow-root={"open", "closed"}. The default attachment mode is "closed".',
  '9.1.0':
    'Added new telemetry false positive filtering logic to exclude data flows and cookies generated by extensions, this behavior is on by default but can be disabled with the data-tfpf="off" attribute.',
  '9.0.0': `airgap.js now auto-protects same-origin realms. This includes same-origin sub-frames, parent realms, open() calls, and Worker/SharedWorker threads. In order to change back to the old behavior, set data-protect-realms="self" on your airgap.js script tag. In order to opt-in to our new experimental 'sub-realm navigation' and 'worker creation' realm protection mechanisms, set data-realm-protection-hooks="nav worker" on your airgap.js script tag. Additionally, there is a "nav:async" hook with better performance characteristics but a slightly higher chance of site breakage. The 'worker creation' hook requires site owners to update any applicable Content Security Policies to allow data: and blob: URIs, and the 'sub-realm-navigation' hook requires just blob: URIs. Breaking change: airgap.override() and airgap.overrideCookies() no longer support click-based authorization, and now require a airgap.js script load event reference.`,
  '8.41.9': 'Backported configurable UI shadow root attachment mode feature.',
  '8.41.8': 'Ensures remote sync is not gated on local sync endpoint.',
  '8.41.7':
    'Allow local sync to be disabled without disabling remote consent sync.',
  '8.41.6':
    'Fixed bug where an empty document.cookie value in certain browser contexts would throw an error',
  '8.41.5':
    'Allows disabling of the integration between Google Consent Mode and TCF. More info here: https://developers.google.com/tag-platform/security/guides/implement-TCF-strings.',
  '8.41.4': 'Minor bump to the NodeJS version that builds the SDK',
  '8.41.3':
    'Makes bridge.js backward compatible with older versions of the mobile SDK.',
  '8.41.2':
    'Exposed Consent UI version number in transcend.version and added XDI version number disclosure in debug logs. This can help assist with debugging UI and consent sync issues.',
  '8.41.1':
    'Improved the UI to reach WCAG A compliance, and reordered UI options to not disadvantage keyboard navigation',
  '8.41.0': 'Significant performance improvements to DOM regulation engine.',
  '8.40.1':
    'Supports the integration between Google Consent Mode and TCF. More info here: https://developers.google.com/tag-platform/security/guides/implement-TCF-strings.',
  '8.40.0':
    'Resolves an issue with opt out cookie clearing for require-full-consent unknown cookie policies and for cookies with explicitly set domains in browsers without the CookieStore API',
  '8.38.6': 'Internal type updates',
  '8.38.5': 'Enables Mobile bridge to use partition key.',
  '8.38.4':
    'Only regulate open(..., \'_blank\') when data-regulate-navigation="on", similarly to how we treat _self/_top/_parent open() targets.',
  '8.38.3': 'Optimized URL parsing.',
  '8.38.2': 'Internal type updates',
  '8.38.1':
    'Refactored session counting implementation to no longer depend on BroadcastChannel. This can result in better BFCache compatibility which improves site navigation performance.',
  '8.38.0':
    'Transcend XDI sync protocol improvements. Resolves an issue with airgap.reset() not propagating to sync endpoints.',
  '8.37.5':
    "Disabled the default third-party sync endpoint now that all web browsers have adopted state partitioning. This increases performance for users that aren't using cross-domain sync.",
  '8.37.4': 'Internal type updates',
  '8.37.2': 'Adds Mobile telemetry support.',
  '8.37.1':
    'Modifies the WebSocket patcher to mitigate compatibility issues with the LivePerson chat widget.',
  '8.37.0':
    'We no longer annotate data-airgap-id attributes when data-replay=off. This serves as an easy escape hatch for anyone having DOM conformity attestation issues.',
  '8.36.0':
    'Always initialize cookie purpose map even if cookie regulation is disabled. This makes the results of calling airgap.getCookiePurposes() more deterministic.' +
    'Fixes an issue where regulate-unknown-cookies="block" was not being respected correctly if disable-when-consented="on" was also set.',
  '8.35.0':
    'Add clearCookies() and clearDisallowedCookies() APIs to airgap.js. See the API reference for more details',
  '8.34.0': 'Fix regression in cookie regulation logic introduced in v8.24.0',
  '8.33.8': 'Fix BroadcastChannel issue preventing BFCache page restore',
  '8.33.7': 'Update @transcend-io/type-utils dependency to version v1.4.1.',
  '8.33.6': 'Fixed issue with XDI sync channel not being reused.',
  '8.33.5': 'Ensure backward compatibility for bridge.js.',
  '8.33.4':
    'Added new data-tcf-ui-height-mode attribute to control TCF modal styling.',
  '8.33.3':
    'Fix data-disable-when-consented="on" disabling consent signal integrations from initializing when airgap.js protections are disabled.',
  '8.33.2': 'Add aria-live attribute to the consent UI modal.',
  '8.33.1': 'Enable support for autoShowUI on Mobile Consent libraries.',
  '8.33.0':
    'Our Google Consent Mode integration has been upgraded to emit ad_user_data and ad_personalization parameters for Google Consent Mode v2 compatibility. See more in our docs on this feature: https://docs.transcend.io/docs/consent-management/configuration/integrations#google-consent-mode',
  '8.32.4':
    'Removes a workaround in our WebSocket patcher that was previously required to mitigate compatibility issues with the LivePerson chat widget.',
  '8.32.3': 'Add US National signal to the GPP module.',
  '8.32.2': 'Internal type updates.',
  '8.32.1': 'Enable support for Preference Store in Consent libraries.',
  '8.32.0':
    'Users acknowledging the Privacy Policy Notice UI will no longer result in a consent change telemetry event',
  '8.32.0-rc.1':
    '[PATCH] Added new data-tcf-ui-height-mode attribute to control TCF modal styling.',
  '8.31.4':
    'Event queue nrc reference fix, send gpp string to preference stores',
  '8.31.3':
    'Added error message when invalid input is provided to showConsentManager() in the UI',
  '8.31.2': 'UI set prompted only if necessary',
  '8.31.1':
    'Supports the display of vendors with only special purposes in the TCF UI.',
  '8.31.0': 'Improved DOM protection scoping algorithm',
  '8.30.1': 'Internal type updates',
  '8.30.0':
    'Fixed compatibility issue with Element.append() patcher causing appended scripts to not execute.',
  '8.29.12':
    "TCF UI will partition consent state in localStorage based on the bundle's partition key",
  '8.29.11':
    'Mobile Webview now partitions saved consent data using bundle ID to avoid state collision',
  '8.29.10':
    'Fixed compatibility regression in Element.prototype.insertAdjacentElement patcher.',
  '8.29.9': 'TC string hydration from localStorage now triggers a change event',
  '8.29.8': 'Misc TCF UI updates',
  '8.29.7': 'Switch mobile TCF UI away from viewport units',
  '8.29.6': 'Minor bug fixes and UI updates for the TCF UI.',
  '8.29.5':
    'UI modules now use an EventTarget shim compatible with Safari < 14',
  '8.29.4':
    'Fixes an issue with events being fired twice for a single TCF UI view state change',
  '8.29.3': 'Added link[rel=preload][imagesrcset] regulation to airgap.js.',
  '8.29.2':
    'Relaxed consent change authorization checks to allow references to any load event for any asset loaded prior to airgap.js. Previously, these load events had to be associated with a script element.',
  '8.29.1':
    'Updates TCF UI configuration to allow for internationalization customization.',
  '8.29.0': 'New view state: PrivacyPolicyNoticeWithCloseButton.',
  '8.28.8':
    'Updates TCF UI Translations. Update TCF UI max-height attribute in mobile browsers.',
  '8.28.7': 'Update Bridge.js to push consent data in TCF flows.',
  '8.28.6':
    'Update TCF UI text and buttons. Fix issue with TCF UI not correctly restoring consent state on page refresh.',
  '8.28.5': 'Track sampling in telemetry events',
  '8.28.4': 'Update TCF UI version to accept default consent on modal closure.',
  '8.28.3':
    'Modify bridge.js to relay close event to the mobile SDK in non-TCF flows.',
  '8.28.2':
    'Patch TCF UI to be compatible with CMP Validator. TCF UI also supports translations.',
  '8.28.1': 'Internal type updates.',
  '8.28.0': 'airgap.js can now run from documents on file:// URIs.',
  '8.27.5': 'TCF UI stub returns the gvlVersion.',
  '8.27.4': 'Internal Node version update.',
  '8.27.3':
    'Updates TCF UI to fix empty Intro Modal and incorrect vendor purpose info. Updates Transcend Consent UI to fix incorrect labels in CompleteOptionsInverted view state.',
  '8.27.2': 'Update mobile bridge module to listen to view-state-change events',
  '8.27.1': 'Updated copy for the TCF UI',
  '8.27.0':
    'Adds the ability to set description text in the CompleteOptionsInverted view state.',
  '8.26.11': 'Internal type updates',
  '8.26.10':
    "Fixed an issue with timeouts over a certain threshold triggering setTimeout's maximum delay logic resulting in immediate timeout execution",
  '8.26.9':
    'Updated TC String encoding to set isServiceSpecific flag to be true',
  '8.26.8': 'TCF stub bug fixes and performance improvements',
  '8.26.7': 'Fixed insecure template literal transpilation in build process.',
  '8.26.6': 'Patched TCF stub to align with IAB specifications',
  '8.26.5':
    'Fixed arithmetic error resulting in immediate consent expiry timeout execution',
  '8.26.4': 'Internal CI updates',
  '8.26.3': 'Minor bug fixes and UI updates for the TCF UI.',
  '8.26.2':
    'Updated internal types to support new mobile consent configuration module',
  '8.26.1':
    'Added an IAB TCF-compatible API stub to airgap.js. This API stub can be enabled through the data-tcf="on" load option.',
  '8.26.0':
    'Added built-in consent expiry features. New options: data-consent-expiry=[time in minutes]; default="off", data-on-consent-expiry={"Prompt", "Reset", "ResetOptIns"}; default="Prompt"',
  '8.25.15':
    'TCF UI supports the full TranscendAPI methods, and supports event listeners for view state changes',
  '8.25.14':
    'TCF UI displays data retention period for each purpose and special purpose. Also includes minor bug fixes for the TCF UI.',
  '8.25.13': 'Added type support for mobile configuration',
  '8.25.12': 'Internal type updates',
  '8.25.11': 'Internal type updates',
  '8.25.10':
    'Changed policy module URL resolution to resolve relative URLs using the CDN base address. TCF UI supports rendering non-TCF vendor information.',
  '8.25.9': 'Internal type updates',
  '8.25.8':
    'Fire consent-change events only when the consent change resolves to a different consent object than is cached',
  '8.25.7':
    'TCF UI module can be configured to hide processing purposes based on legitimate interest legal basis. Also includes minor bug fixes for the TCF UI.',
  '8.25.6':
    'Postpone airgap.js sync events until after both xdi and preference store sync have completed',
  '8.25.5':
    'Publish new versions of static assets required to support mobile consent when publishing new versions of airgap.',
  '8.25.4': 'Update internal types (reduces bundle size)',
  '8.25.3': 'Include static html file to load airgap.js in mobile SDK.',
  '8.25.2':
    'Fixed bug where window.open() patcher was not initializing properly',
  '8.25.1':
    'New UI option: data-ui-allow-in-embeds="off" suppresses UI within embeds',
  '8.25.0':
    'Implemented a new privileged airgap.toggle() API that can be used to temporarily toggle all protections. See docs for more details: https://docs.transcend.io/docs/consent-management/reference/api#airgap.toggle(auth:airgapauth,protection?:boolean):boolean',
  '8.24.1':
    'The TCF UI now responds to airgap sync events and updates the saved tc string/model accordingly',
  '8.24.0':
    'Cookie matchers can now be narrowed down to specific cookie values to support allowlisting explicit "opt-out" values (e.g. `tracker-cookie=opt-out-token`). Regular expression cookie matchers can now evaluate full cookie name-value pairs with a new opt-in flag. This can be enabled with data-regulate-cookies="2"',
  '8.23.1':
    'Add transcend.setTCFConsent setter function for manual TC string setting in TCF regimes by customers with their own preference store',
  '8.23.0':
    'Our Google Consent Mode ads_data_redaction integration can now be configured via the tracker overrides configuration string. Supported values are off, on, and [comma-separated list of lack-of-consent trigger tracking purposes]. Example config: data-tracker-overrides="GoogleConsentMode:ads_data_redaction=on" - example config with trigger purposes: data-tracker-overrides="GoogleConsentMode:ads_data_redaction=Advertising,SaleOfInfo"',
  '8.22.0': 'Add regulation support for `ParentNode.replaceChildren()`',
  '8.21.4': 'Internal system updates',
  '8.21.3': 'Fix an issue with conditionally loaded ui module bundling',
  '8.21.2': 'Tracking type can be any string (to support custom purposes)',
  '8.21.1': 'Extend bride.js to communicate with IOS Framework',
  '8.21.0':
    'Regionalized experiences can now specify dynamic policy bundles (i.e. custom data flow & cookie policies per-regional experience). Fixed cookie matchers with value constraints not matching.',
  '8.20.1': 'Updates copy and adds the display of stacks to the TCF UI module',
  '8.20.0': "Added support for signalling the USP section of IAB's GPP string",
  '8.19.0':
    'Added support for a configurable mapping of sites to sync endpoints through the data-sync-endpoint-map attribute. e.g. data-sync-endpoint-map=\'{"example.com":"https://sync.example.com/.well-known/xdi", "example.org":"https://sync.example.org/.well-known/xdi"}\'',
  '8.18.6': 'Internal system updates (type changes, no run time impact)',
  '8.18.5': 'Internal system updates',
  '8.18.4': 'Updated package dependencies',
  '8.18.3':
    'Fixes a race condition with the bridge.js module (new mobile consent feature)',
  '8.18.2': 'Fixes transcend.ready() for the TCF UI module',
  '8.18.1':
    'Fixes how displayStatus was not being updated when the TCF modal is opened',
  '8.18.0':
    'Fixed issue with essential tracking purposes being inherited from parent domains onto subdomain requests with existing purposes',
  '8.16.0':
    'New UI view state - CompleteOptionsToggles (useful for preference management)',
  '8.15.8': 'Performance improvements to internal utilities.',
  '8.15.7':
    'Add configurable z-index support for the consent manager ui through the data-ui-z-index attribute',
  '8.15.6':
    'Changed load option disableWhenConsented to be on by default for improved site performance. With disableWhenConsented is enabled, airgap will no longer attempt to regulate requests that the user has already consented to, thereby improving performance. Also optimized code relating to mutation observers for additional performance improvement.',
  '8.15.5':
    'Fixes a regression where CompleteOptions UI view state was not respecting the configured purposes for different regional experiences',
  '8.15.4': 'Use TCF module with TCF view state',
  '8.15.3': 'Performance improvements for regulating data: URIs',
  '8.15.2': 'DOM regulation engine performance improvements',
  '8.15.1': 'Update to airgap.js types for TCF support',
  '8.15.0':
    'Added support for regime-specific tracker overrides configs and external module selection',
  '8.14.0':
    'airgap.js "optIn"/"optOut" methods will not impact purposes that are hidden from the UI.',
  '8.13.1': 'airgap.js no longer hides its own script element.',
  '8.13.0':
    'Add support for tracker override options & removes FacebookLDUStrict tracker override. Currently this is only used by the FacebookLDU integration. The options shape for FacebookLDU is mode={on,off,fb-auto};{strict}. Example config: data-tracker-overrides="GoogleConsentMode GoogleAdsRDP YoutubePrivacyEnhancedMode FacebookLDU:mode=on;strict"',
  '8.12.2': 'Minor formatting change',
  '8.12.1': 'Updated TCF-related types in Transcend backend.',
  '8.12.0': 'Client-side sampling of outgoing requests and cookie mutations',
  '8.11.11':
    'Disable tamper resistance by default. Tamper resistance can be re-enabled with data-tamper-resist="on".',
  '8.11.10':
    'Added server-side support for client-side sampling of outgoing requests and cookie mutations',
  '8.11.9': 'Upgrade to new version of typescript.',
  '8.11.8': 'Updated types for airgap.js to support new typescript version.',
  '8.11.7': 'Added expiry field (iat) to the codec for JWT consent tokens.',
  '8.11.6': 'Updates package linter dependencies.',
  '8.11.5': 'Cleans up outdated comments in airgap code.',
  '8.11.4': 'Updated version of typescript that airgap is written in.',
  '8.11.3': 'Fixed srcset patcher to support trailing commas.',
  '8.11.2': 'Updates package dependencies.',
  '8.11.1': 'Optimized WebSocket patcher size.',
  '8.11.0':
    'Customized message for the privacy link footer of every consent banner view state. If you' +
    ' are customizing this link text from this version on, please do so for each affected view state,' +
    ' including LanguageOptions.',
  '8.10.0':
    'Improves DOM regulation performance with DOMTreeWalker and a checked node cache. The checked node' +
    'cache can be disabled with disabled with the following load option: `data-dom-protection-cache="off"`',
  '8.9.1': 'Accessibility improvements for the Consent Manager UI dialog',
  '8.9.0': 'Upgrade Node to v18',
  '8.8.0': 'Fixes an infinite-loop issue when auto-prompting the default UI',
  '8.7.0':
    'New AcceptAllRejectAllToggle view state. ' +
    'LanguageOptions says "Go back" instead of "More Choices".' +
    'Support for custom purposes in out of the box consent UI. ' +
    'Ability to enable specific languages in out of the box consent UI using data-languages data attribute.',
  '8.6.0':
    'New AcceptAllRejectAllToggle view state. Added back button to the Language view state.',
  '8.5.4': 'Mutation regulation and observer optimizations.',
  '8.5.3':
    'Improves regulation performance by skipping the processing of long data URIs',
  '8.5.2':
    'Makes airgap.js compatible with LivePerson native WebSocket requirement.',
  '8.5.1':
    'Fixes a minor typo for a description for one of the messages in the PrivacyPolicyNotice consent manager UI view state.',
  '8.5.0': 'Adds support for telemetry sampling.',
  '8.4.2':
    'Fixes an issue with our GCM/GTM integration by using function wrapper around dataLayer.push(...)',
  '8.4.1':
    'Fixes an issue with our GCM/GTM integration by using prototypeful objects',
  '8.4.0':
    'Improves compatibility with LivePerson chat system.' +
    '`data-ws-patcher="function"` enables a function-based WebSocket API patcher that has better compatibility with LivePerson.',
  '8.3.4': 'Improves compatibility for link node rel attribute regulation.',
  '8.3.3': 'Fixes unnecessary double-regulation of link nodes.',
  '8.3.2': 'Fixes handleLiveMutation handling of null request',
  '8.3.1':
    'Fixes link.rel setter regulation\n' +
    'Improves DOM regulation perf regulated to link.{href, rel} regulation\n' +
    'Improves telemetry collection accuracy — links without dangerous rels should not end up in telemetry anymore',
  '8.3.0':
    'Updates airgap to have new getViewState() function and event listener for view state changing',
  '8.1.0':
    'Adds API to get the current viewState of the consent-manger-ui: transcend.getViewState()',
  '8.0.1': 'Auto-clears disallowed cookies on every consent change',
  '8.0.0':
    'Changes how we handle invalid URLs. Invalid URLs cannot cause requests so we now allow them through.' +
    'This introduces a breaking change to the IPendingEvent.URLs API. ' +
    'IPendingEvent.URLs entries may now be nullish when they correspond with invalid input URLs.',
  '7.29.1':
    'Support GPC & DNT implementations that incorrectly expose values on `navigator` instead of `Navigator.prototype`.' +
    'This allows us to detect GPC signals from popular browser extensions.',
  '7.29.0':
    'Metadata can be cleared by specifying false as the value. Adds a new UI state `AcceptOrRejectAdvertising`',
  '7.28.8': 'Fix sessionStorage error in incognito iframes',
  '7.28.7':
    'Supports new data attribute data-mutation-observer="off" to disable our MutationObserver regulation fallback feature. This option should help with init-time site performance.',
  '7.28.6':
    'Set dom-protection-scoping=legacy as the default and allow customers to opt-in to the new mode of DOM regulation which is more strict but potentially less performant.',
} as const; // satisifes Record<string, string | AirgapRawChangelogEntry> <-- add once our build process can handle it
/* eslint-enable max-len */

/** Airgap version string */
export type AirgapVersionKey = keyof typeof AIRGAP_VERSION_CHANGELOG;

export interface AirgapChangelogIssueKeys {
  /** List of known issue keys associated with this airgap version */
  issues: AirgapIssueKey[];
}

export interface AirgapChangelogIssues {
  /** List of known issues associated with this airgap version */
  issues: AirgapIssue[];
}

export interface AirgapChangelogReleasedOn {
  /** ISO string of the date the version was released on */
  releasedOn: string;
}

/** full changelog entry type */
export type AirgapChangelogEntry = AirgapRawChangelogEntry &
  AirgapChangelogIssues &
  AirgapChangelogReleasedOn;

/** The record of all airgap versions available and their known issues */
export type AirgapRawChangelog = Record<
  AirgapVersionKey,
  string | AirgapRawChangelogEntry
>;

/** generic airgap changelog type */
export type AirgapChangelogI<T extends AirgapRawChangelogEntry> = Record<
  AirgapVersionKey,
  T
>;

/** The record of all airgap versions available, their release dates and known issues */
export type AirgapChangelog = AirgapChangelogI<AirgapChangelogEntry>;

/**
 * This iterates along changelog entries and constructs a new changelog with
 * the corresponding known issues for each version represented in the object
 *
 * @param changelog - raw changelog with only string/urgent object values
 * @param issues - record of airgap known version issues
 * @returns processed changelog with issues added
 */
export function populateChangelogIssues<T extends AirgapRawChangelogEntry>(
  changelog: AirgapRawChangelog,
  issues: Record<AirgapIssueKey, AirgapIssue>,
): AirgapChangelogI<T> {
  return Object.entries(changelog).reduce((agg, [version, entry]) => {
    const applicableIssues: AirgapIssue[] = [];
    Object.entries(issues).forEach(([, issue]) => {
      if ('version' in issue && version === issue.version) {
        applicableIssues.push(issue);
      } else if ('fixedIn' in issue) {
        let applicable = compareVersions(issue.fixedIn, version) === 1;
        if (issue.introducedIn) {
          applicable &&= [0, -1].includes(
            compareVersions(issue.introducedIn, version),
          );
        }
        if (applicable) {
          applicableIssues.push(issue);
        }
      }
    });
    const baseEntry =
      typeof entry === 'string' ? { description: entry } : { ...entry };
    return { ...agg, [version]: { ...baseEntry, issues: applicableIssues } };
  }, {}) as AirgapChangelogI<T>;
}

/**
 * This iterates along changelog entries and constructs a new changelog with
 * the corresponding release dates for each version represented in the object
 *
 * @param changelog - raw changelog with only string/urgent object values
 * @param releaseDates - mapping of airgap version to release date
 * @returns processed changelog with release date ISO strings added
 */
export function populateChangelogReleasedOn<T extends AirgapRawChangelogEntry>(
  changelog: AirgapRawChangelog,
  releaseDates: Record<string, Date> = {},
): AirgapChangelogI<T & AirgapChangelogReleasedOn> {
  return Object.entries(changelog).reduce((agg, [version, entry]) => {
    const releasedOn = releaseDates[version];
    const baseEntry =
      typeof entry === 'string' ? { description: entry } : { ...entry };
    return { ...agg, [version]: { ...baseEntry, releasedOn } };
  }, {}) as AirgapChangelogI<T & AirgapChangelogReleasedOn>;
}

/* eslint-enable max-lines */
