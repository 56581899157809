/* eslint-disable max-lines */
import { defineMessages } from '@transcend-io/internationalization';

import {
  AUDIT_EVENT_SENSITIVE_FIELDS,
  AuditEventCode,
} from '@main/audit-types';

import { DisplayedBaseType } from './types';

export const auditTrailMessages = defineMessages(
  'ad-core-components.AuditTrail.auditTrail',
  {
    apiKey: {
      defaultMessage: 'API Key: {title}',
    },
    noEvents: {
      defaultMessage: 'No events found',
    },
    auditCount: {
      defaultMessage: '{count} {count, plural, one {event} other {events}}',
    },
  },
);

export const auditTrailSensitiveChangeMessages =
  defineMessages<AUDIT_EVENT_SENSITIVE_FIELDS>(
    'ad-core-components.AuditTrail.auditTrailSensitiveChange',
    {
      saaSContext: {
        defaultMessage: 'Integration connection information (encrypted)',
      },
    },
  );

export const auditTrailStateMessages = defineMessages(
  'ad-core-components.AuditTrail.auditTrailState',
  {
    created: {
      defaultMessage: '{type} {name} was created {additionalMessage}',
    },
    updated: {
      defaultMessage: '{type} {name} was updated {additionalMessage}',
    },
    deleted: {
      defaultMessage: '{type} {name} was deleted {additionalMessage}',
    },
  },
);

export const auditTrailJoinMessages = defineMessages(
  'ad-core-components.AuditTrail.auditTrailJoin',
  {
    added: {
      defaultMessage: '{type} for {baseType} {name} were added',
    },
    removed: {
      defaultMessage: '{type} for {baseType} {name} were removed',
    },
    replaced: {
      defaultMessage: '{type} for {baseType} {name} were replaced',
    },
    cleared: {
      defaultMessage: '{type} for {baseType} {name} were cleared',
    },
    updated: {
      defaultMessage: '{type} for {baseType} {name} were updated',
    },
    addedSingle: {
      defaultMessage: '{name} was added',
    },
    removedSingle: {
      defaultMessage: '{name} was removed',
    },
    replacedDescription: {
      defaultMessage: 'All previous {type} were replaced with',
    },
  },
);

// Pretty name for `type` in auditTrailStateMessages and auditTrailJoinMessages above.
export const auditTrailPrettyCodeMessages = defineMessages<AuditEventCode>(
  'ad-core-components.AuditTrail.auditTrailPrettyCode',
  {
    // state events are singular
    [AuditEventCode.ActionItem]: {
      defaultMessage: 'Action Item',
    },
    [AuditEventCode.DataSilo]: {
      defaultMessage: 'Integration',
    },
    [AuditEventCode.DataReport]: {
      defaultMessage: 'Data Report',
    },
    [AuditEventCode.SubDataPoint]: {
      defaultMessage: 'Datapoint',
    },
    [AuditEventCode.DataPoint]: {
      defaultMessage: 'Datapoint',
    },
    [AuditEventCode.DataSubCategory]: {
      defaultMessage: 'Category',
    },
    [AuditEventCode.ProcessingPurposeSubCategory]: {
      defaultMessage: 'Purpose',
    },
    [AuditEventCode.ProcessingPurposeSubCategoryTeam]: {
      defaultMessage: 'Purpose Teams',
    },
    [AuditEventCode.ProcessingPurposeSubCategoryOwner]: {
      defaultMessage: 'Purpose Owners',
    },
    [AuditEventCode.DataSubCategoryTeam]: {
      defaultMessage: 'Category Teams',
    },
    [AuditEventCode.DataSubCategoryOwner]: {
      defaultMessage: 'Category Owners',
    },
    [AuditEventCode.Identifier]: {
      defaultMessage: 'Identifier',
    },
    [AuditEventCode.Preference]: {
      defaultMessage: 'Preference',
    },
    [AuditEventCode.Policy]: {
      defaultMessage: 'Policy',
    },
    [AuditEventCode.PolicyVersion]: {
      defaultMessage: 'Policy Version',
    },
    [AuditEventCode.Message]: {
      defaultMessage: 'Message',
    },
    [AuditEventCode.AirgapBundle]: {
      defaultMessage: 'Airgap Bundle',
    },
    [AuditEventCode.AirgapBundleDeployment]: {
      defaultMessage: 'Airgap Bundle Deployment',
    },
    [AuditEventCode.AirgapBundleVersion]: {
      defaultMessage: 'Airgap Bundle Deployment (Old Audit Format)',
    },
    [AuditEventCode.AirgapCookie]: {
      defaultMessage: 'Airgap Cookie',
    },
    [AuditEventCode.AirgapDataFlow]: {
      defaultMessage: 'Airgap Data Flow',
    },
    [AuditEventCode.AirgapTheme]: {
      defaultMessage: 'Airgap Theme',
    },
    [AuditEventCode.PrivacyCenter]: {
      defaultMessage: 'Privacy Center',
    },
    [AuditEventCode.Theme]: {
      defaultMessage: 'Theme',
    },
    [AuditEventCode.DataCollection]: {
      defaultMessage: 'Data Collection',
    },
    [AuditEventCode.AssetFile]: {
      defaultMessage: 'Asset File',
    },
    [AuditEventCode.EmailSenderAddress]: {
      defaultMessage: 'Conditional Email Address',
    },
    [AuditEventCode.RiskFramework]: {
      defaultMessage: 'Risk Framework',
    },
    [AuditEventCode.RiskLevel]: {
      defaultMessage: 'Risk Level',
    },
    [AuditEventCode.RiskCategory]: {
      defaultMessage: 'Risk Category',
    },
    [AuditEventCode.RiskMatrixColumn]: {
      defaultMessage: 'Risk Matrix Column',
    },
    [AuditEventCode.RiskMatrixRow]: {
      defaultMessage: 'Risk Matrix Row',
    },
    // join events are plural
    [AuditEventCode.DataPointAction]: {
      defaultMessage: 'Request Actions',
    },
    [AuditEventCode.DataPointLevelDescription]: {
      defaultMessage: 'Data Point Level Description',
    },
    [AuditEventCode.DataPointLevelOwner]: {
      defaultMessage: 'Data Point Level Owners',
    },
    [AuditEventCode.DataPointLevelTeam]: {
      defaultMessage: 'Data Point Level Teams',
    },
    [AuditEventCode.AirgapDataFlowOwner]: {
      defaultMessage: 'Airgap Data Flow Owners',
    },
    [AuditEventCode.AirgapDataFlowTeam]: {
      defaultMessage: 'Airgap Data Flow Teams',
    },
    [AuditEventCode.AirgapDataFlowAttribute]: {
      defaultMessage: 'Airgap Data Flow Custom Fields',
    },
    [AuditEventCode.AirgapDataFlowPurpose]: {
      defaultMessage: 'Purposes',
    },
    [AuditEventCode.AirgapCookieOwner]: {
      defaultMessage: 'Airgap Cookie Owners',
    },
    [AuditEventCode.AirgapCookieTeam]: {
      defaultMessage: 'Airgap Cookie Teams',
    },
    [AuditEventCode.AirgapCookieAttribute]: {
      defaultMessage: 'Airgap Cookie Custom Fields',
    },
    [AuditEventCode.AirgapCookiePurpose]: {
      defaultMessage: 'Purposes',
    },
    [AuditEventCode.DataPointOwner]: {
      defaultMessage: 'Data Point Owners',
    },
    [AuditEventCode.DataPointTeam]: {
      defaultMessage: 'Data Point Teams',
    },
    [AuditEventCode.SubDataPointDataSubCategory]: {
      defaultMessage: 'Data Categories',
    },
    [AuditEventCode.DataSiloOwner]: {
      defaultMessage: 'Owners',
    },
    [AuditEventCode.VendorOwner]: {
      defaultMessage: 'Owners',
    },
    [AuditEventCode.VendorTeam]: {
      defaultMessage: 'Teams',
    },
    [AuditEventCode.Vendor]: {
      defaultMessage: 'Vendor',
    },
    [AuditEventCode.RiskCategoryTeam]: {
      defaultMessage: 'Teams',
    },
    [AuditEventCode.RiskCategoryOwner]: {
      defaultMessage: 'Owners',
    },
    [AuditEventCode.BusinessEntity]: {
      defaultMessage: 'Business Entity',
    },
    [AuditEventCode.BusinessEntityTeam]: {
      defaultMessage: 'Teams',
    },
    [AuditEventCode.BusinessEntityOwner]: {
      defaultMessage: 'Owners',
    },
    [AuditEventCode.VendorBusinessEntity]: {
      defaultMessage: 'Vendor Business Entity',
    },
    [AuditEventCode.DataSiloVendor]: {
      defaultMessage: 'Vendors',
    },
    [AuditEventCode.DataSiloBusinessEntity]: {
      defaultMessage: 'Data Silo Business Entities',
    },
    [AuditEventCode.VendorAttribute]: {
      defaultMessage: 'Custom Fields',
    },
    [AuditEventCode.BusinessEntityAttribute]: {
      defaultMessage: 'Custom Fields',
    },
    [AuditEventCode.DataSubCategoryAttribute]: {
      defaultMessage: 'Custom Fields',
    },
    [AuditEventCode.ProcessingPurposeSubCategoryAttribute]: {
      defaultMessage: 'Custom Fields',
    },
    [AuditEventCode.DataSiloPlugin]: {
      defaultMessage: 'Plugin',
    },
    [AuditEventCode.DataSiloSubjectDenies]: {
      defaultMessage: 'Subject Exclusions',
    },
    [AuditEventCode.DataSiloTeam]: {
      defaultMessage: 'Team Owners',
    },
    [AuditEventCode.DataSiloDataSilo]: {
      defaultMessage: '"Sends Data To" Silos',
    },
    [AuditEventCode.SubDataPointAttribute]: {
      defaultMessage: 'Datapoint Custom Fields',
    },
    [AuditEventCode.DataSiloApiKey]: {
      defaultMessage: 'Integration API Keys',
    },
    [AuditEventCode.DataSiloAttribute]: {
      defaultMessage: 'Integration Custom Fields',
    },
    [AuditEventCode.SubDataPointProcessingPurposeSubCategory]: {
      defaultMessage: 'Purposes',
    },
    [AuditEventCode.SubDataPointSubject]: {
      defaultMessage: 'Subjects',
    },
    [AuditEventCode.DataSiloDependentDataSilo]: {
      defaultMessage: 'Data Silo Dependencies',
    },
    [AuditEventCode.IdentifierDataSilo]: {
      defaultMessage: 'Identifiers',
    },
    [AuditEventCode.IdentifierAction]: {
      defaultMessage: 'Actions',
    },
    [AuditEventCode.IdentifierSubject]: {
      defaultMessage: 'Subjects',
    },
    [AuditEventCode.AssessmentFormAssignee]: {
      defaultMessage: 'Assessment Form Assignees',
    },
    [AuditEventCode.AssessmentSectionAssignee]: {
      defaultMessage: 'Assessment Section Assignees',
    },
    [AuditEventCode.AssessmentFormReviewer]: {
      defaultMessage: 'Assessment Form Reviewers',
    },
    [AuditEventCode.Experience]: {
      defaultMessage: 'Experience',
    },
    [AuditEventCode.ExperiencePurpose]: {
      defaultMessage: 'Experience',
    },
    [AuditEventCode.TrackingPurpose]: {
      defaultMessage: 'Purpose',
    },
    [AuditEventCode.AssessmentEmailSet]: {
      defaultMessage: 'Assessment Email Set',
    },
    [AuditEventCode.AssessmentAnswer]: {
      defaultMessage: 'Assessment Answer',
    },
    [AuditEventCode.AssessmentQuestionComment]: {
      defaultMessage: 'Assessment Question Comment',
    },
    [AuditEventCode.AssessmentSectionComment]: {
      defaultMessage: 'Assessment Section Comment',
    },
    [AuditEventCode.AssessmentFormTemplate]: {
      defaultMessage: 'Assessment Form Template',
    },
    [AuditEventCode.AssessmentGroup]: {
      defaultMessage: 'Assessment Group',
    },
    [AuditEventCode.AssessmentForm]: {
      defaultMessage: 'Assessment Form',
    },
    [AuditEventCode.AssessmentFormComment]: {
      defaultMessage: 'Assessment Form Comment',
    },
    [AuditEventCode.AssessmentQuestion]: {
      defaultMessage: 'Assessment Question',
    },
    [AuditEventCode.AssessmentSection]: {
      defaultMessage: 'Assessment section',
    },
    [AuditEventCode.EmailSenderAddressAttributeValue]: {
      defaultMessage: 'Custom Field Values',
    },
    [AuditEventCode.PrivacyCenterEmailSenderAddress]: {
      defaultMessage: 'Privacy Centers',
    },
    [AuditEventCode.ScannedObject]: {
      defaultMessage: 'Unstructured Data Discovery Objects',
    },
    [AuditEventCode.UnstructuredSubDataPointRecommendation]: {
      defaultMessage: 'Unstructured Data Discovery Recommendation',
    },
    [AuditEventCode.Sombra]: {
      defaultMessage: 'Sombra',
    },
    [AuditEventCode.SombraApiKey]: {
      defaultMessage: 'Sombra API Key',
    },
    [AuditEventCode.OrgTcfPurpose]: { defaultMessage: 'TCF Purpose' },
    [AuditEventCode.OrgTcfSpecialFeature]: {
      defaultMessage: 'TCF Special Feature',
    },
    [AuditEventCode.OrgTcfPurposePurpose]: { defaultMessage: 'Purposes' },
    [AuditEventCode.OrgTcfSpecialFeaturePurpose]: {
      defaultMessage: 'Purposes',
    },
    [AuditEventCode.ConsentService]: { defaultMessage: 'Consent Service' },
    [AuditEventCode.AirgapBundleTcfStack]: {
      defaultMessage: 'TCF Stacks',
    },
    [AuditEventCode.DataSiloDataSubCategory]: {
      defaultMessage: 'Data Categories',
    },
    [AuditEventCode.DataSiloProcessingPurposeSubCategory]: {
      defaultMessage: 'Processing Purposes',
    },
    [AuditEventCode.DataSiloSaaSCategory]: {
      defaultMessage: 'Categories of Recipients',
    },
    [AuditEventCode.AirgapPartition]: { defaultMessage: 'Airgap Partition' },
    [AuditEventCode.PreferenceOption]: { defaultMessage: 'Preference Option' },
    [AuditEventCode.PreferenceTopic]: { defaultMessage: 'Preference Topic' },
    [AuditEventCode.ConsentWorkflowTrigger]: {
      defaultMessage: 'Consent Workflows',
    },
    [AuditEventCode.CustomFunctionCode]: {
      defaultMessage: 'Custom Function Code',
    },
    [AuditEventCode.CustomFunctionCodeContext]: {
      defaultMessage: 'Custom Function Code Context',
    },
  },
);

// Pretty name for `baseType` in auditTrailJoinMessages above.
export const auditTrailBaseTypePrettyCodeMessages =
  defineMessages<DisplayedBaseType>(
    'ad-core-components.AuditTrail.auditTrailBaseTypePrettyCode',
    {
      [AuditEventCode.SubDataPointAttribute]: {
        defaultMessage: 'Datapoint Property',
      },
      [AuditEventCode.SubDataPointDataSubCategory]: {
        defaultMessage: 'Datapoint Property',
      },
      [AuditEventCode.SubDataPointProcessingPurposeSubCategory]: {
        defaultMessage: 'Datapoint Property',
      },
      [AuditEventCode.SubDataPointSubject]: {
        defaultMessage: 'Datapoint Property',
      },
      [AuditEventCode.IdentifierAction]: {
        defaultMessage: 'Identifier',
      },
      [AuditEventCode.IdentifierSubject]: {
        defaultMessage: 'Identifier',
      },
      [AuditEventCode.DataPointAction]: {
        defaultMessage: 'Datapoint Object',
      },
    },
  );

/* eslint-enable max-lines */
