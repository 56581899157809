import { Popover, StyleUtils } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { Button, ButtonProps } from './Button';
import { commonButtonMessages } from './messages';
import { HeaderWrapper, StyledVerticalBar } from './wrappers';

interface FilterPopoverButtonProps {
  /** the placement of the popover */
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  /** The width of the popover contents window */
  width?: string;
  /** the number of active filters */
  activeFilterCount?: number;
  /** whether to hide the standard clear and done controls */
  hideStandardControls?: boolean;
  /** whether to show the standard filter text on the button */
  showFilterText?: boolean;
  /** on clear handler */
  onClear?: () => void;
}

/**
 * FilterPopoverButton
 */
export const FilterPopoverButton: React.FC<
  ButtonProps & FilterPopoverButtonProps
> = ({
  children,
  placement,
  activeFilterCount = 0,
  hideStandardControls,
  onClear,
  showFilterText,
  width,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const hasActiveFilters = activeFilterCount > 0;
  const theme = useTheme();

  // Warning: Do not copy, this will be changing soon
  const specialButtonActiveStyling = {
    color: theme.colors.transcend,
    borderColor: theme.colors.transcend3,
  };

  const popover = hideStandardControls ? (
    children
  ) : (
    <>
      <HeaderWrapper>
        <Button
          style={hasActiveFilters ? specialButtonActiveStyling : undefined}
          size="sm"
          variant="secondary"
          onClick={() => {
            onClear?.();
          }}
        >
          {formatMessage(commonButtonMessages.clear)}
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            // dismiss the popover
            document.body.click();
          }}
        >
          {formatMessage(commonButtonMessages.done)}
        </Button>
      </HeaderWrapper>
      <div style={{ padding: StyleUtils.Spacing.md }}>{children}</div>
    </>
  );

  return (
    <Popover
      trigger="click"
      placement={placement || 'bottom'}
      contents={popover}
      rootClose
      noPadding
      width={width || undefined}
      id="filters-popover"
    >
      <Button
        style={hasActiveFilters ? specialButtonActiveStyling : undefined}
        icon="filter"
        variant="secondary"
        iconOnly={!hasActiveFilters && !showFilterText}
        size="sm"
        {...rest}
      >
        {showFilterText && (
          <span
            style={
              hasActiveFilters
                ? { marginRight: StyleUtils.Spacing.sm }
                : undefined
            }
          >
            {formatMessage(commonButtonMessages.filter)}
          </span>
        )}
        {hasActiveFilters && (
          <>
            <StyledVerticalBar />
            {activeFilterCount}
          </>
        )}
      </Button>
    </Popover>
  );
};
