import styled from 'styled-components';

import { FlexColumn, StyleUtils } from '@main/core-ui';

/**
 * Upload wrapper props
 */
export interface WrapperProps {
  /** The amount of padding for the wrapper */
  padding: string;
}

/**
 * The Upload wrapper
 */
export const UploadWrapper = styled.div<WrapperProps>`
  padding-left: ${({ padding }) => padding};
  padding-right: ${({ padding }) => padding};
`;

export const DropZoneWrapper = styled(FlexColumn)`
  border: 2px dashed ${({ theme }) => theme.colors.transcendNavy4};
  border-radius: 0.9em;
  padding: ${StyleUtils.Spacing.md};
  gap: ${StyleUtils.Spacing.md};
  transition:
    background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.transcendNavy3};
  }
`;
