import {
  AdminDashboardAdministrationPath,
  AdminDashboardInfrastructurePath,
  AdminDashboardPrivateMiscPath,
} from '@main/access-control-types';
import { AdminDashboardPrivacyRequestPath } from '@main/dsr-types';
import { AdminDashboardPrivacyCenterPath } from '@main/pc-types';

export const SETTINGS_REDIRECT_MAP: Record<string, string> = {
  '#RequestSettings': AdminDashboardPrivacyRequestPath.Settings,
  '#RequestIdentifierSettings': AdminDashboardPrivacyRequestPath.Identifiers,
  '#TemplateSettings': AdminDashboardPrivacyRequestPath.EmailSettingsTemplates,
  '#Sombra': AdminDashboardInfrastructurePath.SombraRotateKeys,
  '#Developer': AdminDashboardInfrastructurePath.ApiKeys,
  '#EmailDomains': AdminDashboardInfrastructurePath.EmailDomains,
  '#Billing': AdminDashboardAdministrationPath.Billing,
  '#OrganizationSettings': AdminDashboardAdministrationPath.Organization,
  '#UserProfile': AdminDashboardPrivateMiscPath.Profile,
  '#UserScopes': AdminDashboardAdministrationPath.Users,
  '#Security': AdminDashboardAdministrationPath.Sso,
};

export const PRIVACY_CENTER_REDIRECT_MAP: Record<string, string> = {
  '#GeneralSettings': AdminDashboardPrivacyCenterPath.GeneralSettings,
  '#Policies': AdminDashboardPrivacyCenterPath.Policies,
  '#ColorPalette': AdminDashboardPrivacyCenterPath.ColorsAndStyles,
  '#AssetFiles': AdminDashboardPrivacyCenterPath.AssetFiles,
  '#GlobalMessages':
    AdminDashboardPrivacyCenterPath.MessagesInternationalization,
};
