import { RegionInput } from '@main/datamap-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import {
  ConsentPreferenceResponseSombra,
  ConsentRecordSchema,
  DecryptionContext,
  MCDConsentLookupInput,
  PreferenceStoreIdentifierInput,
  PurposeInput,
} from '@main/sombra-types';

export const ConsentPreferencesInput = mkInput({
  name: 'ConsentPreferencesInput',
  comment: 'Input for querying consent preferences',
  fields: {
    partition: MCDConsentLookupInput.fields.partition,
    lastKey: MCDConsentLookupInput.fields.lastKey,
    timestampBefore: MCDConsentLookupInput.fields.timestampBefore,
    timestampAfter: MCDConsentLookupInput.fields.timestampAfter,
    updatedBefore: MCDConsentLookupInput.fields.updatedBefore,
    updatedAfter: MCDConsentLookupInput.fields.updatedAfter,
    identifiers: {
      comment: 'Identifiers to query for, in plaintext',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ConsentPreferencesInput = SchemaToType<
  typeof ConsentPreferencesInput
>;

export const ConsentPreferenceUpdateInput = mkInput({
  name: 'ConsentPreferenceUpdateInput',
  comment: 'Input for updating a single consent preference',
  fields: {
    partition: ConsentRecordSchema.fields.partition,
    identifier: {
      comment: 'Identifier associated with consent data -- in plaintext',
      type: 'string',
      optional: true,
    },
    purposes: {
      comment: 'Consent purposes',
      type: PurposeInput,
      list: true,
    },
    identifiers: {
      comment: 'The identifiers for the user',
      type: PreferenceStoreIdentifierInput,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type ConsentPreferenceUpdateInput = SchemaToType<
  typeof ConsentPreferenceUpdateInput
>;

export const UpdateConsentPreferenceInput = mkInput({
  name: 'UpdateConsentPreferenceInput',
  comment: 'Input for updating consent preferences',
  fields: {
    consentPreferences: {
      type: ConsentPreferenceUpdateInput,
      comment: 'The consent preferences to update',
      list: true,
    },
    subjectType: {
      comment: "The unique name of the organization's data subject class",
      type: 'string',
      optional: true,
    },
    locale: {
      comment: 'The locale to translate the request it',
      type: 'string',
      optional: true,
    },
    region: {
      comment: 'The region that the request was submitted in',
      type: RegionInput,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateConsentPreferenceInput = SchemaToType<
  typeof UpdateConsentPreferenceInput
>;

export const UpdateConsentPreferenceOutput = mkType({
  name: 'UpdateConsentPreferenceOutput',
  comment: 'Response when updating consent preferences',
  fields: {
    data: ConsentPreferenceResponseSombra.fields.nodes,
    decryptionContext: {
      comment:
        'The decryption context containing a mapping of encrypted consent identifiers to their plaintext values',
      type: DecryptionContext,
    },
  },
});

/** Override type */
export type UpdateConsentPreferenceOutput = SchemaToType<
  typeof UpdateConsentPreferenceOutput
>;
