import { DoubleTag, Icon, Popover, SingleTag } from '@main/core-ui';
import {
  dataCategoryTypeMessages,
  FALLBACK_CATEGORY_NAME,
} from '@main/datamap-types';
import type { ClassificationMethod } from '@main/sombra-types';
import { DataCategoryType } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { DATA_CATEGORY_TYPE_COLOR_NAMES } from '../../constants';
import {
  classificationMethodMessages,
  selectSubCategoryMessages,
} from './messages';
import { StyledRightLabelSpan } from './wrappers';

export interface DataCategoryPillProps {
  /** whether to add guess styling */
  variant: 'outline' | 'filled';
  /** The classification method that produced this guess, if a guess */
  classificationMethod?: ClassificationMethod;
  /** Classifier version for a guess */
  classifierVersion?: number;
  /** Name of subcategory */
  name?: string;
  /** Label */
  label: string | JSX.Element;
  /** The selected data subcategories for this subdatapoint */
  rightIcon?: JSX.Element;
  /** Data category type */
  dataCategory: DataCategoryType;
}

export const DataCategoryPill: React.FC<DataCategoryPillProps> = ({
  variant,
  classificationMethod,
  classifierVersion,
  dataCategory,
  rightIcon,
  name,
  label,
}) => {
  const { formatMessage } = useIntl();
  const color = DATA_CATEGORY_TYPE_COLOR_NAMES[dataCategory];

  const getClassificationMessage = (): string => {
    if (!classificationMethod) return '';
    const baseMessage = formatMessage(selectSubCategoryMessages.classifiedBy, {
      classificationMethod: formatMessage(
        classificationMethodMessages[classificationMethod],
      ),
    });
    return classifierVersion
      ? `${baseMessage} ${formatMessage(
          selectSubCategoryMessages.classifierVersion,
          {
            version: classifierVersion,
          },
        )}`
      : baseMessage;
  };

  const labelWithInfoTooltip = (
    label: JSX.Element | string,
  ): JSX.Element | string =>
    classificationMethod ? (
      <>
        {label}
        <Popover
          trigger={['hover']}
          placement="top"
          contents={getClassificationMessage()}
        >
          <StyledRightLabelSpan>
            <Icon size={14} type="i-circle" />
          </StyledRightLabelSpan>
        </Popover>
      </>
    ) : (
      label ?? ''
    );

  return dataCategory === DataCategoryType.Unspecified ? (
    <SingleTag
      style={{ margin: '0.1em', display: 'inline-flex' }}
      color="transcendNavy2"
      label={formatMessage(dataCategoryTypeMessages[dataCategory])}
    />
  ) : !name || name === FALLBACK_CATEGORY_NAME ? (
    <SingleTag
      style={{ margin: '0.1em', display: 'inline-flex' }}
      variant={variant}
      label={labelWithInfoTooltip(label)}
      color={color}
      rightIcon={rightIcon}
    />
  ) : (
    <DoubleTag
      style={{ margin: '0.1em', display: 'inline-flex' }}
      variant={variant}
      leftLabel={label}
      rightLabel={labelWithInfoTooltip(name)}
      color={color}
      rightIcon={rightIcon}
    />
  );
};
