import {
  FlexRowCenterVertical,
  GenericMessageDescriptor,
  StyleUtils,
} from '@main/core-ui';
import React, { useState } from 'react';
import type { FormControlProps } from 'react-bootstrap/FormControl';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { Copy } from '../Copy';
import { inlineUrlMessages } from './messages';
import { ButtonWrapper, StyledInlineTextInput } from './wrappers';

/** Props for the `InlineUrlInput` component */
export type InlineUrlInputProps = FormControlProps & {
  /** Show placeholder */
  showPlaceholder?: boolean;
  /** Placeholder */
  placeholder?: GenericMessageDescriptor;
  /** HTML element name attribute */
  name?: string;
};

export const InlineUrlInput = React.forwardRef<
  HTMLInputElement,
  InlineUrlInputProps
>(({ value, onChange, ...props }, ref) => {
  const { formatMessage } = useIntl();

  // Only used to store the value for buttons; not passed to parent or children
  const [internalValue, setInternalValue] = useState(value);

  return (
    <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.xs }}>
      <StyledInlineTextInput
        ref={ref}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInternalValue(e.target.value);
          onChange?.(e);
        }}
        {...props}
      />

      <ButtonWrapper className="url-input-buttons">
        {internalValue ? (
          <Copy
            value={typeof internalValue === 'string' ? internalValue : ''}
            iconOnly
            aria-label={formatMessage(inlineUrlMessages.copyButtonLabel)}
          />
        ) : (
          <Button
            disabled
            size="sm"
            variant="outline-secondary"
            icon="copy"
            aria-label={formatMessage(inlineUrlMessages.copyButtonLabel)}
          />
        )}
        <Button
          disabled={!internalValue}
          size="sm"
          variant="outline-secondary"
          icon="external-link"
          aria-label={formatMessage(inlineUrlMessages.followLinkButtonLabel)}
          // Only make this button a link if value is provided
          {...(typeof internalValue === 'string'
            ? {
                target: '_blank',
                // TODO: https://transcend.height.app/T-19071 - validate the URL
                // When no protocol is provided, prefix with `//`  to make sure it isn't interpreted as a relative URL
                href: `${
                  !internalValue.includes('//') ? '//' : ''
                }${internalValue}`,
                // user-generated content
                rel: 'ugc',
              }
            : {})}
        />
      </ButtonWrapper>
    </FlexRowCenterVertical>
  );
});
