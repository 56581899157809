import React from 'react';
import { useIntl } from 'react-intl';

import { DropdownMenu, DropdownMenuProps } from '../DropdownMenu';
import { Button, ButtonProps } from './Button';
import { buttonWithDropdownMessages } from './messages';
import { ButtonWithDropdownMenuWrapper } from './wrappers';

export const ButtonWithDropdownMenu: React.FC<
  ButtonProps & {
    /** items for the dropdown menu */
    dropdownMenuItems: DropdownMenuProps['items'];
  }
> = ({ children, dropdownMenuItems, variant, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <ButtonWithDropdownMenuWrapper>
      <Button
        {...props}
        variant={variant}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          // keep outline variants from having a double border
          borderRightWidth: 0,
          ...props.style,
        }}
      >
        {children}
      </Button>
      <DropdownMenu
        id="share-assessment-dropdown"
        items={dropdownMenuItems}
        buttonProps={{
          'aria-label': formatMessage(buttonWithDropdownMessages.moreOptions),
          style: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftColor:
              !variant ||
              [
                'primary',
                'secondary-filled',
                'success',
                'danger',
                'dark',
              ].includes(variant)
                ? `rgba(255,255,255, 0.25)`
                : '',
          },
          variant,
        }}
      />
    </ButtonWithDropdownMenuWrapper>
  );
};
