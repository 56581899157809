import { ExternalDonutChartData } from '../types';
import { InternalDonutChartData } from './types';

export const getInternalDonutChartData = (
  externalData: ExternalDonutChartData,
): InternalDonutChartData =>
  externalData.labels.map((label, idx) => ({
    label,
    value: externalData.values[idx] || 0,
    key: externalData.keys[idx] || '',
  }));

export const getTotal = (data: InternalDonutChartData): number =>
  data.reduce((soFar, curr) => soFar + curr.value, 0);
