import { formatErrorMessage, message } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import * as streamSaver from 'streamsaver';

import { Button } from '../Button';
import { uploadCsvMessages } from './messages';
import { CsvColumn } from './types';

export interface DownloadSampleCsvButtonProps<T extends {}> {
  /**  The expected columns for the CSV file. */
  expectedColumns: CsvColumn<T>[];
  /** If true, only the required columns will be included in the sample CSV. */
  requiredColumnsOnly?: boolean;
  /** The label for the button. */
  buttonLabel?: string;
  /** The name of the file to download */
  csvFileName?: string;
}

/**
 * Button that downloads a sample CSV file with the expected columns.
 */
export function DownloadSampleCsvButton<T extends {}>({
  expectedColumns,
  requiredColumnsOnly = false,
  buttonLabel,
  csvFileName,
}: DownloadSampleCsvButtonProps<T>): JSX.Element {
  const { formatMessage } = useIntl();
  const handleDownload = async (): Promise<void> => {
    const finalFileName = `${csvFileName}.csv` || 'sample-csv.csv';
    const fileHandle = streamSaver.createWriteStream(finalFileName);
    const writer = fileHandle.getWriter();
    const textEncoder = new TextEncoder();

    try {
      const columnsToInclude = requiredColumnsOnly
        ? expectedColumns.filter((col) => col.required)
        : expectedColumns;
      const str = columnsToInclude.map((col) => col.columnName).join(',');
      const encoded = textEncoder.encode(str);

      await writer.write(encoded);
      // for some reason writes don't flush immediately (even when awaiting on
      // the .write() call), so we need to wait for an arbitrary amount of time
      // for them to flush correctly before saving the file
      setTimeout(() => {
        // no need for an await bc it's in a timeout
        writer.close();
      }, 500);
    } catch (e) {
      message.error(formatErrorMessage(e.message));
      await writer.abort();
    }
  };

  return (
    <Button onClick={handleDownload} variant="secondary" icon="download">
      {buttonLabel || formatMessage(uploadCsvMessages.downloadSampleCsv)}
    </Button>
  );
}
