import { mkType, SchemaToType } from '@main/schema-utils';
import {
  PreferenceStoreIdentifierSchema,
  PurposeInput,
} from '@main/sombra-types';

import { EnrichedPreference } from './preferenceTopic';

export const EnrichedPurposeSchema = mkType({
  name: 'EnrichedPurposeSchema',
  comment: 'purpose',
  fields: {
    purpose: PurposeInput.fields.purpose,
    consent: PurposeInput.fields.consent,
    preferences: {
      comment: 'User preferences for this purpose',
      type: EnrichedPreference,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type EnrichedPurposeSchema = SchemaToType<typeof EnrichedPurposeSchema>;

export const EnrichedConsentRecordSchema = mkType({
  name: 'EnrichedConsentRecordSchema',
  comment: 'Enriched record of consent preferences',
  fields: {
    encryptedIdentifier: {
      comment: 'encrypted identifier for user',
      type: 'string',
    },
    partition: {
      comment:
        'The partition to lookup consent for (by default, this is the consent manager or airgap bundle id',
      type: 'string',
    },
    purposes: {
      comment: 'purposes',
      type: EnrichedPurposeSchema,
      list: true,
    },
    timestamp: {
      comment: 'Most recent consent event timestamp (ISO 8601)',
      type: 'string',
    },
    usp: {
      comment: 'US IAB Privacy String',
      type: 'string',
      optional: true,
    },
    gpp: {
      comment: 'IAB GPP String, encoding both USP and USNAT',
      type: 'string',
      optional: true,
    },
    tcf: {
      comment: 'IAB TCF String',
      type: 'string',
      optional: true,
    },
    airgapVersion: {
      comment: 'Airgap Version',
      type: 'string',
      optional: true,
    },
    metadata: {
      comment: 'Metadata',
      type: 'string',
      optional: true,
    },
    metadataTimestamp: {
      comment: 'Last-modified for metadata timestamp (ISO 8601)',
      type: 'string',
      optional: true,
    },
    updatedAt: {
      comment: 'Last record modification timestamp (ISO 8601)',
      type: 'string',
      optional: true,
    },
    identifiers: {
      comment: 'The identifiers of the user',
      type: PreferenceStoreIdentifierSchema,
      list: true,
    },
  },
});

/** Override type */
export type EnrichedConsentRecordSchema = SchemaToType<
  typeof EnrichedConsentRecordSchema
>;
