import { Badge, Popover, StyleUtils } from '@main/core-ui';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { joinAssociationPreviewMessages } from './messages';

/**
 * Props for JoinAssociationPreview
 */
export interface JoinAssociationPreviewProps<TJoin> {
  /** The preview header message */
  headerMessage: MessageDescriptor;
  /** The association items to display */
  items: TJoin[];
  /** Render a single item */
  renderItem: (item: TJoin) => React.ReactNode;
  /** Provide the maximum number of items to display as `All` instead of a number */
  maxCount?: number;
  /** The maximum height for the popover */
  maxHeight?: string;
  /** The maximum width for the popover */
  maxWidth?: string;
}

/**
 * Preview join associations for an instance
 */
export function JoinAssociationPreview<TJoin>({
  maxCount,
  items,
  headerMessage,
  renderItem,
  maxHeight = '250px',
  maxWidth = '500px',
}: JoinAssociationPreviewProps<TJoin>): JSX.Element {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  return (
    <Popover
      contents={
        <div
          style={{
            ...StyleUtils.Grid.EvenColumns(3),
            maxWidth,
            maxHeight,
            overflowY: 'scroll',
            gap: StyleUtils.Spacing.sm,
          }}
        >
          {items.length === 0 ? (
            <i>
              <small>
                {formatMessage(joinAssociationPreviewMessages.none)}
              </small>
            </i>
          ) : (
            items.map((item) => (
              <Badge
                color="gray1"
                key={(item as any)?.id || item}
                style={{
                  ...StyleUtils.Flex.Row.CenterVertical,
                  textAlign: 'left',
                }}
              >
                {renderItem(item)}
              </Badge>
            ))
          )}
        </div>
      }
    >
      <b
        style={{
          cursor: 'pointer',
          color: theme.colors.transcend,
          fontWeight: 'bolder',
        }}
      >
        {formatMessage(headerMessage, {
          count:
            maxCount === items.length
              ? formatMessage(joinAssociationPreviewMessages.all)
              : items.length,
        })}
      </b>
    </Popover>
  );
}
