import {
  Icon,
  IconType,
  LoadingSpinner,
  Size,
  StyleUtils,
} from '@main/core-ui';
import React from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';

import { BootstrapButtonProps, ButtonVariant } from './BootstrapButton';
import {
  ButtonIcon,
  HiddenChild,
  LoadingSpinnerWrapper,
  StyledButton,
} from './wrappers';

/**
 * We have two main types of buttons on the Admin Dashboard: primary and secondary, based off Bootstrap's provided prop `variant`
 * They will have their own established styling in `StyledButton` for visual design identity consistency throughout the app.
 *
 * If you must render additional "types" of buttons, the best practice is to create a new variant
 * in @core-ui/bootstrap-theme.scss under theme-colors and then pass it in this component with the `variant` prop.
 * The new variant's color will automatically be used by Bootstrap to calculate a handful of the button's styling under the hood
 * (i.e. focus outline, active and hover colors, etc.).
 *
 * For all other specific customizations, you can provide your own styles directly inline `style={{...}}`, or wrap the
 * `Button` component with styled-components `styled(Button)`...``
 */
export interface ButtonProps extends BootstrapButtonProps {
  /** The child contents of the button */
  children?: React.ReactNode;
  /** Whether the button is in a loading state */
  loading?: boolean;
  /** An optional icon */
  icon?: JSX.Element | IconType;
  /** is it an icon only button (used for conditional react content where empty node is truthy) */
  iconOnly?: boolean;
  /** the button variant plus one for special case */
  variant?: BootstrapButtonProps['variant'] | 'secondary-filled';
  /** the filename for a download url */
  download?: string;
  /** whether the Button should have no padding */
  noPadding?: boolean;
  /** whether the Button should have no border */
  noBorder?: boolean;
  /** whether button should be disabled */
  disabled?: boolean;
}

const VARIANT_SPINNER_COLOR_MODE_MAPPING: Record<
  ButtonVariant,
  'light' | 'dark'
> = {
  primary: 'light',
  secondary: 'light',
  success: 'light',
  warning: 'dark',
  danger: 'light',
  light: 'dark',
  link: 'dark',
  info: 'dark',
  dark: 'light',
  'outline-primary': 'dark',
  'outline-secondary': 'dark',
  'outline-success': 'dark',
  'outline-warning': 'dark',
  'outline-danger': 'dark',
  'outline-light': 'dark',
  'outline-link': 'dark',
  'outline-info': 'dark',
  'outline-dark': 'dark',
};

export const Button = React.forwardRef(
  (
    {
      children,
      loading = false,
      icon,
      iconOnly,
      variant: initialVariant,
      href,
      onClick,
      target,
      noPadding = false,
      noBorder = false,
      as,
      ...props
    }: ButtonProps,
    ref,
  ): JSX.Element => {
    // use correct margin based on content
    const hasContent = children && !iconOnly;
    const iconMargin = hasContent
      ? // space between icon and text, text determines height
        { marginRight: '6px' }
      : // keep icon-only buttons same height as text buttons
        StyleUtils.CommonMargins.vertical(Size.xs);

    const variant =
      initialVariant === undefined
        ? 'primary'
        : initialVariant === 'secondary'
          ? 'outline-secondary'
          : initialVariant === 'secondary-filled'
            ? 'secondary'
            : initialVariant;
    const isRelativeUrl = href && !target && href.startsWith('/');
    const to = useHref(href || '');
    const followLink = useLinkClickHandler(to, { target });

    const buttonIcon = icon ? (
      <ButtonIcon style={iconMargin}>
        {typeof icon === 'string' ? <Icon type={icon} /> : icon}
      </ButtonIcon>
    ) : null;
    return (
      <StyledButton
        forwardedAs={as || (href ? 'a' : undefined)}
        ref={ref}
        disabled={loading}
        {...props}
        aria-busy={loading}
        variant={variant}
        $iconWithText={icon && hasContent}
        href={isRelativeUrl ? to : href}
        style={props.style}
        onClick={onClick || (isRelativeUrl ? followLink : undefined)}
        target={target}
        $noPadding={noPadding}
        $noBorder={noBorder}
      >
        {!loading && icon && buttonIcon}
        {loading && icon && <HiddenChild>{buttonIcon}</HiddenChild>}

        {!iconOnly && !loading && children}
        {!iconOnly && loading && <HiddenChild>{children}</HiddenChild>}

        {loading && (
          <LoadingSpinnerWrapper>
            <LoadingSpinner
              small
              colorVariant={
                VARIANT_SPINNER_COLOR_MODE_MAPPING[variant] || 'light'
              }
            />
          </LoadingSpinnerWrapper>
        )}
      </StyledButton>
    );
  },
);
