import { AdminDashboardInfrastructurePath } from '@main/access-control-types';
import { Alert } from '@main/core-ui';
import { DataSiloConnectionState } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React from 'react';
import { generatePath, useMatch } from 'react-router-dom';

import { dataSiloReConnectionStateMessages } from './messages';

export const DisconnectedDataSiloAlert: React.FC<{
  /** Message to provide as the description */
  connectionState: DataSiloConnectionState;
  /** The data silo to link to, if no href is provided */
  dataSiloId: ID<'dataSilo'>;
}> = ({ connectionState, dataSiloId }) => {
  const isConnectionPage = useMatch(
    AdminDashboardInfrastructurePath.DataSiloConnection,
  );

  const connectionStateMessage =
    dataSiloReConnectionStateMessages[connectionState];

  if (!connectionStateMessage) {
    return null;
  }

  return (
    <Alert
      variant={
        connectionState === DataSiloConnectionState.NotConfigured
          ? 'warning'
          : 'danger'
      }
      icon="exclamation-circle"
      description={connectionStateMessage}
      href={
        !isConnectionPage
          ? generatePath(AdminDashboardInfrastructurePath.DataSiloConnection, {
              dataSiloId,
            })
          : undefined
      }
    />
  );
};
