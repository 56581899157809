import { defineMessages } from '@transcend-io/internationalization';

import { ClassificationMethod } from '@main/sombra-types';

export const selectSubCategoryMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectSubCategory.selectSubCategory',
  {
    allCategories: {
      defaultMessage: 'All Categories',
    },
    recommendedCategories: {
      defaultMessage: 'Recommended Categories',
    },
    classifiedBy: {
      defaultMessage: 'Classified using {classificationMethod}',
    },
    classifierVersion: {
      defaultMessage: 'v{version}',
    },
  },
);

export const classificationMethodMessages =
  defineMessages<ClassificationMethod>(
    'admin-dashboard.DataMap.components.SelectSubCategory.classificationMethod',
    {
      [ClassificationMethod.CUSTOM_REGEX_CLASSIFY]: {
        defaultMessage: 'Regex Matching',
      },
      [ClassificationMethod.TRANSCEND_LLM_CLASSIFY]: {
        defaultMessage: 'Sombra LLM',
      },
      [ClassificationMethod.VECTORIZED_ML_CLASSIFY]: {
        defaultMessage: 'a Random-Forest ML model',
      },
      [ClassificationMethod.PROPERTY_NAME_MATCH]: {
        defaultMessage: 'Property Name Matching',
      },
      [ClassificationMethod.LLM_API]: {
        defaultMessage: 'Transcend-Hosted LLM (Metadata Only)',
      },
    },
  );
