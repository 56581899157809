import { createFrontendUuidv4 } from '@main/utils';
import React, { KeyboardEvent, useMemo } from 'react';
import { animated, easings, useSpring } from 'react-spring';

import { CheckboxValue } from './types';
import {
  CheckboxContainer,
  CheckboxWrapper,
  IconWrapper,
  LabelWrapper,
} from './wrappers';

/*
  NOTE: we do not extend input because native checkbox inputs do not
  support a 'mixed' state in an accessible way as the input value is :: Boolean.
  To get this behavior and have it be accessible we have to use a div and aria to set
  the aria-checked to 'mixed'. This is annoying because our implementation is
  fundamentally different from RadioInput here, but it's alright.
 */
export interface CheckboxInputProps
  extends Omit<React.ComponentPropsWithRef<'div'>, 'children' | 'onClick'> {
  /** flag to disable the checkbox input */
  disabled?: boolean;
  /** current state of checkbox: 'unchecked' | 'checked' | 'partially-checked', or boolean for simple checked unchecked */
  value?: CheckboxValue | boolean;
  /** callback invoked when user clicks checkbox */
  onChange?: () => void;
  /** label element */
  children?: React.ReactNode;
  /** name for checkbox input */
  name?: string;
}

const CheckIcon: React.FC = () => {
  const pathStyles = useSpring({
    delay: 50,
    config: {
      duration: 225,
      easing: easings.easeOutCubic,
    },
    from: {
      strokeDasharray: 17,
      strokeDashoffset: 17,
    },
    to: {
      strokeDasharray: 17,
      strokeDashoffset: 0,
    },
  });
  return (
    <IconWrapper>
      <svg
        width={24}
        height={24}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.path
          {...pathStyles}
          d="M15.0708 21.5653L18.684 25.1785L18.6606 25.1551L26.8124 17.0034"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

const DashIcon: React.FC = () => (
  <svg
    width="10"
    height="2"
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1H9" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

const ARIA_CHECKED_STATES: {
  [key in CheckboxValue]: 'false' | 'true' | 'mixed';
} = {
  unchecked: 'false',
  checked: 'true',
  'partially-checked': 'mixed',
};

export const getCheckboxValue = (
  checkboxValue: CheckboxValue | boolean,
): CheckboxValue =>
  typeof checkboxValue === 'string'
    ? checkboxValue
    : // boolean
      checkboxValue
      ? 'checked'
      : 'unchecked';

export const CheckboxInput = React.forwardRef<
  HTMLDivElement,
  CheckboxInputProps
>(
  (
    {
      disabled = false,
      value = 'unchecked',
      onChange,
      children: label,
      name,
      ...props
    },
    ref,
  ) => {
    const id = useMemo(() => props.id ?? createFrontendUuidv4(), [props.id]);
    const onKeyDown = (evt: KeyboardEvent): void => {
      if (evt.key === ' ' && !disabled) {
        onChange?.();
        evt.preventDefault();
      }
    };
    const stringValue = getCheckboxValue(value);
    return (
      <CheckboxContainer onClick={() => !disabled && onChange?.()}>
        <CheckboxWrapper
          disabled={disabled}
          onKeyDown={onKeyDown}
          ref={ref}
          aria-checked={ARIA_CHECKED_STATES[stringValue]}
          checkboxValue={stringValue}
          role="checkbox"
          tabIndex={0}
          {...props}
          id={`${id}-wrapper`}
        >
          {stringValue === 'checked' && <CheckIcon />}
          {stringValue === 'partially-checked' && <DashIcon />}
        </CheckboxWrapper>
        {label && (
          <LabelWrapper
            htmlFor={id}
            onClick={(e) => {
              // stop propagation but don't prevent default behavior, default
              // behavior allows clicking the label to toggle the checkbox
              e.stopPropagation();
            }}
          >
            {label}
          </LabelWrapper>
        )}
        <input
          type="checkbox"
          id={id}
          style={{ display: 'none' }}
          checked={stringValue === 'checked'}
          name={name}
        />
      </CheckboxContainer>
    );
  },
);
