import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventPreferenceOption = mkAuditEventCodec(
  AuditEventCode.PreferenceOption,
  t.type({
    /** ID of the preference option */
    preferenceOptionId: dbModelId('preferenceOptionValue'),
  }),
);

/** Override types. */
export type AuditEventPreferenceOption = t.TypeOf<
  typeof AuditEventPreferenceOption
>;

export const AuditEventPreferenceTopic = mkAuditEventCodec(
  AuditEventCode.PreferenceTopic,
  t.type({
    /** ID of the preference option */
    preferenceTopicId: dbModelId('preferenceTopic'),
  }),
);

/** Override types. */
export type AuditEventPreferenceTopic = t.TypeOf<
  typeof AuditEventPreferenceTopic
>;

export const AuditEventWorkflowTrigger = mkAuditEventCodec(
  AuditEventCode.ConsentWorkflowTrigger,
  t.type({
    /** ID of the preference option */
    consentWorkflowTriggerId: dbModelId('consentWorkflowTrigger'),
  }),
);

/** Override types. */
export type AuditEventWorkflowTrigger = t.TypeOf<
  typeof AuditEventWorkflowTrigger
>;
