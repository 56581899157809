import { transparentize } from 'polished';
import styled from 'styled-components';

import { Card, StyledCardProps } from '../Card';

export const StyledCard = styled(Card)`
  /** On hover to show the secondary action button if it exists */
  &:hover {
    .action-button-area {
      opacity: 1;
    }
  }
`;

export const StyledActionButtonArea = styled.div<StyledCardProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  // give some extra space for the gradient
  padding-left: 3em;
  padding-right: 1em;
  background: linear-gradient(
    to right,
    ${({ theme }) => transparentize(1, theme.colors.white)},
    ${({ theme }) => theme.colors.white} 2.5em
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 ${({ radius = '12px' }) => `${radius} ${radius}`} 0;
  opacity: 0;
  transition: opacity 0.2s;

  .btn {
    box-shadow: ${({ theme }) =>
      `0 4px 4px ${transparentize(0.9, theme.colors.black)}`};
  }
`;
