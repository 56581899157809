import { StyleUtils } from '@main/core-ui';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import { CSSProperties, useTheme } from 'styled-components';

import { StyledEnterExitHighlight } from './wrappers';

/**
 * the required container to make the exit animation work. Must be the direct parent
 * of the AnimatedEnterExitListItem
 */
export const AnimatedEnterExitListContainer: React.FC = ({ children }) => (
  <AnimatePresence initial={false}>{children}</AnimatePresence>
);

interface AnimatedListItemProps {
  /** should we animate the height? */
  animateHeight?: boolean;
  /** should we animate the width? */
  animateWidth?: boolean;
  /** style overrides for the div wrapper  */
  wrapperStyle?: CSSProperties;
  /** should we highlight the div when it appears in the DOM? useful for lists */
  highlightOnAppear?: boolean;
  /** should we highlight the div when it is removed from the DOM? useful for lists */
  highlightOnRemove?: boolean;
  /** the react key, required for appear/remove */
  key: string;
  /** the duration override of the animation, defaults to .2 */
  durationSeconds?: number;
  /** the duration override of the highlight animation, defaults to 2 * durationSeconds */
  highlightDurationSeconds?: number;
}

/**
 * Animates the entry/exit of a component with a grow/shrink animation
 */
export const AnimatedEnterExitListItem = React.forwardRef<
  any,
  PropsWithChildren<AnimatedListItemProps>
>(
  (
    {
      animateHeight,
      animateWidth,
      children,
      wrapperStyle,
      highlightOnAppear,
      highlightOnRemove,
      durationSeconds,
      highlightDurationSeconds,
    },
    ref,
  ) => {
    const theme = useTheme();
    const durationSecondsOrDefault = durationSeconds ?? 0.2;
    return (
      <motion.div
        style={{
          overflow: 'hidden',
          position: 'relative',
          ...StyleUtils.Flex.Column.base,
          ...wrapperStyle,
        }}
        ref={ref}
        initial={{
          ...(animateHeight ? { height: 0 } : {}),
          ...(animateWidth ? { width: 0 } : {}),
        }}
        animate={{
          ...(animateHeight ? { height: 'auto' } : {}),
          ...(animateWidth ? { width: 'auto' } : {}),
        }}
        exit={{
          ...(animateHeight ? { height: 0 } : {}),
          ...(animateWidth ? { width: 0 } : {}),
          ...(highlightOnRemove
            ? {
                transition: {
                  delay: highlightDurationSeconds ?? durationSecondsOrDefault,
                },
              }
            : {}),
        }}
        transition={{
          duration: durationSecondsOrDefault,
        }}
      >
        {children}
        {(highlightOnAppear || highlightOnRemove) && (
          <StyledEnterExitHighlight
            initial={{
              ...(highlightOnAppear
                ? {
                    opacity: 0.5,
                    backgroundColor: theme.colors.mint1,
                  }
                : {}),
            }}
            animate={{ opacity: 0 }}
            exit={{
              ...(highlightOnRemove
                ? {
                    opacity: 0.5,
                    backgroundColor: theme.colors.red1,
                  }
                : {}),
            }}
            transition={{
              duration: highlightDurationSeconds ?? durationSecondsOrDefault,
            }}
          />
        )}
      </motion.div>
    );
  },
);

interface AnimatedEnterExitSingleItemProps {
  /** override for when to show the children */
  show?: boolean;
}

/**
 * AnimatedEnterExitSingleItem
 */
export const AnimatedEnterExitSingleItem: React.FC<
  Omit<AnimatedListItemProps, 'key'> & AnimatedEnterExitSingleItemProps
> = ({ children, show, ...props }) => (
  <AnimatedEnterExitListContainer>
    {children && (show === undefined || show) && (
      <AnimatedEnterExitListItem key="default" {...props}>
        {children}
      </AnimatedEnterExitListItem>
    )}
  </AnimatedEnterExitListContainer>
);
