import {
  Accordion,
  Button,
  Modal,
  WorkerizedJsonDiff,
} from '@main/ad-core-components';
import {
  Badge,
  FlexColumn,
  FlexRowCenterBoth,
  LoadingSpinner,
  ModalRawProps,
  StyleUtils,
  TabBar,
} from '@main/core-ui';
import { localeDescriptorMessages } from '@main/internationalization';
import type { ID } from '@main/schema-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type PrivacyCenterModuleName,
  privacyCenterModuleNames,
  reviewChangesAndPublishMessages,
} from './messages';
import { PreparePublishSteps } from './PreparePublishSteps';
import { useVersionModifications } from './useVersionModifications';
import { useVersions } from './useVersions';

export const ReviewChangesAndPublishModal: React.FC<
  ModalRawProps & {
    /** Call back to indicate whether changes were made */
    onModified: (isModified: boolean) => void;
    /** Call back to publish changes (or upgrade version) */
    onPublish: (privacyCenterVersionId?: ID<'privacyCenterVersion'>) => void;
    /** Whether the operation to publish changes is ongoing */
    publishLoading: boolean;
    /** Whether the operation to save draft is ongoing */
    saveDraftLoading: boolean;
  }
> = ({
  onModified,
  onPublish,
  publishLoading,
  saveDraftLoading,
  ...modalProps
}) => {
  const { formatMessage } = useIntl();
  const [showPreliminarySteps, setShowPreliminarySteps] = useState(true);

  // Load current and pending versions
  const {
    currentState,
    pendingState,
    deployedIsLoading,
    savedIsLoading,
    savedVersionId,
    loading,
  } = useVersions();

  // Check for modifications
  const { isModified, moduleHasChanges } = useVersionModifications({
    currentState,
    pendingState,
    dataReady: loading,
    onModified,
  });

  const anyInitialStepsLoading =
    deployedIsLoading || savedIsLoading || saveDraftLoading;
  useEffect(() => {
    if (!anyInitialStepsLoading) {
      // delay hiding to allow the user to see the steps marked as complete before disappearing
      setTimeout(() => {
        setShowPreliminarySteps(false);
      }, 1000);
    }
  }, [anyInitialStepsLoading]);

  // TabBar controls
  const [currentTab, setCurrentTab] = useState<PrivacyCenterModuleName>(
    Object.keys(currentState)[0] as PrivacyCenterModuleName,
  );

  const tabs = (Object.keys(currentState) as PrivacyCenterModuleName[]).map(
    (key) => ({
      title: (
        <span
          onClick={() => setCurrentTab(key)}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {formatMessage(privacyCenterModuleNames[key])}
          {moduleHasChanges(key) && (
            <Badge
              color="red1"
              round
              style={{
                display: 'inline-block',
                height: '6px',
                minWidth: '6px',
                padding: '0px',
                marginLeft: '6px',
              }}
            />
          )}
        </span>
      ),
      path: key,
    }),
  );

  const currentTabState = currentState[currentTab];
  const pendingTabState = pendingState[currentTab];

  const localesToDisplay = useMemo(
    () =>
      Object.keys(pendingTabState ?? {}).filter(
        (locale) => currentTabState?.[locale] !== pendingTabState?.[locale],
      ),
    [currentTabState, pendingTabState],
  );

  const accordionItems = useMemo(
    () =>
      localesToDisplay
        .filter((locale) => locale in localeDescriptorMessages)
        .map((locale) => ({
          key: locale,
          header: formatMessage(localeDescriptorMessages[locale]),
          body: (
            <WorkerizedJsonDiff
              value={currentTabState?.[locale] ?? {}}
              otherValue={pendingTabState?.[locale] ?? {}}
              isPretty
            />
          ),
        })),
    [currentTabState, formatMessage, localesToDisplay, pendingTabState],
  );

  return (
    <Modal
      show
      width={900}
      {...modalProps}
      header={reviewChangesAndPublishMessages.modalTitle}
    >
      <FlexColumn style={{ gap: StyleUtils.Spacing.md, marginBottom: '6px' }}>
        {showPreliminarySteps ? (
          <PreparePublishSteps
            saveDraftLoading={saveDraftLoading}
            savedStateLoading={savedIsLoading}
            liveStateLoading={deployedIsLoading}
          />
        ) : (
          <>
            <TabBar tabs={tabs} activePath={currentTab} unlinked />
            {deployedIsLoading || savedIsLoading ? (
              <LoadingSpinner />
            ) : (
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                }}
              >
                {moduleHasChanges(currentTab) ? (
                  <>
                    <div>
                      {formatMessage(
                        reviewChangesAndPublishMessages.reviewChanges,
                      )}
                    </div>
                    {currentTab === 'messages' || currentTab === 'policies' ? (
                      <Accordion
                        destroyOnCollapse
                        defaultActiveKey={undefined}
                        style={{ marginTop: StyleUtils.Spacing.sm }}
                        items={accordionItems}
                      />
                    ) : (
                      <WorkerizedJsonDiff
                        value={currentTabState as any}
                        otherValue={pendingTabState as any}
                      />
                    )}
                  </>
                ) : (
                  <FlexRowCenterBoth style={{ padding: StyleUtils.Spacing.xl }}>
                    <FormattedMessage
                      {...reviewChangesAndPublishMessages.noChangesDetected}
                      values={{
                        module: formatMessage(
                          privacyCenterModuleNames[currentTab],
                        ),
                      }}
                    />
                  </FlexRowCenterBoth>
                )}
              </div>
            )}
            <div>
              <Button
                onClick={() => {
                  onPublish(savedVersionId);
                }}
                loading={deployedIsLoading || savedIsLoading || publishLoading}
                disabled={!isModified}
              >
                {formatMessage(
                  reviewChangesAndPublishMessages.setPrivacyCenterLive,
                )}
              </Button>
            </div>
          </>
        )}
      </FlexColumn>
    </Modal>
  );
};
