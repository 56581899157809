import Papa from 'papaparse';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import * as streamSaver from 'streamsaver';

import { formatErrorMessage, logger, message } from '@main/core-ui';
import {
  AnySchema,
  EndpointParams,
  GraphQLResponse,
  ParamsToType,
} from '@main/schema-utils';

import {
  transformColumnsToCsvCells,
  transformColumnsToCsvHeaders,
} from './helpers';
import { useExportGqlToCsvMessages } from './messages';
import {
  GqlToCsvReadableStreamParams,
  ResponseWithNodes,
  UseExportGqlToCsvParams,
} from './types';

/**
 * helper hook to standardize how we export CSVs from paginated gql responses
 *
 * @param root0 - the args
 * @returns a function to download the data from gql requests as a CSV
 */
export function useExportGqlToCsv<
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>({
  fileName,
  fileNamePrefix,
  ...rest
}: UseExportGqlToCsvParams<TParams, TResult>): [() => Promise<void>, boolean] {
  const { formatMessage } = useIntl();

  const [csvExportLoading, setCsvExportLoading] = useState(false);
  if (!fileNamePrefix && !fileName) {
    throw new Error('Either fileName or fileNamePrefix are required');
  }

  const downloadCsv = async (): Promise<void> => {
    const finalFileName = fileNamePrefix
      ? `${fileNamePrefix}-${new Date().toDateString()}.csv`
      : fileName!;
    const fileStream = streamSaver.createWriteStream(finalFileName);

    setCsvExportLoading(true);
    try {
      await getGqlToCsvReadableStream<TParams, TResult>({
        fileName: finalFileName,
        noDataErrorMessage: formatMessage(useExportGqlToCsvMessages.noData),
        formatMessage,
        ...rest,
      }).pipeTo(fileStream);
    } catch (e) {
      logger.error(e);
      message.error(
        e?.message
          ? formatErrorMessage(e.message)
          : formatMessage(useExportGqlToCsvMessages.failedToExport),
      );
      setCsvExportLoading(false);
    }
    setCsvExportLoading(false);
  };

  return [downloadCsv, csvExportLoading];
}

/**
 * Helper to get paged data from GQL as a readable stream
 *
 * @param root0 - the args
 * @returns a readable steam that will accumulate the data from gql requests
 */
export function getGqlToCsvReadableStream<
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>({
  fileName,
  executeQuery,
  getVariables,
  pageSize = 100,
  useCustomPagination,
  transformRowToCsvRow,
  noDataErrorMessage,
  formatMessage,
  columnDefinition,
  setNumRecordsProcessed,
  transformRowBeforeCsv = (x) => x,
}: GqlToCsvReadableStreamParams<TParams, TResult>): ReadableStream {
  const textEncoder = new TextEncoder();
  let totalReturned = 0;
  let firstPass = true;
  let numReturned = pageSize;
  let previousVariables:
    | (ParamsToType<TParams> & {
        /** the standard offset */
        offset?: number;
      })
    | undefined;
  let previousResult: ResponseWithNodes<TResult> | undefined;

  return new ReadableStream({
    /**
     * Get the readable stream for each page of table data in iterable chunks
     *
     * @param controller - controls the stream's state and internal queue.
     * @returns undefined
     */
    async pull(controller) {
      if (numReturned >= pageSize) {
        if (numReturned > pageSize) {
          logger.error(
            `getGqlToCsvReadableStream had more results returned than the page size allows for ${fileName}`,
          );
        }
        const currentVariables = {
          ...(useCustomPagination
            ? {}
            : {
                first: pageSize,
                offset: previousVariables
                  ? (previousVariables.offset ?? 0) + pageSize
                  : 0,
              }),
          ...getVariables(previousVariables, previousResult),
        };

        const { data, error } = await executeQuery(currentVariables);
        const currentResult = data as ResponseWithNodes<TResult> | undefined;

        const nodes = currentResult?.nodes ?? [];

        if (error) {
          message.error(formatErrorMessage(error.message));
          return controller.error();
        }

        numReturned = nodes.length;
        totalReturned += numReturned;
        setNumRecordsProcessed?.(totalReturned);

        if (numReturned > 0) {
          let mapped:
            | Record<string, object>[]
            | {
                /** The headers for the CSV file */
                fields: string[];
                /** The data for the rows of the CSV file */
                data: any;
              };
          if (transformRowToCsvRow) {
            mapped = nodes.map((obj: any) => {
              try {
                return transformRowToCsvRow(obj);
              } catch (e) {
                e.message = `Error occurred while transforming rows for ${fileName}: ${e.message}`;
                logger.error(e);
                throw e;
              }
            });
          } else {
            if (!columnDefinition) {
              const error = new Error(
                'Either transformRowToCsvRow or columnDefinition must be provided to transform the data to CSV.',
              );
              logger.error(error);
              throw error;
            }

            const headers = transformColumnsToCsvHeaders(
              columnDefinition,
              formatMessage,
            );

            const rows = nodes
              .map(transformRowBeforeCsv)
              .map((obj: any) =>
                transformColumnsToCsvCells(
                  columnDefinition.columns,
                  obj,
                  formatMessage,
                ),
              );

            mapped = {
              fields: headers,
              data: rows,
            };
          }

          const str = Papa.unparse(mapped, {
            header: firstPass,
            skipEmptyLines: true,
          });

          if (str.length > 0) {
            const encoded = textEncoder.encode(
              `${firstPass ? '' : '\r\n'}${str}`,
            );

            if (firstPass) {
              firstPass = false;
            }
            previousResult = currentResult;
            previousVariables = currentVariables;

            return controller.enqueue(encoded);
          }
        }
      }

      if (totalReturned === 0 && noDataErrorMessage) {
        message.error(noDataErrorMessage);
      }

      return controller.close();
    },
  });
}
