/**
 * The privacy center page type.
 */
export enum PageType {
  /** Page where you can manage your privacy settings */
  ManageYourPrivacy = 'MANAGE_YOUR_PRIVACY',
  /** Page where users can manage their marketing preferences */
  CommunicationsPreferences = 'COMMUNICATIONS_PREFERENCES',
  /**
   * The way in which the organization uses the person data they hold
   * TODO: https://transcend.height.app/T-42672 - remove
   */
  HomePage = 'DATA_PRACTICES',
  /** The full privacy policy text */
  Policies = 'POLICIES',
  /** Information about the tracking technologies used by a company */
  TrackingTechnologies = 'TRACKING_TECHNOLOGIES',
  /** A page to display a table with the number of requests processed, for ccpa reporting */
  RequestsProcessedReporting = 'REQUESTS_PROCESSED_REPORTING',
  /** Page where users can manage their sale of info settings */
  SaleOfInfo = 'SALE_OF_INFO',
}
