import { LanguageKey } from '@transcend-io/internationalization';
import { apply } from '@transcend-io/type-utils';

import { createTheme } from '@main/theme';

import { PrivacyCenterStringified } from '../schema';
import type { PrivacyCenter } from '../types';

/**
 * Parses stringified properties in the Privacy Center GraphQL response.
 *
 * TODO: https://github.com/transcend-io/main/issues/7461 - remove this
 *
 * @param privacyCenter - The stringified privacy center
 * @returns The privacy center with defaults applied
 */
export function parsePrivacyCenter({
  assets,
  themeStr,
  ...rest
}: PrivacyCenterStringified): PrivacyCenter {
  const partialTheme = JSON.parse(themeStr);
  return {
    ...rest,
    locales: rest.locales as LanguageKey[],
    defaultLocale: rest.defaultLocale as LanguageKey,
    themeStr,
    partialTheme,
    theme: createTheme({ ...partialTheme, GlobalStyles: null }),
    assets: apply(assets, (msg) => msg || undefined),
  };
}
