import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { buildUseQuery, formatErrorMessage, message } from '@main/core-ui';
import { endpoints } from '@main/pc-types';
import { ID } from '@main/schema-utils';

import { selectPrivacyCenterId } from '../selectors';
import { PrivacyCenterModuleName } from './messages';
import type { ParseVersionDataOptions } from './parseVersionData.worker';

const usePrivacyCenterVersionModules = buildUseQuery(
  endpoints.privacyCenterVersionModules,
);

/**
 * Privacy center modules parsed from JSON
 */
export type ParsedPrivacyCenterModules = Record<
  PrivacyCenterModuleName,
  unknown
>;

const INITIAL_CURRENT_STATE: ParsedPrivacyCenterModules = {
  privacyCenter: {},
  policies: {},
  messages: {},
  subjects: {},
  requestsProcessedStats: {},
  purposes: {},
};

const INITIAL_PENDING_STATE: ParsedPrivacyCenterModules = {
  privacyCenter: null,
  policies: null,
  messages: null,
  subjects: null,
  requestsProcessedStats: null,
  purposes: null,
};

/**
 * Get version data for the privacy center
 *
 * @returns returns
 */
export function useVersions(): {
  /** Current state */
  currentState: ParsedPrivacyCenterModules;
  /** Pending state */
  pendingState: ParsedPrivacyCenterModules;
  /** Is the deployed state loading? */
  deployedIsLoading: boolean;
  /** Is the saved state loading? */
  savedIsLoading: boolean;
  /** Privacy center id of the saved version */
  savedVersionId: ID<'privacyCenterVersion'> | undefined;
  /** Is the data loaded? */
  loading: boolean;
} {
  const privacyCenterId = useSelector(selectPrivacyCenterId);
  const skip = !privacyCenterId;

  const [loading, setLoaded] = useState(false);

  const [currentState, setCurrentState] = useState<ParsedPrivacyCenterModules>(
    INITIAL_CURRENT_STATE,
  );

  const [pendingState, setPendingState] = useState<ParsedPrivacyCenterModules>(
    INITIAL_PENDING_STATE,
  );

  // Load deployed data
  const { data: deployedData, loading: deployedIsLoading } =
    usePrivacyCenterVersionModules({
      variables: {
        input: {
          privacyCenterId,
          inDeployState: true,
        },
      },
      skip,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        message.error(formatErrorMessage(error.message));
      },
    });

  // Load saved data
  const { data: savedData, loading: savedIsLoading } =
    usePrivacyCenterVersionModules({
      variables: {
        input: {
          privacyCenterId,
          inDeployState: false,
        },
      },
      skip,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        message.error(formatErrorMessage(error.message));
      },
    });

  const { parseVersionData } = useParseVersionData();

  useEffect(() => {
    if (deployedData && savedData) {
      const fn = async (): Promise<void> => {
        const current = await parseVersionData({
          isDeployed: true,
          input: deployedData,
        });
        const pending = await parseVersionData({
          isDeployed: false,
          input: savedData,
        });

        setCurrentState(current);
        setPendingState(pending);
        setLoaded(true);
      };

      fn().catch((error) => {
        message.error(formatErrorMessage(error.message));
      });
    }
  }, [deployedData, savedData, setLoaded, parseVersionData]);

  return {
    deployedIsLoading,
    savedIsLoading,
    loading,
    currentState,
    pendingState,
    savedVersionId: savedData?.id,
  };
}

/**
 * Callback to parse version data via worker
 */
type ParseVersionDataCallback = (
  options: ParseVersionDataOptions,
) => Promise<ParsedPrivacyCenterModules>;

/**
 * Parse version data
 *
 * @returns callback
 */
function useParseVersionData(): {
  /** Parses version data */
  parseVersionData: ParseVersionDataCallback;
} {
  const workerRef = useRef<Worker>();

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('./parseVersionData.worker', import.meta.url),
      {
        type: 'module',
      },
    );

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, []);

  const parseVersionData = useCallback<ParseVersionDataCallback>((options) => {
    const worker = workerRef.current!;
    return new Promise((resolve) => {
      worker.onmessage = ({ data }) => {
        resolve(data);
      };

      worker.postMessage(options);
    });
  }, []);

  return {
    parseVersionData,
  };
}
