/**
 * All possible events to add to the audit log
 */
export enum AuditEventCode {
  ActionItem = 'ACTION_ITEM',
  AirgapBundle = 'AIRGAP_BUNDLE',
  // Deprecated - DO NOT USE
  AirgapBundleVersion = 'AIRGAP_BUNDLE_VERSION',
  AirgapBundleDeployment = 'AIRGAP_BUNDLE_DEPLOYMENT',
  AirgapCookie = 'AIRGAP_COOKIE',
  AirgapDataFlow = 'AIRGAP_DATA_FLOW',
  AirgapCookieOwner = 'AIRGAP_COOKIE_OWNER',
  AirgapCookieTeam = 'AIRGAP_COOKIE_TEAM',
  AirgapDataFlowOwner = 'AIRGAP_DATA_FLOW_OWNER',
  AirgapDataFlowTeam = 'AIRGAP_DATA_FLOW_TEAM',
  AirgapTheme = 'AIRGAP_THEME',
  AssessmentAnswer = 'ASSESSMENT_ANSWER',
  AssessmentForm = 'ASSESSMENT_FORM',
  AssessmentFormAssignee = 'ASSESSMENT_FORM_ASSIGNEE',
  AssessmentFormComment = 'ASSESSMENT_FORM_COMMENT',
  AssessmentFormReviewer = 'ASSESSMENT_FORM_REVIEWER',
  AssessmentFormTemplate = 'ASSESSMENT_FORM_TEMPLATE',
  AssessmentGroup = 'ASSESSMENT_GROUP',
  AssessmentQuestion = 'ASSESSMENT_QUESTION',
  AssessmentQuestionComment = 'ASSESSMENT_QUESTION_COMMENT',
  AssessmentSectionComment = 'ASSESSMENT_SECTION_COMMENT',
  AssessmentSection = 'ASSESSMENT_SECTION',
  AssessmentSectionAssignee = 'ASSESSMENT_SECTION_ASSIGNEE',
  AssessmentEmailSet = 'ASSESSMENT_EMAIL_SET',
  CustomFunctionCode = 'CUSTOM_FUNCTION_CODE',
  CustomFunctionCodeContext = 'CUSTOM_FUNCTION_CODE_CONTEXT',
  DataPoint = 'DATA_POINT',
  DataPointAction = 'DATA_POINT_ACTION',
  DataPointLevelDescription = 'DATA_POINT_LEVEL_DESCRIPTION',
  DataPointLevelOwner = 'DATA_POINT_LEVEL_OWNER',
  DataPointLevelTeam = 'DATA_POINT_LEVEL_TEAM',
  DataPointOwner = 'DATA_POINT_OWNER',
  DataPointTeam = 'DATA_POINT_TEAM',
  DataReport = 'DATA_REPORT',
  DataSilo = 'DATA_SILO',
  DataSiloApiKey = 'DATA_SILO_API_KEY',
  DataSiloAttribute = 'DATA_SILO_ATTRIBUTE',
  AirgapCookieAttribute = 'AIRGAP_COOKIE_ATTRIBUTE',
  AirgapDataFlowAttribute = 'AIRGAP_DATA_FLOW_ATTRIBUTE',
  AirgapCookiePurpose = 'AIRGAP_COOKIE_PURPOSE',
  AirgapDataFlowPurpose = 'AIRGAP_DATA_FLOW_PURPOSE',
  DataSiloOwner = 'DATA_SILO_OWNER',
  DataSiloPlugin = 'DATA_SILO_PLUGIN',
  DataSiloDataSubCategory = 'DATA_SILO_DATA_SUB_CATEGORY',
  DataSiloProcessingPurposeSubCategory = 'DATA_SILO_PROCESSING_PURPOSE_SUB_CATEGORY',
  DataSiloSaaSCategory = 'DATA_SILO_SAAS_CATEGORY',
  DataSiloSubjectDenies = 'DATA_SILO_SUBJECT_BLOCKLIST',
  DataSiloTeam = 'DATA_SILO_TEAM',
  DataSiloDataSilo = 'DATA_SILO_DATA_SILO',
  DataSiloVendor = 'DATA_SILO_VENDOR',
  DataSiloBusinessEntity = 'DATA_SILO_BUSINESS_ENTITY',
  DataSiloDependentDataSilo = 'DATA_SILO_DEPENDENT_DATA_SILO',
  BusinessEntity = 'BUSINESS_ENTITY',
  BusinessEntityAttribute = 'BUSINESS_ENTITY_ATTRIBUTE',
  BusinessEntityTeam = 'BUSINESS_ENTITY_TEAM',
  BusinessEntityOwner = 'BUSINESS_ENTITY_OWNER',
  Vendor = 'VENDOR',
  VendorOwner = 'VENDOR_OWNER',
  VendorTeam = 'VENDOR_TEAM',
  VendorBusinessEntity = 'VENDOR_BUSINESS_ENTITY',
  VendorAttribute = 'VENDOR_ATTRIBUTE',
  DataSubCategory = 'DATA_SUB_CATEGORY',
  Identifier = 'IDENTIFIER',
  IdentifierAction = 'IDENTIFIER_ACTION',
  IdentifierDataSilo = 'IDENTIFIER_DATA_SILO',
  IdentifierSubject = 'IDENTIFIER_SUBJECT',
  ProcessingPurposeSubCategory = 'PROCESSING_PURPOSE_SUB_CATEGORY',
  ProcessingPurposeSubCategoryOwner = 'PROCESSING_PURPOSE_SUB_CATEGORY_OWNER',
  ProcessingPurposeSubCategoryTeam = 'PROCESSING_PURPOSE_SUB_CATEGORY_TEAM',
  DataSubCategoryOwner = 'DATA_SUB_CATEGORY_OWNER',
  DataSubCategoryTeam = 'DATA_SUB_CATEGORY_TEAM',
  ProcessingPurposeSubCategoryAttribute = 'PROCESSING_PURPOSE_SUB_CATEGORY_ATTRIBUTE',
  DataSubCategoryAttribute = 'DATA_SUB_CATEGORY_ATTRIBUTE',
  SubDataPoint = 'SUB_DATA_POINT',
  SubDataPointAttribute = 'SUB_DATA_POINT_ATTRIBUTE',
  SubDataPointDataSubCategory = 'SUB_DATA_POINT_DATA_SUB_CATEGORY',
  SubDataPointProcessingPurposeSubCategory = 'SUB_DATA_POINT_PROCESSING_PURPOSE_SUB_CATEGORY',
  SubDataPointSubject = 'SUB_DATA_POINT_SUBJECT',
  Message = 'MESSAGE',
  PrivacyCenter = 'PRIVACY_CENTER',
  PrivacyCenterEmailSenderAddress = 'PRIVACY_CENTER_EMAIL_SENDER_ADDRESS',
  EmailSenderAddress = 'EMAIL_SENDER_ADDRESS',
  EmailSenderAddressAttributeValue = 'EMAIL_SENDER_ADDRESS_ATTRIBUTE_VALUE',
  Theme = 'THEME',
  DataCollection = 'DATA_COLLECTION',
  AssetFile = 'ASSET_FILE',
  Policy = 'POLICY',
  PolicyVersion = 'POLICY_VERSION',
  Experience = 'EXPERIENCE',
  ExperiencePurpose = 'EXPERIENCE_PURPOSE',
  TrackingPurpose = 'TRACKING_PURPOSE',
  Preference = 'PREFERENCE',
  ScannedObject = 'SCANNED_OBJECT',
  UnstructuredSubDataPointRecommendation = 'UNSTRUCTURED_SUB_DATA_POINT_RECOMMENDATION',
  Sombra = 'SOMBRA',
  SombraApiKey = 'SOMBRA_API_KEY',
  OrgTcfPurpose = 'ORG_TCF_PURPOSE',
  OrgTcfSpecialFeature = 'ORG_TCF_SPECIAL_FEATURE',
  OrgTcfPurposePurpose = 'ORG_TCF_PURPOSE_PURPOSE',
  OrgTcfSpecialFeaturePurpose = 'ORG_TCF_SPECIAL_FEATURE_PURPOSE',
  ConsentService = 'CONSENT_SERVICE',
  AirgapBundleTcfStack = 'AIRGAP_BUNDLE_TCF_STACK',
  AirgapPartition = 'AIRGAP_PARTITION',
  RiskFramework = 'RISK_FRAMEWORK',
  RiskLevel = 'RISK_LEVEL',
  RiskCategory = 'RISK_CATEGORY',
  RiskCategoryOwner = 'RISK_CATEGORY_OWNER',
  RiskCategoryTeam = 'RISK_CATEGORY_TEAM',
  RiskMatrixColumn = 'RISK_MATRIX_COLUMN',
  RiskMatrixRow = 'RISK_MATRIX_ROW',
  PreferenceOption = 'PREFERENCE_OPTION',
  PreferenceTopic = 'PREFERENCE_TOPIC',
  ConsentWorkflowTrigger = 'CONSENT_WORKFLOW_TRIGGER',
}

/**
 * Parent/Base models
 */
export enum AuditEventBaseModelCode {
  ActionItem = 'ACTION_ITEM',
  AssessmentAnswer = 'ASSESSMENT_ANSWER',
  AssessmentForm = 'ASSESSMENT_FORM',
  AssessmentFormComment = 'ASSESSMENT_FORM_COMMENT',
  AssessmentFormTemplate = 'ASSESSMENT_FORM_TEMPLATE',
  AssessmentGroup = 'ASSESSMENT_GROUP',
  AssessmentQuestion = 'ASSESSMENT_QUESTION',
  AssessmentQuestionComment = 'ASSESSMENT_QUESTION_COMMENT',
  AssessmentSectionComment = 'ASSESSMENT_SECTION_COMMENT',
  AssessmentSection = 'ASSESSMENT_SECTION',
  AssessmentEmailSet = 'ASSESSMENT_EMAIL_SET',
  CustomFunction = 'CUSTOM_FUNCTION',
  DataPoint = 'DATA_POINT',
  DataPointLevel = 'DATA_POINT_LEVEL',
  DataReport = 'DATA_REPORT',
  DataSilo = 'DATA_SILO',
  DataSiloPlugin = 'DATA_SILO_PLUGIN',
  AirgapBundle = 'AIRGAP_BUNDLE',
  AirgapBundleDeployment = 'AIRGAP_BUNDLE_DEPLOYMENT',
  AirgapCookie = 'AIRGAP_COOKIE',
  AirgapDataFlow = 'AIRGAP_DATA_FLOW',
  AirgapTheme = 'AIRGAP_THEME',
  Vendor = 'VENDOR',
  BusinessEntity = 'BUSINESS_ENTITY',
  DataSubCategory = 'DATA_SUB_CATEGORY',
  Identifier = 'IDENTIFIER',
  ProcessingPurposeSubCategory = 'PROCESSING_PURPOSE_SUB_CATEGORY',
  SubDataPoint = 'SUB_DATA_POINT',
  Message = 'MESSAGE',
  PrivacyCenter = 'PRIVACY_CENTER',
  EmailSenderAddress = 'EMAIL_SENDER_ADDRESS',
  Theme = 'THEME',
  DataCollection = 'DATA_COLLECTION',
  AssetFile = 'ASSET_FILE',
  Policy = 'POLICY',
  Experience = 'EXPERIENCE',
  TrackingPurpose = 'TRACKING_PURPOSE',
  Preference = 'PREFERENCE',
  ScannedObject = 'SCANNED_OBJECT',
  UnstructuredSubDataPointRecommendation = 'UNSTRUCTURED_SUB_DATA_POINT_RECOMMENDATION',
  Sombra = 'SOMBRA',
  OrgTcfPurpose = 'ORG_TCF_PURPOSE',
  OrgTcfSpecialFeature = 'ORG_TCF_SPECIAL_FEATURE',
  ConsentService = 'CONSENT_SERVICE',
  AirgapBundleTcfStack = 'AIRGAP_BUNDLE_TCF_STACK',
  AirgapPartition = 'AIRGAP_PARTITION',
  RiskFramework = 'RISK_FRAMEWORK',
  RiskLevel = 'RISK_LEVEL',
  RiskCategory = 'RISK_CATEGORY',
  RiskMatrixColumn = 'RISK_MATRIX_COLUMN',
  RiskMatrixRow = 'RISK_MATRIX_ROW',
  PreferenceOption = 'PREFERENCE_OPTION',
  PreferenceTopic = 'PREFERENCE_TOPIC',
  ConsentWorkflowTrigger = 'CONSENT_WORKFLOW_TRIGGER',
}

/** Audit events for consent manager */
export const CONSENT_AUDIT_EVENTS = [
  AuditEventCode.AirgapBundle,
  AuditEventCode.AirgapBundleDeployment,
  AuditEventCode.AirgapTheme,
  AuditEventCode.AirgapCookie,
  AuditEventCode.AirgapCookiePurpose,
  AuditEventCode.AirgapCookieOwner,
  AuditEventCode.AirgapCookieTeam,
  AuditEventCode.AirgapCookieAttribute,
  AuditEventCode.AirgapDataFlow,
  AuditEventCode.AirgapDataFlowAttribute,
  AuditEventCode.AirgapDataFlowOwner,
  AuditEventCode.AirgapDataFlowPurpose,
  AuditEventCode.AirgapDataFlowTeam,
  AuditEventCode.AirgapPartition,
  AuditEventCode.ConsentService,
  AuditEventCode.Experience,
  AuditEventCode.ExperiencePurpose,
  // This is deprecated - should not be used to generate new events
  // But we keep it because old events were published in this format
  AuditEventCode.AirgapBundleVersion,
  AuditEventCode.OrgTcfPurpose,
  AuditEventCode.OrgTcfSpecialFeature,
  AuditEventCode.OrgTcfPurposePurpose,
  AuditEventCode.OrgTcfSpecialFeaturePurpose,
  AuditEventCode.AirgapBundleTcfStack,
  AuditEventCode.Message,
];

/** Audit events for preference store */
export const PREFERENCE_STORE_AUDIT_EVENTS = [
  AuditEventCode.PreferenceOption,
  AuditEventCode.PreferenceTopic,
  AuditEventCode.ConsentWorkflowTrigger,
];
