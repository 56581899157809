/**
 *  Enum for message properties that can be used for filtering audit events of type Message.
 */
export enum MessageProperties {
  dataCollectionId = 'dataCollectionId',
  dataPointId = 'dataPointId',
  dataSiloId = 'dataSiloId',
  privacyCenterId = 'privacyCenterId',
  identifierId = 'identifierId',
  subjectId = 'subjectId',
  policyId = 'policyId',
}
