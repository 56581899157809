import { Icon, Popover, ReactSelect } from '@main/core-ui';
import { NATIVE_LANGUAGE_NAMES } from '@main/internationalization';
import { LanguageKey } from '@transcend-io/internationalization';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { setLocale } from '../LanguageProvider/slice';
import { LanguageSelectWrapper } from './wrappers';

const SELECT_LANGUAGE_OPTIONS = Object.values(LanguageKey).map((locale) => ({
  label: NATIVE_LANGUAGE_NAMES[locale],
  value: locale,
}));

export const SelectLanguage: React.FC = () => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  return (
    <LanguageSelectWrapper>
      <Popover
        rootClose
        placement="top"
        width="300px"
        contents={
          <ReactSelect
            menuPosition="absolute"
            menuPlacement="top"
            isClearable={false}
            id="select-global-language"
            options={SELECT_LANGUAGE_OPTIONS}
            value={{
              label: NATIVE_LANGUAGE_NAMES[locale as LanguageKey],
              value: locale,
            }}
            onChange={(selection) => {
              if (selection && 'value' in selection) {
                const locale = selection.value as LanguageKey;
                dispatch(setLocale({ locale }));
              }
            }}
          />
        }
      >
        <i>
          <small
            id="logged-out-language-selection"
            style={{ cursor: 'pointer' }}
          >
            <Icon type="language-translate" size={20} />
          </small>
        </i>
      </Popover>
    </LanguageSelectWrapper>
  );
};
