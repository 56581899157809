import { defineMessages } from '@transcend-io/internationalization';

export const dnsRecordTableHeaderMessages = defineMessages(
  'ad-core-components.DnsRecordTable.dnsRecordTableHeader',
  {
    type: {
      defaultMessage: 'Type',
    },
    name: {
      defaultMessage: 'Name',
    },
    value: {
      defaultMessage: 'Value',
    },
  },
);
