import { defineMessages } from '@transcend-io/internationalization';

export const uploadMessages = defineMessages(
  'ad-core-components.Upload.upload',
  {
    error: {
      defaultMessage: '{file} upload failed',
    },
    header: {
      defaultMessage: 'Click or drag file to this area to upload',
    },
    info: {
      defaultMessage: 'Support for a single or bulk upload.',
    },
    singleInfo: {
      defaultMessage: 'Support for a single file',
    },
    success: {
      defaultMessage: '{file} uploaded successfully',
    },
  },
);
export const uploadNewMessages = defineMessages(
  'ad-core-components.Upload.uploadNew',
  {
    info: {
      defaultMessage: 'Select or drag one or more files to upload',
    },
    singleInfo: {
      defaultMessage: 'Select or drag a file to upload',
    },
    upload: {
      defaultMessage: 'Upload',
    },
    addMore: {
      defaultMessage: 'Add More',
    },
    changeFile: {
      defaultMessage: 'Change File',
    },
    clearFiles: {
      defaultMessage: 'Clear Files',
    },
    selectedFiles: {
      defaultMessage:
        '{prettyNum} {num, plural, one {File} other {Files}} Selected',
    },
    fileTooLarge: {
      defaultMessage: 'File is larger than file size limit: {actual} > {limit}',
    },
    unexpectedError: {
      defaultMessage: 'An unexpected error occurred: {error}',
    },
  },
);
