import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import { PrivacyCenterModuleName } from './messages';

const INITIAL_MODIFICATIONS_STATE: Record<PrivacyCenterModuleName, boolean> = {
  privacyCenter: false,
  policies: false,
  messages: false,
  subjects: false,
  requestsProcessedStats: false,
  purposes: false,
};

/**
 * Detect modifications to the privacy center
 *
 * @param options - options
 * @returns Returning
 */
export function useVersionModifications({
  onModified,
  dataReady,
  currentState,
  pendingState,
}: {
  /** Callback for modification changes */
  onModified: (isModified: boolean) => void;
  /** Has all data been loaded? */
  dataReady: boolean;
  /** Current modules */
  currentState: Record<PrivacyCenterModuleName, unknown>;
  /** Updated modules */
  pendingState: Record<PrivacyCenterModuleName, unknown>;
}): {
  /** Is the given tab modified? */
  moduleHasChanges: (tabName: PrivacyCenterModuleName) => boolean;
  /** Has the state been modified? */
  isModified: boolean;
} {
  const [isModified, setIsModified] = useState(false);

  const [modifications, setModifications] = useState<
    Record<PrivacyCenterModuleName, boolean>
  >(INITIAL_MODIFICATIONS_STATE);

  // Check for modifications
  useEffect(() => {
    if (dataReady) {
      setModifications((existing) => {
        const updated = { ...existing };
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(pendingState)) {
          updated[key] = value !== null && !isEqual(value, currentState[key]);
        }
        return updated;
      });
    }
  }, [dataReady, currentState, pendingState]);

  // Track modification changes
  useEffect(() => {
    setIsModified(Object.values(modifications).some((modified) => modified));
  }, [modifications, setIsModified]);

  // Notify when modifications change
  useEffect(() => {
    onModified(isModified);
  }, [isModified, onModified]);

  /** Detect if a module has been modified */
  const moduleHasChanges = useCallback(
    (moduleName: PrivacyCenterModuleName): boolean => modifications[moduleName],
    [modifications],
  );

  return {
    moduleHasChanges,
    isModified,
  };
}
